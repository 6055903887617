import {
    FETCH_STUDENT_CANDIDATE_COHORT_SUCCESS,
    FETCH_STUDENT_COHORT_SUCCESS,
    FILTERS_STUDENT_COHORT,
    FILTERS_STUDENT_COHORT_SAVE,
    STUDENT_CANDIDATE_COHORT_ON_HIDE_MESSAGE,
    STUDENT_CANDIDATE_COHORT_ON_SHOW_MESSAGE,
    STUDENT_COHORT_ON_SHOW_LOADER,
    STUDENT_COHORT_ON_HIDE_LOADER
} from 'constants/ActionTypes'

const INIT_STATE = {
    allStudentCandidateCohort: [],
    allStudentCohort: [],
    alert_message: '',
    show_message_app: false,
    loader: false,
    type_message: '',
    filtros: {
        scholarship_name: [],
        scholarship_status: [],
        state_prospect: [],
        migration_state: [],
        gender: [],
        city_name: [],
        name_cohorts: []
    },
    filtros_save: []
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FILTERS_STUDENT_COHORT: {
            return {
                ...state,
                filtros: {
                    ...state.filtros,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }
        case FILTERS_STUDENT_COHORT_SAVE: {
            return {
                ...state,
                filtros_save: action.payload
            }
        }
        case FETCH_STUDENT_CANDIDATE_COHORT_SUCCESS: {
            return {
                ...state,
                allStudentCandidateCohort: action.payload
            }
        }
        case FETCH_STUDENT_COHORT_SUCCESS: {
            return {
                ...state,
                allStudentCohort: action.payload
            }
        }
        case STUDENT_CANDIDATE_COHORT_ON_SHOW_MESSAGE: {
            return {
                ...state,
                show_message_app: true,
                alert_message: action.payload.alertMessage,
                type_message: action.payload.typeMessage
            }
        }
        case STUDENT_CANDIDATE_COHORT_ON_HIDE_MESSAGE: {
            return {
                ...state,
                alert_message: '',
                show_message_app: false,
                loader: false
            }
        }
        case STUDENT_COHORT_ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case STUDENT_COHORT_ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        default:
            return state
    }
}