import {
    SHOW_LOADER_BASIC_DATA,
    HIDE_LOADER_BASIC_DATA,
    SHOW_MESSAGE_DIALOG_BASIC_DATA,
    HIDE_MESSAGE_DIALOG_BASIC_DATA,
    GET_BY_ID_USER_CV_BASIC_DATA_SUCCESS,
    ON_CHANGE_INPUT_FORM_BASIC_DATA,
    GET_ALL_PHYSICAL_DISABILITY_SUCCESS,
    GET_ALL_SPECIAL_CONDITION_SUCCESS,
    CHECK_ID_DOCUMENT_SUCCESS,
    URL_FILE,
    PATCH_BY_ID_USER_CV_BASIC_DATA_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
    show_message_app: false,
    alert_message: '',
    type_message: '',
    loader: false,
    basicData: {
        document: '',
        url_document: '',
        url_photo: '',
        url_cv_file: '',
        name_document: '',
        name_photo: '',
        name_cv_file: '',
    },
    physicalDisability: [],
    specialConditions: [],
    verifyIdDocStatus: 200,
    verifyUploadStatus: '',
    urlPersonalPhoto: '',
    urlIdentificationDocument: '',
    urlCv: '',
    statePatch: '',
    github_url_default: ''
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_LOADER_BASIC_DATA:
            return {
                ...state,
                loader: true
            }
        case HIDE_LOADER_BASIC_DATA:
            return {
                ...state,
                loader: false
            }
        case SHOW_MESSAGE_DIALOG_BASIC_DATA:
            return {
                ...state,
                show_message_app: true,
                alert_message: action.payload.alertMessage,
                type_message: action.payload.typeMessage
            }
        case HIDE_MESSAGE_DIALOG_BASIC_DATA:
            return {
                ...state,
                show_message_app: false,
                type_message: '',
                alert_message: '',
            }
        case GET_BY_ID_USER_CV_BASIC_DATA_SUCCESS:
            return {
                ...state,
                basicData: action.payload,
                github_url_default: action.payload.github_url
            }
        case ON_CHANGE_INPUT_FORM_BASIC_DATA:
            state.basicData[action.payload.field] = action.payload.value
            return {
                ...state,
            }
        case GET_ALL_PHYSICAL_DISABILITY_SUCCESS:
            return {
                ...state,
                physicalDisability: action.payload
            }
        case GET_ALL_SPECIAL_CONDITION_SUCCESS:
            return {
                ...state,
                specialConditions: action.payload
            }
        case CHECK_ID_DOCUMENT_SUCCESS:
            return {
                ...state,
                verifyIdDocStatus: action.payload
            }
        case URL_FILE:
            const key = 'url'.concat(action.payload.field)
            return {
                ...state,
                [key]: action.payload.publicURL,
                verifyUploadStatus: action.payload.resServer
            }
        case PATCH_BY_ID_USER_CV_BASIC_DATA_SUCCESS:
            return {
                ...state,
                statePatch: action.payload
            }
        default:
            return state
    }
}