import React from "react";
import { Pagination } from "antd";

const Basic = () => {

  return (
    <Pagination defaultCurrent={1} total={1} />
  );
};

export default Basic;