import { GET_LANGS_FETCH, GET_LANGS_SUCCESS } from "../../constants/ActionTypes"




export const getLangsFetch = payload => {
    return {
        type: GET_LANGS_FETCH,
        payload: payload
    }
}

export const getLangsSuccess = payload => {
    return {
        type: GET_LANGS_SUCCESS,
        payload: payload
    }
}