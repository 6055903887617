const CohortMocksDM = [
  {
    id: 0,
    name: 'FrontEnd 8',
    year: '2021',
    cohorte: 'FE-08-2021',
    dm_teams: [
      'smartrecipes',
      'roadconnect',
      'quantec',
      'mentors',
      'mentalapp',
      'gestwork',
      'dailys'
    ]
  },
  {
    id: 1,
    name: 'FrontEnd 9',
    year: '2022',
    cohorte: 'FE-09-2022',
    dm_teams: [
      'Makefit',
      'Low-price-app',
      'Buona-fit',
      'D-Vestir',
      'Traveling',
      'Asistente-prof',
      'Babyhelp'
    ]
  },
  {
    id: 2,
    name: 'Cohorte Conjunta Frontend y Backend',
    cohorte: ' FE-BE-2022',
    year: '2022',
    dm_teams: ['deliveryFood', 'rapidisimo', 'move', 'prestago', 'openHostal']
  },
  {
    id: 3,
    name: 'FrontEnd 11',
    cohorte: 'FE-11-2022',
    year: '2022',
    dm_teams: ['vivi', 'tyzy', 'patitas-happy', 'micampo']
  },
  {
    id: 4,
    name: 'FrontEnd 12',
    cohorte: 'FE-12-2023',
    year: '2023',
    dm_teams: ['d-d-dungeons-and-developers', 'KoalaFit'],
    dm_teams_project_ids: {
      'D&D Dungeons and Developers': 15,
      'KoalaFit': 16
    },
    dm_teams_repo_names: {
      'D&D Dungeons and Developers': 'DandD-dungeons-and-developers',
      'KoalaFit': 'KoalaFit'
    }
  },
  {
    id: 5,
    name: 'Cotrafa Social 01',
    cohorte: 'CS-001',
    year: '2024',
    dm_teams: ['agrocolombia', 'sdh-sports', 'bookquest'],
    dm_teams_project_ids: {
      // 'AgroColombia': 18,
      'SDH-Sports': 19,
      'BookQuest': 20
    },
    dm_teams_repo_names: {
      // 'AgroColombia': 'demo-day-projects-agrocolombia',
      'SDH-Sports': 'demo-day-projects-sdh-sports',
      'BookQuest': 'demo-day-projects-bookquest'
    }
  }
]

export default CohortMocksDM
