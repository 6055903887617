import {
    GET_SINGLE_COURSE,
    FETCH_SINGLE_COURSE_SUCCESS,
    FETCH_CHALLENGES_AND_DELIVERIES,
    FETCH_CHALLENGES_AND_DELIVERIES_SUCCESS,
    COURSE_TAB_CHANGE,
    LOCSTATE_CHANGE
} from "constants/ActionTypes"

export const onGetCourse = (courseList) => {
    return {
        type: GET_SINGLE_COURSE,
        courseList
    }
}

export const fetchSingleCourseSuccess = (courseList) => {
    return {
        type: FETCH_SINGLE_COURSE_SUCCESS,
        payload: courseList
    }
};

export const fetchChallengesAndDeliveries = (payload) => {
    return {
        type: FETCH_CHALLENGES_AND_DELIVERIES,
        payload: payload
    }
}

export const fetchChallengesAndDeliveriesSuccess = (payload) => {
    return {
        type: FETCH_CHALLENGES_AND_DELIVERIES_SUCCESS,
        payload: payload
    }
}

export const changeCourseTab = (payload) => {
    return {
        type: COURSE_TAB_CHANGE,
        payload: payload
    }
}

export const changeDashCourseTab = (payload) => {
    return {
        type: LOCSTATE_CHANGE,
        payload: payload
    }
}
