import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Dashboard from "./dashboard";
import { pingTokenTest } from 'appRedux/actions/Auth'
import { useDispatch } from 'react-redux'

const Main = ({ match }) => {

  const location = useLocation();
  const dispatch = useDispatch()

  /*
    Cada vez que se cambia la ruta se acciona una saga que hace una peticion al backend para verificar el token, 
    Si este token es válido todo sigue igual, si no se cierra sesion. 
  */
  useEffect(() => {
    dispatch(pingTokenTest())
  }, [dispatch, location])
  return (
    <Switch>
      <Route path={`${match.url}/dashboard`} component={Dashboard} />
    </Switch>
  )
};

export default Main;
