import {
  SHOW_LOADER_SUPPLEMENTARY_INFO,
  HIDE_LOADER_SUPPLEMENTARY_INFO,
  SHOW_MESSAGE_DIALOG_SUPPLEMENTARY_INFO,
  HIDE_MESSAGE_DIALOG_SUPPLEMENTARY_INFO,
  ON_CHANGE_BACKGROUND_DATA,
  ON_CHANGE_BACKGROUND_DATA_ALL,
  ON_CHANGE_AVAILABILITY_RESOURCES_TIME,
  ON_CHANGE_AVAILABILITY_RESOURCES_TIME_ALL,
  ON_CHANGE_FAMILY_INFORMATION,
  ON_CHANGE_FAMILY_INFORMATION_ALL,
  FETCH_SUPPLEMENTARY_INFO_SUCCESS,
  SET_BASE_KEY_SCHOLARSHIP_PROSPECT,
  ON_CHANGE_SUPPLEMENTARY_INFO
} from 'constants/ActionTypes'
import { LOCATION_CHANGE } from 'react-router-redux'

const INIT_STATE = {
  show_message_app: false,
  alert_message: '',
  type_message: '',
  loader: false,
  backgroundData: {},
  availabilityResourcesTime: {},
  familyInformation: {},
  allSupplementariesInfo: [],
  supplementaryInfoSingle: {
    background_sim_program_value: null,
    background_sim_program_text: null,
    background_convoc_method: null,
    background_family_programmer_value: null,
    background_family_programmer_text: null,
    background_family_tec_value: null,
    background_family_tec_text: null,
    availability_internet: null,
    availability_computer: null,
    availability_hours: null,
    availability_activities: null,
    family_quantity_core: null,
    family_quantity_core_underage: null,
    family_quantity_role: null,
    family_quantity_depend: null
  },
  baseKey: ''
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {

    //Reducers generales para los componentes de SupplementaryInfo
    case LOCATION_CHANGE:
      return {
        ...INIT_STATE
      }
    case SHOW_LOADER_SUPPLEMENTARY_INFO:
      return {
        ...state,
        loader: true
      }
    case HIDE_LOADER_SUPPLEMENTARY_INFO:
      return {
        ...state,
        loader: false
      }
    case SHOW_MESSAGE_DIALOG_SUPPLEMENTARY_INFO:
      return {
        ...state,
        show_message_app: true,
        alert_message: action.payload.alertMessage,
        type_message: action.payload.typeMessage
      }
    case HIDE_MESSAGE_DIALOG_SUPPLEMENTARY_INFO:
      return {
        ...state,
        show_message_app: false,
        type_message: '',
        alert_message: '',
      }
    case ON_CHANGE_SUPPLEMENTARY_INFO:
      state.supplementaryInfoSingle[action.payload.field] = action.payload.value
      return {
        ...state
      }
    //Reducers para BackgroundData
    case ON_CHANGE_BACKGROUND_DATA:
      state.backgroundData[action.payload.field] = action.payload.value
      return {
        ...state
      }
    case ON_CHANGE_BACKGROUND_DATA_ALL:
      return {
        ...state,
        backgroundData: action.payload
      }

    //Reducers para AvailabilityResourcesTime
    case ON_CHANGE_AVAILABILITY_RESOURCES_TIME:
      state.availabilityResourcesTime[action.payload.field] = action.payload.value
      return {
        ...state
      }
    case ON_CHANGE_AVAILABILITY_RESOURCES_TIME_ALL:
      return {
        ...state,
        availabilityResourcesTime: action.payload
      }

    //Reducers para FamilyInformation
    case ON_CHANGE_FAMILY_INFORMATION:
      state.familyInformation[action.payload.field] = action.payload.value
      return {
        ...state
      }
    case ON_CHANGE_FAMILY_INFORMATION_ALL:
      return {
        ...state,
        familyInformation: action.payload
      }
    case FETCH_SUPPLEMENTARY_INFO_SUCCESS:
      return {
        ...state,
        allSupplementariesInfo: action.payload,
        supplementaryInfoSingle: action.payload[0] !== undefined ? action.payload[0] : state.supplementaryInfoSingle
      }
    case (SET_BASE_KEY_SCHOLARSHIP_PROSPECT): {
      return {
        ...state,
        baseKey: action.payload
      }
    }
    default:
      return state
  }
}