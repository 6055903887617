import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { pingTokenTest } from 'appRedux/actions/Auth'
import { TECHNICAL_MENTOR_ID, PSYCHOSOCIAL_ADVISOR_ID, COMMUNICATIONS_SUPPORT_ID, ACADEMIC_COORDINATOR_ID } from 'constants/Roles'
import ErrorPages from "../errorPages"
import Members from "./Members";
import Scholarships from "./Scholarships"
import GitHubClassroom from "./GitHubClassroom";
import Communications from "./Communications";
import Courses from "./Courses";
import Mentor from "./Mentor";

const Admin = ({ match }) => {

  const location = useLocation();
  const dispatch = useDispatch();

  const { id_role } = useSelector(({ authorization }) => authorization)
  const { user } = useSelector(({ auth }) => auth)

  /*
    Cada vez que se cambia la ruta se acciona una saga que hace una peticion al backend para verificar el token, 
    Si este token es válido todo sigue igual, si no se cierra sesion. 
  */
  useEffect(() => {
    dispatch(pingTokenTest())
  }, [dispatch, location])

  const getRoutes = () => {
    let routes
    if (id_role === TECHNICAL_MENTOR_ID) {
      routes = (
        <Switch>
          <Route path={`${match.url}/mentor`} component={Mentor} />
          <Route path={`${match.url}error-pages`} component={ErrorPages} />
          <Redirect to='/error-pages/error-404' />
        </Switch>
      )
    } else if (id_role === PSYCHOSOCIAL_ADVISOR_ID || id_role === COMMUNICATIONS_SUPPORT_ID) {
      routes = (
        <Switch>
          <Route path={`${match.url}/members`} component={Members} />
          <Route path={`${match.url}error-pages`} component={ErrorPages} />
          <Redirect to='/error-pages/error-404' />
        </Switch>
      )
    } else if (id_role === ACADEMIC_COORDINATOR_ID) {
      routes = (
        <Switch>
          <Route path={`${match.url}/members`} component={Members} />
          <Route path={`${match.url}/gitHubClassroom`} component={GitHubClassroom} />
          <Route path={`${match.url}/courses`} component={Courses} />
          <Route path={`${match.url}error-pages`} component={ErrorPages} />
          <Redirect to='/error-pages/error-404' />
        </Switch>
      )
    } else if (user.admin) {
      routes = (
        <Switch>
          <Route path={`${match.url}/members`} component={Members} />
          <Route path={`${match.url}/scholarships`} component={Scholarships} />
          <Route path={`${match.url}/gitHubClassroom`} component={GitHubClassroom} />
          <Route path={`${match.url}/communications`} component={Communications} />
          <Route path={`${match.url}/courses`} component={Courses} />
          <Route path={`${match.url}/mentor`} component={Mentor} />
        </Switch>
      )
    }
    return routes
  }
  return (id_role || user.admin) && getRoutes()
};

export default Admin;