import { GET_LANGS_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
    langs: []
}


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_LANGS_SUCCESS: {
            return {
                ...state,
                langs: action.payload
            }

        }

        default:
            return state

    }
}