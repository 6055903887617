import {
    REGISTER_PRICES_MODAL_DELIVERY_ADD,
    REGISTER_PRICES_MODAL_DELIVERY_EDIT,
    REGISTER_PRICES_CHANGE,
    REGISTER_PRICES_MODAL_CLEAN,
    ADD_PRICE,
    REGISTER_PRICE_ON_SHOW_LOADER,
    REGISTER_PRICE_ON_HIDE_LOADER,
    REGISTER_PRICE_ON_SHOW_MESSAGE,
    REGISTER_PRICE_ON_HIDE_MESSAGE,
    FETCH_PRICES,
    FETCH_PRICES_SUCCESS,
    DELETE_PRICE,
    EDIT_PRICE,
    REGISTER_PRICES_CHANGE_ALL,
    FILTER_PRICE
} from 'constants/ActionTypes'

export const openOrCloseModalAddRegisterPrice = () => {
    return {
        type: REGISTER_PRICES_MODAL_DELIVERY_ADD
    }
}

export const openOrCloseModalEditRegisterPrice = () => {
    return {
        type: REGISTER_PRICES_MODAL_DELIVERY_EDIT
    }
}

export const cleanModal = () => {
    return {
        type: REGISTER_PRICES_MODAL_CLEAN
    }
}

export const showLoaderPrice = () => {
    return {
        type: REGISTER_PRICE_ON_SHOW_LOADER
    }
}

export const hideLoaderPrice = () => {
    return {
        type: REGISTER_PRICE_ON_HIDE_LOADER
    }
}

export const showMessageDialog = configMessage => {
    return {
        type: REGISTER_PRICE_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}

export const hideMessageDialog = () => {
    return {
        type: REGISTER_PRICE_ON_HIDE_MESSAGE
    }
}

export const fetchPrice = (payload) => {
    return {
        type: FETCH_PRICES,
        payload: payload
    }
}

export const fetchPriceSuccess = (payload) => {
    return {
        type: FETCH_PRICES_SUCCESS,
        payload: payload
    }
}

export const deletePrice = (payload) => {
    return {
        type: DELETE_PRICE,
        payload: payload
    }
}

export const editPrice = (payload) => {
    return {
        type: EDIT_PRICE,
        payload: payload
    }
}

export const changePriceAll = (payload) => {
    return {
        type: REGISTER_PRICES_CHANGE_ALL,
        payload: payload
    }
}

export const changePrice = payload => {
    return {
        type: REGISTER_PRICES_CHANGE,
        payload: payload
    }
}

export const addPrice = (payload) => {
    return {
        type: ADD_PRICE,
        payload: payload
    }
}

export const filterPrice = (payload) => {
    return {
        type: FILTER_PRICE,
        payload: payload
    }
}

export const fetchLoadListPrices = () => {
    return {
        type: FETCH_PRICES
    }
}