// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const LOCSTATE_CHANGE = 'LOCSTATE_CHANGE';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const COMPLETE_USER_REGISTRATION = 'COMPLETE_USER_REGISTRATION';
export const RESET_LOGIN_INIT = 'RESET_LOGIN_INIT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const CHANGE_REGISTER_STATE_SUCCESS = 'CHANGE_REGISTER_STATE_SUCCESS'
export const PING_TOKEN_TEST = 'PING_TOKEN_TEST'

//User
export const GET_USER = 'GET_USER';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER;'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS"
export const FETCH_INFO_TOKEN_CELLPHONE = 'FETCH_INFO_TOKEN_CELLPHONE'
export const RESPONSE_INFO_TOKEN_CELLPHONE = 'RESPONSE_INFO_TOKEN_CELLPHONE'
export const FETCH_VALIDATE_CELLPHONE = 'FETCH_VALIDATE_CELLPHONE'
export const FETCH_VALIDATE_CELLPHONE_SUCCESS = 'FETCH_VALIDATE_CELLPHONE_SUCCESS'
export const FETCH_INFO_TOKEN_EMAIL = 'FETCH_INFO_TOKEN_EMAIL'
export const FETCH_VALIDATE_EMAIL = 'FETCH_VALIDATE_EMAIL'
export const RESPONSE_INFO_EMAIL = 'RESPONSE_INFO_EMAIL'
export const RESPONSE_INFO_TOKEN_EMAIL = 'RESPONSE_INFO_TOKEN_EMAIL'
export const FETCH_VALIDATE_EMAIL_SUCCESS = 'FETCH_VALIDATE_EMAIL_SUCCESS'
export const FETCH_INFO_USER_JWT = 'FETCH_INFO_USER_JWT'
export const RESPONSE_INFO_JWT_TOKEN = 'RESPONSE_INFO_JWT_TOKEN'
export const USER_ON_HIDE_LOADER = 'USER_ON_HIDE_LOADER'
export const USER_ON_SHOW_LOADER = 'USER_ON_SHOW_LOADER'
export const USER_ON_RESEND_EMAIL = 'USER_ON_RESEND_EMAIL'
export const USER_ON_RESEND_CELL_PHONE = 'USER_ON_RESEND_CELL_PHONE'

//Authorization
export const GET_USER_ROLE = 'GET_USER_ROLE';
export const GET_USER_ROLE_SUCCESS = 'GET_USER_ROLE_SUCCESS';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';

//Prospect Preferences 
export const CREATE_PROSPECT_PREFERENCES = 'CREATE_PROSPECT_PREFERENCES';
export const CREATE_PROSPECT_PREFERENCES_SUCCESS = 'CREATE_PROSPECT_PREFERENCES_SUCCESS';
export const GET_PROSPECT_PREFERENCES = 'GET_PROSPECT_PREFERENCES';
export const FETCH_PROSPECT_PREFERENCES_SUCCESS = 'FETCH_PROSPECT_PREFERENCES_SUCCESS';
export const GET_PROSPECT_PREFERENCES_BY_COURSE_ID = 'GET_PROSPECT_PREFERENCES_BY_COURSE_ID';
export const UPDATE_PROSPECT_PREFERENCES = 'UPDATE_PROSPECT_PREFERENCES';
export const UPDATE_PROSPECT_PREFERENCES_SUCCESS = 'UPDATE_PROSPECT_PREFERENCES_SUCCESS';
export const CREATE_PROSPECT_STAGE = 'CREATE_PROSPECT_STAGE';
export const GET_LAST_PROSPECT_PREFERENCES = 'GET_LAST_PROSPECT_PREFERENCES';
export const GET_LAST_PROSPECT_PREFERENCES_SUCCESS = 'GET_LAST_PROSPECT_PREFERENCES_SUCCESS';
export const GET_APPLICATION_OPPORTUNITIES_BY_USER = 'GET_APPLICATION_OPPORTUNITIES_BY_USER';
export const GET_APPLICATION_OPPORTUNITIES_BY_USER_SUCCESS = 'GET_APPLICATION_OPPORTUNITIES_BY_USER_SUCCESS';
export const GET_INFO_ABOUT_COHORT_BY_COURSE_USER = 'GET_INFO_ABOUT_COHORT_BY_COURSE_USER';
export const GET_INFO_ABOUT_COHORT_BY_COURSE_USER_SUCCESS = 'GET_INFO_ABOUT_COHORT_BY_COURSE_AND_USER_SUCCESS';

//Evidences
export const UPDATE_EVIDENCE = 'UPDATE_EVIDENCE;'
export const UPDATE_EVIDENCE_SUCCESS = 'UPDATE_EVIDENCE_SUCCESS';

//Curriculum Vitae
export const SHOW_MESSAGE_DIALOG_CV_USER = 'SHOW_MESSAGE_DIALOG_CV_USER';
export const GET_SINGLE_PROSPECT = 'GET_SINGLE_PROSPECT';
export const FETCH_SINGLE_PROSPECT_SUCCESS = 'FETCH_SINGLE_PROSPECT_SUCCESS';
export const VALIDATE_CV_USER = 'VALIDATE_CV_USER';
export const VALIDATE_CV_USER_SUCCESS = 'VALIDATE_CV_USER_SUCCESS';

//Courses
export const GET_COURSES = "GET_COURSES"
export const CHANGE_TYPE_OF_LIST_OFFERED_COURSES = 'CHANGE_TYPE_OF_LIST_OFFERED_COURSES';
export const FETCH_PREREQUISITE_COURSES = 'FETCH_PREREQUISITE_COURSES';
export const FETCH_PREREQUISITE_COURSES_SUCCESS = 'FETCH_PREREQUISITE_COURSES_SUCCESS';

//Challenge Course
export const CHALLENGE_COURSE_MODAL_DELIVERY_ADD = 'CHALLENGE_COURSE_MODAL_DELIVERY_ADD'
export const CHALLENGE_COURSE_MODAL_DELIVERY_EDIT = 'CHALLENGE_COURSE_MODAL_DELIVERY_EDIT'
export const CHALLENGE_COURSE_LIST_CHALLENGES = 'CHALLENGE_COURSE_LIST_CHALLENGES'
export const CHALLENGE_COURSE_FETCH_LIST_CHALLENGES = 'CHALLENGE_COURSE_FETCH_LIST_CHALLENGES'
export const CHALLENGE_COURSE_CREATE_CHALLENGE = 'CHALLENGE_COURSE_CREATE_CHALLENGE'
export const CHALLENGE_COURSE_CREATE_CHALLENGE_SUCCESS = 'CHALLENGE_COURSE_CREATE_CHALLENGE_SUCCESS'
export const CHALLENGE_COURSE_SET_ADM_PROSPECT_PREFERENCE = 'CHALLENGE_COURSE_SET_ADM_PROSPECT_PREFERENCE'
export const CHALLENGE_COURSE_FETCH_GET_PROSPECT_PREFERENCE = 'CHALLENGE_COURSE_FETCH_GET_PROSPECT_PREFERENCE'
export const CHALLENGE_COURSE_UPDATE_REQUERIMENT_FULLFIMENT = 'CHALLENGE_COURSE_UPDATE_REQUERIMENT_FULLFIMENT'
export const CHALLENGE_COURSE_ON_SHOW_LOADER = 'CHALLENGE_COURSE_ON_SHOW_LOADER'
export const CHALLENGE_COURSE_ON_SHOW_MESSAGE = 'CHALLENGE_COURSE_ON_SHOW_MESSAGE'
export const CHALLENGE_COURSE_ON_HIDE_MESSAGE = 'CHALLENGE_COURSE_ON_HIDE_MESSAGE'
export const CHALLENGE_COURSE_ON_HIDE_LOADER = 'CHALLENGE_COURSE_ON_HIDE_LOADER'
export const CHALLENGE_COURSE_LOAD_LIST = 'CHALLENGE_COURSE_LOAD_LIST'
export const CHALLENGE_COURSE_RELOAD_CHALLENGES_LIST = 'CHALLENGE_COURSE_RELOAD_CHALLENGES_LIST'
export const CHALLENGE_COURSE_SHOW_LOAD = 'CHALLENGE_COURSE_SHOW_LOAD'
export const CHALLENGE_COURSE_HIDE_LOAD = 'CHALLENGE_COURSE_HIDE_LOAD'
export const CHALLENGE_COURSE_RESET_REQUERIMENT_FULLFIMENT = 'CHALLENGE_COURSE_RESET_REQUERIMENT_FULLFIMENT'
export const CHALLENGE_COURSE_DELETE = 'CHALLENGE_COURSE_DELETE'
export const CHALLENGE_COURSE_SUCCESSFUL_DELETE = 'CHALLENGE_COURSE_SUCCESSFUL_DELETE'
export const CHALLENGE_COURSE_REMOVE_CHALLENGE_FROM_LIST = 'CHALLENGE_COURSE_REMOVE_CHALLENGE_FROM_LIST'
export const CHALLENGE_COURSE_LOAD_LIST_FILTER = 'CHALLENGE_COURSE_LOAD_LIST_FILTER'
export const CHALLENGE_COURSE_LOAD_DATA_FROM_REQUERIMENT_FULLFIMENT = 'CHALLENGE_COURSE_LOAD_DATA_FROM_REQUERIMENT_FULLFIMENT'
export const CHALLENGE_COURSE_UPDATE_CHALLENGE = 'CHALLENGE_COURSE_UPDATE_CHALLENGE'
export const CHALLENGE_COURSE_RESET_FORM = 'CHALLENGE_COURSE_RESET_FORM'
export const CHALLENGE_COURSE_NOT_RESET_FORM = 'CHALLENGE_COURSE_NOT_RESET_FORM'

//Single Courses
export const GET_SINGLE_COURSE = "GET_SINGLE_COURSE"
export const FETCH_SINGLE_COURSE_SUCCESS = "FETCH_SINGLE_COURSE_SUCCESS"
export const FETCH_CHALLENGES_AND_DELIVERIES = "FETCH_CHALLENGES_AND_DELIVERIES"
export const FETCH_CHALLENGES_AND_DELIVERIES_SUCCESS = "FETCH_CHALLENGES_AND_DELIVERIES_SUCCESS"
export const COURSE_TAB_CHANGE = "COURSE_TAB_CHANGE"

//Type Messagge
export const SHOW_MESSAGE_APP = 'SHOW_MESSAGE_APP'
export const HIDE_MESSAGE_APP = 'HIDE_MESSAGE_APP'
export const TYPE_MESSAGE_SUCCESS = 'TYPE_MESSAGE_SUCCESS'
export const TYPE_MESSAGE_ERROR = 'TYPE_MESSAGE_ERROR'
export const TYPE_MESSAGE_WARNING = 'TYPE_MESSAGE_WARNING'
export const TYPE_MESSAGE_INFO = 'TYPE_MESSAGE_INFO'
export const ON_SHOW_LOADER_APP = 'ON_SHOW_LOADER_APP'
export const ON_HIDE_LOADER_APP = 'ON_HIDE_LOADER_APP'
export const HIDE_MESSAGE_APP_v1 = 'HIDE_MESSAGE_APP_v1'
export const SHOW_MESSAGE_APP_V1 = 'SHOW_MESSAGE_APP_V1'

//Programming Laguages
export const LANGUAGES_TO_DEVELOP_MODAL_DELIVERY_ADD = 'LANGUAGES_TO_DEVELOP_MODAL_DELIVERY_ADD'
export const LANGUAGES_TO_DEVELOP_MODAL_DELIVERY_EDIT = 'LANGUAGES_TO_DEVELOP_MODAL_DELIVERY_EDIT'
export const GET_LANGUAGES_TO_DEVELOP = 'GET_LANGUAGES_TO_DEVELOP'
export const CREATE_LANGUAGE_TO_DEVELOP = 'CREATE_LANGUAGE_TO_DEVELOP'
export const CREATE_LANGUAGE_TO_DEVELOP_SUCCESS = 'CREATE_LANGUAGE_TO_DEVELOP_SUCCESS'
export const FETCH_LANGUAGES_TO_DEVELOP_SUCCESS = 'FETCH_LANGUAGES_TO_DEVELOP_SUCCESS'
export const FETCH_LANGUAGES_TO_DEVELOP_BY_ID_SUCCESS = 'FETCH_LANGUAGES_TO_DEVELOP_BY_ID_SUCCESS'
export const FETCH_LANGUAGES_TO_DEVELOP_BY_ID = 'FETCH_LANGUAGES_TO_DEVELOP_BY_ID'
export const DELETE_LANGUAGES_TO_DEVELOP = 'DELETE_LANGUAGES_TO_DEVELOP'
export const DELETE_LANGUAGES_TO_DEVELOP_SUCCESS = 'DELETE_LANGUAGES_TO_DEVELOP_SUCCESS'
export const EDIT_LANGUAGE_TO_DEVELOP = 'EDIT_LANGUAGE_TO_DEVELOP'
export const PATCH_LANGUAGE_TO_DEVELOP = 'PATCH_LANGUAGE_TO_DEVELOP'
export const LANGUAGES_TO_DEVELOP_ON_SHOW_LOADER = 'LANGUAGES_TO_DEVELOP_ON_SHOW_LOADER'
export const LANGUAGES_TO_DEVELOP_ON_SHOW_MESSAGE = 'LANGUAGES_TO_DEVELOP_ON_SHOW_MESSAGE'
export const LANGUAGES_TO_DEVELOP_ON_HIDE_MESSAGE = 'LANGUAGES_TO_DEVELOP_ON_HIDE_MESSAGE'
export const LANGUAGES_TO_DEVELOP_ON_HIDE_LOADER = 'LANGUAGES_TO_DEVELOP_ON_HIDE_LOADER'
export const GET_LANGUAGES_TO_DEVELOP_AVAILABLE = 'GET_LANGUAGES_TO_DEVELOP_AVAILABLE'
export const GET_LANGUAGES_TO_DEVELOP_AVAILABLE_SUCCESS = 'GET_LANGUAGES_TO_DEVELOP_AVAILABLE_SUCCESS'


//Countries
export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES'
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'
export const GET_STATES_BY_COUNTRY_ID = 'GET_STATES_BY_COUNTRY_ID'
export const GET_STATES_BY_COUNTRY_ID_SUCCESS = 'GET_STATES_BY_COUNTRY_ID_SUCCESS'
export const GET_CITIES_BY_STATE_ID = 'GET_CITIES_BY_STATE_ID'
export const GET_CITIES_BY_STATE_ID_SUCCESS = 'GET_CITIES_BY_STATE_ID_SUCCESS'
export const GET_CITY_BY_ID = 'GET_CITY_BY_ID'
export const GET_CITY_BY_ID_SUCCESS = 'GET_CITY_BY_ID_SUCCESS'

//Basic Data
export const SHOW_LOADER_BASIC_DATA = 'SHOW_LOADER_BASIC_DATA'
export const HIDE_LOADER_BASIC_DATA = 'HIDE_LOADER_BASIC_DATA'
export const SHOW_MESSAGE_DIALOG_BASIC_DATA = 'SHOW_MESSAGE_DIALOG_BASIC_DATA'
export const HIDE_MESSAGE_DIALOG_BASIC_DATA = 'HIDE_MESSAGE_DIALOG_BASIC_DATA'
export const GET_ALL_PHYSICAL_DISABILITY = 'GET_ALL_PHYSICAL_DISABILITY'
export const GET_ALL_PHYSICAL_DISABILITY_SUCCESS = 'GET_ALL_PHYSICAL_DISABILITY_SUCCESS'
export const GET_ALL_SPECIAL_CONDITION = 'GET_ALL_SPECIAL_CONDITION'
export const GET_ALL_SPECIAL_CONDITION_SUCCESS = 'GET_ALL_SPECIAL_CONDITION_SUCCESS'
export const GET_BY_ID_USER_CV_BASIC_DATA = 'GET_BY_ID_USER_CV_BASIC_DATA'
export const GET_BY_ID_USER_CV_BASIC_DATA_SUCCESS = 'GET_BY_ID_USER_CV_BASIC_DATA_SUCCESS'
export const PATCH_BY_ID_USER_CV_BASIC_DATA = 'PATCH_BY_ID_USER_CV_BASIC_DATA'
export const PATCH_BY_ID_USER_CV_BASIC_DATA_SUCCESS = 'PATCH_BY_ID_USER_CV_BASIC_DATA_SUCCESS'
export const ON_CHANGE_INPUT_FORM_BASIC_DATA = 'ON_CHANGE_INPUT_FORM_BASIC_DATA'
export const UPLOAD_FILES = 'UPLOAD_FILES'
export const URL_FILE = 'URL_FILE'
export const CHECK_ID_DOCUMENT = 'CHECK_ID_DOCUMENT'
export const CHECK_ID_DOCUMENT_SUCCESS = 'CHECK_ID_DOCUMENT_SUCCESS'
export const CV_BASIC_DATA_ON_SHOW_MESSAGE = 'CV_BASIC_DATA_ON_SHOW_MESSAGE'

//Languages
export const CREATE_LANGUAGE = 'CREATE_LANGUAGE';
export const CREATE_LANGUAGE_SUCCESS = 'CREATE_LANGUAGE_SUCCESS';
export const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const LANGUAGES_MODAL_DELIVERY_ADD = 'LANGUAGES_MODAL_DELIVERY_ADD';
export const LANGUAGES_MODAL_DELIVERY_EDIT = 'LANGUAGES_MODAL_DELIVERY_EDIT';
export const FETCH_LANGUAGES_BY_ID = 'FETCH_LANGUAGES_BY_ID';
export const FETCH_LANGUAGES_BY_ID_SUCCESS = 'FETCH_LANGUAGES_BY_ID_SUCCESS';
export const DELETE_LANGUAGES = 'DELETE_LANGUAGES';
export const DELETE_LANGUAGES_SUCCESS = 'DELETE_LANGUAGES_SUCCESS';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_ALL_LANGUAGE = 'CHANGE_ALL_LANGUAGE';
export const CLEAN_MODAL = 'CLEAN_MODAL';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE'
export const UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS';
export const LANGUAGES_ON_SHOW_LOADER = 'LANGUAGES_ON_SHOW_LOADER';
export const LANGUAGES_ON_HIDE_LOADER = 'LANGUAGES_ON_HIDE_LOADER';
export const LANGUAGES_ON_SHOW_MESSAGE = 'LANGUAGES_ON_SHOW_MESSAGE';
export const LANGUAGES_ON_HIDE_MESSAGE = 'LANGUGES_ON_HIDE_MESSAGE';

//Academic Info
export const SHOW_LOADER_ACADEMIC_INFO = 'SHOW_LOADER_ACADEMIC_INFO'
export const HIDE_LOADER_ACADEMIC_INFO = 'HIDE_LOADER_ACADEMIC_INFO'
export const SHOW_MESSAGE_DIALOG_ACADEMIC_INFO = 'SHOW_MESSAGE_DIALOG_ACADEMIC_INFO'
export const HIDE_MESSAGE_DIALOG_ACADEMIC_INFO = 'HIDE_MESSAGE_DIALOG_ACADEMIC_INFO'
export const CREATE_ACADEMIC_INFO = 'CREATE_ACADEMIC_INFO'
export const EDIT_ACADEMIC_INFO = 'EDIT_ACADEMIC_INFO'
export const DELETE_ACADEMIC_INFO = 'DELETE_ACADEMIC_INFO'
export const ON_CHANGE_HIGH_SCHOOL = 'ON_CHANGE_HIGH_SCHOOL'
export const ACTION_TAKEN_HIGH_SCHOOL = 'ACTION_TAKEN_HIGH_SCHOOL'
export const GET_HIGH_SCHOOL = 'GET_HIGH_SCHOOL'
export const GET_HIGH_SCHOOL_SUCCESS = 'GET_HIGH_SCHOOL_SUCCESS'
export const ACADEMIC_INFO_MODAL_DELIVERY_ADD = 'ACADEMIC_INFO_MODAL_DELIVERY_ADD'
export const ACADEMIC_INFO_MODAL_DELIVERY_EDIT = 'ACADEMIC_INFO_MODAL_DELIVERY_EDIT'
export const ON_CHANGE_COLLEGE_EDUCATION = 'ON_CHANGE_COLLEGE_EDUCATION'
export const RESET_COLLEGE_EDUCATION = 'RESET_COLLEGE_EDUCATION'
export const GET_COLLEGE_EDUCATION = 'GET_COLLEGE_EDUCATION'
export const GET_COLLEGE_EDUCATION_SUCCESS = 'GET_COLLEGE_EDUCATION_SUCCESS'
export const SET_COLLEGE_EDUCATION_SINGLE = 'SET_COLLEGE_EDUCATION_SINGLE'
export const COURSES_MODAL_DELIVERY_ADD = 'COURSES_MODAL_DELIVERY_ADD'
export const COURSES_MODAL_DELIVERY_EDIT = 'COURSES_MODAL_DELIVERY_EDIT'
export const ON_CHANGE_COMPLEMENTARY_COURSES = 'ON_CHANGE_COMPLEMENTARY_COURSES'
export const RESET_COMPLEMENTARY_COURSES = 'RESET_COMPLEMENTARY_COURSES'
export const GET_COMPLEMENTARY_COURSES = 'GET_COMPLEMENTARY_COURSES'
export const GET_COMPLEMENTARY_COURSES_SUCCESS = 'GET_COMPLEMENTARY_COURSES_SUCCESS'
export const SET_COMPLEMENTARY_COURSES_SINGLE = 'SET_COMPLEMENTARY_COURSES_SINGLE'
export const GET_EDUCATION_LEVEL = 'GET_EDUCATION_LEVEL'
export const GET_EDUCATION_LEVEL_SUCCESS = 'GET_EDUCATION_LEVEL_SUCCESS'
//Work Experience
export const WORK_EXPERIENCE_MODAL_DELIVERY_ADD = 'WORK_EXPERIENCE_MODAL_DELIVERY_ADD';
export const WORK_EXPERIENCE_MODAL_DELIVERY_EDIT = 'WORK_EXPERIENCE_MODAL_DELIVERY_EDIT';
export const WORK_EXPERIENCE_CHANGE = 'WORK_EXPERIENCE_CHANGE';
export const ADD_WORK_EXPERIENCE = 'ADD_WORK_EXPERIENCE';
export const WORK_EXPERIENCE_MODAL_CLEAN = 'WORK_EXPERIENCE_MODAL_CLEAN';
export const WORK_EXPERIENCE_ON_SHOW_LOADER = 'WORK_EXPERIENCE_ON_SHOW_LOADER';
export const WORK_EXPERIENCE_ON_HIDE_LOADER = 'WORK_EXPERIENCE_ON_HIDE_LOADER';
export const WORK_EXPERIENCE_ON_SHOW_MESSAGE = 'WORK_EXPERIENCE_ON_SHOW_MESSAGE';
export const WORK_EXPERIENCE_ON_HIDE_MESSAGE = 'WORK_EXPERIENCE_ON_HIDE_MESSAGE';
export const FETCH_WORK_EXPERIENCES = 'FETCH_WORK_EXPERIENCES';
export const FETCH_WORK_EXPERIENCES_SUCCESS = 'FETCH_WORK_EXPERIENCES_SUCCESS';
export const WORK_EXPERIENCE_CHANGE_ALL = 'WORK_EXPERIENCE_CHANGE_ALL';
export const DELETE_WORK_EXPERIENCE = 'DELETE_WORK_EXPERIENCE';
export const UPDATE_WORK_EXPERIENCE = 'UPDATE_WORK_EXPERIENCE';

//Register Sponsors
export const REGISTER_SPONSORS_MODAL_DELIVERY_ADD = 'REGISTER_SPONSORS_MODAL_DELIVERY_ADD';
export const REGISTER_SPONSORS_MODAL_DELIVERY_EDIT = 'REGISTER_SPONSORS_MODAL_DELIVERY_EDIT';
export const REGISTER_SPONSORS_CHANGE = 'REGISTER_SPONSORS_CHANGE';
export const REGISTER_SPONSORS_MODAL_CLEAN = 'REGISTER_SPONSORS_MODAL_CLEAN';
export const ADD_SPONSOR = 'ADD_SPONSOR';
export const ADD_SPONSOR_SUCCESS = 'ADD_SPONSOR_SUCCESS';
export const FETCH_SPONSORS = 'FETCH_SPONSOR'
export const DELETE_SPONSOR = 'DELETE_SPONSOR';
export const EDIT_SPONSOR = 'EDIT_SPONSOR'
export const REGISTER_SPONSOR_ON_SHOW_LOADER = 'REGISTER_SPONSOR_ON_SHOW_LOADER';
export const REGISTER_SPONSOR_ON_HIDE_LOADER = 'REGISTER_SPONSOR_ON_HIDE_LOADER';
export const REGISTER_SPONSOR_ON_SHOW_MESSAGE = 'REGISTER_SPONSOR_ON_SHOW_MESSAGE';
export const REGISTER_SPONSOR_ON_HIDE_MESSAGE = 'REGISTER_SPONSOR_ON_HIDE_MESSAGE';
export const REGISTER_SPONSORS_CHANGE_ALL = 'REGISTER_SPONSORS_CHANGE_ALL';
export const GET_SPONSORS_SCHOLARSHIP = 'GET_SPONSORS_SCHOLARSHIP';
export const FETCH_SPONSORS_SUCCESS = 'FETCH_SPONSORS_SUCCESS';
export const FILTER_SPONSOR = 'FILTER_SPONSOR';

//RegisterScholarships
export const REGISTER_SCHOLARSHIPS_MODAL_DELIVERY_ADD = ' REGISTER_SCHOLARSHIPS_MODAL_DELIVERY_ADD';
export const REGISTER_SCHOLARSHIPS_CHANGE = ' REGISTER_SCHOLARSHIPS_CHANGE';
export const ADD_SCHOLARSHIP = 'ADD_SCHOLARSHIP';
export const FETCH_SCHOLARSHIP_SUCCESS = 'FETCH_SCHOLARSHIP_SUCCESS';
export const REGISTER_SCHOLARSHIP_ON_HIDE_LOADER = 'REGISTER_SCHOLARSHIP_ON_HIDE_LOADER';
export const REGISTER_SCHOLARSHIP_ON_SHOW_MESSAGE = 'REGISTER_SCHOLARSHIP_ON_SHOW_MESSAGE';
export const REGISTER_SCHOLARSHIP_SEARCH_CITY = 'REGISTER_SCHOLARSHIP_SEARCH_CITY';
export const REGISTER_SCHOLARSHIP_SEARCH_CITY_SUCCESS = 'REGISTER_SCHOLARSHIP_SEARCH_CITY_SUCCESS';
export const REGISTER_SCHOLARSHIP_ON_SHOW_LOADER = ' REGISTER_SCHOLARSHIP_ON_SHOW_LOADER';
export const REGISTER_SCHOLARSHIP_ON_HIDE_MESSAGE = 'REGISTER_SCHOLARSHIP_ON_HIDE_MESSAGE';
export const FETCH_SCHOLARSHIPS = 'FETCH_SCHOLARSHIPS';
export const REGISTER_SCHOLARSHIPS_MODAL_DELIVERY_EDIT = 'REGISTER_SCHOLARSHIPS_MODAL_DELIVERY_EDIT'
export const REGISTER_SCHOLARSHIPS_MODAL_CLEAN = 'REGISTER_SCHOLARSHIPS_MODAL_CLEAN'
export const DELETE_SCHOLARSHIP = 'DELETE_SCHOLARSHIP'
export const EDIT_SCHOLARSHIP = 'EDIT_SCHOLARSHIP'
export const REGISTER_SCHOLARSHIPS_CHANGE_ALL = 'REGISTER_SCHOLARSHIPS_CHANGE_ALL'
export const VALIDATE_SCHOLARSHIP_STAGE = 'VALIDATE_SCHOLARSHIP_STAGE'
export const VALIDATE_SCHOLARSHIP_STAGE_SUCCESS = 'VALIDATE_SCHOLARSHIP_STAGE_SUCCESS'

//Registrar préstamos
export const REGISTER_SPONSORSHIPS_MODAL_DELIVERY_ADD = ' REGISTER_SPONSORSHIPS_MODAL_DELIVERY_ADD';
export const REGISTER_SPONSORSHIPS_CHANGE = ' REGISTER_SPONSORSHIPS_CHANGE';
export const ADD_SPONSORSHIP = 'ADD_SPONSORSHIP';
export const FETCH_SPONSORSHIP_SUCCESS = 'FETCH_SPONSORSHIP_SUCCESS';
export const REGISTER_SPONSORSHIP_ON_HIDE_LOADER = 'REGISTER_SPONSORSHIP_ON_HIDE_LOADER';
export const REGISTER_SPONSORSHIP_ON_SHOW_MESSAGE = 'REGISTER_SPONSORSHIP_ON_SHOW_MESSAGE';
export const REGISTER_SPONSORSHIPS_CHANGE_ALL = 'REGISTER_SPONSORSHIPS_CHANGE_ALL';
export const REGISTER_SPONSORSHIPS_MODAL_DELIVERY_EDIT = 'REGISTER_SPONSORSHIPS_MODAL_DELIVERY_EDIT';
export const REGISTER_SPONSORSHIPS_MODAL_CLEAN = 'REGISTER_SPONSORSHIPS_MODAL_CLEAN'
export const DELETE_SPONSORSHIP = 'DELETE_SPONSORSHIP'
export const EDIT_SPONSORSHIP = 'EDIT_SPONSORSHIP'
export const REGISTER_SPONSORSHIP_ON_SHOW_LOADER = ' REGISTER_SPONSORSHIP_ON_SHOW_LOADER';
export const REGISTER_SPONSORSHIP_ON_HIDE_MESSAGE = 'REGISTER_SPONSORSHIP_ON_HIDE_MESSAGE'
export const FETCH_SPONSORSHIPS = 'FETCH_SPONSORSHIPS';
export const FETCH_VALID_USER_SPONSORSHIPS = 'FETCH_VALID_USER_SPONSORSHIPS';
export const REGISTER_SPONSORSHIP_SEARCH_CITY = 'REGISTER_SPONSORSHIP_SEARCH_CITY';
export const REGISTER_SPONSORSHIP_SEARCH_CITY_SUCCESS = 'REGISTER_SPONSORSHIP_SEARCH_CITY_SUCCESS';
export const GET_SPONSORS_SPONSORSHIPS = 'GET_SPONSORS_SPONSORSHIPS'
//Grid
export const GRID_ON_SHOW_LOADER = 'GRID_ON_SHOW_LOADER'
export const GRID_ON_HIDE_LOADER = 'GRID_ON_HIDE_LOADER'
export const GRID_GET_DATA = 'GRID_GET_DATA'
export const GRID_LOAD_DATA = 'GRID_LOAD_DATA'

//Curriculum Vitae 
export const SET_BASE_KEY_CURRICULUM_VITAE = 'SET_BASE_KEY_CURRICULUM_VITAE'

//ScholarshipProspect
export const FETCH_SCHOLARSHIP_PROSPECT = 'FETCH_SCHOLARSHIP_PROSPECT'
export const FETCH_SCHOLARSHIP_PROSPECT_SUCCESS = 'FETCH_SCHOLARSHIP_PROSPECT_SUCCESS'
export const SCHOLARSHIP_PROSPECT_ON_SHOW_LOADER = 'SCHOLARSHIP_PROSPECT_ON_SHOW_LOADER';
export const SCHOLARSHIP_PROSPECT_ON_HIDE_LOADER = 'SCHOLARSHIP_PROSPECT_ON_HIDE_LOADER';
export const SCHOLARSHIP_PROSPECT_ON_SHOW_MESSAGE = 'SCHOLARSHIP_PROSPECT_ON_SHOW_MESSAGE';
export const SCHOLARSHIP_PROSPECT_ON_HIDE_MESSAGE = 'SCHOLARSHIP_PROSPECT_ON_HIDE_MESSAGE';
export const SCHOLARSHIP_PROSPECT_VINCULATION_STATUS = 'SCHOLARSHIP_PROSPECT_VINCULATION_STATUS';
export const SCHOLARSHIP_PROSPECT_VINCULATION_STATUS_SUCCESS = 'SCHOLARSHIP_PROSPECT_VINCULATION_STATUS_SUCCESS';
export const SCHOLARSHIP_PROSPECT_MODAL = 'SCHOLARSHIP_PROSPECT_MODAL';
export const SCHOLARSHIP_PROSPECT_MODAL_DATA = 'SCHOLARSHIP_PROSPECT_MODAL_DATA';
export const FETCH_SCHOLARSHIP_HOLDERS = 'FETCH_SCHOLARSHIP_HOLDERS';
export const FETCH_SCHOLARSHIP_HOLDERS_SUCCESS = 'FETCH_SCHOLARSHIP_HOLDERS_SUCCESS';
export const SCHOLARSHIP_PROSPECT_HOLDER_SINGLE = 'SCHOLARSHIP_PROSPECT_HOLDER_SINGLE';
export const SCHOLARSHIP_PROSPECT_HOLDER_SINGLE_FIELD = 'SCHOLARSHIP_PROSPECT_HOLDER_SINGLE_FIELD';
export const SCHOLARSHIP_PROSPECT_ACTIVE_TAB = 'SCHOLARSHIP_PROSPECT_ACTIVE_TAB';
export const CREATE_SCHOLARSHIP_HOLDERS = 'CREATE_SCHOLARSHIP_HOLDERS';
export const SET_BASE_KEY_SCHOLARSHIP_PROSPECT = 'SET_BASE_KEY_SCHOLARSHIP_PROSPECT'
export const UNPOSTULATE_SCHOLARSHIP = 'UNPOSTULATE_SCHOLARSHIP';
export const REAPPLY_SCHOLARSHIP = 'REAPPLY_SCHOLARSHIP';

//SponsorshipProspect
export const FETCH_SPONSORSHIP_PROSPECT = 'FETCH_SPONSORSHIP_PROSPECT';
export const FETCH_SPONSORSHIP_PROSPECT_SUCCESS = 'FETCH_SPONSORSHIP_PROSPECT_SUCCESS';
export const SPONSORSHIP_PROSPECT_ON_SHOW_LOADER = 'SPONSORSHIP_PROSPECT_ON_SHOW_LOADER';
export const SPONSORSHIP_PROSPECT_ON_HIDE_LOADER = 'SPONSORSHIP_PROSPECT_ON_HIDE_LOADER';
export const SPONSORSHIP_PROSPECT_ON_SHOW_MESSAGE = 'SPONSORSHIP_PROSPECT_ON_SHOW_MESSAGE';
export const SPONSORSHIP_PROSPECT_ON_HIDE_MESSAGE = 'SPONSORSHIP_PROSPECT_ON_HIDE_MESSAGE';
export const SPONSORSHIP_PROSPECT_MODAL = 'SPONSORSHIP_PROSPECT_MODAL';
export const SPONSORSHIP_PROSPECT_MODAL_DATA = 'SPONSORSHIP_PROSPECT_MODAL_DATA';
export const CREATE_SPONSORSHIP_HOLDERS = 'CREATE_SPONSORSHIP_HOLDERS';
export const UNPOSTULATE_SPONSORSHIP = 'UNPOSTULATE_SPONSORSHIP';
export const REAPPLY_SPONSORSHIP = 'REAPPLY_SPONSORSHIP';
export const FETCH_VALID_USER_SPONSORSHIPS_SUCCESS = 'FETCH_VALID_USER_SPONSORSHIPS_SUCCESS';
export const FETCH_SPONSORSHIP_APPLICATION_BY_USER = 'FETCH_SPONSORSHIP_APPLICATION_BY_USER';
export const FETCH_SPONSORSHIP_APPLICATION_BY_USER_SUCCESS = 'FETCH_SPONSORSHIP_APPLICATION_BY_USER_SUCCESS';
export const FETCH_SPONSORSHIP_VALIDATION_COHORT_SCHOLARSHIP = 'FETCH_SPONSORSHIP_VALIDATION_COHORT_SCHOLARSHIP';
export const FETCH_SPONSORSHIP_HISTORY = 'FETCH_SPONSORSHIP_HISTORY';
export const FETCH_SPONSORSHIP_HISTORY_SUCCESS = 'FETCH_SPONSORSHIP_HISTORY_SUCCESS';
export const UNPOSTULATE_SPONSORSHIP_SUCCESS = 'UNPOSTULATE_SPONSORSHIP_SUCCESS';
export const FETCH_SPONSORSHIP_VALIDATION_COHORT_SCHOLARSHIP_SUCCESS = 'FETCH_SPONSORSHIP_VALIDATION_COHORT_SCHOLARSHIP_SUCCESS';
export const SPONSORSHIP_EVALUATION_CHANGE = 'SPONSORSHIP_EVALUATION_CHANGE';

//GitHubClassroom
export const GET_USER_DATA_GITHUB_CLASSROOM = 'GET_USER_DATA_GITHUB_CLASSROOM';
export const GET_ISSUES_GITHUB_CLASSROOM = 'GET_ISSUES_GITHUB_CLASSROOM';
export const GET_REPOSITORIES_GITHUB_CLASSROOM = 'GET_REPOSITORIES_GITHUB_CLASSROOM';
export const GET_USER_DATA_GITHUB_CLASSROOM_SUCCESS = 'GET_USER_DATA_GITHUB_CLASSROOM_SUCCESS';
export const GET_ISSUES_GITHUB_CLASSROOM_SUCCESS = 'GET_ISSUES_GITHUB_CLASSROOM_SUCCESS';
export const GET_REPOSITORIES_GITHUB_CLASSROOM_SUCCESS = 'GET_REPOSITORIES_GITHUB_CLASSROOM_SUCCESS';

//SupplementaryInfo 
export const SHOW_LOADER_SUPPLEMENTARY_INFO = 'SHOW_LOADER_SUPPLEMENTARY_INFO'
export const HIDE_LOADER_SUPPLEMENTARY_INFO = 'HIDE_LOADER_SUPPLEMENTARY_INFO'
export const SHOW_MESSAGE_DIALOG_SUPPLEMENTARY_INFO = 'SHOW_MESSAGE_DIALOG_SUPPLEMENTARY_INFO'
export const HIDE_MESSAGE_DIALOG_SUPPLEMENTARY_INFO = 'HIDE_MESSAGE_DIALOG_SUPPLEMENTARY_INFO'
export const CREATE_SUPPLEMENTARY_INFO = 'CREATE_SUPPLEMENTARY_INFO'
export const ON_CHANGE_BACKGROUND_DATA = 'ON_CHANGE_BACKGROUND_DATA'
export const ON_CHANGE_BACKGROUND_DATA_ALL = 'ON_CHANGE_BACKGROUND_DATA_ALL'
export const ON_CHANGE_AVAILABILITY_RESOURCES_TIME = 'ON_CHANGE_AVAILABILITY_RESOURCES_TIME'
export const ON_CHANGE_AVAILABILITY_RESOURCES_TIME_ALL = 'ON_CHANGE_AVAILABILITY_RESOURCES_TIME_ALL'
export const ON_CHANGE_FAMILY_INFORMATION = 'ON_CHANGE_FAMILY_INFORMATION'
export const ON_CHANGE_FAMILY_INFORMATION_ALL = 'ON_CHANGE_FAMILY_INFORMATION_ALL'
export const FETCH_SUPPLEMENTARY_INFO = 'FETCH_SUPPLEMENTARY_INFO';
export const FETCH_SUPPLEMENTARY_INFO_SUCCESS = 'FETCH_SUPPLEMENTARY_INFO_SUCCESS';
export const UPDATE_SUPPLEMENTARY_INFO = 'UPDATE_SUPPLEMENTARY_INFO';
export const ON_CHANGE_SUPPLEMENTARY_INFO = 'ON_CHANGE_SUPPLEMENTARY_INFO';

//PreScholarshipEvaluation
export const GET_BY_ID_USER_CV = 'GET_BY_ID_USER_CV'
export const GET_BY_ID_USER_CV_SUCCESS = 'GET_BY_ID_USER_CV_SUCCESS'
export const EDIT_PRE_SCHOLARSHIP_EVALUATION = 'EDIT_PRE_SCHOLARSHIP_EVALUATION'
export const PRE_SCHOLARSHIP_EVALUATION_ON_SHOW_LOADER = 'PRE_SCHOLARSHIP_EVALUATION_ON_SHOW_LOADER'
export const PRE_SCHOLARSHIP_EVALUATION_ON_HIDE_LOADER = 'PRE_SCHOLARSHIP_EVALUATION_ON_HIDE_LOADER'
export const PRE_SCHOLARSHIP_EVALUATION_ON_SHOW_MESSAGE = 'PRE_SCHOLARSHIP_EVALUATION_ON_SHOW_MESSAGE'
export const PRE_SCHOLARSHIP_EVALUATION_ON_HIDE_MESSAGE = 'PRE_SCHOLARSHIP_EVALUATION_ON_HIDE_MESSAGE'
export const PRE_SCHOLARSHIP_EVALUATION_CHANGE = 'PRE_SCHOLARSHIP_EVALUATION_CHANGE'
export const EDIT_PRE_SCHOLARSHIP_EVALUATION_INTERN_REVIEW = 'EDIT_PRE_SCHOLARSHIP_EVALUATION_INTERN_INTERVIEW'

//RegisterCourse
export const REGISTER_COURSES_MODAL_DELIVERY_ADD = 'REGISTER_COURSES_MODAL_DELIVERY_ADD'
export const REGISTER_COURSES_MODAL_DELIVERY_EDIT = 'REGISTER_COURSES_MODAL_DELIVERY_EDIT'
export const REGISTER_COURSES_MODAL_CLEAN = 'REGISTER_COURSES_MODAL_CLEAN'
export const REGISTER_COURSE_ON_SHOW_LOADER = 'REGISTER_COURSE_ON_SHOW_LOADER'
export const REGISTER_COURSE_ON_HIDE_LOADER = 'REGISTER_COURSE_ON_HIDE_LOADER'
export const REGISTER_COURSE_ON_SHOW_MESSAGE = 'REGISTER_COURSE_ON_SHOW_MESSAGE'
export const REGISTER_COURSE_ON_HIDE_MESSAGE = 'REGISTER_COURSE_ON_HIDE_MESSAGE'
export const REGISTER_COURSES_CHANGE = 'REGISTER_COURSES_CHANGE'
export const REGISTER_COURSES_CHANGE_ALL = 'REGISTER_COURSES_CHANGE_ALL'
export const ADD_COURSE = 'ADD_COURSE'
export const DELETE_COURSE = 'DELETE_COURSE'
export const EDIT_COURSE = 'EDIT_COURSE'
export const FETCH_COURSES = 'FETCH_COURSES'
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS'
export const ADD_RESOURCE = 'ADD_RESOURCE'
export const SINGLE_RESOURCE_CHANGE = 'SINGLE_RESOURCE_CHANGE'
export const REGISTER_RESOURCE_MODAL_CLEAN = 'REGISTER_RESOURCE_MODAL_CLEAN'

//RegisterRequirement
export const REGISTER_REQUIREMENTS_MODAL_DELIVERY_ADD = 'REGISTER_REQUIREMENTS_MODAL_DELIVERY_ADD'
export const REGISTER_REQUIREMENTS_MODAL_DELIVERY_EDIT = 'REGISTER_REQUIREMENTS_MODAL_DELIVERY_EDIT'
export const REGISTER_REQUIREMENTS_MODAL_CLEAN = 'REGISTER_REQUIREMENTS_MODAL_CLEAN'
export const REGISTER_DETAILED_REQUIREMENTS_MODAL_CLEAN = ' REGISTER_DETAILED_REQUIREMENTS_MODAL_CLEAN'
export const REGISTER_REQUIREMENT_ON_SHOW_LOADER = 'REGISTER_REQUIREMENT_ON_SHOW_LOADER'
export const REGISTER_REQUIREMENT_ON_HIDE_LOADER = 'REGISTER_REQUIREMENT_ON_HIDE_LOADER'
export const REGISTER_REQUIREMENT_ON_SHOW_MESSAGE = 'REGISTER_REQUIREMENT_ON_SHOW_MESSAGE'
export const REGISTER_REQUIREMENT_ON_HIDE_MESSAGE = 'REGISTER_REQUIREMENT_ON_HIDE_MESSAGE'
export const REGISTER_REQUIREMENTS_CHANGE = 'REGISTER_REQUIREMENTS_CHANGE'
export const REGISTER_REQUIREMENTS_CHANGE_ALL = 'REGISTER_REQUIREMENTS_CHANGE_ALL'
export const ADD_REQUIREMENT = 'ADD_REQUIREMENT'
export const DELETE_REQUIREMENT = 'DELETE_REQUIREMENT'
export const EDIT_REQUIREMENT = 'EDIT_REQUIREMENT'
export const FETCH_REQUIREMENTS = 'FETCH_REQUIREMENTS'
export const FETCH_REQUIREMENTS_SUCCESS = 'FETCH_REQUIREMENTS_SUCCESS'
export const FILTER_REQUIREMENT = 'FILTER_REQUIREMENT'
export const ADD_REQUISITE = 'ADD_REQUISITE'
export const SINGLE_REQUISITE_CHANGE = 'SINGLE_REQUISITE_CHANGE'

//RegisterCohorts
export const REGISTER_COHORTS_MODAL_DELIVERY_ADD = 'REGISTER_COHORTS_MODAL_DELIVERY_ADD'
export const REGISTER_COHORTS_MODAL_DELIVERY_EDIT = 'REGISTER_COHORTS_MODAL_DELIVERY_EDIT'
export const REGISTER_COHORTS_MODAL_CLEAN = 'REGISTER_COHORTS_MODAL_CLEAN'
export const REGISTER_COHORT_ON_SHOW_LOADER = 'REGISTER_COHORT_ON_SHOW_LOADER'
export const REGISTER_COHORT_ON_HIDE_LOADER = 'REGISTER_COHORT_ON_HIDE_LOADER'
export const REGISTER_COHORT_ON_SHOW_MESSAGE = 'REGISTER_COHORT_ON_SHOW_MESSAGE'
export const REGISTER_COHORT_ON_HIDE_MESSAGE = 'REGISTER_COHORT_ON_HIDE_MESSAGE'
export const REGISTER_COHORTS_CHANGE = 'REGISTER_COHORTS_CHANGE'
export const REGISTER_COHORTS_CHANGE_ALL = 'REGISTER_COHORTS_CHANGE_ALL'
export const ADD_COHORT = 'ADD_COHORT'
export const DELETE_COHORT = 'DELETE_COHORT'
export const EDIT_COHORT = 'EDIT_COHORT'
export const FETCH_COHORTS = 'FETCH_COHORTS'
export const FETCH_COHORTS_SUCCESS = 'FETCH_COHORTS_SUCCESS'
export const FILTER_COHORT = 'FILTER_COHORT'

//RegisterPrices
export const REGISTER_PRICES_MODAL_DELIVERY_ADD = 'REGISTER_PRICES_MODAL_DELIVERY_ADD'
export const REGISTER_PRICES_MODAL_DELIVERY_EDIT = 'REGISTER_PRICES_MODAL_DELIVERY_EDIT'
export const REGISTER_PRICES_MODAL_CLEAN = 'REGISTER_PRICES_MODAL_CLEAN'
export const REGISTER_PRICE_ON_SHOW_LOADER = 'REGISTER_PRICE_ON_SHOW_LOADER'
export const REGISTER_PRICE_ON_HIDE_LOADER = 'REGISTER_PRICE_ON_HIDE_LOADER'
export const REGISTER_PRICE_ON_SHOW_MESSAGE = 'REGISTER_PRICE_ON_SHOW_MESSAGE'
export const REGISTER_PRICE_ON_HIDE_MESSAGE = 'REGISTER_PRICE_ON_HIDE_MESSAGE'
export const REGISTER_PRICES_CHANGE = 'REGISTER_PRICES_CHANGE'
export const REGISTER_PRICES_CHANGE_ALL = 'REGISTER_PRICES_CHANGE_ALL'
export const ADD_PRICE = 'ADD_PRICE'
export const DELETE_PRICE = 'DELETE_PRICE'
export const EDIT_PRICE = 'EDIT_PRICE'
export const FETCH_PRICES = 'FETCH_PRICES'
export const FETCH_PRICES_SUCCESS = 'FETCH_PRICES_SUCCESS'
export const FILTER_PRICE = 'FILTER_PRICE'
// export const CHALLENGE_PRICE_LOAD_LIST_FILTER = 'CHALLENGE_PRICE_LOAD_LIST_FILTER'
// Mentores
export const FETCH_MENTORS = 'FETCH_MENTORS'
export const FETCH_MENTORS_SUCCESS = 'FETCH_MENTORS_SUCCESS'
export const REGISTER_MENTOR_ON_SHOW_MESSAGE = 'REGISTER_MENTOR_ON_SHOW_MESSAGE'

//Estudiantes de una cohorte
export const FETCH_STUDENT_CANDIDATE_COHORT = 'FETCH_STUDENT_CANDIDATE_COHORT'
export const FETCH_STUDENT_CANDIDATE_COHORT_SUCCESS = 'FETCH_STUDENT_CANDIDATE_COHORT_SUCCESS'
export const FETCH_STUDENT_COHORT = 'FETCH_STUDENT_COHORT'
export const FETCH_STUDENT_COHORT_SUCCESS = 'FETCH_STUDENT_COHORT_SUCCESS'
export const STUDENT_CANDIDATE_COHORT_ON_SHOW_MESSAGE = '_STUDENT_CANDIDATE_COHORT_ON_SHOW_MESSAGE'
export const STUDENT_CANDIDATE_COHORT_ON_HIDE_MESSAGE = '_STUDENT_CANDIDATE_COHORT_ON_HIDE_MESSAGE'
export const SAVE_STUDENT_COHORT = 'SAVE_STUDENT_COHORT'
export const DELETE_STUDENT_COHORT_AFTER_DEADLINE = 'DELETE_STUDENT_COHORT_AFTER_DEADLINE'
export const FILTERS_STUDENT_COHORT = 'FILTERS_STUDENT_COHORT'
export const FILTERS_STUDENT_COHORT_SAVE = 'FILTERS_STUDENT_COHORT_SAVE'
export const STUDENT_COHORT_ON_SHOW_LOADER = 'STUDENT_COHORT_ON_SHOW_LOADER'
export const STUDENT_COHORT_ON_HIDE_LOADER = 'STUDENT_COHORT_ON_HIDE_LOADER'

//Inactive users
export const CHANGE_GENERAL_USER_STATUS = 'CHANGE_GENERAL_USER_STATUS'
export const GET_BY_ID_USER_USER_STAGES = 'GET_BY_ID_USER_USER_STAGES'
export const GET_BY_ID_USER_USER_STAGES_SUCCESS = 'GET_BY_ID_USER_USER_STAGES_SUCCESS'
export const GET_BY_ID_USER_ALL_STAGES = 'GET_BY_ID_USER_ALL_STAGES'
export const GET_BY_ID_USER_ALL_STAGES_SUCCESS = 'GET_BY_ID_USER_ALL_STAGES_SUCCESS'


//Notifications
export const NOTIFICATIONS_SEND_EMAIL = 'SEND_EMAIL';
export const NOTIFICATIONS_SEND_EMAIL_UPDATE_STATUS = 'SEND_EMAIL';
//Calificación de estudiantes
export const FETCH_REQUERIMENT_PROSPECT_EVALUATION_SUCCESS = 'FETCH_REQUERIMENT_PROSPECT_EVALUATION_SUCCESS'
export const FETCH_FINAL_PROSPECT_EVALUATION_SUCCESS = 'FETCH_FINAL_PROSPECT_EVALUATION_SUCCESS'
export const FETCH_REQUERIMENT_PROSPECT_EVALUATION = 'FETCH_REQUERIMENT_PROSPECT_EVALUATION'
export const FETCH_FINAL_PROSPECT_EVALUATION = 'FETCH_FINAL_PROSPECT_EVALUATION'
export const PROSPECT_EVALUATION_ON_SHOW_MESSAGE = 'PROSPECT_EVALUATION_ON_SHOW_MESSAGE'
export const PROSPECT_EVALUATION_ON_HIDE_MESSAGE = 'PROSPECT_EVALUATION_ON_HIDE_MESSAGE'
export const PROSPECT_EVALUATION_ON_SHOW_LOADER = 'PROSPECT_EVALUATION_ON_SHOW_LOADER'
export const PROSPECT_EVALUATION_ON_HIDE_LOADER = 'PROSPECT_EVALUATION_ON_HIDE_LOADER'
export const PROSPECT_REQUERIMENT_EVALUATION_CHANGE = 'PROSPECT_REQUERIMENT_EVALUATION_CHANGE'
export const ADD_REQUERIMENT_PROSPECT_EVALUATION = 'ADD_REQUERIMENT_PROSPECT_EVALUATION'
export const PROSPECT_REQUERIMENT_EVALUATION_SINGLE = 'PROSPECT_REQUERIMENT_EVALUATION_SINGLE'
export const EDIT_REQUERIMENT_PROSPECT_EVALUATION = 'EDIT_REQUERIMENT_PROSPECT_EVALUATION'
export const EDIT_FINAL_PROSPECT_EVALUATION = 'EDIT_FINAL_PROSPECT_EVALUATION'
export const FINAL_PROSPECT_EVALUATION_CHANGE = 'FINAL_PROSPECT_EVALUATION_CHANGE'
export const FINAL_PROSPECT_EVALUATION_SINGLE = 'FINAL_PROSPECT_EVALUATION_SINGLE'
export const DETAILED_REQUISITE_EVALUATION_CHANGE = 'DETAILED_REQUISITE_EVALUATION_CHANGE'
export const DETAILED_REQUISITE_EVALUATION_SINGLE = 'DETAILED_REQUISITE_EVALUATION_SINGLE'
export const EDIT_DETAILED_REQUISITE_EVALUATION = 'EDIT_DETAILED_REQUISITE_EVALUATION'

// DemoDay Module
export const GET_TEAMS_DEMODAY = 'GET_TEAMS_DEMODAY';
export const GET_TEAMS_DEMODAY_SUCCESS = 'GET_TEAMS_DEMODAY_SUCCESS';
export const GET_PROJECT_DEMODAY = 'GET_PROJECT_DEMODAY';
export const GET_PROJECT_DEMODAY_SUCCESS = 'GET_PROJECT_DEMODAY_SUCCESS';
export const GET_PROJECT_CONTRIBUTORS_DEMODAY = 'GET_PROJECT_CONTRIBUTORS_DEMODAY';
export const GET_PROJECT_CONTRIBUTORS_DEMODAY_SUCCESS = 'GET_PROJECT_CONTRIBUTORS_DEMODAY_SUCCESS';
export const GET_PROJECT_MILESTONE_DEMODAY = 'GET_PROJECT_MILESTONE_DEMODAY';
export const GET_PROJECT_MILESTONE_DEMODAY_SUCCESS = 'GET_PROJECT_MILESTONE_DEMODAY_SUCCESS';
export const SHOW_LOADER_MILESTONE = 'SHOW_LOADER_MILESTONE';
export const HIDE_LOADER_MILESTONE = 'HIDE_LOADER_MILESTONE';

// Langs (Language)
export const GET_LANGS_FETCH = "GET_LANGS_FETCH";
export const GET_LANGS_SUCCESS = "GET_LANGS_SUCCESS";

// Templates 
export const CREATE_TEMPLATE_FETCH = "CREATE_TEMPLATE_FETCH";
export const CREATE_TEMPLATE_SUCCESS = "CREATE_TEMPLATE_SUCCESS";
export const CREATE_TEMPLATE_LANGS_FETCH = "CREATE_TEMPLATE_LANGS_FETCH"; 
export const CREATE_TEMPLATE_LANGS_SUCCESS = "CREATE_TEMPLATE_LANGS_SUCCESS"; 
export const GET_ALL_TEMPLATES_FETCH = "GET_ALL_TEMPLATES_FETCH"; 
export const GET_ALL_TEMPLATES_SUCCESS = "GET_ALL_TEMPLATES_SUCCESS"; 
export const CHANGE_TEMPLATE_TEXT = "CHANGE_TEMPLATE_TEXT"; 
export const DELETE_TEMPLATE_FETCH = "DELETE_TEMPLATE_FETCH";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS"
export const DELETE_TEMPLATE_LANGS_FETCH = "DELETE_TEMPLATE_LANGS_FETCH";
export const DELETE_TEMPLATE_LANGS_SUCCESS = "DELETE_TEMPLATE_LANGS_FETCH";
export const TEMPLATES_ON_SHOW_MESSAGE = "TEMPLATES_ON_SHOW_MESSAGE"; 
export const TEMPLATES_ON_HIDE_MESSAGE = "TEMPLATES_ON_HIDE_MESSAGE"; 
export const SHOW_HIDE_MODAL_CREATE_NEW_TEMPLATE_LANG_TEXT = "SHOW_HIDE_MODAL_CREATE_NEW_TEMPLATE_LANG_TEXT";
export const DATA_TEMPLATE_NEW_LANG = "DATA_TEMPLATE_NEW_LANG";
export const SET_DATA_NEW_TEMPLATE_LANG = "SET_DATA_NEW_TEMPLATE_LANG"; 
export const EDIT_TEMPLATE_FETCH = "EDIT_TEMPLATE_FETCH";
export const EDIT_TEMPLATE_LANG_FETCH = "EDIT_TEMPLATE_LANG_FETCH"; 
export const DATA_LANG_SELECTED = "DATA_LANG_SELECTED"; 
export const DATA_PARAMS_SELECTED_DINAMIC = "DATA_PARAMS_SELECTED_DINAMIC";
export const DATA_LANG_SELECTED_STATIC = "DATA_LANG_SELECTED_STATIC";
export const DATA_USERS_TO_SEND_TEMPLATE = "DATA_USERS_TO_SEND_TEMPLATE";
export const SEND_MESSAGE_WITH_TEMPLATE_FETCH = "SEND_MESSAGE_WITH_TEMPLATE_FETCH"; 
export const SEND_MESSAGE_WITHOUT_TEMPLATE_FETCH = "SEND_MESSAGE_WITHOUT_TEMPLATE_FETCH";
export const CLEAR_DATA_STATE_MESSAGE = "CLEAR_DATA_STATE_MESSAGE";
export const GET_DATA_MESSAGES_BY_USER_FETCH = "GET_DATA_MESSAGES_BY_USER_FETCH";
export const GET_DATA_MESSAGES_BY_USER_SUCCESS = "GET_DATA_MESSAGES_BY_USER_SUCCESS";