import {
    NOTIFICATIONS_SEND_EMAIL,
    NOTIFICATIONS_SEND_EMAIL_UPDATE_STATUS
} from 'constants/ActionTypes'

export const sendEmail = payload => {
    return {
        type: NOTIFICATIONS_SEND_EMAIL,
        payload: payload
    }
}

export const updateStatus = payload => {
    return {
        type: NOTIFICATIONS_SEND_EMAIL_UPDATE_STATUS,
        payload: payload
    }
}