import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { config } from "util/Authorization";
import { GET_ALL_COUNTRIES, GET_STATES_BY_COUNTRY_ID, GET_CITIES_BY_STATE_ID, GET_CITY_BY_ID } from 'constants/ActionTypes';
import { getCountriesSuccess, getStatesByCountryIdSuccess, getCitiesByStateIdSuccess, getCityByIdSuccess } from 'appRedux/actions/Country';
import { fetchError } from "appRedux/actions/Common";

const axiosGetCountries = async () => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}country`, config());
    return data;
  } catch (err) {
    return err;
  }
}

const axiosGetStatesByCountryId = async idCountry => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}country/${idCountry}/states`, config());
    return data;
  } catch (err) {
    return err;
  }
}

const axiosGetCitiesByStateId = async ({ payload }) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}country/${payload}/cities`, config())
    return { res: data, status: true };
  }
  catch (err) {
    return { res: err, status: false };
  }
}

const axiosGetCityById = async (payload) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}country/${payload.id}/city`, config())
  return data;
}

function* getCountriesRequest() {
  try {
    const allContries = yield call(axiosGetCountries);
    yield put(getCountriesSuccess(allContries));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getStatesByCountryIdRequest({ idCountry, fieldName }) {
  try {
    const states = yield call(axiosGetStatesByCountryId, idCountry);
    yield put(getStatesByCountryIdSuccess(states, fieldName));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getCitiesByStateIdRequest(data) {
  try {
    const cities = yield call(axiosGetCitiesByStateId, data);
    if (!cities.status) yield put(fetchError(cities.res));
    else yield put(getCitiesByStateIdSuccess(cities.res, data.fieldName))
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* getCityByIdRequest({ payload }) {

  try {
    const city = yield call(axiosGetCityById, payload)
    yield put(getCityByIdSuccess(city))
  } catch (err) {
    yield put(fetchError(err));
  }
}

export function* getCountries() {
  yield takeEvery(GET_ALL_COUNTRIES, getCountriesRequest);
}

export function* getStatesByCountryId() {
  yield takeEvery(GET_STATES_BY_COUNTRY_ID, getStatesByCountryIdRequest);
}

export function* getCitiesByStateId() {
  yield takeEvery(GET_CITIES_BY_STATE_ID, getCitiesByStateIdRequest);
}

export function* getCityById() {
  yield takeEvery(GET_CITY_BY_ID, getCityByIdRequest)
}

export default function* rootSaga() {
  yield all([
    fork(getCountries),
    fork(getStatesByCountryId),
    fork(getCitiesByStateId),
    fork(getCityById)
  ]);
}