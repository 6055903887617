import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  COMPLETE_USER_REGISTRATION,
  RESET_LOGIN_INIT,
  CREATE_USER_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  CHANGE_REGISTER_STATE_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
  register_success: false,
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  emailRevocer: '',
  authUser: localStorage.getItem('user_id'),
  user: localStorage.getItem('user') == null ? {} : JSON.parse(localStorage.getItem('user'))
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_REGISTER_STATE_SUCCESS: {
      return {
        ...state,
        register_success: action.payload
      }
    }
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload.authUser,
        user: action.payload.user
      }
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload.authUser,
        user: action.payload.user
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false,
        user: {}
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload.user_id,
        user: action.payload.user
      }
    }

    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload.user_id,
        user: action.payload.user
      }
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload.user_id,
        user: action.payload.user
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case COMPLETE_USER_REGISTRATION: {
      return {
        ...state,
        loader: false,
        authUser: null,
        user: action.payload
      }
    }
    case RESET_LOGIN_INIT: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
        initURL: '',
        user: {}
      }
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loader: false,
        emailRevocer: action.payload
      }
    }
    default:
      return state;
  }
}