import {
    FETCH_SINGLE_PROSPECT_SUCCESS,
    VALIDATE_CV_USER_SUCCESS
} from "constants/ActionTypes"

const INIT_STATE = {
    userCv: [{
        id: '',
        id_user: '',
        document: "",
        birth_date: '',
        id_birth_country: '',
        id_birth_city: '',
        address: "",
        citizenship: "",
        residence_phone: "",
        facebook_url: "",
        twitter_url: "",
        linkedin_url: "",
        github_url: "",
        id_photo: "",
        id_cv_file: "",
        registration_date: "",
        document_type: "",
        gender: "",
        scolarity: "",
        disability: "",
        population_type: "",
        socioeconomic_status: ""
    }],
    validateCv: ''
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_SINGLE_PROSPECT_SUCCESS:
            return {
                ...state,
                userCv: action.payload
            }
        case VALIDATE_CV_USER_SUCCESS:
            return {
                ...state,
                validateCv: action.payload
            }
        default:
            return state;
    }
};