import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";
import {
    addLanguagesToDevelopSuccess,
    deleteLanguageSuccess,
    fetchListLanguagesToDevelop,
    onGetLanguagesToDevelopAvailableSuccess,
    fetchListLanguagesToDevelopById,
    onGetLanguagesToDevelopById,
    showMessageDialog,
    showLoaderLanguageToDevelop
} from 'appRedux/actions/LanguagesToDevelop'
import { fetchError } from 'appRedux/actions/Common'
import {
    CREATE_LANGUAGE_TO_DEVELOP,
    DELETE_LANGUAGES_TO_DEVELOP,
    PATCH_LANGUAGE_TO_DEVELOP,
    FETCH_LANGUAGES_TO_DEVELOP_BY_ID,
    GET_LANGUAGES_TO_DEVELOP,
    GET_LANGUAGES_TO_DEVELOP_AVAILABLE,
    TYPE_MESSAGE_ERROR,
    TYPE_MESSAGE_SUCCESS
} from "constants/ActionTypes";

const listLanguages = async () => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}languages_to_develop`, config());
        return data;
    } catch (err) {
        return false
    }
}

const listLanguagesAvailable = async id_cv_user => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}handleLanguagesDevelop/${id_cv_user}/names`, config());
        return data;
    } catch (err) {
        return false
    }
}

const createLanguageToDevelop = async (language) => {
    const { id_cv_user, id_language_dev, language_proficiency, observations } = language
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}handleLanguagesDevelop`, { id_cv_user, id_language_dev, language_proficiency, observations }, config())
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}handleLanguagesDevelop/${id_cv_user}`, config())
        return data
    } catch (err) {
        return false //Status 500 cuando falla cualquiera de las dos peticiones    
    }
}

const getLanguageToDevelopById = async (language) => {
    const { id_cv_user } = language.payload
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}handleLanguagesDevelop/${id_cv_user}`, config())
        return data;
    } catch (err) {
        return false  //Status 500 cuando falla cualquiera de las dos peticiones  
    }
}

const deleteLanguage = async (language) => {
    const { id, id_cv_user } = language
    try {
        await axios.delete(`${process.env.REACT_APP_API_URL}handleLanguagesDevelop/${id}`, config())
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}handleLanguagesDevelop/${id_cv_user}`, config())
        return data;
    } catch (err) {
        return false // Status 500 cuando falla cualquiera de las dos peticiones    
    }
}

const axiosPatchLanguage = async (language) => {
    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}handleLanguagesDevelop/${language.id}`, { language_proficiency: language.proficiency, observations: language.observations }, config())
        return res;
    } catch (err) {
        return false;  // Status 500 cuando falla cualquiera de las dos peticiones  
    }
}

function* createLTDRequest({ language }) {
    const { locale } = language;
    const messageError = getMessageByString(locale, "app.error.server");
    try {
        yield put(showLoaderLanguageToDevelop());
        const messageSuccess = getMessageByString(locale, "cv.programminglanguages.addsuccess");
        const createdLTD = yield call(createLanguageToDevelop, language);
        if (!createdLTD) return yield put(showMessageDialog({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
        else yield put(showMessageDialog({ alertMessage: messageSuccess, typeMessage: TYPE_MESSAGE_SUCCESS }))
        return yield put(addLanguagesToDevelopSuccess(createdLTD))
    } catch (err) {
        yield put(showLoaderLanguageToDevelop());
        return yield put(showMessageDialog({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
    }
}

function* fetchListLanguagesRequest() {
    try {
        const fetchedLanguages = yield call(listLanguages);
        yield put(fetchListLanguagesToDevelop(fetchedLanguages))
    } catch (error) {
        yield put(fetchError(error))
    }
}

function* fetchListLanguagesAvailableRequest({ payload }) {
    const { id_cv_user, locale } = payload;
    const messageError = getMessageByString(locale, "app.error.server");
    try {
        const fetchedLanguagesAvailable = yield call(listLanguagesAvailable, id_cv_user);
        if (!fetchedLanguagesAvailable)
            return yield put(showMessageDialog({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
        return yield put(onGetLanguagesToDevelopAvailableSuccess(fetchedLanguagesAvailable))
    } catch (err) {
        yield put(showLoaderLanguageToDevelop());
        return yield put(showMessageDialog({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
    }
}

function* getlanguageTDByIdRequest(language) {
    try {
        const getLanguageTD = yield call(getLanguageToDevelopById, language);
        yield put(fetchListLanguagesToDevelopById(getLanguageTD))
    } catch (error) {
        yield put(fetchError(error))
    }
}

function* deleteLanguageRequest({ language }) {
    const { locale } = language;
    const messageError = getMessageByString(locale, "app.error.server");
    try {
        yield put(showLoaderLanguageToDevelop());
        const messageSuccess = getMessageByString(locale, "cv.programminglanguages.deletesuccess");
        const deleteLTD = yield call(deleteLanguage, language);
        if (!deleteLTD) return yield put(showMessageDialog({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
        else yield put(showMessageDialog({ alertMessage: messageSuccess, typeMessage: TYPE_MESSAGE_SUCCESS }))
        return yield put(deleteLanguageSuccess(deleteLTD))
    } catch (err) {
        yield put(showLoaderLanguageToDevelop());
        yield put(showMessageDialog({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
    }
}

function* patchLanguageToDevelopRequest({ payload }) {
    const { locale } = payload;
    const messageError = getMessageByString(locale, "app.error.server");
    try {
        yield put(showLoaderLanguageToDevelop());
        const messageSuccess = getMessageByString(locale, "cv.programminglanguages.editsuccess");
        const editLanguage = yield call(axiosPatchLanguage, payload);
        if (!editLanguage) return yield put(showMessageDialog({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
        else yield put(showMessageDialog({ alertMessage: messageSuccess, typeMessage: TYPE_MESSAGE_SUCCESS }))
        return yield put(onGetLanguagesToDevelopById({ id_cv_user: payload.id_cv_user }))
    } catch (err) {
        yield put(showLoaderLanguageToDevelop());
        yield put(showMessageDialog({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
    }
}

export function* fetchListLanguages() {
    yield takeEvery(
        GET_LANGUAGES_TO_DEVELOP,
        fetchListLanguagesRequest
    )
}

export function* fetchListLanguagesAvailable() {
    yield takeEvery(
        GET_LANGUAGES_TO_DEVELOP_AVAILABLE,
        fetchListLanguagesAvailableRequest
    )
}

export function* createLTD() {
    yield takeEvery(
        CREATE_LANGUAGE_TO_DEVELOP, createLTDRequest
    )
}

export function* fetchLanguagesTDById() {
    yield takeEvery(
        FETCH_LANGUAGES_TO_DEVELOP_BY_ID,
        getlanguageTDByIdRequest
    )
}

export function* deleteLanguageTDById() {
    yield takeEvery(
        DELETE_LANGUAGES_TO_DEVELOP, deleteLanguageRequest
    )
}

export function* patchLanguageToDevelop() {
    yield takeEvery(
        PATCH_LANGUAGE_TO_DEVELOP,
        patchLanguageToDevelopRequest
    )
}

export default function* rootSaga() {
    yield all([
        fork(fetchListLanguages),
        fork(createLTD),
        fork(fetchLanguagesTDById),
        fork(deleteLanguageTDById),
        fork(patchLanguageToDevelop),
        fork(fetchListLanguagesAvailable),
        fork(fetchListLanguagesRequest)
    ])
}