import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { openOrCloseModal } from 'appRedux/actions/ScholarshipProspect'
import { Link } from 'react-router-dom/'

const Breadcrumbs = () => {
  const dispatch = useDispatch()
  const { location } = useSelector(({ router }) => router)
  const { locState } = useSelector(({ common }) => common)
  const { modal_data, modal_open_close } = useSelector(
    ({ scholarshipProspect }) => scholarshipProspect
  )
  const { modal_open_close: modalOpen2, sponsorship } = useSelector(
    ({ sponsorshipProspect }) => sponsorshipProspect
  )
  const { courseList, current_tab } = useSelector(
    ({ singleCourse }) => singleCourse
  )

  const match = useRouteMatch()
  const [bcItems, setBcItems] = useState([])

  useEffect(() => {
    switch (location.pathname.split('/').pop()) {
      case 'basic-data':
        setBcItems([
          { label: 'Hoja de Vida' },
          { label: 'Datos básicos', ref: location.pathname }
        ])
        break
      case 'supplementary-info':
        setBcItems([
          { label: 'Hoja de Vida' },
          { label: 'Información complementaria', ref: location.pathname }
        ])
        break
      case 'languages-to-develop':
        setBcItems([
          { label: 'Hoja de Vida' },
          { label: 'Herramientas de programación', ref: location.pathname }
        ])
        break
      case 'high-school':
        setBcItems([
          { label: 'Hoja de Vida' },
          { label: 'Educación secundaria o media', ref: location.pathname }
        ])
        break
      case 'college-education':
        setBcItems([
          { label: 'Hoja de Vida' },
          { label: 'Educación terciaria/universitaria', ref: location.pathname }
        ])
        break
      case 'complementary-courses':
        setBcItems([
          { label: 'Hoja de Vida' },
          { label: 'Cursos complementarios o en línea', ref: location.pathname }
        ])
        break
      case 'languages':
        setBcItems([
          { label: 'Hoja de Vida' },
          { label: 'Idiomas', ref: location.pathname }
        ])
        break
      case 'work-experience':
        setBcItems([
          { label: 'Hoja de Vida' },
          { label: 'Experiencia laboral', ref: location.pathname }
        ])
        break
      case 'all-scholarships':
        if (modal_open_close) {
          setBcItems([
            { label: 'Becas' },
            { label: 'Disponibles', ref: location.pathname },
            {
              label:
                match.url === '/scholarship-prospect/applicable-scholarships'
                  ? 'Becas Aplicables'
                  : 'Todas las becas',
              action: openOrCloseModal()
            },
            {
              label: modal_data.scholarship.scholarship_name
            }
          ])
        } else {
          setBcItems([
            { label: 'Becas' },
            { label: 'Disponibles', ref: location.pathname }
          ])
        }
        break
      case 'applicable-scholarships':
        setBcItems([
          { label: 'Becas' },
          { label: 'Aplicar a una', ref: location.pathname }
        ])
        break
      case 'applied-scholarship':
        setBcItems([
          { label: 'Becas' },
          { label: 'Historial de Aplicación', ref: location.pathname }
        ])
        break
      case 'all-sponsorships':
        if (modalOpen2) {
          setBcItems([
            { label: 'Préstamos' },
            { label: 'Préstamos', ref: location.pathname },
            {
              label:
                match.url === '/sponsorship-prospect/compare-sponsorships'
                  ? 'Comparar préstamos'
                  : 'Todos los préstamos',
              action: openOrCloseModal()
            },
            {
              label: sponsorship.sponsorship_name
            }
          ])
        } else {
          setBcItems([
            { label: 'Préstamos' },
            { label: 'Préstamos', ref: location.pathname }
          ])
        }
        break
      case 'compare-sponsorships':
        setBcItems([
          { label: 'Préstamos' },
          { label: 'Comparar opciones de financiación', ref: location.pathname }
        ])
        break
      case 'applied-sponsorship':
        setBcItems([
          { label: 'Préstamos' },
          { label: 'Historial de Aplicación', ref: location.pathname }
        ])
        break
      case 'geek':
        switch (locState?.activeKey) {
          case '1':
            setBcItems([
              { label: 'Cursos' },
              { label: 'Disponibles', ref: location.pathname }
            ])
            break
          case '2':
            setBcItems([
              { label: 'Cursos' },
              { label: 'En Ejecución', ref: location.pathname }
            ])
            break
          case '3':
            setBcItems([
              { label: 'Cursos' },
              { label: 'Completados', ref: location.pathname }
            ])
            break
          default:
            setBcItems([{ label: 'Inicio' }])
            break
        }
        break
      default:
        if (location.pathname.includes('course-info')) {
          setBcItems([
            { label: 'Cursos' },
            {
              label: 'En Ejecución',
              ref: '/main/dashboard/geek?list_courses=true&activeKey=2'
            },
            {
              label:
                current_tab === '2'
                  ? `Zona entregables: ${courseList.course_name || ""}`
                  : `Información del curso: ${courseList.course_name || ""}`
            }
          ])
        } else {
          setBcItems([{ label: 'Inicio' }])
        }
        break
    }
  }, [
    location,
    locState,
    modal_data,
    match,
    modal_open_close,
    modalOpen2,
    sponsorship,
    courseList,
    current_tab
  ])

  return (
    <>
      <Breadcrumb separator='>'>
        {bcItems.map((item, index) => (
          <Breadcrumb.Item key={index}>
            {location.pathname.includes('course-info') && index === 1 ? (
              <Link
                to={{
                  pathname: '/main/dashboard/geek',
                  state: { list_courses: true, activeKey: current_tab }
                }}
              >
                <span>{item.label}</span>
              </Link>
            ) : (
              <a
                href={item.ref}
                onClick={() => (item.action ? dispatch(item.action) : null)}
              >
                {item.label}
              </a>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </>
  )
}

export default Breadcrumbs
