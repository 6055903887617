import {
    REGISTER_SPONSORS_MODAL_DELIVERY_ADD,
    REGISTER_SPONSORS_CHANGE,
    REGISTER_SPONSORS_MODAL_CLEAN,
    REGISTER_SPONSOR_ON_SHOW_LOADER,
    REGISTER_SPONSOR_ON_HIDE_LOADER,
    REGISTER_SPONSOR_ON_SHOW_MESSAGE,
    REGISTER_SPONSOR_ON_HIDE_MESSAGE,
    FETCH_SPONSORS_SUCCESS,
    REGISTER_SPONSORS_CHANGE_ALL,
    REGISTER_SPONSORS_MODAL_DELIVERY_EDIT,
    FILTER_SPONSOR
} from 'constants/ActionTypes'

const INIT_STATE = {
    modal_delivery_add: false,
    modal_delivery_edit: false,
    sponsor_single: {
        op_phone_id_country: '+57',
        admin_phone_id_country: '+57',
        status: 'activo'
    },
    allSponsors: [],
    alert_message: '',
    show_message_app: false,
    loader: false,
    type_message: '',
    filterSponsor: 'Todos los cooperantes/financiadores'
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case REGISTER_SPONSORS_MODAL_DELIVERY_ADD: {
            return {
                ...state,
                modal_delivery_add: !state.modal_delivery_add
            }
        }
        case REGISTER_SPONSORS_MODAL_DELIVERY_EDIT: {
            return {
                ...state,
                modal_delivery_edit: !state.modal_delivery_edit
            }
        }
        case REGISTER_SPONSORS_CHANGE: {
            return {
                ...state,
                sponsor_single: {
                    ...state.sponsor_single,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }
        case REGISTER_SPONSORS_MODAL_CLEAN: {
            return {
                ...state,
                sponsor_single: {
                    op_phone_id_country: '+57',
                    admin_phone_id_country: '+57',
                    status: 'activo'
                }
            }
        }
        case REGISTER_SPONSOR_ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case REGISTER_SPONSOR_ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case REGISTER_SPONSOR_ON_SHOW_MESSAGE: {
            return {
                ...state,
                show_message_app: true,
                alert_message: action.payload.alertMessage,
                type_message: action.payload.typeMessage
            }
        }

        case REGISTER_SPONSOR_ON_HIDE_MESSAGE: {
            return {
                ...state,
                alert_message: '',
                show_message_app: false,
                loader: false
            }
        }
        case FETCH_SPONSORS_SUCCESS: {
            return {
                ...state,
                allSponsors: action.payload
            }
        }
        case REGISTER_SPONSORS_CHANGE_ALL: {
            return {
                ...state,
                sponsor_single: action.payload
            }
        }
        case FILTER_SPONSOR: {
            return {
                ...state,
                filterSponsor: action.payload
            }
        }
        default:
            return state
    }
}