import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { fetchSingleUserCvSuccess, validateCvUserSuccess, showMessageDialog } from 'appRedux/actions/CvUser';
import { fetchError } from "appRedux/actions/Common";
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";
import { GET_SINGLE_PROSPECT, VALIDATE_CV_USER, TYPE_MESSAGE_ERROR } from 'constants/ActionTypes';

const getSingleUserCv = async (userCv) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}user_cv/${userCv}`, config());
    return data;
  } catch (err) {
    return err;
  }
}

const getValidateCvCompleted = async (id_user) => {
  const data = await axios.get(`${process.env.REACT_APP_API_URL}user_cv/validate_cv_completed/${id_user}`, config());
  return data;
}

function* validateCvCompletedRequest({ payload }) {
  const { locale, id_user } = payload;
  try {
    const responseValidateCv = yield call(getValidateCvCompleted, id_user)
    if (responseValidateCv.status === 200) yield put(validateCvUserSuccess(true))
    else if (responseValidateCv.status === 209) yield put(validateCvUserSuccess(false))
  } catch (error) {
    const message = getMessageByString(
      locale,
      'register.sponsorships.message.internalerror'
    )
    yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
    return false;
  }
}

function* fetchUserCvRequest({ userCv }) {
  try {
    const fetchedUserCv = yield call(getSingleUserCv, userCv);
    yield put(fetchSingleUserCvSuccess(fetchedUserCv));
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* fetchUserCv() {
  yield takeEvery(GET_SINGLE_PROSPECT, fetchUserCvRequest)
}
export function* validateCvCompleted() {
  yield takeEvery(VALIDATE_CV_USER, validateCvCompletedRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchUserCv),
    fork(validateCvCompleted)
  ]);
}