import React from 'react'
import { Tooltip } from 'antd'
import { MailOutlined, PhoneFilled, WhatsAppOutlined } from '@ant-design/icons'

export default function ContactMeans() {
  return (
    <ul className='gx-social-link' style={{ justifyContent: "center", color: '#1978D2' }}>
      <li>
        <Tooltip placement='top' title='3015516541' arrowPointAtCenter >
          <PhoneFilled
            onClick={() => window.open('tel:+573015516541', '_self')}
          />
        </Tooltip>
      </li>
      <li>
        <small>
          <Tooltip
            placement='top'
            title='contacto@agileinnova.org'
            arrowPointAtCenter
          >
            <MailOutlined
              onClick={() =>
                window.open('mailto:contacto@agileinnova.org', '_self')
              }
            />
          </Tooltip>
        </small>
      </li>
      <li>
        <Tooltip
          placement='top'
          title='https://api.whatsapp.com/send?phone=+573015516541&text=Quiero%20saber%20m%C3%A1s%20sobre%20Agile%20Innova'
          arrowPointAtCenter
        >
          <WhatsAppOutlined
            onClick={() => window.open('https://api.whatsapp.com/send?phone=+573015516541&text=Quiero%20saber%20m%C3%A1s%20sobre%20Agile%20Innova', '_blanck')}
          />
        </Tooltip>
      </li>
    </ul>
  )
}