import { GET_DATA_MESSAGES_BY_USER_FETCH, GET_DATA_MESSAGES_BY_USER_SUCCESS } from "../../constants/ActionTypes"




export const getDataMessagesByUserFetch = payload  => {
    return{
        type: GET_DATA_MESSAGES_BY_USER_FETCH,
        payload: payload
    }
}

export const getDataMessagesByUserSuccess = payload  => {
    return{
        type: GET_DATA_MESSAGES_BY_USER_SUCCESS,
        payload: payload
    }
}