import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { showMessageDialog, hideLoaderScholarship, fetchScholarshipSuccess, searchCitySuccess, fetchSponsorsSuccess } from 'appRedux/actions/RegisterScholarships'
import axios from 'axios'
import { config } from "util/Authorization";
import { ADD_SCHOLARSHIP, FETCH_SCHOLARSHIPS, TYPE_MESSAGE_SUCCESS, TYPE_MESSAGE_ERROR, REGISTER_SCHOLARSHIP_SEARCH_CITY, EDIT_SCHOLARSHIP, DELETE_SCHOLARSHIP, GET_SPONSORS_SCHOLARSHIP } from "constants/ActionTypes"
import { getMessageByString } from 'util/Messages'
import { fetchError } from "appRedux/actions/Common";

const serviceFetchScholarships = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}scholarships`, config());
    return data;
}
const serviceAddScholarship = async payload => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}scholarship`, payload.body, config());
    const { id_scholarship } = await data

    let newCourses = payload.body.scholarship_quantity.filter((item) => item.number_scholarships > 0).map((obj) => {
        return {
            course_id: obj.course_id,
            number_scholarships: obj.number_scholarships
        }
    })
    await axios.post(`${process.env.REACT_APP_API_URL}scholarship-courses/${id_scholarship}`, newCourses, config());
    return data;
}

const serviceDeleteScholarship = async payload => {
    const data = await axios.delete(`${process.env.REACT_APP_API_URL}scholarship/${payload.id}`, config());
    return data

}

const serviceEditScholarship = async payload => {
    const data = await axios.patch(`${process.env.REACT_APP_API_URL}scholarship/${payload.id}`, payload.body, config())

    let newCourses = payload.body.scholarship_quantity.filter((item) => item.id_scholarship_course === '' && item.number_scholarships > 0).map((obj) => {
        return {
            course_id: obj.course_id,
            number_scholarships: obj.number_scholarships
        }
    })
    let existingCourse = payload.body.scholarship_quantity.filter((item) => item.id_scholarship_course !== '' && item.number_scholarships > 0).map((obj) => {
        return {
            id: obj.id_scholarship_course,
            data: {
                id_course: obj.course_id,
                max_number_scholarships: obj.number_scholarships
            }
        }
    })
    let deleteCourses = payload.body.scholarship_quantity.filter((item) => item.id_scholarship_course !== '' && item.number_scholarships < 1).map((obj) => {
        return {
            id_scholarship_course: obj.id_scholarship_course,
            number_scholarships: obj.number_scholarships
        }
    })

    for (let index = 0; index < existingCourse.length; index++) {
        await axios.patch(`${process.env.REACT_APP_API_URL}scholarship-course/${existingCourse[index].id}`, existingCourse[index].data, config())
    }
    //agregar los cursos nuevos
    if (newCourses.length > 0) {
        await axios.post(`${process.env.REACT_APP_API_URL}scholarship-courses/${payload.id}`, newCourses, config());
    }

    for (let index = 0; index < deleteCourses.length; index++) {
        await axios.delete(`${process.env.REACT_APP_API_URL}scholarship-course/${deleteCourses[index].id_scholarship_course}`, config());
    }
    return data
}

const serviceSearchCity = async (payload) => {
    const request = { string: payload.string }
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}country/string`, request, config());
    return data;
}
const getSponsors = async () => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}adm_partner`, config());
        return data.filter(sponsor => sponsor.institution_type === 'cooperante');
    } catch (err) {
        return err;
    }
}

function* addScholarshipRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceAddScholarship, payload)
        const allScholarships = yield call(serviceFetchScholarships)
        yield put(fetchScholarshipSuccess(allScholarships))
        yield put(hideLoaderScholarship())
        const message = getMessageByString(
            locale,
            'register.scholarships.message.create'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        let message = getMessageByString(
            locale,
            'app.error.server'
        )
        if (e.message === "Request failed with status code 500") {
            message = "Actualice la pagina y modifique el numero de becas"
        }
        yield put(hideLoaderScholarship())

        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}
function* fetchScholarshipsRequest({ payload }) {
    const { locale } = payload;
    try {
        const allScholarships = yield call(serviceFetchScholarships)
        yield put(fetchScholarshipSuccess(allScholarships))
        yield put(hideLoaderScholarship())
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}
function* deleteScholarshipRequest({ payload }) {
    const { locale } = payload;
    try {
        const deleteScholarshipRes = yield call(serviceDeleteScholarship, payload)
        const allScholarships = yield call(serviceFetchScholarships);
        yield put(fetchScholarshipSuccess(allScholarships))
        yield put(hideLoaderScholarship())
        if (deleteScholarshipRes.status === 270) {
            const message = getMessageByString(
                locale,
                'register.scholarships.message.internalerror.students'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        } else {
            const message = getMessageByString(
                locale,
                'register.scholarships.message.delete'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
        }
    } catch (e) {
        yield put(hideLoaderScholarship())
        let message = 'Error'
        if (e.message === 'Network Error') {
            message = getMessageByString(
                locale,
                'app.error.server'
            )
        } else {
            message = message = getMessageByString(
                locale,
                'register.scholarships.associated.users.error'
            )
        }

        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* editScholarshipRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceEditScholarship, payload)
        const allScholarships = yield call(serviceFetchScholarships);
        yield put(fetchScholarshipSuccess(allScholarships))
        yield put(hideLoaderScholarship())
        const message = getMessageByString(
            locale,
            'register.scholarships.message.update'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderScholarship())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* searchCityRequest({ payload }) {
    const { locale } = payload;
    try {
        const data = yield call(serviceSearchCity, payload);
        yield put(searchCitySuccess(data))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}
function* fetchSponsorsRequest() {
    try {
        const fetchedSponsors = yield call(getSponsors);
        yield put(fetchSponsorsSuccess(fetchedSponsors));
    } catch (error) {
        yield put(fetchError(error));
    }
}

export function* addScholarship() {
    yield takeEvery(ADD_SCHOLARSHIP, addScholarshipRequest)
}

export function* fetchScholarships() {
    yield takeEvery(FETCH_SCHOLARSHIPS, fetchScholarshipsRequest)
}

export function* deleteScholarship() {
    yield takeEvery(DELETE_SCHOLARSHIP, deleteScholarshipRequest)
}
export function* editScholarship() {
    yield takeEvery(EDIT_SCHOLARSHIP, editScholarshipRequest)
}

export function* searchCity() {
    yield takeEvery(REGISTER_SCHOLARSHIP_SEARCH_CITY, searchCityRequest)
}

export function* fetchSponsors() {
    yield takeEvery(GET_SPONSORS_SCHOLARSHIP, fetchSponsorsRequest);
}

export default function* rootSaga() {
    yield all([
        fork(addScholarship),
        fork(fetchScholarships),
        fork(deleteScholarship),
        fork(editScholarship),
        fork(searchCity),
        fork(fetchSponsors),
    ])
}