import {
    REGISTER_SPONSORS_MODAL_DELIVERY_ADD,
    REGISTER_SPONSORS_MODAL_DELIVERY_EDIT,
    REGISTER_SPONSORS_CHANGE,
    REGISTER_SPONSORS_MODAL_CLEAN,
    ADD_SPONSOR,
    REGISTER_SPONSOR_ON_SHOW_LOADER,
    REGISTER_SPONSOR_ON_HIDE_LOADER,
    REGISTER_SPONSOR_ON_SHOW_MESSAGE,
    REGISTER_SPONSOR_ON_HIDE_MESSAGE,
    FETCH_SPONSORS,
    FETCH_SPONSORS_SUCCESS,
    DELETE_SPONSOR,
    EDIT_SPONSOR,
    REGISTER_SPONSORS_CHANGE_ALL,
    FILTER_SPONSOR
} from 'constants/ActionTypes'


export const openOrCloseModalAddRegisterSponsors = () => {
    return {
        type: REGISTER_SPONSORS_MODAL_DELIVERY_ADD
    }
}

export const openOrCloseModalEditRegisterSponsors = () => {
    return {
        type: REGISTER_SPONSORS_MODAL_DELIVERY_EDIT
    }
}

export const changeSponsor = payload => {
    return {
        type: REGISTER_SPONSORS_CHANGE,
        payload: payload
    }
}

export const cleanModal = () => {
    return {
        type: REGISTER_SPONSORS_MODAL_CLEAN
    }
}

export const addSponsor = (payload) => {
    return {
        type: ADD_SPONSOR,
        payload: payload
    }
}

export const showLoaderSponsor = () => {
    return {
        type: REGISTER_SPONSOR_ON_SHOW_LOADER
    }
}

export const hideLoaderSponsor = () => {
    return {
        type: REGISTER_SPONSOR_ON_HIDE_LOADER
    }
}

export const showMessageDialog = configMessage => {
    return {
        type: REGISTER_SPONSOR_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}

export const hideMessageDialog = () => {
    return {
        type: REGISTER_SPONSOR_ON_HIDE_MESSAGE
    }
}

export const fetchSponsor = (payload) => {
    return {
        type: FETCH_SPONSORS,
        payload: payload
    }
}

export const fetchSponsorSuccess = (payload) => {
    return {
        type: FETCH_SPONSORS_SUCCESS,
        payload: payload
    }
}

export const deleteSponsor = (payload) => {
    return {
        type: DELETE_SPONSOR,
        payload: payload
    }
}

export const editSponsor = (payload) => {
    return {
        type: EDIT_SPONSOR,
        payload: payload
    }
}

export const changeSponsorAll = (payload) => {
    return {
        type: REGISTER_SPONSORS_CHANGE_ALL,
        payload: payload
    }
}

export const filterSponsor = (payload) => {
    return {
        type: FILTER_SPONSOR,
        payload: payload
    }
}