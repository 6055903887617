import { LOCATION_CHANGE } from "react-router-redux";
import {
    FETCH_SCHOLARSHIP_PROSPECT_SUCCESS,
    SCHOLARSHIP_PROSPECT_VINCULATION_STATUS_SUCCESS,
    SCHOLARSHIP_PROSPECT_MODAL,
    SCHOLARSHIP_PROSPECT_MODAL_DATA,
    FETCH_SCHOLARSHIP_HOLDERS_SUCCESS,
    SCHOLARSHIP_PROSPECT_HOLDER_SINGLE,
    SCHOLARSHIP_PROSPECT_HOLDER_SINGLE_FIELD,
    SCHOLARSHIP_PROSPECT_ACTIVE_TAB,
    VALIDATE_SCHOLARSHIP_STAGE_SUCCESS
} from 'constants/ActionTypes'


const INIT_STATE = {
    all_scholarships: [],
    user_info: {
        vinculation_status: null
    },
    modal_open_close: false,
    modal_data: {},
    scholarship_holders: null,
    scholarship_holder_single: {},
    activeTabs: [true, true, true],
    statusTracking: {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            return {
                ...INIT_STATE
            }
        }
        case FETCH_SCHOLARSHIP_PROSPECT_SUCCESS: {
            return {
                ...state,
                all_scholarships: action.payload
            }
        }
        case SCHOLARSHIP_PROSPECT_VINCULATION_STATUS_SUCCESS: {
            return {
                ...state,
                user_info: { vinculation_status: action.payload }
            }
        }
        case SCHOLARSHIP_PROSPECT_MODAL: {
            return {
                ...state,
                modal_open_close: !state.modal_open_close
            }
        }
        case SCHOLARSHIP_PROSPECT_MODAL_DATA: {
            return {
                ...state,
                modal_data: action.payload
            }
        }
        case FETCH_SCHOLARSHIP_HOLDERS_SUCCESS: {
            return {
                ...state,
                scholarship_holders: action.payload
            }
        }
        case SCHOLARSHIP_PROSPECT_HOLDER_SINGLE: {
            return {
                ...state,
                scholarship_holder_single: action.payload
            }
        }
        case SCHOLARSHIP_PROSPECT_HOLDER_SINGLE_FIELD: {
            return {
                ...state,
                scholarship_holder_single: {
                    ...state.scholarship_holder_single,
                    [action.payload.field]: action.payload.value
                }
            }
        }
        case SCHOLARSHIP_PROSPECT_ACTIVE_TAB: {
            return {
                ...state,
                activeTabs: action.payload
            }
        }
        case VALIDATE_SCHOLARSHIP_STAGE_SUCCESS: {
            return {
                ...state,
                statusTracking: action.payload
            }
        }
        default:
            return state
    }
}