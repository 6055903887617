import {
    FETCH_COURSES_SUCCESS,
    CHANGE_TYPE_OF_LIST_OFFERED_COURSES,
    FETCH_PREREQUISITE_COURSES_SUCCESS
} from "constants/ActionTypes"

const INIT_STATE = {
    coursesList: [],
    typeListOfferedCourses: {
        courseList: [],
        courseObj: {},
        isList: true,
        actualIdCourse: -1,
        actualCourseName: -1
    },
    prerequisite: {
        prerequisiteCourses: {},
        baseCourses: {}
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_COURSES_SUCCESS:
            return {
                ...state,
                coursesList: action.payload
            }
        case CHANGE_TYPE_OF_LIST_OFFERED_COURSES:
            return {
                ...state,
                typeListOfferedCourses: { ...state.typeListOfferedCourses, ...action.payload }
            }
        case FETCH_PREREQUISITE_COURSES_SUCCESS:
            return {
                ...state,
                prerequisite: action.payload
            }
        default:
            return state;
    }
}