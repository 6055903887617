//User stages
export const USER_DOES_NOT_SIGN_MINUTES = 34
export const USER_ACTIVE_ID = 35
export const USER_IN_PROCESS_ID = 36
export const USER_DESIST_WORK_ID = 37
export const USER_DESIST_STUDY_ID = 38
export const USER_DESIST_NO_INTEREST_ID = 39
export const USER_DESIST_NO_PC_ID = 40
export const USER_ACCESS_DENIED_ID = 41
export const USER_DESIST_NO_INTERNET_ID = 42
export const USER_NOT_ADMITTED = 43
export const USER_INCORRECT_REGISTRATION = 44

//Scholarchip stages
export const SH_REJECTED_BY_TECHNICAL_CONCEPT = 7
export const SH_POSTPONED_PSYCHOSOCIAL_CONCEPT = 8
export const SH_POSTPONED_TECHNICAL_CONCEPT = 11
export const SH_PRE_SCHOLARSHIP = 20
export const SH_PSYCHOSOCIAL_PRE_SCHOLARSHIP = 21
export const SH_DENIAL_APPLICATION = 23
export const SH_APPRENTICE_APPOVED_SECOND_FILTER_TECHNICAL = 26
export const SH_RETURN_APPLICATION = 28
export const SH_PRE_SCHOLARSHIP_PSYCHOSOCIAL_DENIAL = 30
export const SH_WITHDRAWAL = 27

//Sponsorship stages
export const SP_WITHDRAWAL = 27
export const SP_APPLICANT = 45
export const SP_DENIAL_APPLICATION = 46
export const SP_RETURN_APPLICATION = 47
export const SP_PRE_ENDORSED = 48
export const SP_PRE_ENDORSED_PSYCHOSOCIAL_DENIAL = 49
export const SP_PSYCHOSOCIAL_PRE_SPONSORSHIP = 50
export const SP_POSTPONED_PSYCHOSOCIAL_CONCEPT = 51
export const SP_REJECTED_BY_TECHNICAL_CONCEPT = 52
export const SP_POSTPONED_BY_TECHNICAL_CONCEPT = 53
export const SP_APPOVED_SECOND_FILTER_TECHNICAL = 54
export const SP_USED_LOAN = 64

//Prospect stages
export const PP_REGISTERED = 1
export const PP_PROSPECT = 2
export const PP_STUDENT_ID = 12
export const PP_SCHOLARSHIP_HOLDER_ID = 13
export const PP_DESERTER = 14
export const PP_APPROVED_EMPLOYABILITY = 15
export const PP_ASSISTANT = 16
export const PP_REFUND = 17
export const PP_RE_ENTRY = 18
export const PP_INDEPENDENT_APPRENTICE = 24
export const PP_POSTPONED_FORCE_MAJEURE = 31
export const PP_EXPELLED = 32
export const PP_POSTPONED_TO_ANOTHER_COHORT = 33
export const PP_MENTORED_APPRENTICE = 55
export const PP_INDEPENDENT_APPROVED_APPRENTICE = 56
export const PP_APPROVED_MENTORED_APPRENTICE = 57
export const PP_NO_APPROVED_MENTORED_APPRENTICE = 58
export const PP_MENTORED_APPRENTICE_IN_REINFORCEMENT = 59
export const PP_APPROVED = 60
export const PP_REPROVED = 61
export const PP_REINFORCEMENT = 62
export const PP_INSCRIBED = 63

//Cursos
export const APRENDICES_CURSO_ID = 1
export const FRONTEND_CURSO_ID = 2
export const BACKEND_CURSO_ID = 3