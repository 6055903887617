import {
    FETCH_SPONSORSHIP_PROSPECT,
    FETCH_SPONSORSHIP_PROSPECT_SUCCESS,
    SPONSORSHIP_PROSPECT_ON_SHOW_LOADER,
    SPONSORSHIP_PROSPECT_ON_HIDE_LOADER,
    SPONSORSHIP_PROSPECT_ON_SHOW_MESSAGE,
    SPONSORSHIP_PROSPECT_ON_HIDE_MESSAGE,
    SPONSORSHIP_PROSPECT_MODAL,
    SPONSORSHIP_PROSPECT_MODAL_DATA,
    CREATE_SPONSORSHIP_HOLDERS,
    UNPOSTULATE_SPONSORSHIP,
    UNPOSTULATE_SPONSORSHIP_SUCCESS,
    REAPPLY_SPONSORSHIP,
    FETCH_VALID_USER_SPONSORSHIPS,
    FETCH_VALID_USER_SPONSORSHIPS_SUCCESS,
    FETCH_SPONSORSHIP_APPLICATION_BY_USER,
    FETCH_SPONSORSHIP_APPLICATION_BY_USER_SUCCESS,
    FETCH_SPONSORSHIP_VALIDATION_COHORT_SCHOLARSHIP,
    FETCH_SPONSORSHIP_VALIDATION_COHORT_SCHOLARSHIP_SUCCESS,
    FETCH_SPONSORSHIP_HISTORY_SUCCESS,
    FETCH_SPONSORSHIP_HISTORY,
    SPONSORSHIP_EVALUATION_CHANGE
} from 'constants/ActionTypes'

export const showLoader = () => {
    return {
        type: SPONSORSHIP_PROSPECT_ON_SHOW_LOADER
    }
}

export const hideLoader = () => {
    return {
        type: SPONSORSHIP_PROSPECT_ON_HIDE_LOADER
    }
}

export const showMessageDialog = configMessage => {
    return {
        type: SPONSORSHIP_PROSPECT_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}

export const hideMessageDialog = () => {
    return {
        type: SPONSORSHIP_PROSPECT_ON_HIDE_MESSAGE
    }
}
export const fetchSponsorshipProspect = (payload) => {
    return {
        type: FETCH_SPONSORSHIP_PROSPECT,
        payload: payload
    }
}

export const fetchSponsorshipProspectSuccess = (payload) => {
    return {
        type: FETCH_SPONSORSHIP_PROSPECT_SUCCESS,
        payload: payload
    }
}

export const fetchValidUserSponsorships = (payload) => {
    return {
        type: FETCH_VALID_USER_SPONSORSHIPS,
        payload: payload
    }
};

export const fetchValidUserSponsorshipsSuccess = (payload) => {
    return {
        type: FETCH_VALID_USER_SPONSORSHIPS_SUCCESS,
        payload: payload
    }
}

export const fetchSponsorshipApplicationByUser = (payload) => {
    return {
        type: FETCH_SPONSORSHIP_APPLICATION_BY_USER,
        payload: payload
    }
}

export const fetchSponsorshipApplicationByUserSuccess = (payload) => {
    return {
        type: FETCH_SPONSORSHIP_APPLICATION_BY_USER_SUCCESS,
        payload: payload
    }
}

export const fetchSponsorshipValidationCohortScholarship = (payload) => {
    return {
        type: FETCH_SPONSORSHIP_VALIDATION_COHORT_SCHOLARSHIP,
        payload: payload
    }
}

export const fetchSponsorshipValidationCohortScholarshipSuccess = (payload) => {
    return {
        type: FETCH_SPONSORSHIP_VALIDATION_COHORT_SCHOLARSHIP_SUCCESS,
        payload: payload
    }
}

export const fetchSponsorshipHistory = (payload) => {
    return {
        type: FETCH_SPONSORSHIP_HISTORY,
        payload: payload
    }
}

export const fetchSponsorshipHistorySuccess = (payload) => {
    return {
        type: FETCH_SPONSORSHIP_HISTORY_SUCCESS,
        payload: payload
    }
}

export const openOrCloseModal = () => {
    return {
        type: SPONSORSHIP_PROSPECT_MODAL
    }
}

export const changeModalData = (payload) => {
    return {
        type: SPONSORSHIP_PROSPECT_MODAL_DATA,
        payload: payload
    }
}

export const createSponsorshipHolders = (payload) => {
    return {
        type: CREATE_SPONSORSHIP_HOLDERS,
        payload: payload
    }
}

export const unpostulateSponsorship = (payload) => {
    return {
        type: UNPOSTULATE_SPONSORSHIP,
        payload: payload
    }
}

export const unpostulateSponsorshipSuccess = (payload) => {
    return {
        type: UNPOSTULATE_SPONSORSHIP_SUCCESS,
        payload: payload
    }
}

export const reapplySponsorship = (payload) => {
    return {
        type: REAPPLY_SPONSORSHIP,
        payload: payload
    }
}

export const changeSponsorshipEvaluation = payload => {
    return {
        type: SPONSORSHIP_EVALUATION_CHANGE,
        payload: payload
    }
}