import {
  HIDE_MESSAGE_APP,
  SHOW_MESSAGE_APP,
  ON_HIDE_LOADER_APP,
  ON_SHOW_LOADER_APP
} from 'constants/ActionTypes'

export const showMessageDialogApp = configMessage => {
  return {
    type: SHOW_MESSAGE_APP,
    payload: configMessage
  }
}

export const hideMessageApp = () => {
  return {
    type: HIDE_MESSAGE_APP
  }
}

export const showLoaderApp = () => {
  return {
    type: ON_SHOW_LOADER_APP
  }
}

export const hideLoaderApp = () => {
  return {
    type: ON_HIDE_LOADER_APP
  }
}