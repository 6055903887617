import React from 'react';
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Courses = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/courses/prices`} component={asyncComponent(() => import('components/admin/courses/Prices/CreatePrices'))} />
        <Route path={`${match.url}/courses`} component={asyncComponent(() => import('components/admin/courses/Courses/CreateCourse'))} />
        <Route path={`${match.url}/requirements`} component={asyncComponent(() => import('components/admin/courses/Requirements/CreateRequirement'))} />
        <Route path={`${match.url}/cohorts`} component={asyncComponent(() => import('components/admin/courses/Cohorts/CreateCohort'))} />
        <Route path={`${match.url}/cohorts-students/:cohort_code/disassociate`} component={asyncComponent(() => import('components/admin/courses/Cohorts/StudentsCohorts/DisassociateStudents'))} />
        <Route path={`${match.url}/cohorts-students/:cohort_code`} component={asyncComponent(() => import('components/admin/courses/Cohorts/StudentsCohorts/index'))} />
    </Switch>
);

export default Courses