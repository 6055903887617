import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";
import { showMessageDialog, fetchMentorSuccess } from 'appRedux/actions/Mentors'
import { FETCH_MENTORS, TYPE_MESSAGE_ERROR } from "constants/ActionTypes"

const serviceFetchMentors = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}users/mentors`, config());
    return data;
}

function* fetchMentorsRequest({ payload }) {
    const { locale } = payload;
    try {
        const allMentors = yield call(serviceFetchMentors)
        yield put(fetchMentorSuccess(allMentors))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

export function* fetchMentors() {
    yield takeEvery(FETCH_MENTORS, fetchMentorsRequest)
}

export default function* rootSaga() {
    yield all([
        fork(fetchMentors)
    ])
}