import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";
import {
    showMessageDialogAcademicInfo,
    showLoaderAcademicInfo,
    hideLoaderAcademicInfo,
    actionTakenHighSchool,
    getHighSchool,
    getHighSchoolSuccess,
    getCollegeEducation,
    getCollegeEducationSuccess,
    getComplementaryCourses,
    getComplementaryCoursesSuccess,
    getEducationLevelSuccess
} from 'appRedux/actions/AcademicInfo'
import {
    TYPE_MESSAGE_ERROR,
    TYPE_MESSAGE_SUCCESS,
    CREATE_ACADEMIC_INFO,
    EDIT_ACADEMIC_INFO,
    DELETE_ACADEMIC_INFO,
    GET_HIGH_SCHOOL,
    GET_COLLEGE_EDUCATION,
    GET_COMPLEMENTARY_COURSES,
    GET_EDUCATION_LEVEL
} from "constants/ActionTypes"
import { storage } from 'firebase/firebase'

//Peticiones generales para los componentes de AcademicInfo
const axiosCreateAcademicInfo = async payload => {
    try {
        const { id_cv_user, info } = payload
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}user_cv/${id_cv_user}/academic_info`, info, config())
        return { ...data, status: true };
    } catch (err) {
        return { status: false };
    }
}

const axiosGetAcademicInfo = async payload => {
    try {
        const { id_cv_user, type } = payload;
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}user_cv/${id_cv_user}/${type}/academic_info`, config())
        return data;
    } catch (err) {
        return false
    }
}
const axiosGetEducationLevel = async payload => {
    try {
        const { id_cv_user } = payload;
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}user_cv/${id_cv_user}/academic_info/lastYearSchooling`, config())
        return data;
    } catch (err) {
        return false
    }
}
const axiosEditAcademicInfo = async payload => {
    try {
        const { id, info } = payload
        await axios.patch(`${process.env.REACT_APP_API_URL}user_cv/${id}/academic_info`, info, config())
        return true;
    } catch (err) {
        return false;
    }
}

const axiosDeleteAcademicInfo = async payload => {
    try {
        await axios.delete(`${process.env.REACT_APP_API_URL}user_cv/${payload.id}/academic_info`, config())
        return true;
    } catch (err) {
        return false;
    }
}

const axiosAddFileBD = async payload => {
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}user_cv/${payload.id_academic_info}/${payload.id_user}/academic_info/updateFile`, payload.file, config())
        return { status: true }
    } catch (e) {
        return { status: false }
    }
}

const axiosUploadWorkCert = async payload => {
    try {
        const { id_cv_user, user_name, type, file_certificate } = payload;
        var storageRef = storage.ref()
        var spaceRef = storageRef.child(`curriculum-vitae/${id_cv_user}-${user_name.replaceAll(' ', '-')}/academic-info/${type}/${file_certificate.name}`)
        await spaceRef.put(file_certificate)
        const publicURL = await spaceRef.getDownloadURL();
        const metada = await spaceRef.getMetadata()
        return { ...metada, publicURL, status: true }
    } catch (e) {
        return { status: false }
    }
}

//Requests generales para los componentes de AcademicInfo
function* createAcademicInfoRequest({ payload }) {
    const { locale, id_cv_user, id_user, type } = payload;
    const messageError = getMessageByString(locale, "app.error.server");
    try {
        yield put(showLoaderAcademicInfo());
        const messageSuccess = getMessageByString(locale, "cv.academicinfo.addsuccess");
        const response = yield call(axiosCreateAcademicInfo, payload);
        if (!response.status) return yield put(showMessageDialogAcademicInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
        if (payload.file_certificate !== undefined) {
            //Acciones para subir archivo a firebase y asociarlo a la BD
            const file = yield call(axiosUploadWorkCert, payload)
            if (!file.status) return yield put(showMessageDialogAcademicInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
            delete file.status
            const updateFileBD = yield call(axiosAddFileBD, { file, id_user, id_academic_info: response.id })
            if (!updateFileBD.status) return yield put(showMessageDialogAcademicInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
        }
        //Actualizar la vista cuando se crea una informacion academica nueva 
        if (type === 'basica_secundaria')
            yield put(getHighSchool({ id_cv_user, type }))
        if (type === 'terciaria')
            yield put(getCollegeEducation({ id_cv_user, type }))
        if (type === 'curso')
            yield put(getComplementaryCourses({ id_cv_user, type }))
        return yield put(showMessageDialogAcademicInfo({ alertMessage: messageSuccess, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (err) {
        yield put(showLoaderAcademicInfo());
        yield put(showMessageDialogAcademicInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
    }
}

function* editAcademicInfoRequest({ payload }) {
    const { locale, id_user, id_cv_user, type } = payload;
    const messageError = getMessageByString(locale, "app.error.server");
    try {
        yield put(showLoaderAcademicInfo());
        const messageSuccess = getMessageByString(locale, "cv.academicinfo.editsuccess");

        if (payload.file_certificate !== undefined && payload.file_certificate.lastModified !== undefined) {
            //Acciones para subir archivo a firebase y asociarlo a la BD
            const file = yield call(axiosUploadWorkCert, payload)
            if (!file.status) return yield put(showMessageDialogAcademicInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
            delete file.status
            const updateFileBD = yield call(axiosAddFileBD, { file, id_user, id_academic_info: payload.id })
            if (!updateFileBD.status) return yield put(showMessageDialogAcademicInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))

        } else if (payload.file_certificate === undefined) payload.info.id_acad_certf = null

        const response = yield call(axiosEditAcademicInfo, payload)
        if (!response) return yield put(showMessageDialogAcademicInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
        //Actualizar la vista cuando se actualiza una informacion academica nueva 
        if (type === 'basica_secundaria')
            yield put(getHighSchool({ id_cv_user, type }))
        if (type === 'terciaria')
            yield put(getCollegeEducation({ id_cv_user, type }))
        if (type === 'curso')
            yield put(getComplementaryCourses({ id_cv_user, type }))
        return yield put(showMessageDialogAcademicInfo({ alertMessage: messageSuccess, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (err) {
        yield put(showLoaderAcademicInfo());
        yield put(showMessageDialogAcademicInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
    }
}

function* deleteAcademicInfoRequest({ payload }) {
    const { locale, type, id_cv_user } = payload;
    const messageError = getMessageByString(locale, "app.error.server");
    try {
        yield put(showLoaderAcademicInfo());
        const messageSuccess = getMessageByString(locale, "cv.academicinfo.deletesuccess");
        const response = yield call(axiosDeleteAcademicInfo, payload)
        //Actualizar la vista cuando se elimina una informacion academica nueva 
        if (type === 'terciaria')
            yield put(getCollegeEducation({ id_cv_user, type }))
        if (type === 'curso')
            yield put(getComplementaryCourses({ id_cv_user, type }))
        if (!response) return yield put(showMessageDialogAcademicInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
        else return yield put(showMessageDialogAcademicInfo({ alertMessage: messageSuccess, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (err) {
        yield put(showLoaderAcademicInfo());
        yield put(showMessageDialogAcademicInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
    }
}

function* getEducationLevelRequest({ payload }) {
    try {
        yield put(showLoaderAcademicInfo());
        const response = yield call(axiosGetEducationLevel, payload);
        if (response !== false) {
            yield put(hideLoaderAcademicInfo())
            yield put(getEducationLevelSuccess(response));
        }
        return
    } catch (err) {
        return yield put(showLoaderAcademicInfo());
    }
}

//Requests para HighSchool
function* getHighSchoolRequest({ payload }) {
    try {
        yield put(showLoaderAcademicInfo());
        const response = yield call(axiosGetAcademicInfo, payload);
        if (response !== false) {
            yield put(hideLoaderAcademicInfo())
            if (response.length > 0) {
                let info = response[0]
                if (info.finish_date !== null && info.finish_date !== undefined) {
                    const splitInfo = info.finish_date.split('-')
                    const finish_date = splitInfo[0] + '-' + splitInfo[1]
                    info.finish_date = finish_date
                }
                info.certificate = info.url_file !== null && info.url_file !== undefined ? [{
                    uid: info.id_work_certf,
                    name: info.name_file,
                    status: 'done',
                    url: info.url_file
                }] : []
                yield put(getHighSchoolSuccess(info))
                yield put(actionTakenHighSchool('edit'))
            } else yield put(actionTakenHighSchool('create'))
        }
        return
    } catch (err) {
        return yield put(showLoaderAcademicInfo());
    }
}

//Request para CollegeEducation
function* getCollegeEducationRequest({ payload }) {
    try {
        yield put(showLoaderAcademicInfo());
        const response = yield call(axiosGetAcademicInfo, payload);
        if (response !== false) {
            yield put(hideLoaderAcademicInfo())
            yield put(getCollegeEducationSuccess(response));
        }
        return
    } catch (err) {
        return yield put(showLoaderAcademicInfo());
    }
}

//Request para ComplementaryCourses
function* getComplementaryCoursesRequest({ payload }) {
    try {
        yield put(showLoaderAcademicInfo());
        const response = yield call(axiosGetAcademicInfo, payload);
        if (response !== false) {
            yield put(hideLoaderAcademicInfo())
            yield put(getComplementaryCoursesSuccess(response));
        }
        return
    } catch (err) {
        return yield put(showLoaderAcademicInfo());
    }
}

export function* createAcademicInfo() {
    yield takeEvery(CREATE_ACADEMIC_INFO, createAcademicInfoRequest)
}

export function* editAcademicInfo() {
    yield takeEvery(EDIT_ACADEMIC_INFO, editAcademicInfoRequest)
}

export function* deleteAcademicInfo() {
    yield takeEvery(DELETE_ACADEMIC_INFO, deleteAcademicInfoRequest)
}

export function* getEducationLevelSaga() {
    yield takeEvery(GET_EDUCATION_LEVEL, getEducationLevelRequest)
}

export function* getHighSchoolSaga() {
    yield takeEvery(GET_HIGH_SCHOOL, getHighSchoolRequest)
}

export function* getCollegeEducationSaga() {
    yield takeEvery(GET_COLLEGE_EDUCATION, getCollegeEducationRequest)
}

export function* getComplementaryCoursesSaga() {
    yield takeEvery(GET_COMPLEMENTARY_COURSES, getComplementaryCoursesRequest)
}
export default function* rootSaga() {
    yield all([
        fork(createAcademicInfo),
        fork(editAcademicInfo),
        fork(deleteAcademicInfo),
        fork(getHighSchoolSaga),
        fork(getEducationLevelSaga),
        fork(getCollegeEducationSaga),
        fork(getComplementaryCoursesSaga)
    ])
}