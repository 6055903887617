import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getUserRoleSuccess } from 'appRedux/actions/Authorization'
import { GET_USER_ROLE } from 'constants/ActionTypes'

const serviceGetUserRole = async idUser => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}authorization/user_role/${idUser}`
  )
}

function* getUserRoleRequest({ payload }) {
  try {
    const { data } = yield call(serviceGetUserRole, payload.id_user)
    return yield put(getUserRoleSuccess(data))
  } catch (err) {
    //Validar que hago cuando entre en el catch
  }
}

export function* getUserRole() {
  yield takeEvery(GET_USER_ROLE, getUserRoleRequest)
}

export default function* rootSaga() {
  yield all([fork(getUserRole)])
}