import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pingTokenTest } from "appRedux/actions/Auth";
import asyncComponent from "util/asyncComponent";
import {
  PSYCHOSOCIAL_ADVISOR_ID,
  COMMUNICATIONS_SUPPORT_ID,
  ACADEMIC_COORDINATOR_ID,
} from "constants/Roles";

const Members = ({ match }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { id_role } = useSelector(({ authorization }) => authorization);
  const { user } = useSelector(({ auth }) => auth);

  /*
    Cada vez que se cambia la ruta se acciona una saga que hace una peticion al backend para verificar el token, 
    Si este token es válido todo sigue igual, si no se cierra sesion. 
  */
  useEffect(() => {
    dispatch(pingTokenTest());
  }, [dispatch, location]);

  const getRoutes = () => {
    let routes;
    if (id_role === PSYCHOSOCIAL_ADVISOR_ID) {
      routes = (
        <Switch>
          <Route
            path={`${match.url}/applicants`}
            component={asyncComponent(() =>
              import("components/admin/members/scholarship/Applicants")
            )}
          />
          <Route
            path={`${match.url}/prescholarship-evaluation/:id_user/:id_cv_user`}
            component={asyncComponent(() =>
              import(
                "components/admin/scholarshipHoldersReview/PreScholarshipEvaluation/index"
              )
            )}
          />
          <Route
            path={`${match.url}/prospect/curriculum-vitae`}
            component={asyncComponent(() =>
              import("../../curriculumVitae/index")
            )}
          />
          <Route
            path={`${match.url}/preScholarshipHolders`}
            component={asyncComponent(() =>
              import(
                "components/admin/members/scholarship/PreScholarshipHolders"
              )
            )}
          />
          <Route
            path={`${match.url}/psychosocialInterview/:id_user/:id_cv_user`}
            component={asyncComponent(() =>
              import(
                "components/admin/scholarshipHoldersReview/PsychosocialInterview"
              )
            )}
          />
        </Switch>
      );
    } else if (id_role === COMMUNICATIONS_SUPPORT_ID) {
      routes = (
        <Switch>
          <Route
            path={`${match.url}/registered`}
            component={asyncComponent(() =>
              import("components/admin/members/Registered")
            )}
          />
          <Route
            path={`${match.url}/scholarshipHolders`}
            component={asyncComponent(() =>
              import("components/admin/members/scholarship/ScholarshipHolders")
            )}
          />
          <Route
            path={`${match.url}/internReview/:id_user/:id_cv_user`}
            component={asyncComponent(() =>
              import("components/admin/scholarshipHoldersReview/InternReview")
            )}
          />
          <Route
            path={`${match.url}/prospect/curriculum-vitae`}
            component={asyncComponent(() =>
              import("../../curriculumVitae/index")
            )}
          />
          <Route
            path={`${match.url}/usersStages`}
            component={asyncComponent(() =>
              import("components/admin/members/UsersStages")
            )}
          />
        </Switch>
      );
    } else if (id_role === ACADEMIC_COORDINATOR_ID) {
      routes = (
        <Switch>
          <Route
            path={`${match.url}/psychosocialPreApproved`}
            component={asyncComponent(() =>
              import(
                "components/admin/members/scholarship/PsychosocialPreApproved"
              )
            )}
          />
          <Route
            path={`${match.url}/technicalInterview/:id_user/:id_cv_user`}
            component={asyncComponent(() =>
              import(
                "components/admin/scholarshipHoldersReview/TechnicalInterview"
              )
            )}
          />
          <Route
            path={`${match.url}/scholarshipHolders`}
            component={asyncComponent(() =>
              import("components/admin/members/scholarship/ScholarshipHolders")
            )}
          />
          <Route
            path={`${match.url}/preScholarshipHolders`}
            component={asyncComponent(() =>
              import(
                "components/admin/members/scholarship/PreScholarshipHolders"
              )
            )}
          />
        </Switch>
      );
    } else if (user.admin) {
      routes = (
        <Switch>
          <Route
            path={`${match.url}/registered`}
            component={asyncComponent(() =>
              import("components/admin/members/Registered")
            )}
          />
          <Route
            path={`${match.url}/prospects`}
            component={asyncComponent(() =>
              import("components/admin/members/MembersTable/MembersTableGrid")
            )}
          />
          <Route
            path={`${match.url}/usersStages`}
            component={asyncComponent(() =>
              import("components/admin/members/UsersStages")
            )}
          />
          <Route
            path={`${match.url}/reportFilters`}
            component={asyncComponent(() =>
              import("components/admin/members/ReportFilters")
            )}
          />
          <Route
            path={`${match.url}/graduates`}
            component={asyncComponent(() =>
              import("components/admin/members/Graduates")
            )}
          />

          {/* Becarios */}
          <Route
            path={`${match.url}/applicants`}
            component={asyncComponent(() =>
              import("components/admin/members/scholarship/Applicants")
            )}
          />
          <Route
            path={`${match.url}/preScholarshipHolders`}
            component={asyncComponent(() =>
              import(
                "components/admin/members/scholarship/PreScholarshipHolders"
              )
            )}
          />
          <Route
            path={`${match.url}/psychosocialPreApproved`}
            component={asyncComponent(() =>
              import(
                "components/admin/members/scholarship/PsychosocialPreApproved"
              )
            )}
          />
          <Route
            path={`${match.url}/scholarshipHolders`}
            component={asyncComponent(() =>
              import("components/admin/members/scholarship/ScholarshipHolders")
            )}
          />
          <Route
            path={`${match.url}/student-cohorts`}
            component={asyncComponent(() =>
              import("components/admin/members/scholarship/StudentCohorts")
            )}
          />

          {/* Revisión becarios */}
          <Route
            path={`${match.url}/prescholarship-evaluation/:id_user/:id_cv_user`}
            component={asyncComponent(() =>
              import(
                "components/admin/scholarshipHoldersReview/PreScholarshipEvaluation/index"
              )
            )}
          />
          <Route
            path={`${match.url}/psychosocialInterview/:id_user/:id_cv_user`}
            component={asyncComponent(() =>
              import(
                "components/admin/scholarshipHoldersReview/PsychosocialInterview"
              )
            )}
          />
          <Route
            path={`${match.url}/technicalInterview/:id_user/:id_cv_user`}
            component={asyncComponent(() =>
              import(
                "components/admin/scholarshipHoldersReview/TechnicalInterview"
              )
            )}
          />
          <Route
            path={`${match.url}/internReview/:id_user/:id_cv_user`}
            component={asyncComponent(() =>
              import("components/admin/scholarshipHoldersReview/InternReview")
            )}
          />

          {/* inscritos */}
          <Route
            path={`${match.url}/sponsorship/requesters`}
            component={asyncComponent(() =>
              import("components/admin/members/sponsorship/Requesters")
            )}
          />
          <Route
            path={`${match.url}/sponsorship/preEndorsed`}
            component={asyncComponent(() =>
              import("components/admin/members/sponsorship/PreEndorsed")
            )}
          />
          <Route
            path={`${match.url}/sponsorship/preSponsorship`}
            component={asyncComponent(() =>
              import("components/admin/members/sponsorship/PreSponsorship")
            )}
          />
          <Route
            path={`${match.url}/sponsorship/preSponsorshipApproved`}
            component={asyncComponent(() =>
              import(
                "components/admin/members/sponsorship/PreSponsorshipApproved"
              )
            )}
          />
          <Route
            path={`${match.url}/sponsorship/sponsorship`}
            component={asyncComponent(() =>
              import("components/admin/members/sponsorship/Sponsorship")
            )}
          />

          {/* Revisión de inscritos */}
          <Route
            path={`${match.url}/loanApplicationReview/:id_user/:id_cv_user`}
            component={asyncComponent(() =>
              import(
                "components/admin/sponsorshipReview/LoanApplicationReview/index"
              )
            )}
          />
          <Route
            path={`${match.url}/psychosocialInterviewLoan/:id_user/:id_cv_user`}
            component={asyncComponent(() =>
              import(
                "components/admin/sponsorshipReview/PsychosocialInterview/index"
              )
            )}
          />
          <Route
            path={`${match.url}/technicalInterviewLoan/:id_user/:id_cv_user`}
            component={asyncComponent(() =>
              import(
                "components/admin/sponsorshipReview/TechnicalInterview/index"
              )
            )}
          />

          <Route
            path={`${match.url}/prospect/curriculum-vitae`}
            component={asyncComponent(() =>
              import("../../curriculumVitae/index")
            )}
          />
          <Route
            path={`${match.url}/prospect/scholarship-prospect`}
            component={asyncComponent(() =>
              import("../../scholarshipProspect/index")
            )}
          />
        </Switch>
      );
    }
    return routes;
  };

  return (id_role || user.admin) && getRoutes();
};

export default Members;
