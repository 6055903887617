import {
    GET_COUNTRIES_SUCCESS, GET_STATES_BY_COUNTRY_ID_SUCCESS, GET_CITIES_BY_STATE_ID_SUCCESS, GET_CITY_BY_ID_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    countriesList: [],
    listStatesByCountryId: {
        births: [],
        address: [],
        academicInfo: [],
        complementaryCourses: [],
    },
    listCitiesByStateId: {
        births: [],
        address: [],
        academicInfo: [],
        complementaryCourses: [],
    },
    cityInformation: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COUNTRIES_SUCCESS: {
            return {
                ...state,
                countriesList: action.payload
            };
        }
        case GET_STATES_BY_COUNTRY_ID_SUCCESS: {
            return {
                ...state,
                listStatesByCountryId: {
                    ...state.listStatesByCountryId,
                    [action.fieldName]: action.payload
                }
            };
        }
        case GET_CITIES_BY_STATE_ID_SUCCESS: {
            return {
                ...state,
                listCitiesByStateId: {
                    ...state.listCitiesByStateId,
                    [action.fieldName]: action.payload
                }
            }
        }
        case GET_CITY_BY_ID_SUCCESS:
            return {
                ...state,
                cityInformation: action.payload
            }
        default:
            return state;
    }
}