import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { hideLoaderWorkExperience, showMessageDialog, fetchWorkExperiencesSuccess } from 'appRedux/actions/WorkExperience'
import { storage } from 'firebase/firebase'
import axios from 'axios'
import { ADD_WORK_EXPERIENCE, TYPE_MESSAGE_ERROR, TYPE_MESSAGE_SUCCESS, FETCH_WORK_EXPERIENCES, DELETE_WORK_EXPERIENCE, UPDATE_WORK_EXPERIENCE } from "constants/ActionTypes"
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";

const serviceUpdateWorkExperience = async payload => {
    const body = {
        id_cv_user: payload.id_cv_user,
        company_name: payload.company_name,
        company_url: payload.company_url,
        position: payload.position,
        start_date: payload.start_date,
        finish_date: payload.finish_date,
        id_work_certf: payload.id_work_certf,
        functions_performed: payload.functions_performed,
        contract_type: payload.contract_type,
        name_cont_person: payload.name_cont_person,
        phone_cont_person: payload.phone_cont_person,
        email_cont_person: payload.email_cont_person,
        phone_id_city: payload.phone_id_city,
        phone_id_country: payload.phone_id_country,
        vinculation_status: payload.vinculation_status
    }
    const { data } = await axios.patch(`${process.env.REACT_APP_API_URL}user_cv/${payload.id}/work_info`, body, config())
    return data;
}

const serviceDeleteWorkExperience = async payload => {
    const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}user_cv/${payload.id}/work_info`, config())
    return data;
}

const serviceListWorkExperiences = async payload => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}user_cv/${payload.id_cv_user}/work_info`, config())
    return data;
}

const serviceAddFileBD = async payload => {
    return await axios.post(`${process.env.REACT_APP_API_URL}user_cv/${payload.id_work_info}/${payload.id_user}/work_info/updateFile`, payload.file, config())
}

const serviceUploadWorkCert = async payload => {
    const { id_cv_user, user_name, /*id_work_info,*/ workFile } = payload;
    var storageRef = storage.ref()
    var spaceRef = storageRef.child(`curriculum-vitae/${id_cv_user}-${user_name.replaceAll(' ', '-')}/work-info/${workFile.name}`)
    await spaceRef.put(workFile)
    const publicURL = await spaceRef.getDownloadURL();
    const metada = await spaceRef.getMetadata()
    return { ...metada, publicURL }
}

const serviceAddWorkExperience = async payload => {
    const body = {
        id_cv_user: payload.id_cv_user,
        company_name: payload.company_name,
        company_url: payload.company_url,
        position: payload.position,
        start_date: payload.start_date,
        finish_date: payload.finish_date,
        id_work_certf: payload.id_work_certf,
        functions_performed: payload.functions_performed,
        contract_type: payload.contract_type,
        name_cont_person: payload.name_cont_person,
        phone_cont_person: payload.phone_cont_person,
        email_cont_person: payload.email_cont_person,
        phone_id_city: payload.phone_id_city,
        phone_id_country: payload.phone_id_country,
        vinculation_status: payload.vinculation_status
    }
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}user_cv/${payload.id_cv_user}/work_info`, body, config())
    return data;
}

function* addWorkExperienceRequest({ payload }) {
    const { locale } = payload;
    try {
        if (payload.finish_date !== undefined && payload.finish_date === '') payload.finish_date = undefined;
        const createWorkExperience = yield call(serviceAddWorkExperience, payload)
        if (payload.workFile !== undefined && payload.workFile !== null && payload.workFile !== '') {
            const id_work_info = createWorkExperience.id;
            //Subida de archivo
            const file = yield call(serviceUploadWorkCert, { ...payload, id_work_info })
            //Guardar referencia en BD 
            yield call(serviceAddFileBD, { ...payload, file, id_work_info })
        }
        //Traer la lista actualizada
        const getAllWorkExperiences = yield call(serviceListWorkExperiences, payload);
        //Solución formato fechas
        yield put(fetchWorkExperiencesSuccess(getAllWorkExperiences))
        const message = getMessageByString(
            locale,
            'cv.workexperience.message.create'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}


function* fetchWorkExperiencesRequest({ payload }) {
    const { locale } = payload;
    try {
        //Traer la lista actualizada
        const getAllWorkExperiences = yield call(serviceListWorkExperiences, payload);
        yield put(fetchWorkExperiencesSuccess(getAllWorkExperiences))
        yield put(hideLoaderWorkExperience())
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* deleteWorkExperienceRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceDeleteWorkExperience, payload);
        const getAllWorkExperiences = yield call(serviceListWorkExperiences, payload);
        yield put(fetchWorkExperiencesSuccess(getAllWorkExperiences))
        yield put(hideLoaderWorkExperience())
        const message = getMessageByString(
            locale,
            'cv.workexperience.message.delete'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* updateWorkExperienceRequest({ payload }) {
    const { locale } = payload;
    try {
        if (payload.finish_date !== undefined && payload.finish_date === '') payload.finish_date = null;
        if (payload.workFile !== undefined && payload.workFile.lastModified !== undefined) {
            const file = yield call(serviceUploadWorkCert, payload)
            const id_work_info = payload.id;
            yield call(serviceAddFileBD, { ...payload, file, id_work_info })
        } else if (payload.workFile === undefined) payload.id_work_certf = null;

        yield call(serviceUpdateWorkExperience, payload)
        const getAllWorkExperiences = yield call(serviceListWorkExperiences, payload);
        yield put(fetchWorkExperiencesSuccess(getAllWorkExperiences))
        const message = getMessageByString(
            locale,
            'cv.workexperience.message.update'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

export function* updateWorkExperience() {
    yield takeEvery(UPDATE_WORK_EXPERIENCE, updateWorkExperienceRequest)
}

export function* deleteWorkExperience() {
    yield takeEvery(DELETE_WORK_EXPERIENCE, deleteWorkExperienceRequest)
}

export function* fetchWorkExperiences() {
    yield takeEvery(FETCH_WORK_EXPERIENCES, fetchWorkExperiencesRequest)
}

export function* addWorkExperience() {
    yield takeEvery(ADD_WORK_EXPERIENCE, addWorkExperienceRequest)
}

export default function* rootSaga() {

    yield all([
        fork(addWorkExperience),
        fork(fetchWorkExperiences),
        fork(deleteWorkExperience),
        fork(updateWorkExperience)
    ])
}