import {
    FETCH_SCHOLARSHIP_PROSPECT,
    FETCH_SCHOLARSHIP_PROSPECT_SUCCESS,
    SCHOLARSHIP_PROSPECT_ON_SHOW_LOADER,
    SCHOLARSHIP_PROSPECT_ON_HIDE_LOADER,
    SCHOLARSHIP_PROSPECT_ON_SHOW_MESSAGE,
    SCHOLARSHIP_PROSPECT_ON_HIDE_MESSAGE,
    SCHOLARSHIP_PROSPECT_VINCULATION_STATUS,
    SCHOLARSHIP_PROSPECT_VINCULATION_STATUS_SUCCESS,
    SCHOLARSHIP_PROSPECT_MODAL,
    SCHOLARSHIP_PROSPECT_MODAL_DATA,
    FETCH_SCHOLARSHIP_HOLDERS,
    FETCH_SCHOLARSHIP_HOLDERS_SUCCESS,
    SCHOLARSHIP_PROSPECT_HOLDER_SINGLE,
    SCHOLARSHIP_PROSPECT_HOLDER_SINGLE_FIELD,
    SCHOLARSHIP_PROSPECT_ACTIVE_TAB,
    CREATE_SCHOLARSHIP_HOLDERS,
    SET_BASE_KEY_SCHOLARSHIP_PROSPECT,
    UNPOSTULATE_SCHOLARSHIP,
    REAPPLY_SCHOLARSHIP,
    VALIDATE_SCHOLARSHIP_STAGE,
    VALIDATE_SCHOLARSHIP_STAGE_SUCCESS
} from 'constants/ActionTypes'

export const setBaseKeyScholarshipProspect = baseKey => {
    return {
        type: SET_BASE_KEY_SCHOLARSHIP_PROSPECT,
        payload: baseKey
    }
}

export const showLoader = () => {
    return {
        type: SCHOLARSHIP_PROSPECT_ON_SHOW_LOADER
    }
}

export const hideLoader = () => {
    return {
        type: SCHOLARSHIP_PROSPECT_ON_HIDE_LOADER
    }
}

export const showMessageDialog = configMessage => {
    return {
        type: SCHOLARSHIP_PROSPECT_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}

export const hideMessageDialog = () => {
    return {
        type: SCHOLARSHIP_PROSPECT_ON_HIDE_MESSAGE
    }
}
export const fetchScholarshipProspect = (payload) => {
    return {
        type: FETCH_SCHOLARSHIP_PROSPECT,
        payload: payload
    }
}

export const fetchScholarshipProspectSuccess = (payload) => {
    return {
        type: FETCH_SCHOLARSHIP_PROSPECT_SUCCESS,
        payload: payload
    }
}

export const checkVinculationUserWorks = (payload) => {
    return {
        type: SCHOLARSHIP_PROSPECT_VINCULATION_STATUS,
        payload: payload
    }
}

export const checkVinculationUserWorksSuccess = (payload) => {
    return {
        type: SCHOLARSHIP_PROSPECT_VINCULATION_STATUS_SUCCESS,
        payload: payload
    }
}

export const openOrCloseModal = () => {
    return {
        type: SCHOLARSHIP_PROSPECT_MODAL
    }
}

export const changeModalData = (payload) => {
    return {
        type: SCHOLARSHIP_PROSPECT_MODAL_DATA,
        payload: payload
    }
}

export const fetchScholarshipHolders = (payload) => {
    return {
        type: FETCH_SCHOLARSHIP_HOLDERS,
        payload: payload
    }
}

export const fetchScholarshipHoldersSuccess = (payload) => {
    return {
        type: FETCH_SCHOLARSHIP_HOLDERS_SUCCESS,
        payload: payload
    }
}

export const changeHolderSingle = (payload) => {
    return {
        type: SCHOLARSHIP_PROSPECT_HOLDER_SINGLE,
        payload: payload
    }
}

export const changeHolderSingleField = (payload) => {
    return {
        type: SCHOLARSHIP_PROSPECT_HOLDER_SINGLE_FIELD,
        payload: payload
    }
}

export const changeActiveTab = (payload) => {
    return {
        type: SCHOLARSHIP_PROSPECT_ACTIVE_TAB,
        payload: payload
    }
}

export const createScholarshipHolders = (payload) => {
    return {
        type: CREATE_SCHOLARSHIP_HOLDERS,
        payload: payload
    }
}


export const unpostulateScholarship = (payload) => {
    return {
        type: UNPOSTULATE_SCHOLARSHIP,
        payload: payload
    }
}

export const reapplyScholarship = (payload) => {
    return {
        type: REAPPLY_SCHOLARSHIP,
        payload: payload
    }
}

export const validateScholarshipStage = (payload) => {
    return {
        type: VALIDATE_SCHOLARSHIP_STAGE,
        payload
    }
}

export const validateScholarshipStagSuccess = (payload) => {
    return {
        type: VALIDATE_SCHOLARSHIP_STAGE_SUCCESS,
        payload
    }
}