import {
    ADD_SCHOLARSHIP,
    REGISTER_SCHOLARSHIPS_MODAL_DELIVERY_ADD,
    REGISTER_SCHOLARSHIPS_CHANGE,
    FETCH_SCHOLARSHIP_SUCCESS,
    REGISTER_SCHOLARSHIP_ON_HIDE_LOADER,
    REGISTER_SCHOLARSHIP_ON_SHOW_MESSAGE,
    REGISTER_SCHOLARSHIP_SEARCH_CITY,
    REGISTER_SCHOLARSHIP_SEARCH_CITY_SUCCESS,
    GET_SPONSORS_SCHOLARSHIP,
    FETCH_SPONSORS_SUCCESS,
    REGISTER_SCHOLARSHIP_ON_SHOW_LOADER,
    REGISTER_SCHOLARSHIP_ON_HIDE_MESSAGE,
    FETCH_SCHOLARSHIPS,
    REGISTER_SCHOLARSHIPS_MODAL_DELIVERY_EDIT,
    REGISTER_SCHOLARSHIPS_MODAL_CLEAN,
    DELETE_SCHOLARSHIP,
    EDIT_SCHOLARSHIP,
    REGISTER_SCHOLARSHIPS_CHANGE_ALL
} from 'constants/ActionTypes'


export const openOrCloseModalAddRegisterScholarships = () => {
    return {
        type: REGISTER_SCHOLARSHIPS_MODAL_DELIVERY_ADD
    }
}
export const openOrCloseModalEditRegisterScholarship = () => {
    return {
        type: REGISTER_SCHOLARSHIPS_MODAL_DELIVERY_EDIT
    }
}

export const changeScholarship = payload => {
    return {
        type: REGISTER_SCHOLARSHIPS_CHANGE,
        payload: payload
    }
}

export const cleanModal = () => {
    return {
        type: REGISTER_SCHOLARSHIPS_MODAL_CLEAN
    }
}
export const addScholarship = (payload) => {
    return {
        type: ADD_SCHOLARSHIP,
        payload: payload
    }
}
export const showLoaderScholarship = () => {
    return {
        type: REGISTER_SCHOLARSHIP_ON_SHOW_LOADER
    }
}

export const hideLoaderScholarship = () => {
    return {
        type: REGISTER_SCHOLARSHIP_ON_HIDE_LOADER
    }
}

export const showMessageDialog = configMessage => {
    return {
        type: REGISTER_SCHOLARSHIP_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}
export const hideMessageDialog = () => {
    return {
        type: REGISTER_SCHOLARSHIP_ON_HIDE_MESSAGE
    }
}

export const fetchScholarship = (payload) => {
    return {
        type: FETCH_SCHOLARSHIPS,
        payload: payload
    }
};

export const fetchScholarshipSuccess = (payload) => {
    return {
        type: FETCH_SCHOLARSHIP_SUCCESS,
        payload: payload
    }
}

export const deleteScholarship = (payload) => {
    return {
        type: DELETE_SCHOLARSHIP,
        payload: payload
    }
}

export const editScholarship = (payload) => {
    return {
        type: EDIT_SCHOLARSHIP,
        payload: payload
    }
}
export const changeScholarshipAll = (payload) => {
    return {
        type: REGISTER_SCHOLARSHIPS_CHANGE_ALL,
        payload: payload
    }
}

export const searchCity = (payload) => {
    return {
        type: REGISTER_SCHOLARSHIP_SEARCH_CITY,
        payload: payload
    }
}
export const searchCitySuccess = (payload) => {
    return {
        type: REGISTER_SCHOLARSHIP_SEARCH_CITY_SUCCESS,
        payload: payload
    }
}

export const onGetSponsors = () => {
    return {
        type: GET_SPONSORS_SCHOLARSHIP
    }
}

export const fetchSponsorsSuccess = (sponsorsList) => {
    return {
        type: FETCH_SPONSORS_SUCCESS,
        payload: sponsorsList
    }
};


