import React from 'react'
import loader from "../../assets/images/loaderCV.svg"

export const ProgressView = ({ className, backgroundColor = "#000000", opacity = 0.5 }) => (
  <div className='gx-loader-view gx-loader-position' style={{ opacity: opacity, backgroundColor: backgroundColor }}>
    <div className={`loader ${className}`}>
      <img src={loader} alt='loader' />
    </div>
  </div>
)

export default ProgressView