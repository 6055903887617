import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Divider, message } from "antd";
import { Formik } from "formik";
import { Form, Input, Checkbox } from "formik-antd";
import * as Yup from "yup";
import {
  hideMessage,
  showAuthLoader,
  userGoogleSignIn,
  userSignIn,
} from "appRedux/actions/Auth";
import { getMessageByString, getMessageValidate } from "util/Messages";
import IntlMessages from "util/IntlMessages";
import {
  TECHNICAL_MENTOR_ID,
  PROSPECT_STUDENT_ID,
  PSYCHOSOCIAL_ADVISOR_ID,
  COMMUNICATIONS_SUPPORT_ID,
  ACADEMIC_COORDINATOR_ID,
} from "constants/Roles";
import CircularProgress from "components/CircularProgress/index";
import logo from "assets/images/logo.png";
import { GoogleOutlined } from "@ant-design/icons";

const SignIn = () => {
  const dispatch = useDispatch();

  const { loader, alertMessage, showMessage, authUser, user } = useSelector(
    ({ auth }) => auth
  );
  const { locale } = useSelector(({ settings }) => settings);
  const { id_role } = useSelector(({ authorization }) => authorization);

  const buttonStyles = {
    color: "#1978D2",
    borderColor: "#1978D2",
    width: "300px",
    marginLeft: 15,
  };

  const logoStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const history = useHistory();
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      if (user.admin) {
        history.push("/main/dashboard/admin");
      }
      switch (id_role) {
        case PROSPECT_STUDENT_ID:
          history.push("/main/dashboard/geek");
          break;
        case TECHNICAL_MENTOR_ID:
          history.push("/admin/mentor/students");
          break;
        case PSYCHOSOCIAL_ADVISOR_ID:
          history.push("/admin/members/applicants");
          break;
        case COMMUNICATIONS_SUPPORT_ID:
          history.push("/admin/members/registered");
          break;
        case ACADEMIC_COORDINATOR_ID:
          history.push("/admin/members/psychosocialPreApproved");
          break;
        default:
          break;
      }
    } else {
      if (Object.keys(user).length > 0) {
        history.push("/signup");
      }
    }
  }, [authUser, dispatch, history, id_role, showMessage, user]);

  const onFinish = (values) => {
    if (user !== null) {
      values.providerUser = user;
    }
    dispatch(showAuthLoader());
    values.locale = locale;
    dispatch(userSignIn(values));
  };

  const SignupSchema = Yup.object().shape({
    password: Yup.string().required(
      getMessageValidate(locale, "contraseña", "valid.field.required")
    ),
    email: Yup.string()
      .email(getMessageValidate(locale, "email", "valid.field.mail"))
      .required(getMessageValidate(locale, "email", "valid.field.required")),
  });

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div
          style={{ maxWidth: 700, height: "100%" }}
          className="gx-app-login-main-content"
        >
          <div className="gx-app-logo-content" style={{ padding: "20px 35px" }}>
            <div style={logoStyle} className="gx-app-logo gx-mb-3">
              <img alt="Logo Agile Innova" src={logo} />
            </div>
            <div className="gx-app-logo-wid">
              <br />
              <p
                style={{
                  textAlign: "justify",
                  color: "DimGrey",
                  whiteSpace: "break-spaces",
                }}
              >
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <br />
              <p
                style={{
                  textAlign: "justify",
                  color: "DimGrey",
                  whiteSpace: "break-spaces",
                }}
              >
                <IntlMessages id="app.userAuth.bySigning2" />
              </p>
              {/* <span className="gx-contact-buttoms gx-mt-4">
                <div
                  className="gx-mb-2 gx-text-center"
                  style={{ color: "#1978D2" }}
                >
                  <IntlMessages id="app.userAuth.orConnectWith" />
                </div>
                <ContactMeans />
              </span> */}
              {/* <span className="gx-mb-2" style={{ color: "dimgrey" }}>
                <IntlMessages id="app.userAuth.technicalSupport" />
              </span> */}
              {/* <ul className="gx-support-link gx-mt-2">
                <li>
                  <small>
                    <Tooltip
                      placement="top"
                      title="contacto@agileinnova.org"
                      arrowPointAtCenter
                    >
                      <MailOutlined
                        onClick={() =>
                          window.open("mailto:contacto@agileinnova.org", "_self")
                        }
                      />
                    </Tooltip>
                  </small>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="gx-app-login-content">
            <Formik
              onSubmit={onFinish}
              initialValues={{
                email: "",
                password: "",
                remember: true,
              }}
              validationSchema={SignupSchema}
            >
              {({ errors, touched }) => (
                <Form name="basic" className="gx-signin-form gx-form-row0">
                  <div className="gx-flex-row gx-justify-content-between">
                    <span>
                      {/* <IntlMessages id='app.userAuth.orSignInWidth' />
                      <br></br>
                      <br />
                      <Button
                        style={buttonStyles}
                        onClick={() => {
                          let values = {}
                          values.locale = locale
                          dispatch(showAuthLoader())
                          dispatch(userGoogleSignIn(values))
                        }}
                      >
                        {' '}
                        <IntlMessages id='button.signupwithgoogle' />
                        <GoogleOutlined />{' '}
                      </Button>
                      <br></br> */}
                      {/* <Button
                        style={buttonStyles}
                        onClick={() => {
                          let values = {};
                          values.locale = locale;
                          dispatch(showAuthLoader());
                          dispatch(userFacebookSignIn(values));
                        }}
                      >
                        {" "}
                        <IntlMessages id="button.signupwithfacebook" />
                        <FacebookOutlined />
                      </Button>{" "}
                      <br></br> */}
                      {/* <Button
                        style={buttonStyles}
                        onClick={() => {
                          let values = {};
                          values.locale = locale;
                          dispatch(showAuthLoader());
                          dispatch(userGithubSignIn(values));
                        }}
                      >
                        {" "}
                        <IntlMessages id="button.signupwithgithub" />
                        <GithubOutlined />
                      </Button> */}
                      {/* <li>
                        <br />
                        <GoogleOutlined
                          onClick={() => {
                            let values = {};
                            values.locale = locale;
                            dispatch(showAuthLoader());
                            dispatch(userGoogleSignIn(values));
                          }}
                          title="Google"
                        />
                      </li>
                      <li>
                        <br />
                        <FacebookOutlined
                          onClick={() => {
                            let values = {};
                            values.locale = locale;
                            dispatch(showAuthLoader());
                            dispatch(userFacebookSignIn(values));
                          }}
                          title="Facebook"
                        />
                      </li>
                      <li>
                        <br />
                        <GithubOutlined
                          onClick={() => {
                            let values = {};
                            values.locale = locale;
                            dispatch(showAuthLoader());
                            dispatch(userGithubSignIn(values));
                          }}
                          title="Github"
                        />
                      </li> */}
                      {/* </ul> */}
                    </span>
                  </div>
                  {/* <Divider plain>
                    <IntlMessages id='app.userAuth.or' />
                  </Divider> */}
                  <span>
                    <p>
                      <IntlMessages id="app.userAuth.signIn.description" />
                    </p>
                  </span>
                  <Form.Item name="email">
                    <Input
                      suffix={<span />}
                      type="email"
                      name="email"
                      placeholder={getMessageByString(
                        locale,
                        "app.userAuth.mail"
                      )}
                    />
                  </Form.Item>
                  <Form.Item name="password">
                    <Input.Password
                      suffix={<span />}
                      name="password"
                      placeholder={getMessageByString(
                        locale,
                        "app.userAuth.insert.password"
                      )}
                    ></Input.Password>
                  </Form.Item>
                  <div className="ant-row ant-form-item">
                    <div className="ant-col">
                      <Checkbox name="remember">
                        <IntlMessages id="appModule.rememberMe" />
                      </Checkbox>
                      <Button
                        type="primary"
                        className="gx-mb-0"
                        htmlType="submit"
                        value="Iniciar Sesión"
                      >
                        <IntlMessages id="app.userAuth.signIn" />
                      </Button>
                    </div>
                  </div>
                  <Link className="gx-login-form-forgot" to="/forgotpassword">
                    <IntlMessages id="app.userAuth.forgotPassword" />
                  </Link>
                  <Divider plain></Divider>
                  <div className="ant-row ant-form-item">
                    <div className="ant-col">
                      <Button type="success" className="gx-mt-3">
                        <Link to="/signUp">
                          <IntlMessages id="app.userAuth.signUp" />
                        </Link>
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {loader && (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && message.error(alertMessage.toString())}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
