import {
    SAVE_STUDENT_COHORT,
    DELETE_STUDENT_COHORT_AFTER_DEADLINE,
    FETCH_STUDENT_CANDIDATE_COHORT,
    FETCH_STUDENT_CANDIDATE_COHORT_SUCCESS,
    FETCH_STUDENT_COHORT,
    FETCH_STUDENT_COHORT_SUCCESS,
    STUDENT_CANDIDATE_COHORT_ON_SHOW_MESSAGE,
    STUDENT_CANDIDATE_COHORT_ON_HIDE_MESSAGE,
    FILTERS_STUDENT_COHORT,
    FILTERS_STUDENT_COHORT_SAVE,
    STUDENT_COHORT_ON_SHOW_LOADER,
    STUDENT_COHORT_ON_HIDE_LOADER
} from 'constants/ActionTypes'

export const showMessageDialog = configMessage => {
    return {
        type: STUDENT_CANDIDATE_COHORT_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}

export const hideMessageDialog = () => {
    return {
        type: STUDENT_CANDIDATE_COHORT_ON_HIDE_MESSAGE
    }
}

export const showLoaderStudentCohort = () => {
    return {
        type: STUDENT_COHORT_ON_SHOW_LOADER
    }
}

export const hideLoaderStudentCohort = () => {
    return {
        type: STUDENT_COHORT_ON_HIDE_LOADER
    }
}

export const fetchStudentCandidateCohort = (payload) => {
    return {
        type: FETCH_STUDENT_CANDIDATE_COHORT,
        payload: payload
    }
}

export const fetchStudentCandidateCohortSuccess = (payload) => {
    return {
        type: FETCH_STUDENT_CANDIDATE_COHORT_SUCCESS,
        payload: payload
    }
}

export const fetchStudentCohort = (payload) => {
    return {
        type: FETCH_STUDENT_COHORT,
        payload: payload
    }
}

export const fetchStudentCohortSuccess = (payload) => {
    return {
        type: FETCH_STUDENT_COHORT_SUCCESS,
        payload: payload
    }
}

export const saveStudentCohort = (payload) => {
    return {
        type: SAVE_STUDENT_COHORT,
        payload: payload
    }
}

export const deleteStudentCohortAfterDeadline = (payload) => {
    return {
        type: DELETE_STUDENT_COHORT_AFTER_DEADLINE,
        payload: payload
    }
}

export const changeFilters = payload => {
    return {
        type: FILTERS_STUDENT_COHORT,
        payload: payload
    }
}

export const saveFilters = payload => {
    return {
        type: FILTERS_STUDENT_COHORT_SAVE,
        payload: payload
    }
}