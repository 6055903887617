import {
    GET_SINGLE_PROSPECT,
    FETCH_SINGLE_PROSPECT_SUCCESS,
    VALIDATE_CV_USER,
    VALIDATE_CV_USER_SUCCESS,
    SHOW_MESSAGE_DIALOG_CV_USER
} from "constants/ActionTypes"

export const showMessageDialog = configMessage => {
    return {
        type: SHOW_MESSAGE_DIALOG_CV_USER,
        payload: configMessage
    }
}

export const onGetUserCv = (userCv) => {
    return {
        type: GET_SINGLE_PROSPECT,
        userCv
    }
}

export const fetchSingleUserCvSuccess = (userCv) => {
    return {
        type: FETCH_SINGLE_PROSPECT_SUCCESS,
        payload: userCv
    }
};

export const validateCvUser = (userCv) => {
    return {
        type: VALIDATE_CV_USER,
        payload: userCv
    }
}

export const validateCvUserSuccess = (userCv) => {
    return {
        type: VALIDATE_CV_USER_SUCCESS,
        payload: userCv
    }
};