import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { PROSPECT_STUDENT_ID } from "constants/Roles";
import UserInfo from "components/UserInfo";
import HorizontalNav from "../HorizontalNav";

const { Header } = Layout;

// const Option = Select.Option;
// const menu = (
//   <Menu onClick={handleMenuClick}>
//     <Menu.Item key="1">Products</Menu.Item>
//     <Menu.Item key="2">Apps</Menu.Item>
//     <Menu.Item key="3">Blogs</Menu.Item>
//   </Menu>
// );

// function handleMenuClick(e) {
//   message.info('Click on menu item.');
// }

// function handleChange(value) {
//   console.log(`selected ${value}`);
// }

const BelowHeader = () => {

  // const locale = useSelector(({settings}) => settings.locale);
  // const { navCollapsed } = useSelector(({ common }) => common);
  // const { user } = useSelector(({ auth }) => auth);
  const { id_role } = useSelector(({ authorization }) => authorization);

  // const [searchText, setSearchText] = useState('');

  // const updateSearchChatUser = (evt) => {
  //   setSearchText(evt.target.value)
  // };

  // const languageMenu = () => (
  //   <CustomScrollbars className="gx-popover-lang-scroll">
  //     <ul className="gx-sub-popover">
  //       {languageData.map(language =>
  //         <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
  //           dispatch(switchLanguage(language))
  //         }>
  //           <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
  //           <span className="gx-language-text">{language.name}</span>
  //         </li>
  //       )}
  //     </ul>
  //   </CustomScrollbars>);

  return (
    <div
      className="gx-header-horizontal gx-header-horizontal-dark gx-below-header-horizontal"
      style={{ backgroundColor: "#d4dce3", padding: "0px 0px" }}
    >
      <Header
        className="gx-header-horizontal-main"
        style={{ padding: "0px 0px" }}
      >
        <div className="gx-container" style={{ padding: "10px 0 10px 0" }}>
          <div className="gx-header-horizontal-main-flex">
            {/* Pone el menú hamburguesa que despliega el sidebar
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />
            </div> */}
            {/* Cuando es responsive */}
            <Link
              to="/"
              className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
            >
              <img
                alt="Logo de Agile Innova"
                src={require("assets/images/w-logo.png").default}
              />
            </Link>
            {/* Cuando es escritorio */}
            <Link
              to="/"
              className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
            >
              <img
                alt="Logo de Agile Innova"
                src={require("assets/images/logo.png").default}
              />
            </Link>
            <ul className="gx-header-notifications gx-ml-auto">
              <li className="gx-notify gx-notify-search"></li>
              <li className="gx-user-nav">
                {id_role !== PROSPECT_STUDENT_ID && <UserInfo />}
              </li>
            </ul>
          </div>
        </div>
      </Header>
      <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
        <div className="gx-container">
          <div className="gx-header-horizontal-nav-flex">
            <HorizontalNav />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BelowHeader;