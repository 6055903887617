import {
    FETCH_MENTORS,
    FETCH_MENTORS_SUCCESS,
    REGISTER_MENTOR_ON_SHOW_MESSAGE
} from 'constants/ActionTypes'

export const showMessageDialog = configMessage => {
    return {
        type: REGISTER_MENTOR_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}

export const fetchMentor = (payload) => {
    return {
        type: FETCH_MENTORS,
        payload: payload
    }
}

export const fetchMentorSuccess = (payload) => {
    return {
        type: FETCH_MENTORS_SUCCESS,
        payload: payload
    }
}