import {
    GET_BY_ID_USER_CV,
    GET_BY_ID_USER_CV_SUCCESS,
    EDIT_PRE_SCHOLARSHIP_EVALUATION,
    PRE_SCHOLARSHIP_EVALUATION_ON_SHOW_MESSAGE,
    PRE_SCHOLARSHIP_EVALUATION_ON_HIDE_MESSAGE,
    PRE_SCHOLARSHIP_EVALUATION_ON_SHOW_LOADER,
    PRE_SCHOLARSHIP_EVALUATION_ON_HIDE_LOADER,
    PRE_SCHOLARSHIP_EVALUATION_CHANGE,
    EDIT_PRE_SCHOLARSHIP_EVALUATION_INTERN_REVIEW
} from "constants/ActionTypes";

export const getByIdUserCv = payload => {
    return {
        type: GET_BY_ID_USER_CV,
        payload: payload
    }
}

export const getByIdUserCvSuccess = basicData => {
    return {
        type: GET_BY_ID_USER_CV_SUCCESS,
        payload: basicData
    }
}

export const showMessageDialog = configMessage => {
    return {
        type: PRE_SCHOLARSHIP_EVALUATION_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}

export const showLoaderPreScholarshipEvaluation = () => {
    return {
        type: PRE_SCHOLARSHIP_EVALUATION_ON_SHOW_LOADER
    }
}

export const hideLoaderPreScholarshipEvaluation = () => {
    return {
        type: PRE_SCHOLARSHIP_EVALUATION_ON_HIDE_LOADER
    }
}

export const hideMessageDialog = () => {
    return {
        type: PRE_SCHOLARSHIP_EVALUATION_ON_HIDE_MESSAGE
    }
}

export const changePreScholarshipEvaluation = payload => {
    return {
        type: PRE_SCHOLARSHIP_EVALUATION_CHANGE,
        payload: payload
    }
}

export const editPreScholarshipEvaluation = (payload) => {
    return {
        type: EDIT_PRE_SCHOLARSHIP_EVALUATION,
        payload: payload
    }
}

export const editPreScholarshipEvaluationInternReview = (payload) => {
    return {
        type: EDIT_PRE_SCHOLARSHIP_EVALUATION_INTERN_REVIEW,
        payload: payload
    }
}