import {
    GET_USER_DATA_GITHUB_CLASSROOM_SUCCESS,
    GET_ISSUES_GITHUB_CLASSROOM,
    GET_REPOSITORIES_GITHUB_CLASSROOM_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    userBasicData: [],
    issuesEntregas: [],
    repositories: []
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_USER_DATA_GITHUB_CLASSROOM_SUCCESS:
            return {
                ...state,
                userBasicData: action.payload
            }
        case GET_ISSUES_GITHUB_CLASSROOM:
            return {
                ...state,
                issuesEntregas: [...state.issuesEntregas, action.payload]
            }
        case GET_REPOSITORIES_GITHUB_CLASSROOM_SUCCESS:
            return {
                ...state,
                repositories: [...state.repositories, action.payload]
            }
        default:
            return state
    }
}