import {
  PP_PROSPECT,
  PP_INDEPENDENT_APPRENTICE,
  PP_MENTORED_APPRENTICE,
  PP_STUDENT_ID,
  PP_SCHOLARSHIP_HOLDER_ID,
  PP_REINFORCEMENT,
  PP_INSCRIBED,
  PP_MENTORED_APPRENTICE_IN_REINFORCEMENT,
  PP_INDEPENDENT_APPROVED_APPRENTICE,
  PP_APPROVED_MENTORED_APPRENTICE,
  PP_NO_APPROVED_MENTORED_APPRENTICE,
  PP_POSTPONED_TO_ANOTHER_COHORT,
  PP_POSTPONED_FORCE_MAJEURE,
  PP_APPROVED_EMPLOYABILITY,
  PP_REPROVED,
  PP_APPROVED,
  PP_ASSISTANT,
  PP_DESERTER
} from 'constants/MainStages';

export const courseListStages = {
  stagesCompleted: [
    PP_INDEPENDENT_APPROVED_APPRENTICE,
    PP_APPROVED_MENTORED_APPRENTICE,
    PP_NO_APPROVED_MENTORED_APPRENTICE,
    PP_POSTPONED_TO_ANOTHER_COHORT,
    PP_POSTPONED_FORCE_MAJEURE,
    PP_APPROVED_EMPLOYABILITY,
    PP_REPROVED,
    PP_APPROVED,
    PP_ASSISTANT,
    PP_DESERTER],
  stagesInProgress: [
    PP_PROSPECT,
    PP_INDEPENDENT_APPRENTICE,
    PP_MENTORED_APPRENTICE,
    PP_STUDENT_ID,
    PP_SCHOLARSHIP_HOLDER_ID,
    PP_REINFORCEMENT,
    PP_INSCRIBED,
    PP_MENTORED_APPRENTICE_IN_REINFORCEMENT],
}