import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar } from 'antd'
import { getByIdUserCvBasicData } from 'appRedux/actions/BasicData'

const UserProfile = () => {
  const dispatch = useDispatch()

  const { user } = useSelector(({ auth }) => auth)
  const { basicData } = useSelector(({ basicData }) => basicData)

  useEffect(() => {
    dispatch(getByIdUserCvBasicData(user.id))
  }, [dispatch, user.id])

  return (
    <div
      className='gx-mb-2 gx-avatar-row'
      style={{ display: 'grid', justifyItems: 'center' }}
    >
      <Avatar
        alt='Foto de Perfil del Usuario'
        src={
          user.admin || !basicData.url_photo
            ? 'https://cdn.pixabay.com/photo/2017/10/25/12/33/rocket-2887845_960_720.png'
            : basicData.url_photo
        }
        className='gx-size-50 gx-mb-3'
        style={{ color: "#000" }}
      />
      <span className='gx-avatar-name'>
        <h3 style={{ textAlign: "center" }}>
          {user.full_name === undefined && user.last_name === undefined
            ? ''
            : `${user.full_name} ${user.last_name}`}
        </h3>
      </span>
    </div>
  )
}

export default UserProfile
