import {
    FETCH_SINGLE_COURSE_SUCCESS,
    FETCH_CHALLENGES_AND_DELIVERIES_SUCCESS,
    COURSE_TAB_CHANGE
} from "constants/ActionTypes"

const INIT_STATE = {
    courseList: [{
        id_course: '',
        course: "",
        description: "",
        url:"",
        exit_profile: "",
        resources: "",
        requeriments: {
            id: "",
            requeriment_concept: '',
            requeriment_description: '',
        }
    }],
    challengesDeliveries: [],
    current_tab: "1",
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_SINGLE_COURSE_SUCCESS:
            return {
                ...state,
                courseList: action.payload
            }
        case FETCH_CHALLENGES_AND_DELIVERIES_SUCCESS:
            return {
                ...state,
                challengesDeliveries: action.payload
            }
        case COURSE_TAB_CHANGE:
            return {
                ...state,
                current_tab: action.payload
            }
        default:
            return state;
    }
}