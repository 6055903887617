import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Scholarships = ({ match }) => (
  <Switch>
    {<Route path={`${match.url}/register_sponsors`} component={asyncComponent(() => import('components/admin/scholarships/RegisterSponsors/CreateSponsors'))} />}
    {<Route path={`${match.url}/register_scholarships`} component={asyncComponent(() => import('components/admin/scholarships/RegisterScholarships/CreateScholarships'))} />}
    {<Route path={`${match.url}/register_sponsorships`} component={asyncComponent(() => import('components/admin/scholarships/RegisterSponsorships/CreateSponsorships'))} />}
  </Switch>
);

export default Scholarships;