import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Button, Divider, message } from 'antd'
import { Form, Input } from 'formik-antd'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  hideMessage,
  forgotPassword,
  showAuthLoader
} from 'appRedux/actions/Auth'
import { hideMessageApp } from 'appRedux/actions/App'
import IntlMessages from 'util/IntlMessages'
import { getMessageValidate, getMessageByString } from 'util/Messages'
import CircularProgress from 'components/CircularProgress/index'
import logo from 'assets/images/logo.png'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loader, alertMessage, showMessage } = useSelector(({ auth }) => auth)
  const { locale } = useSelector(({ settings }) => settings)
  const { showMessageApp } = useSelector(({ app }) => app)

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage())
      }, 200)
    }

    if (showMessageApp) {
      setTimeout(() => {
        dispatch(hideMessageApp())
        history.push('/signin')
      }, 200)
    }
  }, [dispatch, history, showMessage, showMessageApp])

  const onFinish = values => {
    values.locale = locale
    dispatch(showAuthLoader())
    dispatch(forgotPassword(values))
  }

  const ForgotPassword = Yup.object().shape({
    email: Yup.string()
      .email(getMessageValidate(locale, 'email', 'valid.field.mail'))
      .required(getMessageValidate(locale, 'email', 'valid.field.required'))
  })

  return (
    <div className='gx-app-login-wrap'>
      <div className='gx-app-login-container'>
        <div className='gx-app-login-header'>
          <img alt='Logo Campus Virtual' src={logo} />
        </div>
        <div className='gx-login-content'>
          <div className='gx-mb-4'>
            <h2>¿Olvidaste tu contraseña?</h2>
            <p>
              <IntlMessages id='app.userAuth.forgot' />
            </p>
          </div>
          <Formik
            onSubmit={onFinish}
            initialValues={{ email: '' }}
            validationSchema={ForgotPassword}
          >
            {({ errors, touched }) => (
              <Form name='basic' className='gx-signin-form gx-form-row0 '>
                <Form.Item name='email'>
                  <Input
                    suffix={<span />}
                    className='gx-input-lineheight'
                    type='email'
                    name='email'
                    id='email'
                    placeholder={getMessageByString(
                      locale,
                      'app.userAuth.mail'
                    )}
                  />
                </Form.Item>
                <div className='ant-row ant-form-item'>
                  <div className='ant-col'>
                    <Button
                      type='primary'
                      className='gx-mb-0'
                      htmlType='submit'
                    >
                      <IntlMessages id='app.userAuth.recover' />
                    </Button>
                    <Divider plain>
                      <IntlMessages id='app.userAuth.or' />
                    </Divider>
                    <span>
                      <Link to='/signIn' className='gx-m-2'>
                        <IntlMessages id='app.userAuth.signIn' />
                      </Link>
                    </span>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {loader && (
          <div className='gx-loader-view'>
            <CircularProgress />
          </div>
        )}
        {showMessage && message.error(alertMessage.toString())}
      </div>
    </div>
  )
}

export default ForgotPassword
