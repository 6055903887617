import {
    USER_ACTIVE_ID,
    USER_DESIST_WORK_ID,
    USER_DESIST_STUDY_ID,
    USER_DESIST_NO_INTEREST_ID,
    USER_DESIST_NO_PC_ID,
    USER_DESIST_NO_INTERNET_ID,
    USER_NOT_ADMITTED,
    USER_INCORRECT_REGISTRATION,
    USER_DOES_NOT_SIGN_MINUTES,
    PP_APPROVED_MENTORED_APPRENTICE,
    PP_NO_APPROVED_MENTORED_APPRENTICE,
    PP_MENTORED_APPRENTICE_IN_REINFORCEMENT,
    PP_APPROVED_EMPLOYABILITY,
    PP_APPROVED,
    PP_REPROVED,
    PP_ASSISTANT,
    PP_REINFORCEMENT,
    PP_EXPELLED,
    SH_PSYCHOSOCIAL_PRE_SCHOLARSHIP,
    SH_APPRENTICE_APPOVED_SECOND_FILTER_TECHNICAL,
    PP_DESERTER,
    PP_POSTPONED_FORCE_MAJEURE,
    PP_POSTPONED_TO_ANOTHER_COHORT,
    SH_PRE_SCHOLARSHIP_PSYCHOSOCIAL_DENIAL,
    SH_POSTPONED_PSYCHOSOCIAL_CONCEPT,
    SP_PSYCHOSOCIAL_PRE_SPONSORSHIP,
    PP_INDEPENDENT_APPRENTICE,
    SP_APPOVED_SECOND_FILTER_TECHNICAL,
    PP_INDEPENDENT_APPROVED_APPRENTICE,
    PP_REFUND,
    PP_RE_ENTRY,
    SP_PRE_ENDORSED,
    SP_RETURN_APPLICATION,
    SP_DENIAL_APPLICATION,
    SP_POSTPONED_BY_TECHNICAL_CONCEPT,
    SP_REJECTED_BY_TECHNICAL_CONCEPT,
    SP_POSTPONED_PSYCHOSOCIAL_CONCEPT,
    SP_PRE_ENDORSED_PSYCHOSOCIAL_DENIAL,
    SH_PRE_SCHOLARSHIP,
    SH_RETURN_APPLICATION,
    SH_DENIAL_APPLICATION,
    SH_POSTPONED_TECHNICAL_CONCEPT,
    SH_REJECTED_BY_TECHNICAL_CONCEPT
} from './MainStages'

//Datos básicos
export const LIST_ENUM_TYPE_DOCUMENT = [
    { type: "CC", value: "Cédula de Ciudadania" },
    { type: "TI", value: "Tarjeta de Identidad" },
    { type: "CE", value: "Cédula de Extrajería" },
    { type: "PA", value: "Pasaporte" },
    { type: "PEP", value: "Permiso Especial de Permanencia" },
    { type: "NES", value: "Número establecido por la Secretaría de Educación" },
    { type: "PPT", value: "Permiso por Protección Temporal" },
    { type: "Otro", value: "Otro" }
]

export const LIST_ENUM_GENDER = [
    { type: "masculino", value: "Masculino" },
    { type: "femenino", value: "Femenino" },
    { type: "otro", value: "Otro" },
    { type: "prefiero_no_decirlo", value: "Prefiero no decirlo" }
]

export const LIST_ENUM_MARITAL_STATUS = [
    { type: "soltero/a", value: "Soltero/a" },
    { type: "casado/a", value: "Casado/a" },
    { type: "separado/a", value: "Separado/a" },
    { type: "divorciado/a", value: "Divorciado/a" },
    { type: "union_libre", value: "Unión Libre" },
    { type: "viudo/a", value: "Viudo/a" },
    { type: "union_marital_de_hecho", value: "Unión Marital de Hecho" },
    { type: "prefiero_no_decirlo", value: "Prefiero no decirlo" },
    { type: "ns/nr", value: "No sabe/No responde" }
]

export const LIST_ENUM_SCOLARITY = [
    { type: "bachiller", value: "Bachiller" },
    { type: "tecnico", value: "Técnico" },
    { type: "tecnologo", value: "Tecnólogo" },
    { type: "profesional", value: "Profesional" },
    { type: "especialista", value: "Especialista" },
    { type: "maestria", value: "Maestría" },
    { type: "doctorado", value: "Doctorado" },
    { type: "otro", value: "Otro" },
]

export const LIST_FILTER_SCOLARITY_LABELS = LIST_ENUM_SCOLARITY.map(item => item.value)

export const LIST_ENUM_MIGRATION_STATE = [
    { type: "colombiano_residente", value: "Colombiano residente" },
    { type: "colombiano_retornado", value: "Colombiano retornado" },
    { type: "migrante", value: "Migrante" },
    { type: "extranjero_residente", value: "Extranjero residente" },
    { type: "prefiero_no_decirlo", value: "Prefiero no decirlo" }
]

export const LIST_ENUM_MIGRATION_STATE_OBJ = {
    "colombiano_residente": "Colombiano residente",
    "colombiano_retornado": "Colombiano retornado",
    "migrante": "Migrante",
    "extranjero_residente": "Extranjero residente",
    "prefiero_no_decirlo": "Prefiero no decirlo"
}

export const LIST_ENUM_RACE = [
    { type: "prefiero_no_decirlo", value: "Prefiero no decirlo" },
    { type: "negra", value: "Negra" },
    { type: "afrocolombiana", value: "Afrocolombiana" },
    { type: "raizal", value: "Raizal" },
    { type: "palenquera", value: "Palenquera" },
    { type: "indigena", value: "Indígena" },
    { type: "gitana_rom", value: "Gitana o Rom" },
    { type: "asiatico", value: "Asiático" },
    { type: "blanco", value: "Blanco" },
    { type: "mestizo", value: "Mestizo" }
]

export const LIST_ENUM_SOCIOECONOMIC_STATUS = [
    { type: 1, value: "1" },
    { type: 2, value: "2" },
    { type: 3, value: "3" },
    { type: 4, value: "4" },
    { type: 5, value: "5" },
    { type: 6, value: "6" },
    { type: "ninguno", value: "Ninguno" }
]

export const LIST_ENUM_SISBEN = [
    { type: "A", value: "A" },
    { type: "B", value: "B" },
    { type: "C", value: "C" },
    { type: "D", value: "D" },
    { type: "ninguno", value: "Ninguno" }
]

//Lenguajes de programación 
export const LIST_ENUM_LANGUAGE_PROFICIENCY = [
    { type: "basico", value: "Básico" },
    { type: "intermedio", value: "Intermedio" },
    { type: "alto", value: "Avanzado" }
]


export const LIST_ENUM_LANGUAGE_PROFICIENCY_OBJ = {
    "basico": "Básico",
    "intermedio": "Intermedio",
    "alto": "Alto",
    "avanzado": "Avanzado"
}

export const LIST_ENUM_LANGUAGES_LEVEL = {
    "intermedio": "Intermedio",
    "avanzado": "Avanzado",
    "basico": "Básico"
}

//Información academica
export const LIST_ENUM_LAST_YEAR_APPROVED = [
    { type: "6", value: "6" },
    { type: "7", value: "7" },
    { type: "8", value: "8" },
    { type: "9", value: "9" },
    { type: "10", value: "10" },
    { type: "11", value: "11" },
    { type: "12", value: "12" }
]

export const LIST_ENUM_PROGRAM_STATE = [
    { type: "en_curso", value: "En curso" },
    { type: "incompleto", value: "Incompleto" },
    { type: "graduado", value: "Graduado" }
]

export const LIST_ENUM_PROGRAM_STATE_COURSES = [
    { type: "en_curso", value: "En curso" },
    { type: "incompleto", value: "Incompleto" },
    { type: "graduado", value: "Completo" }
]

export const LIST_ENUM_PROGRAM_TYPE_COLLEGE = [
    { type: "tecnico", value: "Técnico" },
    { type: "tecnico_laboral", value: "Técnico laboral" },
    { type: "tecnico_profesional", value: "Técnico profesional" },
    { type: "tecnologia", value: "Tecnología" },
    { type: "profesional", value: "Profesional" },
    { type: "especializacion", value: "Especialización" },
    { type: "maestria", value: "Maestría" },
    { type: "doctorado", value: "Doctorado" }
]

export const LIST_ENUM_SCOLARITY_OBJ =
{
    "bachiller": "Bachiller",
    "tecnico": "Técnico",
    "tecnologo": "Tecnólogo",
    "profesional": "Profesional",
    "especialista": "Especialista",
    "maestria": "Maestría",
    "doctorado": "Doctorado",
    "otro": "Otro"
}

export const LIST_ENUM_PROGRAM_TYPE_COURSES = [
    { type: "curso", value: "Curso" },
    { type: "curso_virtual", value: "Curso virtual" },
    { type: "seminario", value: "Seminario" },
    { type: "diplomado", value: "Diplomado" },
    { type: "webinar", value: "Webinar" },
    { type: "congreso", value: "Congreso" },
    { type: "especializacion", value: "Especialización" },
    { type: "otro", value: "Otro" }
]

export const LIST_ENUM_PP_COMPLETE = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export const LIST_ENUM_PP_TOTAL = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]


// Experiencia laboral
export const LIST_ENUM_CONTRACT_TYPE = [
    { type: "termino_fijo", value: "Término fijo" },
    { type: "termino_indefinido", value: "Término indefinido" },
    { type: "prestacion_servicios", value: "Prestación de servicios" },
    { type: "practica_formacion", value: "Práctica o formación" },
    { type: "sin_formalizar", value: "Sin formalizar " },
    { type: "autoempleo_emprendedor_independiente", value: "Autoempleo/Emprendedor/Independiente" }
]

//Registro de financiadores
export const LIST_ENUM_SPONSOR_TYPE = [
    { type: "privada", value: "Privada" },
    { type: "publica", value: "Pública" },
    { type: "mixta", value: "Mixta" }
]

export const LIST_ENUM_SPONSOR_STATUS = [
    { type: "activo", value: "Activo" },
    { type: "inactivo", value: "Inactivo" }
]

export const LIST_ENUM_SPONSOR_STATUS_CELL = {
    "activo": "Activo",
    "inactivo": "Inactivo"
}

export const LIST_ENUM_COURSE_STATUS_CELL = {
    "activo": "Activo",
    "inactivo": "Inactivo"
}

// Cursos becas de estudio
export const LIST_ENUM_SCHOLARSHIPS = [
    { type: "aprendices_geek", value: "Aprendices Geek" },
    { type: "backend", value: "Backend" },
    { type: "frontend_en_Reactjs", value: "Frontend en ReactJS" }
]

//Información complementaria de la beca
export const LIST_CONVOC_METHOD = [
    { type: "referred", value: "Referido por otra persona" },
    { type: "instagram", value: "Instagram" },
    { type: "whatsapp", value: "WhatsApp" },
    { type: "facebook", value: "Facebook" },
    { type: "twitter", value: "Twitter" },
    { type: "sms", value: "SMS" },
    { type: "mail", value: "Correo electrónico" },
    { type: "radio", value: "Radio" },
    { type: "online_newspaper", value: "Periódico en línea" },
    { type: "tv", value: "Televisión" },
    { type: "website", value: "Sitio web" }
]

export const LIST_AVAILABILITY_ACTIVITIES = [
    { type: "self_study", value: "Autoestudio en Plataformas Virtuales" },
    { type: "student_institution", value: "Estudiante de una Institución de Educación Formal/No Formal" },
    { type: "domestic", value: "Actividades domésticas" },
    { type: "child_or_adult_care", value: "Cuidados de niños o adultos mayores" },
    { type: "independent_work", value: "Trabajo independiente" },
    { type: "contractor", value: "Contratista/Trabajo a tiempo parcial o por horas" },
    { type: "employee", value: "Empleado" },
    { type: "entrepreneurship", value: "Emprendimiento/Negocio Familiar" },
    { type: "other", value: "Otras" },
    { type: "none", value: "Ninguna" }
]

export const LIST_ENUM_PROGRAM_TYPE_COLLEGE_OBJ = {
    tecnico: "Técnico",
    basica_secundaria: "Basica secundaria",
    tecnico_laboral: "Técnico laboral",
    tecnico_profesional: "Técnico profesional",
    tecnologia: "Tecnología",
    profesional: "Profesional",
    especializacion: "Especialización",
    maestria: "Maestría",
    doctorado: "Doctorado"
}

export const LIST_ENUM_SCHOLARSHIP_PRESCHOLARSHIP_REQUEST_STATUS = [
    { type: SH_PRE_SCHOLARSHIP, value: "Pre-becario" },
    { type: SH_RETURN_APPLICATION, value: "Devolver solicitud para completar datos" },
    { type: SH_DENIAL_APPLICATION, value: "Negación de la aplicación" }
]

export const LIST_ENUM_SCHOLARSHIP_PSICHOSOCIALINTERVIEW_REQUEST_STATUS = [
    { type: SH_PSYCHOSOCIAL_PRE_SCHOLARSHIP, value: "Pre-aprobado Psicosocial" },
    { type: SH_POSTPONED_PSYCHOSOCIAL_CONCEPT, value: "Aplazado por concepto psicosocial" },
    { type: SH_PRE_SCHOLARSHIP_PSYCHOSOCIAL_DENIAL, value: "Negación pre-becario" }
]

export const LIST_ENUM_SCHOLARSHIP_TECHNICALINTERVIEW_REQUEST_STATUS = [
    { type: SH_APPRENTICE_APPOVED_SECOND_FILTER_TECHNICAL, value: "Aprobado segundo filtro técnico" },
    { type: SH_POSTPONED_TECHNICAL_CONCEPT, value: "Aplazado por concepto técnico" },
    { type: SH_REJECTED_BY_TECHNICAL_CONCEPT, value: "Rechazado por concepto técnico" }
]

export const LIST_ENUM_LOAN_APPLICATION_REVIEW_REQUEST_STATUS = [
    { type: SP_PRE_ENDORSED, value: "Pre-Avalado" },
    { type: SP_RETURN_APPLICATION, value: "Devolver solicitud para completar datos" },
    { type: SP_DENIAL_APPLICATION, value: "Negación de la solicitud" }
]

export const LIST_ENUM_LOAN_PSYCHOSOCIAL_REVIEW_REQUEST_STATUS = [
    { type: SP_PSYCHOSOCIAL_PRE_SPONSORSHIP, value: "Pre-Inscrito Pre-aprobado Psicosocial" },
    { type: SP_POSTPONED_PSYCHOSOCIAL_CONCEPT, value: "Aplazado por concepto psicosocial" },
    { type: SP_PRE_ENDORSED_PSYCHOSOCIAL_DENIAL, value: "Negación Psicosocial del Pre-avalado" }
]

export const LIST_ENUM_LOAN_TECHNICAL_REVIEW_REQUEST_STATUS = [
    { type: SP_APPOVED_SECOND_FILTER_TECHNICAL, value: "Aprendiz aprobado segundo filtro técnico" },
    { type: SP_POSTPONED_BY_TECHNICAL_CONCEPT, value: "Aplazado por concepto técnico" },
    { type: SP_REJECTED_BY_TECHNICAL_CONCEPT, value: "Rechazado por concepto técnico" }
]

//Tipo de evidencia
export const LIST_ENUM_EVIDENCE_TYPE = [
    { type: "URL", value: "Enlace URL" },
    { type: "File", value: "Archivo" },
    { type: "Ambos", value: "Ambos" }
]

//Evidencia en github
export const LIST_ENUM_EVIDENCE_GITHUB = [
    { type: false, value: "Sin envidencia" },
    { type: true, value: "Con envidencia" },
]

//Semanas del requisito detallado
export const LIST_ENUM_REQUISITE_WEEK = [
    { type: 1, value: "Semana 1" },
    { type: 2, value: "Semana 2" },
    { type: 3, value: "Semana 3" },
    { type: 4, value: "Semana 4" },
    { type: 5, value: "Semana 5" },
    { type: 6, value: "Semana 6" },
    { type: 7, value: "Semana 7" },
    { type: 8, value: "Semana 8" },
    { type: 9, value: "Semana 9" },
]

//Actualmente estudia
export const LIST_ENUM_STUDYING = [
    { type: "self_study", value: "Autoestudio en Plataformas Virtuales" },
    { type: "student_institution", value: "Estudiante de una Institución de Educación Formal/No Formal" },
    { type: "none", value: "Ninguna" },
]

//Actualmente working
export const LIST_ENUM_WORKING = [
    { type: "domestic", value: "Actividades domésticas" },
    { type: "child_or_adult_care", value: "Cuidados de niños o adultos mayores" },
    { type: "independent_work", value: "Trabajo independiente" },
    { type: "contractor", value: "Contratista/Trabajo a tiempo parcial o por horas" },
    { type: "employee", value: "Empleado" },
    { type: "entrepreneurship", value: "Emprendimiento/Negocio Familiar" },
    { type: "other", value: "Otras" },
    { type: "none", value: "Ninguna" },
]

//Desasignar estudiante a una cohorte
export const LIST_ENUM_DISASSOCIATE_STUDENTS = [
    { type: PP_POSTPONED_FORCE_MAJEURE, value: "Aplazado por fuerza mayor/caso fortuito" },
    { type: PP_DESERTER, value: "Desertor" },
    { type: PP_EXPELLED, value: "Expulsado" },
    { type: PP_POSTPONED_TO_ANOTHER_COHORT, value: "Postergado a otra cohorte" }
]

//Estados generales usuarios 
export const LIST_ENUM_GENERAL_STATUS = [
    { type: USER_ACTIVE_ID, value: "Activo" },
    { type: USER_DESIST_WORK_ID, value: "Desiste/ Trabaja" },
    { type: USER_DESIST_STUDY_ID, value: "Desiste/ Estudia" },
    { type: USER_DESIST_NO_INTEREST_ID, value: "Desiste/ Sin interés" },
    { type: USER_DESIST_NO_PC_ID, value: "Desiste/ Sin PC" },
    { type: USER_DESIST_NO_INTERNET_ID, value: "Desiste/ Sin Internet" },
    { type: USER_DOES_NOT_SIGN_MINUTES, value: "No firma acta" },
    { type: USER_NOT_ADMITTED, value: "No admitido" },
    { type: USER_INCORRECT_REGISTRATION, value: "Registro errado" },
]

//Estado de la aplicación si es un curso prerequisito
export const LIST_ENUM_APLICATION_COHORT_SCHOLARSHIP = [
    { type: SH_PRE_SCHOLARSHIP, value: "Pre-becario", text: "Pre-becario" },
    { type: SH_PSYCHOSOCIAL_PRE_SCHOLARSHIP, value: 'Pre-becario Pre-aprobado Psicosocial', text: 'Pre-aprobado Psicosocial' },
    { type: SP_APPOVED_SECOND_FILTER_TECHNICAL, value: "Aprendiz aprobado segundo filtro técnico", text: "Aprobado" },
]

//Estado del prospecto si es un curso prerequisito
export const LIST_ENUM_PROSPECT_COHORT_PREREQUISITE = [
    { type: PP_INDEPENDENT_APPRENTICE, value: 'Aprendiz Autónomo', text: 'Aprendiz Autónomo' },
    { type: PP_APPROVED, text: 'Aprobado', value: 'Aprobado' },
    { type: PP_APPROVED_EMPLOYABILITY, text: 'Aprobado - Empleabilidad', value: 'Aprobado - Empleabilidad' },
    { type: PP_POSTPONED_FORCE_MAJEURE, text: 'Aplazado por fuerza mayor/caso fortuito', value: 'Aplazado por fuerza mayor/caso fortuito' },
    { type: PP_POSTPONED_TO_ANOTHER_COHORT, text: 'Postergado a otra cohorte', value: 'Postergado a otra cohorte' },
    { type: PP_ASSISTANT, text: 'Asistente', value: 'Asistente' },
    { type: PP_REFUND, text: 'Reintegro', value: 'Reintegro' },
    { type: PP_RE_ENTRY, text: 'Reingreso', value: 'Reingreso' },
]

//Estado de la aplicación si NO es un curso prerequisito
export const LIST_ENUM_APLICATION_COHORT_SPONSORSHIP = [
    { type: SP_PRE_ENDORSED, value: 'Pre-avalado', text: 'Pre-avalado' },
    { type: SP_PSYCHOSOCIAL_PRE_SPONSORSHIP, value: 'Pre-avalado Pre-aprobado Psicosocial', text: 'Pre-inscrito Pre-aprobado Psicosocial' },
    { type: SP_APPOVED_SECOND_FILTER_TECHNICAL, value: 'Pre-avalado Aprobado Filtro Técnico', text: 'Pre-inscrito aprobado segundo filtro técnico' },
]

//Estado del prospecto si NO es un curso prerequisito
export const LIST_ENUM_PROSPECT_COHORT_BASE = [
    { type: PP_INDEPENDENT_APPROVED_APPRENTICE, value: 'Aprendiz Autónomo Aprobado', text: 'Aprendiz Autónomo Aprobado' },
    { type: PP_APPROVED_MENTORED_APPRENTICE, value: 'Aprendiz Mentoreado Aprobado', text: 'Aprendiz Mentoreado Aprobado' },
    { type: PP_POSTPONED_FORCE_MAJEURE, value: 'Aplazado por fuerza mayor/caso fortuito', text: 'Aplazado por fuerza mayor/caso fortuito' },
    { type: PP_POSTPONED_TO_ANOTHER_COHORT, value: 'Postergado a otra cohorte', text: 'Postergado a otra cohorte' },
    { type: PP_REFUND, value: 'Reintegro', text: 'Reintegro' },
    { type: PP_RE_ENTRY, value: 'Reingreso', text: 'Reingreso' },
]

export const LIST_ENUM_QUALIFICATION_EMPLOYABILITY_ETAPA_1 = [
    { type: "mentoreado_aprobo", value: "Aprendiz Mentoreado Aprobado", id: PP_APPROVED_MENTORED_APPRENTICE },
    { type: "mentoreado_no_aprobo", value: "Aprendiz Mentoreado No aprobado", id: PP_NO_APPROVED_MENTORED_APPRENTICE },
    { type: "mentoreado_plan_refuerzo", value: "Aprendiz Mentoreado en Refuerzo", id: PP_MENTORED_APPRENTICE_IN_REINFORCEMENT }
]

//Estados calificación para la valoración Final de etapa 2
export const LIST_ENUM_QUALIFICATION_EMPLOYABILITY_ETAPA_2 = [
    { type: "aprobo_empleabilidad", value: "Aprobado - Empleabilidad", id: PP_APPROVED_EMPLOYABILITY },
    { type: "aprobo", value: "Aprobado", id: PP_APPROVED },
    { type: "asistente", value: "Asistente", id: PP_ASSISTANT },
    { type: "plan_refuerzo", value: "En refuerzo", id: PP_REINFORCEMENT },
    { type: "no_aprobo", value: "Reprobado", id: PP_REPROVED },
]

//Estados calificación por sprint
export const LIST_ENUM_QUALIFICATION_SPRINT = [
    { type: "sobresaliente", value: "Sobresaliente" },
    { type: "cumple", value: "Cumple" },
    { type: "cumple_parcialmente", value: "Cumple parcialmente" },
    { type: "no_cumple", value: "No cumple" },
    { type: "sin_informacion", value: "Sin información" },
]
export const LIST_ENUM_QUALIFICATION_SPRINT_OBJECT = {
    sobresaliente: "Sobresaliente",
    cumple: "Cumple",
    cumple_parcialmente: "Cumple parcialmente",
    no_cumple: "No cumple",
    sin_informacion: "Sin información"
}

export const LIST_ENUM_QUALIFICATION_EMPLOYABILITY_OBJECT = {
    "mentoreado_aprobo": "Aprendiz Mentoreado Aprobado",
    "mentoreado_no_aprobo": "Aprendiz Mentoreado No aprobado",
    "mentoreado_plan_refuerzo": "Aprendiz Mentoreado en Refuerzo",
    "aprobo_empleabilidad": "Aprobado - Empleabilidad",
    "aprobo": "Aprobado",
    "asistente": "Asistente",
    "plan_refuerzo": "En refuerzo",
    "no_aprobo": "Reprobado"
}

//Estado del prospecto tanto para beca como para préstamo
export const LIST_ENUM_SCHOLARSHIP_SPONSORSHIP_STATE = [
    { text: "Aplazado", value: "Aplazado por fuerza mayor/caso fortuito" },
    { text: "Aprendiz autónomo", value: "Aprendiz Autónomo" },
    { text: "Aprendiz mentoreado", value: "Aprendiz Mentoreado" },
    { text: "Aprendiz postergado", value: "Aprendiz Mentoreado Postergado" },
    { text: "Aprobado", value: "Aprobado" },
    { text: "Aprobado - Empleabilidad", value: "Aprobado - Empleabilidad" },
    { text: "Asistente", value: "Asistente" },
    { text: "Autónomo aprobado", value: "Aprendiz Autónomo Aprobado" },
    { text: "Becario", value: "Becario" },
    { text: "Desertor", value: "Desertor" },
    { text: "En refuerzo", value: "En refuerzo" },
    { text: "Expulsado", value: "Expulsado" },
    { text: "Inscrito", value: "Inscrito" },
    { text: "Mentoreado aprobado", value: "Aprendiz Mentoreado Aprobado" },
    { text: "Mentoreado no aprobado", value: "Aprendiz Mentoreado No aprobado" },
    { text: "No aprobado", value: "Reprobado/ No Aprobado" },
    { text: "Postergado", value: "Postergado a otra cohorte" },
    { text: "Prospecto", value: "Prospecto" },
    { text: "Reingreso", value: "Reingreso" },
    { text: "Reintegro", value: "Reintegro" },
    { text: "Registrado", value: "Registrado" },
]

//Estado de aplicación préstamo
export const LIST_ENUM_SPONSORSHIP_APLICATION_STATE = [
    { text: "Aplazado psicosocial", value: "Pre-avalado aplazado por concepto psicosocial" },
    { text: "Aplazado técnico", value: "Pre-avalado Aplazado por concepto técnico" },
    { text: "Desestimiento", value: "Desestimiento" },
    { text: "Devolución solicitud", value: "Devolución Solicitud" },
    { text: "Negación solicitud", value: "Negación de la solicitud" },
    { text: "Negación psicosocial ", value: "Negación Psicosocial del Pre-avalado" },
    { text: "Pre-avalado", value: "Pre-avalado" },
    { text: "Pre-avalado aprobado", value: "Pre-avalado Aprobado Segundo Filtro Técnico" },
    { text: "Pre-inscrito pre-aprobado", value: "Pre-inscrito Pre-aprobado Psicosocial" },
    { text: "Sin préstamo", value: null },
    { text: "Solicitante", value: "Solicitante" },
    { text: "Rechazado", value: "Pre-avalado Rechazado por concepto técnico" },
]

export const LIST_ENUM_FILTER_GRID_REQUESTERS = [
    { text: "Solicitante", value: "Solicitante" },
    { text: "Devolución solicitud", value: "Devolución Solicitud" },
    { text: "Negación solicitud", value: "Negación de la solicitud" },
]

export const LIST_ENUM_FILTER_GRID_PRE_ENDORSED = [
    { text: "Pre-avalado", value: "Pre-avalado" },
    { text: "Negación psicosocial", value: "Pre-avalado Negación Psicosocial" },
    { text: "Aplazado psicosocial", value: "Pre-avalado aplazado por concepto psicosocial" }
]

export const LIST_ENUM_FILTER_GRID_PRE_SPONSORSHIP = [
    { text: "Pre-inscrito pre-aprobado", value: "Pre-avalado Pre-aprobado Psicosocial" },
    { text: "Aplazado técnico", value: "Pre-avalado Aplazado por concepto técnico" },
    { text: "Rechazado técnico", value: "Pre-avalado Rechazado por concepto técnico" },
]

export const LIST_ENUM_FILTER_GRID_PRE_SPONSORSHIP_APPROVED = [
    { text: "Pre-avalado aprobado", value: "Pre-avalado Aprobado Filtro Técnico" },
]

//Estato de aplicación de la beca
export const LIST_ENUM_SCHOLARSHIP_APLICATION_STATE = [
    { text: "Aplazado psicosocial", value: "Aplazado por concepto psicosocial" },
    { text: "Aplazado técnico", value: "Aplazado por concepto técnico" },
    { text: "Aplicante", value: "Aplicante" },
    { text: "Aprobado", value: "Aprendiz aprobado segundo filtro técnico" },
    { text: "Desestimiento", value: "Desestimiento" },
    { text: "Devolución Aplicación", value: "Devolución Aplicación" },
    { text: "Negación aplicación", value: "Negación de la aplicación" },
    { text: "Negación psicosocial", value: "Pre-becario Negación Psicosocial" },
    { text: "Pre-aprobado", value: "Pre-aprobado psicosocial" },
    { text: "Pre-becario", value: "Pre-becario" },
    { text: "Rechazado técnico", value: "Rechazado por concepto técnico" },
    { text: "Sin beca", value: null }
]

export const LIST_ENUM_FILTER_GRID_APPLICANTS = [
    { text: "Aplicante", value: "Aplicante" },
    { text: "Devolución Aplicación", value: "Devolución Aplicación" },
    { text: "Negación aplicación", value: "Negación de la aplicación" }
]

export const LIST_ENUM_FILTER_GRID_PRE_SCHOLARSHIP_HOLDERS = [
    { text: "Pre-becario", value: "Pre-becario" },
    { text: "Aplazado psicosocial", value: "Aplazado por concepto psicosocial" },
    { text: "Negación psicosocial", value: "Pre-becario Negación Psicosocial" },
]

export const LIST_ENUM_FILTER_GRID_PSYCHOSOCIAL_PRE_APPROVED = [
    { text: "Pre-aprobado", value: "Pre-aprobado psicosocial" },
    { text: "Aplazado técnico", value: "Aplazado por concepto técnico" },
    { text: "Rechazado técnico", value: "Rechazado por concepto técnico" }
]

export const LIST_ENUM_FILTER_GRID_SCHOLARSHIP_HOLDERS = [
    { text: "Aprobado", value: "Aprendiz aprobado segundo filtro técnico" },
]

export const LIST_ENUM_FILTER_GRID_DOCUMENT_TYPE = [
    { text: "CC", value: "CC" },
    { text: "TI", value: "TI" },
    { text: "CE", value: "CE" },
    { text: "PA", value: "PA" },
    { text: "PEP", value: "PEP" },
    { text: "NES", value: "NES" },
    { text: "Otro", value: "Otro" },
    { text: "Sin llenar", value: null }
]

export const LIST_ENUM_FILTER_GRID_GENDER = [
    { text: "Femenino", value: "femenino" },
    { text: "Masculino", value: "masculino" },
    { text: "Otro", value: "Otro" },
    { text: "Prefiero no decirlo", value: "Prefiero no decirlo" }
]

export const LIST_ENUM_FILTER_GRID_MIGRATORY_STATE = [
    { text: "Colombiano residente", value: "colombiano_residente" },
    { text: "Colombiano retornado", value: "colombiano_retornado" },
    { text: "Migrante", value: "migrante" },
    { text: "Extranjero residente", value: "extranjero_residente" },
    { text: "Prefiero no decirlo", value: "prefiero_no_decirlo" }
]

export const LIST_ENUM_FILTER_GRID_SCOLARITY = [
    { text: "Bachiller", value: "bachiller" },
    { text: "Técnico", value: "tecnico" },
    { text: "Tecnólogo", value: "tecnologo" },
    { text: "Profesional", value: "profesional" },
    { text: "Especialista", value: "especialista" },
    { text: "Maestría", value: "maestria" },
    { text: "Doctorado", value: "doctorado" },
    { text: "Otro", value: "otro" },
]

export const LIST_ENUM_FILTER_GRID_COURSE = [
    { text: "Frontend", value: "Frontend en React.JS" },
    { text: "Backend", value: "Backend" },
    { text: "Aprendices Geek", value: "Aprendices Geek" }
]
