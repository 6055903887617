import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Alert, Button, Card, Col, Row, Tooltip } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import { fetchInfoUseJWT, showLoaderUser, resendEmailUser, resendCellPhoneUser } from 'appRedux/actions/User'
import { showLoaderApp } from 'appRedux/actions/App'
import { hideMessageAppV1 } from 'appRedux/actions/AppMessage'
import IntlMessages from 'util/IntlMessages'
import { getMessageByString, MessageAppSwitchV1 } from 'util/Messages'
import Progress from 'util/MyProgress'
import ProgressView from 'components/CircularProgress/ProgressView'
import ContactMeans from './ContactMeans'

const SuccessfulRegistration = props => {

  const dispatch = useDispatch()
  const history = useHistory()
  const { jwt } = useParams()

  const { locale } = useSelector(({ settings }) => settings)
  const { loaderApp } = useSelector(({ app }) => app)
  const { info_jwt_token, loader, user } = useSelector(({ user }) => user)
  const { authUser } = useSelector(({ auth }) => auth)
  const { showMessageAppV1, alertMessageAppV1, typeMessageAppV1, timeShowMessageAppV1 } = useSelector(({ app_message }) => app_message)

  useEffect(() => {
    dispatch(showLoaderApp())
    dispatch(fetchInfoUseJWT(jwt))
  }, [dispatch, jwt])

  useEffect(() => {
    if (showMessageAppV1) {
      MessageAppSwitchV1({ alertMessageAppV1, typeMessageAppV1, timeShowMessageAppV1 })
      dispatch(hideMessageAppV1())
    }
  }, [alertMessageAppV1, dispatch, showMessageAppV1, timeShowMessageAppV1, typeMessageAppV1])

  useEffect(() => {
    if (authUser !== null) {
      history.push('/signin')
    }
  }, [authUser, history])

  const sendEmailValidation = () => {
    dispatch(showLoaderUser())
    dispatch(resendEmailUser({ ...info_jwt_token.infoToken, locale }))
  }

  const sendCellPhoneValidation = () => {
    dispatch(showLoaderUser())
    dispatch(resendCellPhoneUser({ ...info_jwt_token.infoToken, locale }))
  }

  const propsToltipResendCellPhone = {
    placement: 'top',
    title: <IntlMessages id='app.user.register.resend.max.send.msn.cellphone' />
  }
  const propsToltipResendEmail = {
    placement: 'top',
    title: <IntlMessages id='app.userAuth.signIn.error.mail_verified.1' />
  }

  if (user.email_verification_info === null || user.email_verification_info === undefined || !user.email_verification_info.mail_verified) {
    propsToltipResendEmail['visible'] = false
  }

  if (user.email_verification_info && user.email_verification_info.registerWithProvider) {
    delete propsToltipResendEmail['visible']
    propsToltipResendEmail['title'] = <IntlMessages id='app.user.register.resend.not.need.forward.mail' />
  }

  if (
    user.phone_verification_info === null ||
    user.phone_verification_info === undefined ||
    user.phone_verification_info.number_sending_attempts_made < 3
  ) {
    propsToltipResendCellPhone['visible'] = false
  }

  if (
    user.phone_verification_info &&
    user.phone_verification_info.cell_phone_verified
  ) {
    delete propsToltipResendCellPhone['visible']
    propsToltipResendCellPhone['title'] = (
      <IntlMessages id='app.userAuth.signIn.error.cellphone_verified.1' />
    )
  }

  return (
    <>
      <section className='ant-layout'>
        <main className='ant-layout-content'>
          <div className='gx-main-content-wrapper'>
            <div className='gx-app-login-container'>
              <Card className={`gx-card-widget gx-card-full gx-text-center`}>
                <div className='gx-pt-4 gx-px-3'>
                  <div className='gx-separator gx-bg-success-dark' />
                  <h2 className='gx-mb-4 gx-text-success-dark'>
                    <IntlMessages id='app.user.register.ending' />
                  </h2>
                  <Card
                    className='gx-card-full gx-p-2 gx-text-center gx-text-primary'
                    style={{ border: 'none', marginBottom: '2px' }}
                  >
                    <i className={`icon icon-user-o gx-fs-iconcard`} />
                  </Card>
                  {info_jwt_token.response !== undefined &&
                    !info_jwt_token.response && (
                      <>
                        <Row>
                          <Col lg={4} md={3}></Col>
                          <Col lg={16} md={18} xs={24}>
                            <Alert
                              message='Error'
                              description={
                                info_jwt_token.message === 'jwt expired' ? (
                                  <IntlMessages id='app.user.register.error.jwt.expired' />
                                ) : info_jwt_token.message ===
                                'invalid signature' && (
                                  <IntlMessages id='app.user.register.error.jwt.notfound' />
                                )
                              }
                              type='error'
                              showIcon
                            />
                          </Col>
                          <Col lg={4} md={3}></Col>
                        </Row>
                        <ButtonGroup
                          size='large'
                          className='gx-fs-sm gx-text-uppercase'
                          style={{ width: '100%', marginBottom: '1em' }}
                        >
                          <Button
                            type='secundary'
                            style={{ marginBottom: '0px' }}
                            className='gx-fs-sm'
                            size='large'
                            htmlType='submit'
                            block
                          >
                            <Link to='/signin'>
                              <IntlMessages id='app.userAuth.login' />
                            </Link>
                          </Button>
                        </ButtonGroup>
                      </>
                    )}

                  {info_jwt_token.response !== undefined &&
                    info_jwt_token.response && (
                      <>
                        <Row>
                          <Col lg={1} md={3} xs={1}></Col>
                          <Col lg={22} md={18} xs={21} className='gx-text-left'>
                            <Alert
                              message={<div dangerouslySetInnerHTML={{
                                __html: getMessageByString(
                                  locale,
                                  'app.user.register.resend.title.1'
                                )
                              }} />}
                              description={
                                <div>
                                  <p>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: getMessageByString(
                                          locale,
                                          'app.user.register.resend.item.1'
                                        )
                                      }}
                                    />
                                  </p>
                                  <p>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: getMessageByString(
                                          locale,
                                          'app.user.register.resend.item.2'
                                        )
                                      }}
                                    />
                                  </p>
                                  <p>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: getMessageByString(
                                          locale,
                                          'app.user.register.resend.item.3'
                                        )
                                      }}
                                    />
                                  </p>
                                  <p>
                                    <IntlMessages id='app.user.register.resend.item.4' />
                                  </p>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: getMessageByString(
                                        locale,
                                        'app.user.register.resend.note'
                                      )
                                    }}
                                  />
                                </div>
                              }
                              type='success'
                              align=''
                            />
                          </Col>
                          <Col lg={1} md={3} xs={1}></Col>
                        </Row>
                        <Row>
                          <Col lg={24} md={24}>
                            <ButtonGroup
                              size='large'
                              className='gx-fs-sm gx-text-uppercase btn-group-registration-successful'
                              style={{ width: '100%', marginBottom: '1em' }}
                            >
                              <Tooltip {...propsToltipResendEmail}>
                                <Button
                                  type='primary'
                                  style={{ marginBottom: '0px' }}
                                  className='gx-fs-sm'
                                  size='large'
                                  htmlType='submit'
                                  onClick={sendEmailValidation}
                                  disabled={loader || (user.email_verification_info && (user.email_verification_info.mail_verified || user.email_verification_info.registerWithProvider))}
                                  block
                                >
                                  <IntlMessages id='app.user.register.resend.email' />
                                </Button>
                              </Tooltip>
                              <Tooltip {...propsToltipResendCellPhone}>
                                <Button
                                  type='success'
                                  style={{ marginBottom: '0px' }}
                                  className='gx-fs-sm'
                                  size='large'
                                  htmlType='submit'
                                  onClick={sendCellPhoneValidation}
                                  disabled={
                                    loader ||
                                    (user.phone_verification_info &&
                                      user.phone_verification_info
                                        .number_sending_attempts_made >= 3) ||
                                    (user.phone_verification_info &&
                                      user.phone_verification_info
                                        .cell_phone_verified)
                                  }
                                  block
                                >
                                  {getMessageByString(
                                    locale,
                                    'app.user.register.resend.celphone'
                                  ).replaceAll(
                                    '%number_attempts%',
                                    user.phone_verification_info &&
                                      user.phone_verification_info
                                        .number_sending_attempts_made
                                      ? user.phone_verification_info
                                        .number_sending_attempts_made
                                      : 0
                                  )}
                                </Button>
                              </Tooltip>
                              <Button
                                type='secundary'
                                style={{ marginBottom: '0px' }}
                                className='gx-fs-sm'
                                size='large'
                                htmlType='submit'
                                block
                              >
                                <Link to='/signin'>
                                  <IntlMessages id='app.userAuth.login' />
                                </Link>
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                      </>
                    )}
                  <Row>
                    <Col className="ant-col ant-col-md-24 ant-col-lg-24">
                      <ContactMeans />
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          </div>
          <Progress loaderApp={loader} />
        </main>
      </section>
      {loaderApp && (
        <ProgressView backgroundColor='rgb(255 255 255)' opacity='initial' />
      )}
    </>
  )
}

export default SuccessfulRegistration