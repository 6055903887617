import {
    FETCH_REQUERIMENT_PROSPECT_EVALUATION_SUCCESS,
    FETCH_FINAL_PROSPECT_EVALUATION_SUCCESS,
    PROSPECT_EVALUATION_ON_SHOW_MESSAGE,
    PROSPECT_EVALUATION_ON_HIDE_MESSAGE,
    PROSPECT_EVALUATION_ON_SHOW_LOADER,
    PROSPECT_EVALUATION_ON_HIDE_LOADER,
    PROSPECT_REQUERIMENT_EVALUATION_CHANGE,
    PROSPECT_REQUERIMENT_EVALUATION_SINGLE,
    FINAL_PROSPECT_EVALUATION_CHANGE,
    FINAL_PROSPECT_EVALUATION_SINGLE,
    DETAILED_REQUISITE_EVALUATION_CHANGE,
    DETAILED_REQUISITE_EVALUATION_SINGLE
} from 'constants/ActionTypes'

const INIT_STATE = {
    requeriment_prospect_evaluation: [],
    final_prospect_evaluation: [],
    requeriment_prospect_evaluation_single: {},
    final_prospect_evaluation_single: {},
    detailed_requisite_single: {},
    show_message_app: false,
    loader: false,
    type_message: '',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_REQUERIMENT_PROSPECT_EVALUATION_SUCCESS: {
            return {
                ...state,
                requeriment_prospect_evaluation: action.payload
            }
        }
        case FETCH_FINAL_PROSPECT_EVALUATION_SUCCESS: {
            return {
                ...state,
                final_prospect_evaluation: action.payload
            }
        }
        case PROSPECT_EVALUATION_ON_SHOW_MESSAGE: {
            return {
                ...state,
                show_message_app: true,
                alert_message: action.payload.alertMessage,
                type_message: action.payload.typeMessage
            }
        }
        case PROSPECT_EVALUATION_ON_HIDE_MESSAGE: {
            return {
                ...state,
                alert_message: '',
                show_message_app: false,
                loader: false
            }
        }
        case PROSPECT_EVALUATION_ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case PROSPECT_EVALUATION_ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case PROSPECT_REQUERIMENT_EVALUATION_CHANGE: {
            return {
                ...state,
                requeriment_prospect_evaluation_single: {
                    ...state.requeriment_prospect_evaluation_single,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }
        case PROSPECT_REQUERIMENT_EVALUATION_SINGLE: {
            return {
                ...state,
                requeriment_prospect_evaluation_single: action.payload
            }
        }
        case FINAL_PROSPECT_EVALUATION_CHANGE: {
            return {
                ...state,
                final_prospect_evaluation_single: {
                    ...state.final_prospect_evaluation_single,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }
        case FINAL_PROSPECT_EVALUATION_SINGLE: {
            return {
                ...state,
                final_prospect_evaluation_single: action.payload
            }
        }
        case DETAILED_REQUISITE_EVALUATION_CHANGE: {
            return {
                ...state,
                detailed_requisite_single: {
                    ...state.detailed_requisite_single,
                    adm_requeriment_fullfiments: [
                        {
                            ...state.detailed_requisite_single.adm_requeriment_fullfiments[0],
                            [action.payload.fieldName]: action.payload.value
                        }
                    ]
                }
            }
        }
        case DETAILED_REQUISITE_EVALUATION_SINGLE: {
            return {
                ...state,
                detailed_requisite_single: action.payload
            }
        }
        default:
            return state
    }
}