import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import {
  GET_PROJECT_CONTRIBUTORS_DEMODAY,
  GET_PROJECT_DEMODAY,
  GET_TEAMS_DEMODAY,
  GET_PROJECT_MILESTONE_DEMODAY
} from 'constants/ActionTypes'
import { octokit } from 'components/Demoday/octokit'
import {
  getProjectDemoDaySuccess,
  hideLoaderProjectMilestone,
  showLoaderProjectMilestone,
  getProjectMilestoneSuccess,
  getTeamsDemoDaySuccess,
  getProjectContributorsSuccess
} from 'appRedux/actions/DemodayModule'

// Obtener Teams // Get a team by name
const axiosGetTeamsDemoDay = async payload => {
  try {
    const { data } = await octokit.request(
      'GET /orgs/{org}/teams/{team_slug}',
      {
        org: 'academia-geek',
        team_slug: payload,
        headers: {
          'X-GitHub-Api-Version': '2022-11-28'
        }
      }
    )
    return data
  } catch (error) {
    return false
  }
}

// Obtener Project de team
const axiosGetProjectDemoDay = async payload => {
  try {
    if (sessionStorage.getItem('idCohorte') < 4) {
      const repo = await octokit.paginate(
        `GET /organizations/61026890/team/${payload}/repos`
      )
      const project = await octokit.paginate(
        `GET /repos/academia-geek/${repo[0].name}/projects`
      )
      return project[0]
    } else {
      const query = `{
         organization(login: "academia-geek") {
           projectV2(number: ${payload}) {
               id
               title
               shortDescription
               createdAt
               updatedAt
               url
               creator {
                 url
               }
               owner {
                 id
               }
           }
         }
       }`
      const project2 = await fetch('https://api.github.com/graphql', {
        method: 'POST',
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_GITHUB_ACADEMY_TOKEN}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query })
      })
      const { data } = await project2.json()
      const projectData = data.organization.projectV2
      const formattedProject = {
        owner_url: `https://api.github.com/user/${projectData.owner.id}`,
        url: `https://api.github.com/projects/${projectData.id}`,
        html_url: projectData.url,
        id: projectData.id,
        name: projectData.title,
        body: projectData.shortDescription,
        created_at: projectData.createdAt,
        updated_at: projectData.updatedAt,
        creator: {
          url: projectData.creator.url
        }
      }
      return formattedProject
    }
  } catch (error) {
    return false
  }
}

//Obtener contribuidores del proyecto
const axiosGetProjectContributorsDemoDay = async payload => {
  const academiaGeekStaffIds = [
    22262727,
    50517423,
    63367789,
    63740158,
    66570886,
    85580508,
    87379899,
    96480701,
    'MDQ6VXNlcjIyMjYyNzI3',
    'MDQ6VXNlcjUwNTE3NDIz',
    'MDQ6VXNlcjYzMzY3Nzg5',
    'MDQ6VXNlcjYzNzQwMTU4',
    'MDQ6VXNlcjY2NTcwODg2',
    'MDQ6VXNlcjg1NTgwNTA4',
    'MDQ6VXNlcjg3Mzc5ODk5',
    'U_kgDOBcAtvQ'
  ]
  try {
    if (sessionStorage.getItem('idCohorte') < 4) {
      const data = await octokit.paginate(`GET ${payload}/collaborators`, {
        affiliation: 'all'
      })
      const realCollaborators = data.filter(
        collaborator => !academiaGeekStaffIds.includes(collaborator.id)
      )
      return realCollaborators
    } else {
      const query = `{
        repository(owner: "academia-geek", name: "${payload}") {
          description
          url
          collaborators {
            nodes {
              name
              id
              login
              bio
              avatarUrl
            }
          }
        }
      }`
      const project2 = await fetch('https://api.github.com/graphql', {
        method: 'POST',
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_GITHUB_ACADEMY_TOKEN}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query })
      })
      const { data } = await project2.json()
      const realCollaborators = data.repository.collaborators.nodes.filter(
        collaborator => !academiaGeekStaffIds.includes(collaborator.id)
      )
      return realCollaborators
    }
  } catch (error) {
    return false
  }
}

//Obtener informacion acerca de los contribuidores del proyecto
const axiosGetProjectContributorsInfoDemoDay = payload => {
  try {
    const data = payload.map(async item => {
      const info = await octokit.paginate(`GET /users/${item.login}`)
      return info
    })

    return Promise.all(data)
  } catch (error) {
    return false
  }
}

//Obtener los milestone de un proyecto
const axiosGetProjectMilestoneDemoday = async payload => {
  try {
    if (sessionStorage.getItem('idCohorte') < 4) {
      const data = await octokit.paginate(`GET ${payload}/milestones`)
      return data
    } else {
      const query = `{
            repository(owner: "academia-geek", name: "${payload}") {
              description
              url
              milestones (states: [OPEN], first:100) {
                nodes {
                  url
                  id
                  number
                  title
                  description
                  state
                  createdAt
                  updatedAt
                  dueOn
                  closedAt
                  issues (states: [OPEN, CLOSED], first:100) {
                    nodes {
                      title
                      state
                    }
                  }
                }
              }
            }
          }`
      const project2 = await fetch('https://api.github.com/graphql', {
        method: 'POST',
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_GITHUB_ACADEMY_TOKEN}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query })
      })
      const { data } = await project2.json()
      const milestoneData = data.repository.milestones.nodes
      const formattedMilestone = milestoneData.map(item => {
        return {
          projectDescription: data.repository.description,
          url: item.url,
          id: item.id,
          number: item.number,
          title: item.title,
          description: item.description,
          open_issues: item.issues.nodes.filter(node => node.state === 'OPEN')
            .length,
          closed_issues: item.issues.nodes.filter(
            node => node.state === 'CLOSED'
          ).length,
          state: item.state,
          created_at: item.createdAt,
          updated_at: item.updatedAt,
          due_on: item.dueOn,
          closed_at: item.closedAt
        }
      })
      return formattedMilestone
    }
  } catch (error) {}
}

function* getTeamsDemoDayRequest ({ payload }) {
  try {
    yield put(showLoaderProjectMilestone())
    const response = yield call(axiosGetTeamsDemoDay, payload)
    if (response !== false) {
      yield put(hideLoaderProjectMilestone())
      yield put(getTeamsDemoDaySuccess(response))
    }
  } catch (error) {}
}

function* getProjectDemoDayRequest ({ payload }) {
  try {
    yield put(showLoaderProjectMilestone())
    const response = yield call(axiosGetProjectDemoDay, payload)
    if (response !== false) {
      yield put(hideLoaderProjectMilestone())
      yield put(getProjectDemoDaySuccess(response))
    }
  } catch (error) {}
}

function* getProjectContributorsDemodayRequest ({ payload }) {
  try {
    const users = yield call(axiosGetProjectContributorsDemoDay, payload)
    yield put(showLoaderProjectMilestone())
    if (users !== false) {
      yield put(hideLoaderProjectMilestone())
      const response = yield call(axiosGetProjectContributorsInfoDemoDay, users)
      yield put(getProjectContributorsSuccess(response.flat()))
    }
  } catch (error) {}
}

function* getProjectMilestoneDemoDayRequest ({ payload }) {
  try {
    yield put(showLoaderProjectMilestone())
    const response = yield call(axiosGetProjectMilestoneDemoday, payload)
    if (response !== false) {
      yield put(hideLoaderProjectMilestone())
      yield put(getProjectMilestoneSuccess(response))
    }
  } catch (error) {}
}

export function* getTeamsDemoDay () {
  yield takeEvery(GET_TEAMS_DEMODAY, getTeamsDemoDayRequest)
}

export function* getReposDemoDay () {
  yield takeEvery(GET_PROJECT_DEMODAY, getProjectDemoDayRequest)
}

export function* getReposContributorsDemoday () {
  yield takeEvery(
    GET_PROJECT_CONTRIBUTORS_DEMODAY,
    getProjectContributorsDemodayRequest
  )
}

export function* getProjectMilestoneDemoDay () {
  yield takeEvery(
    GET_PROJECT_MILESTONE_DEMODAY,
    getProjectMilestoneDemoDayRequest
  )
}

export default function* rootSaga () {
  yield all([
    fork(getTeamsDemoDay),
    fork(getReposDemoDay),
    fork(getReposContributorsDemoday),
    fork(getProjectMilestoneDemoDay)
  ])
}
