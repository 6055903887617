import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";
import { showMessageDialog, fetchRequirementSuccess, hideLoaderRequirement } from 'appRedux/actions/RegisterRequirements'
import { ADD_REQUIREMENT, FETCH_REQUIREMENTS, TYPE_MESSAGE_ERROR, TYPE_MESSAGE_SUCCESS, DELETE_REQUIREMENT, EDIT_REQUIREMENT } from "constants/ActionTypes"

//Requisitos
const serviceFetchRequirements = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}course/courses_requeriments`, config());
    return data;
}

const serviceAddRequirement = async payload => {
    const { requisite } = payload;
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}course/courses_requeriments`, payload.body, config());
    requisite.forEach(req => {
        req.id_courses_requeriments = data.id
        req.id = undefined
    })
    await axios.post(`${process.env.REACT_APP_API_URL}course/detailed_requeriments`, requisite, config());
    return data;
}

const serviceDeleteRequirement = async payload => {
    //Trae todos los requisitos detallados
    const detailedReqsDB = await serviceGetDetailedRequirements();
    //Se crea un array con los reqDetallados del curso especifico
    const detailedReqsCourseDB = []
    detailedReqsDB.forEach((detailedReqDB) => {
        if (detailedReqDB.id_courses_requeriments === payload.id) {
            detailedReqsCourseDB.push(detailedReqDB)
        }
    })
    detailedReqsCourseDB.map(async detailedReqCourseDB => {
        await serviceDeleteDetailedRequirement(detailedReqCourseDB.id)
    })
    return await axios.delete(`${process.env.REACT_APP_API_URL}course/${payload.id}/courses_requeriments`, config());
}

const serviceEditRequirement = async payload => {
    //Actualiza el requisito
    await axios.patch(`${process.env.REACT_APP_API_URL}course/${payload.id}/courses_requeriments`, payload.body, config())
    //Trae todos los requisitos detallados
    const detailedReqsDB = await serviceGetDetailedRequirements();
    //Se crea un array con los reqDetallados del curso especifico
    const detailedReqsCourseDB = []
    detailedReqsDB.forEach((detailedReqDB) => {
        if (detailedReqDB.id_courses_requeriments === payload.id) {
            detailedReqsCourseDB.push(detailedReqDB)
        }
    })

    //Recorre los requisitos detallados del curso especifico de la db y los de local
    detailedReqsCourseDB.map(async detailedReqCourseDB => {
        //Bandera para identificar si el reqDetallado existe o no en el local
        let flagDelete = 0;
        payload.requisite.map(async detailedReq => {
            if (detailedReq.id === detailedReqCourseDB.id) {
                await serviceEditDetailedRequirement(detailedReq)
            }
            else if (detailedReqCourseDB.id !== detailedReq.id) {
                flagDelete += 1;
            }
        })
        if (flagDelete === payload.requisite.length) {
            await serviceDeleteDetailedRequirement(detailedReqCourseDB.id)
        }
    })

    const detailedReqsCreate = [];
    payload.requisite.map(async req => {
        //si el id de la db es undefined significa que en la db no existe y hay que crearlo
        if (req.id === undefined) {
            detailedReqsCreate.push({ ...req, id_courses_requeriments: payload.id })
        }
    })
    return await serviceCrateDetailedRequirements(detailedReqsCreate);
}

//Requisitos detallados
const serviceGetDetailedRequirements = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}course/detailed_requeriments`, config())
    return data;
}

const serviceCrateDetailedRequirements = async payload => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}course/detailed_requeriments`, payload, config());
    return data;
}

const serviceDeleteDetailedRequirement = async payload => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}course/${payload}/detailed_requeriments`, config());
}

const serviceEditDetailedRequirement = async payload => {
    return await axios.patch(`${process.env.REACT_APP_API_URL}course/${payload.id}/detailed_requeriments`, payload, config());
}

function* addRequirementRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceAddRequirement, payload)
        const allRequirements = yield call(serviceFetchRequirements)
        yield put(fetchRequirementSuccess(allRequirements))
        yield put(hideLoaderRequirement())
        const message = getMessageByString(
            locale,
            'courses.register.requirement.message.create'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderRequirement())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* fetchRequirementsRequest({ payload }) {
    const { locale } = payload;
    try {
        const allRequirements = yield call(serviceFetchRequirements)
        yield put(fetchRequirementSuccess(allRequirements))
        yield put(hideLoaderRequirement())
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* deleteRequirementRequest({ payload }) {
    const { locale } = payload;
    try {
        const deleteRequirementRes = yield call(serviceDeleteRequirement, payload)
        const allRequirements = yield call(serviceFetchRequirements);
        yield put(fetchRequirementSuccess(allRequirements))
        yield put(hideLoaderRequirement())

        if (deleteRequirementRes.status === 270) {
            const message = getMessageByString(
                locale,
                'register.requirements.message.internalerror.students'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        } else {
            const message = getMessageByString(
                locale,
                'courses.register.requirement.message.delete'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
        }
    } catch (e) {
        yield put(hideLoaderRequirement())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* editRequirementRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceEditRequirement, payload)
        const allRequirements = yield call(serviceFetchRequirements);
        yield put(fetchRequirementSuccess(allRequirements))
        yield put(hideLoaderRequirement())

        const message = getMessageByString(
            locale,
            'courses.register.requirement.message.update'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderRequirement())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

export function* addRequirement() {
    yield takeEvery(ADD_REQUIREMENT, addRequirementRequest)
}

export function* fetchRequirements() {
    yield takeEvery(FETCH_REQUIREMENTS, fetchRequirementsRequest)
}

export function* deleteRequirement() {
    yield takeEvery(DELETE_REQUIREMENT, deleteRequirementRequest)
}

export function* editRequirement() {
    yield takeEvery(EDIT_REQUIREMENT, editRequirementRequest)
}

export default function* rootSaga() {
    yield all([
        fork(addRequirement),
        fork(fetchRequirements),
        fork(deleteRequirement),
        fork(editRequirement),
    ])
}