import {
  FETCH_USER_SUCCESS,
  RESPONSE_INFO_TOKEN_CELLPHONE,
  FETCH_VALIDATE_CELLPHONE_SUCCESS,
  RESPONSE_INFO_EMAIL,
  FETCH_VALIDATE_EMAIL,
  RESPONSE_INFO_TOKEN_EMAIL,
  FETCH_VALIDATE_EMAIL_SUCCESS,
  RESPONSE_INFO_JWT_TOKEN,
  USER_ON_SHOW_LOADER,
  USER_ON_HIDE_LOADER,
  GET_BY_ID_USER_USER_STAGES_SUCCESS,
  GET_BY_ID_USER_ALL_STAGES_SUCCESS
} from 'constants/ActionTypes'
import { USER_ACTIVE_ID } from 'constants/MainStages'

const INIT_STATE = {
  token_cell_phone: null,
  info_token_email: null,
  info_jwt_token: {},
  user: [
    {
      id: '',
      full_name: '',
      last_name: '',
      email: '',
      pass: '',
      register_time: '',
      last_entry: '',
      status: '',
      admin: '',
      parent_id: '',
      upgrade_time: '',
      cv: ''
    }
  ],
  loader: false,
  user_stages_by_id_user: [
    {
      id_stage: USER_ACTIVE_ID
    }
  ],
  all_stages_by_id_user: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case USER_ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case RESPONSE_INFO_JWT_TOKEN: {
      return {
        ...state,
        info_jwt_token: action.payload
      }
    }
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload
      }
    case RESPONSE_INFO_TOKEN_CELLPHONE:
      return {
        ...state,
        info_token_cell_phone: action.payload
      }
    case RESPONSE_INFO_TOKEN_EMAIL:
      return {
        ...state,
        info_token_email: action.payload
      }
    case FETCH_VALIDATE_CELLPHONE_SUCCESS:
      return {
        ...state,
        info_token_cell_phone: action.payload
      }
    case FETCH_VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        info_token_email: action.payload
      }
    case RESPONSE_INFO_EMAIL:
      return {
        ...state,
        info_token_email: action.payload
      }
    case FETCH_VALIDATE_EMAIL:
      return {
        ...state,
        info_token_email: action.payload
      }
    case GET_BY_ID_USER_USER_STAGES_SUCCESS:
      return {
        ...state,
        user_stages_by_id_user: action.payload,
      }
    case GET_BY_ID_USER_ALL_STAGES_SUCCESS:
      return {
        ...state,
        all_stages_by_id_user: action.payload,
      }
    default:
      return state
  }
}