import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { GET_COURSES, FETCH_PREREQUISITE_COURSES } from 'constants/ActionTypes';
import { fetchCoursesSuccess, fetchPrerequisiteCoursesSuccess } from 'appRedux/actions/Courses';
import { fetchError } from "appRedux/actions/Common";
import { config } from "util/Authorization";

const getCourses = async () => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}courses`, config());
    return data;
  } catch (err) {
    return err;
  }
}
const serviceFetchPrerequisiteCourses = async (id_course) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}course/prerequisite/${id_course}`)
  return data;
}

function* fetchCoursesRequest() {
  try {
    const fetchedCourses = yield call(getCourses);
    yield put(fetchCoursesSuccess(fetchedCourses));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchPrerequisiteCoursesRequest({ payload }) {
  const { id_course } = payload
  try {
    const courses = yield call(serviceFetchPrerequisiteCourses, id_course);
    yield put(fetchPrerequisiteCoursesSuccess(courses))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export function* fetchCourses() {
  yield takeEvery(GET_COURSES, fetchCoursesRequest);
}

export function* fetchPrerequisiteCourses() {
  yield takeEvery(FETCH_PREREQUISITE_COURSES, fetchPrerequisiteCoursesRequest)
}

export default function* rootSaga() {
  yield all([
    fork(fetchCourses),
    fork(fetchPrerequisiteCourses)
  ]);
}