import {
    SET_BASE_KEY_CURRICULUM_VITAE,
} from 'constants/ActionTypes'

export const setBaseKeyCurriculumVitae = baseKey => {
    return {
        type: SET_BASE_KEY_CURRICULUM_VITAE,
        payload: baseKey
    }
}
