import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Layout } from 'antd'
import { footerText } from 'util/config'
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from 'constants/ThemeSetting'
import Sidebar from '../Sidebar/index'
import HorizontalDefault from '../Topbar/HorizontalDefault/index'
import HorizontalDark from '../Topbar/HorizontalDark/index'
import InsideHeader from '../Topbar/InsideHeader/index'
import AboveHeader from '../Topbar/AboveHeader/index'
import BelowHeader from '../Topbar/BelowHeader/index'
import Topbar from '../Topbar/index'
import NoHeaderNotification from '../Topbar/NoHeaderNotification/index'
import App from 'routes/index'
import Breadcrumbs from '../../components/Breadcrumbs'
import { checkAdmin } from '../../util/adminFunctions'

const MainApp = () => {
  const match = useRouteMatch()

  const { Content, Footer } = Layout

  const { id_role } = useSelector(({ authorization }) => authorization)
  const { user } = useSelector(({ auth }) => auth)
  const { navStyle } = useSelector(({ settings }) => settings)

  const getContainerClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return 'gx-container-wrap'
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return 'gx-container-wrap'
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return 'gx-container-wrap'
      case NAV_STYLE_BELOW_HEADER:
        return 'gx-container-wrap'
      case NAV_STYLE_ABOVE_HEADER:
        return 'gx-container-wrap'
      default:
        return ''
    }
  }
  const getNavStyles = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />
      // Estilo para usuarios con solo una interfaz
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />
      //Aquí se debe de gestionar el que cuando se le de al logo lo lleve a un lugar u otro
      case NAV_STYLE_FIXED:
        return <Topbar />
      case NAV_STYLE_DRAWER:
        return <Topbar />
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />
      default:
        return null
    }
  }

  return (
    (id_role || user.admin) && (
      <Layout className='gx-app-layout'>
        {/* Personalizar el sidebar */}
        {<Sidebar />}
        <Layout>
          {/* Verificar el comentario de la función */}
          {getNavStyles(navStyle)}
          <Content
            className={`gx-layout-content ${getContainerClass('navStyle')} `}
          >
            {/* Ingresar acá */}
            {!checkAdmin(user) && <Breadcrumbs /> }
            <App match={match} />
            <Footer>
              <div className='gx-layout-footer-content'>{footerText}</div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    )
  )
}
export default MainApp
