import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { storage } from 'firebase/firebase'
import axios from 'axios'
import {
  TYPE_MESSAGE_ERROR,
  TYPE_MESSAGE_SUCCESS,
  CHALLENGE_COURSE_FETCH_LIST_CHALLENGES,
  CHALLENGE_COURSE_CREATE_CHALLENGE,
  CHALLENGE_COURSE_FETCH_GET_PROSPECT_PREFERENCE,
  CHALLENGE_COURSE_LOAD_LIST_FILTER,
  CHALLENGE_COURSE_DELETE,
  CHALLENGE_COURSE_UPDATE_CHALLENGE
} from 'constants/ActionTypes'
import {
  reloadListChallenges,
  setDataAdmProspectPreferentCourse,
  showMessageDialog,
  hideLoaderChallenge,
  openOrCloseModalAddDeliveryChallenge,
  reloadChallengesList,
  hideLoadChallengeCourse,
  resetRequirementFullfilment,
  successfulDelete,
  openOrCloseModalEditDeliveryChallenge,
  resetFormChallenge
} from 'appRedux/actions/ChallengeCourse'
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";

const serviceUpdateChallengeCourse = async data =>
  axios.put(
    `${process.env.REACT_APP_API_URL}requeriment_fullfiment/${data.id}`,
    {
      evidence_type: data.evidence_type,
      id_prospect: data.id_pospect_preference,
      id_detailed_req: data.challenge.value,
      url_validation: data.url_validation,
      file: data.file,
      comment: data.comment,
      evidence_name: data.evidence_name,
      id_user: data.id_user,
      upload_date: data.upload_date
    }, config()
  )

const serviceAddChallengeCourse = async data =>
  axios.post(`${process.env.REACT_APP_API_URL}requeriment_fullfiment`, {
    evidence_type: data.evidence_type,
    id_prospect: data.id_pospect_preference,
    id_detailed_req: data.challenge.value,
    url_validation: data.url_validation,
    file: data.file,
    comment: data.comment,
    evidence_name: data.evidence_name,
    id_user: data.id_user
  }, config())

const deleteFileFirebaseStorage = async data => {
  try {
    var storageRef = storage.ref()
    var desertRef = storageRef.child(data.fullPath)
    await desertRef.delete()
    return true
  } catch (e) {
    return false
  }
}

const serviceDeleteChallenge = async data =>
  await axios.delete(
    `${process.env.REACT_APP_API_URL}requeriment_fullfiment/${data.id}`, config()
  )

const uploadFirebaseStorage = async ({
  challengeFile,
  course_name,
  course_id,
  challenge,
  name_user,
  id_user
}) => {
  try {
    var storageRef = storage.ref()

    var spaceRef = storageRef.child(
      `courses/${course_id}-${course_name}/challenges/${challenge.label
        .replaceAll(' ', '-')
        .toLowerCase()}/${id_user}-${name_user
          .replaceAll(' ', '-')
          .toLowerCase()}/${challengeFile.name}`)

    await spaceRef.put(challengeFile)

    const publicURL = await spaceRef.getDownloadURL()

    const metadata = await spaceRef.getMetadata()

    return { ...metadata, publicURL }
  } catch (e) {
    return false
  }
}

const serviceListChallenges = async ({ course_id, id_prospect }) =>
  await axios.get(
    `${process.env.REACT_APP_API_URL}course/${course_id}/challenges/prospect/${id_prospect}`, config()
  )

const serviceGetAdmProspectPreferentCourse = async ({
  course_id,
  id_cv_user,
  id_user
}) =>
  await axios.get(
    `${process.env.REACT_APP_API_URL}prospect/preferences/${course_id}/${id_cv_user}/${id_user}`, config()
  )

// const serviceGetChallengeById = async ({ id }) =>
//   await axios.get(
//     `${process.env.REACT_APP_API_URL}requeriment_fullfiment/${id}`, config()
//   )
const serviceGetAdmRequerimentFullfimentByProspect = async ({
  id_prospect_preference
}) =>
  await axios.get(
    `${process.env.REACT_APP_API_URL}requeriment_fullfiment/prospect/${id_prospect_preference}`, config()
  )

const serviceGetAdmRequerimentFullfimentByProspectFilter = async ({
  id_prospect_preference,
  filter
}) =>
  await axios.get(
    `${process.env.REACT_APP_API_URL}requeriment_fullfiment/prospect/${id_prospect_preference}`, { params: { filter: filter } }, config())

function* fetchListChallengesRequest({ payload }) {
  const { data } = yield call(serviceListChallenges, payload)
  if (payload.detailed_req)
    data.push(payload.detailed_req)
  yield put(reloadListChallenges(data))
}

function* fetchGetAdmProspectPreferentCourseRequest({ payload }) {
  const { data } = yield call(serviceGetAdmProspectPreferentCourse, payload)
  if (data[0]) {
    const prospectPreference = data[0].prospectPrefereces
      ? data[0].prospectPrefereces[0]
      : {}
    yield put(
      setDataAdmProspectPreferentCourse({ isProspect: true, ...data[0] })
    )
    const data_prospect = yield call(
      serviceGetAdmRequerimentFullfimentByProspect,
      { id_prospect_preference: prospectPreference.id }
    )
    yield put(reloadChallengesList(data_prospect.data))
    yield put(hideLoadChallengeCourse())
  } else yield put(setDataAdmProspectPreferentCourse({ isProspect: false }))
}

// function* fetchLoadListChallengesRequest({ payload }) {
//   yield call(
//     serviceGetAdmRequerimentFullfimentByProspect,
//     payload
//   )
// }

function* fetchLoadListChallengesFilter({ payload }) {
  const { data } = yield call(
    serviceGetAdmRequerimentFullfimentByProspectFilter,
    payload
  )
  yield put(reloadChallengesList(data))
  yield put(hideLoadChallengeCourse())
}

function* fetchDeleteChallengeRequest({ payload }) {
  const { locale, id_detailed_req, id_pospect_preference, id_user, id } = payload

  if (payload.id_file_validation && payload.id_file_validation.file)
    yield call(deleteFileFirebaseStorage, payload.id_file_validation.file)

  if (id_detailed_req.bool_in_github) {

    const data = {
      evidence_type: null,
      id_prospect: id_pospect_preference,
      challenge: { value: id_detailed_req.id },
      url_validation: null,
      file: null,
      comment: null,
      evidence_name: null,
      id_user,
      upload_date: null,
      id: id
    }

    yield call(serviceUpdateChallengeCourse, data);
  } else {
    yield call(serviceDeleteChallenge, payload);
  }

  yield put(successfulDelete())
  const message = getMessageByString(
    locale,
    'curseinfo.challengedeliverableare.challenge.delete.successful'
  )
  yield put(
    showMessageDialog({
      alertMessage: message,
      typeMessage: TYPE_MESSAGE_SUCCESS
    })
  )
}

function* updateChallengeCourseRequest({ payload }) {
  const { locale } = payload

  try {
    const flagUpdateIssue = payload.upload_date ? true : false;
    let file = payload.challengeFile
    if (payload.challengeFile instanceof File) {
      file = yield call(uploadFirebaseStorage, payload)
    }

    yield call(serviceUpdateChallengeCourse, {
      ...payload,
      file
    })

    payload.id_prospect_preference = payload.id_pospect_preference;
    const { data } = yield call(
      serviceGetAdmRequerimentFullfimentByProspect, payload
    )
    yield put(reloadChallengesList(data))

    const message = getMessageByString(
      locale,
      'curseinfo.challengedeliverableare.challenge.frm.update.successful'
    )

    yield put(hideLoaderChallenge())
    if (flagUpdateIssue) yield put(openOrCloseModalAddDeliveryChallenge())
    else yield put(openOrCloseModalEditDeliveryChallenge())
    yield put(resetRequirementFullfilment())
    yield put(
      showMessageDialog({
        alertMessage: message,
        typeMessage: TYPE_MESSAGE_SUCCESS
      })
    )
  } catch (e) {
    const message = getMessageByString(
      locale,
      'curseinfo.challengedeliverableare.challenge.frm.save.error'
    )
    yield put(
      showMessageDialog({
        alertMessage: message,
        typeMessage: TYPE_MESSAGE_ERROR
      })
    )
  }
}

function* addChallengeCourseRequest({ payload }) {
  const { locale } = payload
  try {
    const file = yield call(uploadFirebaseStorage, payload)
    //const file = {}
    yield call(serviceAddChallengeCourse, { ...payload, file })

    payload.id_prospect_preference = payload.id_pospect_preference;
    const { data } = yield call(
      serviceGetAdmRequerimentFullfimentByProspect, payload
    )
    yield put(reloadChallengesList(data))

    const message = getMessageByString(
      locale,
      'curseinfo.challengedeliverableare.challenge.frm.save.successful'
    )
    yield put(hideLoaderChallenge())
    yield put(openOrCloseModalAddDeliveryChallenge())
    yield put(resetFormChallenge())
    yield put(resetRequirementFullfilment())
    yield put(
      showMessageDialog({
        alertMessage: message,
        typeMessage: TYPE_MESSAGE_SUCCESS
      })
    )
  } catch (e) {
    const message = getMessageByString(
      locale,
      'curseinfo.challengedeliverableare.challenge.frm.save.error'
    )
    yield put(
      showMessageDialog({
        alertMessage: message,
        typeMessage: TYPE_MESSAGE_ERROR
      })
    )
  }
}

export function* fetchListChallenges() {
  yield takeEvery(
    CHALLENGE_COURSE_FETCH_LIST_CHALLENGES,
    fetchListChallengesRequest
  )
}

export function* fetchGetAdmProspectPreferentCourse() {
  yield takeEvery(
    CHALLENGE_COURSE_FETCH_GET_PROSPECT_PREFERENCE,
    fetchGetAdmProspectPreferentCourseRequest
  )
}

export function* updateChallengeCourse() {
  yield takeEvery(
    CHALLENGE_COURSE_UPDATE_CHALLENGE,
    updateChallengeCourseRequest
  )
}

export function* addChallengeCourse() {
  yield takeEvery(CHALLENGE_COURSE_CREATE_CHALLENGE, addChallengeCourseRequest)
}

export function* fetchLoadListChallenges() {
  yield takeEvery(
    CHALLENGE_COURSE_LOAD_LIST_FILTER,
    fetchLoadListChallengesFilter
  )
}

export function* fetchDeleteChallenge() {
  yield takeEvery(CHALLENGE_COURSE_DELETE, fetchDeleteChallengeRequest)
}

export default function* rootSaga() {
  yield all([
    fork(fetchListChallenges),
    fork(addChallengeCourse),
    fork(fetchGetAdmProspectPreferentCourse),
    fork(fetchLoadListChallenges),
    fork(fetchDeleteChallenge),
    fork(updateChallengeCourse)
  ])
}