import {
  CHALLENGE_COURSE_MODAL_DELIVERY_ADD,
  CHALLENGE_COURSE_MODAL_DELIVERY_EDIT,
  CHALLENGE_COURSE_LIST_CHALLENGES,
  CHALLENGE_COURSE_SET_ADM_PROSPECT_PREFERENCE,
  CHALLENGE_COURSE_UPDATE_REQUERIMENT_FULLFIMENT,
  CHALLENGE_COURSE_ON_SHOW_LOADER,
  CHALLENGE_COURSE_ON_SHOW_MESSAGE,
  CHALLENGE_COURSE_ON_HIDE_MESSAGE,
  CHALLENGE_COURSE_ON_HIDE_LOADER,
  CHALLENGE_COURSE_RELOAD_CHALLENGES_LIST,
  CHALLENGE_COURSE_SHOW_LOAD,
  CHALLENGE_COURSE_HIDE_LOAD,
  CHALLENGE_COURSE_RESET_REQUERIMENT_FULLFIMENT,
  CHALLENGE_COURSE_DELETE,
  CHALLENGE_COURSE_SUCCESSFUL_DELETE,
  CHALLENGE_COURSE_REMOVE_CHALLENGE_FROM_LIST,
  CHALLENGE_COURSE_LOAD_LIST_FILTER,
  CHALLENGE_COURSE_LOAD_DATA_FROM_REQUERIMENT_FULLFIMENT,
  CHALLENGE_COURSE_RESET_FORM,
  CHALLENGE_COURSE_NOT_RESET_FORM
} from 'constants/ActionTypes'

const INIT_STATE = {
  modal_delivery_add: false,
  modal_delivery_edit: false,
  challenges: [],
  prospect_preference: {},
  requeriment_fullfiment: {
    challenge: null,
    evidence_type: '',
    evidence_name: '',
    challengeFile: '',
    comment: ''
  },
  loader: false,
  show_message_app: false,
  alert_message: '',
  type_message: '',
  challenges_list: [],
  load_challenge_course: false,
  successful_delete: false,
  challenge: {},
  searchChallenge: '',
  reset_form_challenge: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHALLENGE_COURSE_NOT_RESET_FORM: {
      return {
        ...state,
        reset_form_challenge: false
      }
    }
    case CHALLENGE_COURSE_RESET_FORM: {
      return {
        ...state,
        reset_form_challenge: true
      }
    }
    case CHALLENGE_COURSE_LOAD_LIST_FILTER: {
      return {
        ...state,
        searchChallenge: action.payload.filter
      }
    }
    case CHALLENGE_COURSE_REMOVE_CHALLENGE_FROM_LIST: {
      const { challenges_list } = state
      for (let index in challenges_list) {
        if (challenges_list[index].id === state.challenge.id) {
          challenges_list.splice(index, 1)
          break
        }
      }
      return {
        ...state,
        challenges_list
      }
    }
    case CHALLENGE_COURSE_SUCCESSFUL_DELETE: {
      return {
        ...state,
        successful_delete: true,
      }
    }
    case CHALLENGE_COURSE_DELETE: {
      return {
        ...state,
        challenge: action.payload
      }
    }
    case CHALLENGE_COURSE_SHOW_LOAD: {
      return {
        ...state,
        load_challenge_course: true
      }
    }
    case CHALLENGE_COURSE_HIDE_LOAD: {
      return {
        ...state,
        load_challenge_course: false,
        successful_delete: false
      }
    }
    case CHALLENGE_COURSE_ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case CHALLENGE_COURSE_ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case CHALLENGE_COURSE_MODAL_DELIVERY_ADD: {
      return {
        ...state,
        modal_delivery_add: !state.modal_delivery_add
      }
    }
    case CHALLENGE_COURSE_MODAL_DELIVERY_EDIT: {
      return {
        ...state,
        modal_delivery_edit: !state.modal_delivery_edit
      }
    }
    case CHALLENGE_COURSE_LIST_CHALLENGES: {
      return {
        ...state,
        challenges: action.payload
      }
    }
    case CHALLENGE_COURSE_SET_ADM_PROSPECT_PREFERENCE: {
      return {
        ...state,
        prospect_preference: action.payload
      }
    }
    case CHALLENGE_COURSE_RESET_REQUERIMENT_FULLFIMENT: {
      return {
        ...state,
        requeriment_fullfiment: {
          challenge: null,
          evidence_type: '',
          evidence_name: '',
          challengeFile: '',
          comment: ''
        }
      }
    }
    case CHALLENGE_COURSE_LOAD_DATA_FROM_REQUERIMENT_FULLFIMENT: {
      return {
        ...state,
        requeriment_fullfiment: action.payload
      }
    }
    case CHALLENGE_COURSE_UPDATE_REQUERIMENT_FULLFIMENT: {
      state.requeriment_fullfiment[action.payload.field] = action.payload.value
      return {
        ...state
      }
    }
    case CHALLENGE_COURSE_ON_SHOW_MESSAGE: {
      return {
        ...state,
        show_message_app: true,
        alert_message: action.payload.alertMessage,
        type_message: action.payload.typeMessage
      }
    }
    case CHALLENGE_COURSE_ON_HIDE_MESSAGE: {
      return {
        ...state,
        alert_message: '',
        show_message_app: false,
        loader: false
      }
    }
    case CHALLENGE_COURSE_RELOAD_CHALLENGES_LIST: {
      return {
        ...state,
        challenges_list: action.payload
      }
    }

    default:
      return state
  }
}