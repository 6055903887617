import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { storage } from 'firebase/firebase'
import Axios from "axios";
import { fetchError } from "appRedux/actions/Common";
import { showMessageDialogBasicData, getByIdUserCvBasicDataSuccess, getAllPhysicalDisabilitySuccess, gellAllSpecialConditionSuccess, checkIdDocumentSuccess, urlFile, pathCvBasicDataSuccess, } from "appRedux/actions/BasicData"
import { config } from "util/Authorization";
import { getMessageByString } from 'util/Messages'
import {
    TYPE_MESSAGE_ERROR,
    GET_BY_ID_USER_CV_BASIC_DATA,
    GET_ALL_PHYSICAL_DISABILITY,
    GET_ALL_SPECIAL_CONDITION,
    CHECK_ID_DOCUMENT,
    UPLOAD_FILES,
    PATCH_BY_ID_USER_CV_BASIC_DATA
} from 'constants/ActionTypes';

//Falta organizar bien el control de errores
const axiosGetCvBasicDataByIdUser = async idUser => {
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URL}user_cv/${idUser}`, config())
        return data
    } catch (err) {
        return err;
    }
}

//Falta organizar bien el control de errores
const axiosGetAllPhysicalDisability = async () => {
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URL}physical_disability`, config());
        return data;
    } catch (err) {
        return err;
    }
}

//Falta organizar bien el control de errores
const axiosGetAllSpecialCondition = async () => {
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URL}special_condition`, config());
        return data;
    } catch (err) {
        return err;
    }
}

const axiosCheckIdDocument = async payload => {
    try {
        const { status } = await Axios.post(`${process.env.REACT_APP_API_URL}user_cv/${payload.idUser}/checkDocument`, { document: [payload.document] }, config())
        return { info: status, status: true }
    } catch (err) {
        return { info: err, status: false };
    }
}

const axiosServiceUploadFilesFirestore = async ({ idUser, file, type }) => {
    try {
        const data = await Axios.post(`${process.env.REACT_APP_API_URL}user_cv/${idUser}/updateProfilePicture`, { file: file, type: type }, config());
        return { info: data, status: true };
    } catch (err) {
        return { info: err, status: false };
    }
}

const axiosPatchCvBasicDataByIdUser = async payload => {
    try {
        const data = await Axios.patch(`${process.env.REACT_APP_API_URL}user_cv/${payload.idCv}`, payload.data, config());
        return { info: data, status: true };
    } catch (err) {
        return { info: err, status: false };
    }
}

const axiosCheckGitHubAccountName = async accountName => {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}cv-info/github-account/${accountName}`, config());
    return response;
}

function* getCvBasicDataByUserIdRequest({ idUser }) {
    try {
        const data = yield call(axiosGetCvBasicDataByIdUser, idUser);
        return yield put(getByIdUserCvBasicDataSuccess(data))
    } catch (err) {
        return yield put(fetchError(err));
    }
}

function* getAllPhysicalDisabilityRequest() {
    try {
        const disabilities = yield call(axiosGetAllPhysicalDisability)
        return yield put(getAllPhysicalDisabilitySuccess(disabilities));
    } catch (err) {
        return yield put(fetchError(err));
    }
}

function* getAllSpecialConditionRequest() {
    try {
        const specialConditions = yield call(axiosGetAllSpecialCondition);
        return yield put(gellAllSpecialConditionSuccess(specialConditions))
    } catch (err) {
        return yield put(fetchError(err));
    }
}

function* checkIdDocumentRequest({ payload }) {
    try {
        const res = yield call(axiosCheckIdDocument, payload);
        if (!res.status) return yield put(checkIdDocumentSuccess(res.info.response.status))
        return yield put(checkIdDocumentSuccess(res.info))
    } catch (err) {
        return yield put(checkIdDocumentSuccess(500))
    }
}

const serviceUploadFilesFirestore = async ({ info, field }) => {
    const { idUser, name, idCv, file } = info
    try {
        var storageRef = storage.ref()
        const nameUser = name.replaceAll(' ', '-');
        var spaceRef = storageRef.child(`curriculum-vitae/${idCv}-${nameUser}/basic-data/${file.name}`)
        //Task
        await spaceRef.put(file)
        const publicURL = await spaceRef.getDownloadURL()
        const metadata = await spaceRef.getMetadata()

        let type = ''
        if (field === 'PersonalPhoto') type = 'id_photo'
        else if (field === 'IdentificationDocument') type = 'id_document'
        else if (field === 'Cv') type = 'id_cv_file'
        else return false
        //Objeto para la petición
        const payload = {
            idUser: idUser,
            file: { ...metadata, publicURL },
            type: type
        }
        const resServer = await axiosServiceUploadFilesFirestore(payload)
        if (!resServer.status) return { info: resServer.info, status: false }
        return { info: { ...metadata, publicURL }, status: true, resServer: resServer.info }
    } catch (err) {
        return { info: { response: { status: 422 } }, status: false }
    }
}

function* uploadFilesRequest({ payload }) {
    try {
        const fileStorage = yield call(serviceUploadFilesFirestore, payload)
        if (!fileStorage.status) return yield put(urlFile({ publicURL: '', field: payload.field, resServer: fileStorage.info.response.status }))
        return yield put(urlFile({ publicURL: fileStorage.info.publicURL, field: payload.field, resServer: fileStorage.resServer.status }))
    } catch (err) {
        return yield put(urlFile({ publicURL: '', field: payload.field, resServer: 422 }))
    }
}

const checkGitHubAccountNameRequest = async ({ gitHubUserAccount, numberUserDocument }) => {
    try {
        //Con esta llamada se realiza la petición al servidor de Agile Innova 
        const response = await axiosCheckGitHubAccountName(gitHubUserAccount);
        if (response.status === 200) {
            /*Si una vez realizada la petición responde con un status 200 significa que el usuario 
            de GitHub ya se encuentra en alguno de los registros de los datos básicos de la hoja de vida 
            en la plataforma de Agile Innova, aquí es necesario una validación adicional: verificar 
            que el nombre de usuario de GitHub que se está ingresando no pertenezca a ese mismo 
            usuario, porque si es así todo se encuentra correcto y retornamos un 200.*/
            if (numberUserDocument === response.data.document) return 200;
            /*Si el número de documento del parámetro no coincide con el número de doc que tiene 
            asociado el nombre de la cuenta de GitHub significa que este usuario de GitHub ya está
            asociado a otro usuario, por lo tanto no se permite registrar este nombre de usuario 
            de nuevo, por lo que se responde con un 400*/
            else return 400;
        } else if (response.status === 209) {
            /*Si el estado de respuesta es 209 es porque el usuario de GitHub no se encuentra en ninguno de 
            los registros de los datos básicos de la hoja de vida en la plataforma de Agile Innova, 
            después de tener está certeza se procede a verificar contra la API de GitHub si este nombre
            de usuario es valido y a dar las respuestas necesarias para la validación que se está realizando*/
            const responseGit = await Axios.get(`https://api.github.com/search/users?q= user:${gitHubUserAccount}`)
            if (responseGit.data.total_count > 0) return 200;
            else return 400;
        }
    } catch (error) {
        const { response } = error;
        if (response.status !== 422) return 500;
        else return 400;
    }
}

function* patchCvBasicDataByUserIdRequest({ payload }) {
    const { data, idCv, locale } = payload;
    const messageError = getMessageByString(locale, "cv.basicdata.accountgithubinvalid");

    try {
        //Se realiza la transformación de la URL de GitHub a nombre de usuario
        let gitHubUserAccount = data.github_url;
        if (gitHubUserAccount.startsWith('https://github.com/')) gitHubUserAccount = gitHubUserAccount.replace('https://github.com/', '')
        const checkAccountGitHub = yield call(checkGitHubAccountNameRequest, { gitHubUserAccount: gitHubUserAccount, numberUserDocument: data.document });
        if (checkAccountGitHub === 400) {
            yield put(pathCvBasicDataSuccess(423));
            return yield put(showMessageDialogBasicData({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
        } else if (checkAccountGitHub === 200) {
            data.github_url = gitHubUserAccount;
            const response = yield call(axiosPatchCvBasicDataByIdUser, { data, idCv });
            if (!response.status) return yield put(pathCvBasicDataSuccess(response.info.response.status));
            return yield put(pathCvBasicDataSuccess(response.info.status));
        } else if (checkAccountGitHub === 500) {
            return yield put(pathCvBasicDataSuccess(422));
        }
    } catch (err) {
        return yield put(pathCvBasicDataSuccess(422));
    }
}


export function* getCvBasicDataByIdUser() {
    yield takeEvery(GET_BY_ID_USER_CV_BASIC_DATA, getCvBasicDataByUserIdRequest);
}

export function* getAllPhysicalDisability() {
    yield takeEvery(GET_ALL_PHYSICAL_DISABILITY, getAllPhysicalDisabilityRequest);
}

export function* getAllSpecialCondition() {
    yield takeEvery(GET_ALL_SPECIAL_CONDITION, getAllSpecialConditionRequest);
}

export function* checkIdDocument() {
    yield takeEvery(CHECK_ID_DOCUMENT, checkIdDocumentRequest)
}

export function* uploadFiles() {
    yield takeEvery(UPLOAD_FILES, uploadFilesRequest)
}

export function* patchCvBasicDataByIdUser() {
    yield takeEvery(PATCH_BY_ID_USER_CV_BASIC_DATA, patchCvBasicDataByUserIdRequest);
}

export default function* rootSaga() {
    yield all([
        fork(getCvBasicDataByIdUser),
        fork(getAllPhysicalDisability),
        fork(getAllSpecialCondition),
        fork(checkIdDocument),
        fork(uploadFiles),
        fork(patchCvBasicDataByIdUser)
    ]);
}