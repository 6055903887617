import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import banner1 from "assets/images/educacionFuturoHorizontal.png";
// import banner2 from "assets/images/logoInterPrincipal-01.png";
import banner3 from 'assets/images/logoAgile.png'

const Sponsors = () => {
  // const options1 = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 4,
  //   initialSlide: 0,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   arrows: false,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //         infinite: true
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         infinite: true
  //       }
  //     }
  //   ]
  // }

  return (
    <div style={{ width: 'calc(100vw - 86px)' }}>
      <div>
        <img
          alt='Logo de Agile Innova'
          src={banner3}
          style={{
            width: '90px',
            display: 'block',
            marginRight: 'auto',
            marginLeft: 'auto'
          }}
        />
      </div>
      {/* <Slider {...options1}>
          <div>
            <img alt="" src={banner3} style={{ width: "80px" }} />
          </div>
          {/* <div>
                    <img alt="" src={banner1} style={{ width: "120px" }} />
                </div>
                <div>
                    <img alt="" src={banner2} style={{ width: "75px" }} />
                </div> */}
      {/* </Slider> */}
    </div>
  )
}

export default Sponsors
