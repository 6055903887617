import {
    REGISTER_REQUIREMENTS_MODAL_DELIVERY_ADD,
    REGISTER_REQUIREMENTS_CHANGE,
    REGISTER_REQUIREMENTS_MODAL_CLEAN,
    REGISTER_DETAILED_REQUIREMENTS_MODAL_CLEAN,
    REGISTER_REQUIREMENT_ON_SHOW_LOADER,
    REGISTER_REQUIREMENT_ON_HIDE_LOADER,
    REGISTER_REQUIREMENT_ON_SHOW_MESSAGE,
    REGISTER_REQUIREMENT_ON_HIDE_MESSAGE,
    FETCH_REQUIREMENTS_SUCCESS,
    REGISTER_REQUIREMENTS_CHANGE_ALL,
    REGISTER_REQUIREMENTS_MODAL_DELIVERY_EDIT,
    FILTER_REQUIREMENT,
    ADD_REQUISITE,
    SINGLE_REQUISITE_CHANGE
} from 'constants/ActionTypes'

const INIT_STATE = {
    modal_delivery_add: false,
    modal_delivery_edit: false,
    requirement_single: {
        detailedRequeriments: []
    },
    detailedRequeriments: [],
    detailedRequerimentSingle: {},
    allRequirements: [],
    alert_message: '',
    show_message_app: false,
    loader: false,
    type_message: '',
    filterRequirement: 'Todos los cursos'
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case REGISTER_REQUIREMENTS_MODAL_DELIVERY_ADD: {
            return {
                ...state,
                modal_delivery_add: !state.modal_delivery_add
            }
        }
        case REGISTER_REQUIREMENTS_MODAL_DELIVERY_EDIT: {
            return {
                ...state,
                modal_delivery_edit: !state.modal_delivery_edit
            }
        }
        case REGISTER_REQUIREMENTS_CHANGE: {
            return {
                ...state,
                requirement_single: {
                    ...state.requirement_single,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }
        case REGISTER_REQUIREMENTS_MODAL_CLEAN: {
            return {
                ...state,
                requirement_single: {
                    detailedRequeriments: []
                },
            }
        }
        case REGISTER_DETAILED_REQUIREMENTS_MODAL_CLEAN: {
            return {
                ...state,
                detailedRequerimentSingle: {}
            }
        }
        case REGISTER_REQUIREMENT_ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case REGISTER_REQUIREMENT_ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case REGISTER_REQUIREMENT_ON_SHOW_MESSAGE: {
            return {
                ...state,
                show_message_app: true,
                alert_message: action.payload.alertMessage,
                type_message: action.payload.typeMessage
            }
        }

        case REGISTER_REQUIREMENT_ON_HIDE_MESSAGE: {
            return {
                ...state,
                alert_message: '',
                show_message_app: false,
                loader: false
            }
        }
        case FETCH_REQUIREMENTS_SUCCESS: {
            return {
                ...state,
                allRequirements: action.payload
            }
        }
        case REGISTER_REQUIREMENTS_CHANGE_ALL: {
            return {
                ...state,
                requirement_single: action.payload
            }
        }
        case FILTER_REQUIREMENT: {
            return {
                ...state,
                filterRequirement: action.payload
            }
        }

        case ADD_REQUISITE: {
            return {
                ...state,
                detailedRequeriments: action.payload
            }
        }
        case SINGLE_REQUISITE_CHANGE: {
            state.detailedRequerimentSingle[action.payload.field] = action.payload.value
            return {
                ...state
            }
        }
        default:
            return state
    }
}