import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { showMessageDialog, hideLoaderSponsorship, fetchSponsorshipSuccess, searchCitySuccess, fetchSponsorsSuccess } from 'appRedux/actions/RegisterSponsorships'
import { fetchError } from "appRedux/actions/Common";
import { config } from "util/Authorization";
import { getMessageByString } from 'util/Messages'
import { ADD_SPONSORSHIP, FETCH_SPONSORSHIPS, TYPE_MESSAGE_SUCCESS, TYPE_MESSAGE_ERROR, REGISTER_SPONSORSHIP_SEARCH_CITY, EDIT_SPONSORSHIP, DELETE_SPONSORSHIP, GET_SPONSORS_SPONSORSHIPS } from "constants/ActionTypes"

const serviceFetchSponsorships = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}sponsorship`, config());
    return data;
}

const serviceAddSponsorship = async payload => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}sponsorship`, payload.body, config());
    return data;
}

const serviceDeleteSponsorship = async payload => {
    const data = await axios.delete(`${process.env.REACT_APP_API_URL}sponsorship/${payload.id}`, config());
    return data
}

const serviceEditSponsorship = async payload => {
    const data = await axios.patch(`${process.env.REACT_APP_API_URL}sponsorship/${payload.id}`, payload.body, config())
    return data
}

const getSponsors = async () => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}adm_partner`, config());
        return data.filter(sponsor => sponsor.institution_type === 'financiadora');
    } catch (err) {
        return err;
    }
}

const serviceSearchCity = async (payload) => {
    const request = { string: payload.string }
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}country/string`, request, config());
    return data;
}

function* fetchSponsorshipsRequest({ payload }) {
    const { locale } = payload;
    try {
        const allSponsorships = yield call(serviceFetchSponsorships)
        yield put(fetchSponsorshipSuccess(allSponsorships))
        yield put(hideLoaderSponsorship())
    } catch (e) {
        const message = getMessageByString(
            locale,
            'register.sponsorships.message.internalerror'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* deleteSponsorshipRequest({ payload }) {
    const { locale } = payload;
    try {
        const deleteSponsorshipRes = yield call(serviceDeleteSponsorship, payload)
        const allSponsorships = yield call(serviceFetchSponsorships);
        yield put(fetchSponsorshipSuccess(allSponsorships))
        yield put(hideLoaderSponsorship())
        if (deleteSponsorshipRes.status === 422) {
            const message = getMessageByString(
                locale,
                'register.sponsorships.message.internalerror.exist'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        } else {
            const message = getMessageByString(
                locale,
                'register.sponsorships.message.delete'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
        }
    } catch (e) {
        yield put(hideLoaderSponsorship())
        const message = getMessageByString(
            locale,
            'register.sponsorships.message.internalerror'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* fetchSponsorsRequest() {
    try {
        const fetchedSponsors = yield call(getSponsors);
        yield put(fetchSponsorsSuccess(fetchedSponsors));
    } catch (error) {
        yield put(fetchError(error));
    }
}

function* addSponsorshipRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceAddSponsorship, payload)
        const allSponsorships = yield call(serviceFetchSponsorships)
        yield put(fetchSponsorshipSuccess(allSponsorships))
        yield put(hideLoaderSponsorship())
        const message = getMessageByString(
            locale,
            'register.sponsorships.message.create'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        let message = getMessageByString(
            locale,
            'register.sponsorships.message.internalerror'
        )
        yield put(hideLoaderSponsorship())
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* editSponsorshipRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceEditSponsorship, payload)
        const allSponsorships = yield call(serviceFetchSponsorships);
        yield put(fetchSponsorshipSuccess(allSponsorships))
        yield put(hideLoaderSponsorship())

        const message = getMessageByString(
            locale,
            'register.sponsorships.message.update'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderSponsorship())
        const message = getMessageByString(
            locale,
            'register.sponsorships.message.internalerror'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* searchCityRequest({ payload }) {

    const { locale } = payload;
    try {
        const data = yield call(serviceSearchCity, payload);
        yield put(searchCitySuccess(data))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'register.sponsorships.message.internalerror'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

export function* fetchSponsorships() {
    yield takeEvery(FETCH_SPONSORSHIPS, fetchSponsorshipsRequest)
}

export function* fetchSponsors() {
    yield takeEvery(GET_SPONSORS_SPONSORSHIPS, fetchSponsorsRequest);
}

export function* addSponsorship() {
    yield takeEvery(ADD_SPONSORSHIP, addSponsorshipRequest)
}

export function* deleteSponsorship() {
    yield takeEvery(DELETE_SPONSORSHIP, deleteSponsorshipRequest)
}

export function* editSponsorship() {
    yield takeEvery(EDIT_SPONSORSHIP, editSponsorshipRequest)
}

export function* searchCity() {
    yield takeEvery(REGISTER_SPONSORSHIP_SEARCH_CITY, searchCityRequest)
}

export default function* rootSaga() {

    yield all([
        fork(fetchSponsorships),
        fork(fetchSponsors),
        fork(searchCity),
        fork(addSponsorship),
        fork(deleteSponsorship),
        fork(editSponsorship),
    ])
}