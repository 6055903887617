import { all, call, fork, put, takeEvery } from "redux-saga/effects";

// thid party
import axios from "axios";

// project imports
import { config } from "util/Authorization";


// action - state management
import {   GET_DATA_MESSAGES_BY_USER_FETCH } from "../../constants/ActionTypes";
import { getDataMessagesByUserSuccess } from "../actions/Messages";


const serviceGetMessagesByUser = async payload => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}noti/report_messages_sent/messages_by_user`, payload, config());
        return data;
    } catch (e) {
        return false;
    }
}


function* getDataMessagesByUserRequest({payload}) {
    try {
        const messages = yield call(serviceGetMessagesByUser, payload);

        if (messages) {
            yield put(getDataMessagesByUserSuccess({fieldName: "Messages", value: messages}));
        }
    } catch (error) {

    }
}


export function* getDataMessagesByUser(){
    yield takeEvery(GET_DATA_MESSAGES_BY_USER_FETCH,getDataMessagesByUserRequest )
}

export default function* rootSaga() {
    yield all([
       fork(getDataMessagesByUser)
    ])
}