import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import * as Yup from "yup";
import moment from "moment";
import { userSignOut } from "appRedux/actions/Auth";
import { changeGeneralUserStatus } from "appRedux/actions/User";
import { getMessageByString, getMessageValidate } from 'util/Messages';
import IntlMessages from "util/IntlMessages";
import { USER_IN_PROCESS_ID, USER_ACTIVE_ID } from "constants/MainStages";
import { REACTIVATION_REQUEST_NOTIFICATION } from 'constants/MailTemplateIds'
import FormInactive from "./Form";
import Pending from './Pending';
import logo from "assets/images/logo.png";

const Inactive = () => {

  const dispatch = useDispatch()
  const history = useHistory()

  const { authUser, user } = useSelector(({ auth }) => auth);
  const { locale } = useSelector(({ settings }) => settings);
  const user_stages_by_id_user = useSelector(({ user }) => user.user_stages_by_id_user);

  const initialValues = {
    activation_reason: ''
  }

  const validationSchema = Yup.object().shape({
    activation_reason: Yup.string().required(getMessageValidate(locale, getMessageByString(locale, ""), "valid.field.required"))
  })

  const onSubmit = (values) => {
    const data = {
      locale,
      id_user: parseInt(authUser),
      id_stage: USER_IN_PROCESS_ID,
      id_user_author: parseInt(authUser),
      observation: values.activation_reason,
      is_voluntary: true,
      data: {
        template_id: REACTIVATION_REQUEST_NOTIFICATION,
        params: {
          full_name: `${user.full_name} ${user.last_name}`,
          endpoint_academia_geek_inactive_grid: `${process.env.REACT_APP_URL}admin/members/usersStages`,
          reactivation_reason: values.activation_reason,
          projected_date: moment().add(2, 'weeks').format('DD-MMM-YY'),
          user_email: user.email,
          endpoint_academia_geek: process.env.REACT_APP_URL
        },
        emails: [process.env.REACT_APP_EMAIL_GOOGLE_ACCOUNT]
      },
      admin: true
    };

    dispatch(changeGeneralUserStatus(data));
  }


  useEffect(() => {
    if (authUser === null) {
      history.push('/')
    }
  }, [authUser, history]);

  useEffect(() => {
    if (user_stages_by_id_user[0].stage_name && user_stages_by_id_user[0].id_stage === USER_ACTIVE_ID) {
      history.push('/main/dashboard/geek')
    }
  }, [history, user_stages_by_id_user])

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div
          style={{ maxWidth: 700, height: "100%" }}
          className="gx-app-login-main-content" >
          <div className="gx-app-logo-content" style={{ padding: "20px 35px" }}>
            <div style={{
              display: "flex",
              justifyContent: "center"
            }} className="gx-app-logo gx-mb-3">
              <img alt="Logo Agile Innova" src={logo} />
            </div>
            <div className="gx-app-logo-wid gx-text-center">
              <p style={{ textAlign: "justify", color: "DimGrey", fontSize: "17px" }}>
                <IntlMessages id="app.userAuth.inactivegeneral" />
              </p>
              <Button
                type="danger"
                className="gx-mb-0"
                htmlType="submit"
                onClick={() => {
                  dispatch(userSignOut())
                }}
              >
                <IntlMessages id="appModule.logout" />
              </Button>
            </div>
          </div>
          {
            user_stages_by_id_user[0].id_stage === USER_IN_PROCESS_ID ? <Pending /> : user_stages_by_id_user[0].id_stage !== USER_ACTIVE_ID && <FormInactive validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit} locale={locale} />
          }
        </div>
      </div>
    </div>

  )
}

export default Inactive;