import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from "antd";
import URLSearchParams from "url-search-params";
import { IntlProvider } from "react-intl";
import { setInitUrl, userSignOut } from "appRedux/actions/Auth";
import { getByIdUserUserStages } from "appRedux/actions/User";
import { getUserRole } from "appRedux/actions/Authorization";
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";
import { hideMessageApp } from "appRedux/actions/App";
import { MessageAppSwitch } from "util/Messages";
import { LAYOUT_TYPE_BOXED, LAYOUT_TYPE_FRAMED, LAYOUT_TYPE_FULL, NAV_STYLE_ABOVE_HEADER, NAV_STYLE_BELOW_HEADER, NAV_STYLE_DARK_HORIZONTAL, NAV_STYLE_DEFAULT_HORIZONTAL, NAV_STYLE_INSIDE_HEADER_HORIZONTAL, THEME_TYPE_DARK } from "constants/ThemeSetting";
import { USER_ACTIVE_ID } from "constants/MainStages";
import { PROSPECT_STUDENT_ID, TECHNICAL_MENTOR_ID, PSYCHOSOCIAL_ADVISOR_ID, COMMUNICATIONS_SUPPORT_ID, ACADEMIC_COORDINATOR_ID } from "constants/Roles";
import ValidateCellPhone from "containers/User/ValidateCellPhone";
import SuccessfulRegistration from "containers/User/SuccessfulRegistration";
import ValidateEmail from "containers/User/ValidateEmail";
import Inactive from "containers/InactiveUser";
import AlCourses from 'components/Demoday/index'
import CorhortDetails from 'components/Demoday/cohortDetails'
import ProjectDetails from 'components/Demoday/projectDetails'
import ProjectDetailsV2 from 'components/Demoday/projectDetailsV2'
import AppLocale from "lngProvider";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import ForgotPassword from "../ForgotPassword";
import MainApp from "./MainApp";

const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  userStage,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (authUser && userStage.id_stage === USER_ACTIVE_ID) return (<Component {...props} />)
      else if (authUser === null || userStage.id_stage === null) return (<Redirect to={{ pathname: '/signin', state: { from: location } }} />)
      else if (authUser && userStage.id_stage !== USER_ACTIVE_ID) return (<Redirect to={{ pathname: '/user/inactive', state: { from: location } }} />)
    }
    }
  />
);

const App = (props) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const { locale, themeType, navStyle, layoutType, themeColor } = useSelector(({ settings }) => settings);
  const { authUser, initURL, user } = useSelector(({ auth }) => auth);
  const user_stages_by_id_user = useSelector(({ user }) => user.user_stages_by_id_user);
  const { id_role } = useSelector(({ authorization }) => authorization);
  const { showMessageApp, alertMessageApp, typeMessageApp, timeShowMessageApp } = useSelector(({ app }) => app);

  useEffect(() => {
    if (initURL === "") dispatch(setInitUrl(location.pathname))

    const params = new URLSearchParams(location.search);

    if (params.has("theme")) dispatch(setThemeType(params.get("theme")))
    if (params.has("nav-style")) dispatch(onNavStyleChange(params.get("nav-style")))
    if (params.has("layout-type")) dispatch(onLayoutTypeChange(params.get("layout-type")))

    setLayoutType(layoutType);
    setNavStyle(navStyle);

    if (authUser !== undefined && authUser !== null && id_role === PROSPECT_STUDENT_ID && JSON.parse(localStorage.getItem("user")).nav_style === 'NAV_STYLE_BELOW_HEADER') {
      dispatch(userSignOut())
    }
  });

  useEffect(() => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = `/css/${themeColor}.css`; //This line is changed, this comment is for explaination purpose.
    link.className = "gx-style";
    document.body.appendChild(link);
    document.body.setAttribute("style", "overflow: auto")

    if (authUser !== undefined && authUser !== null) {
      dispatch(getByIdUserUserStages({ id_user: authUser }));
    }

    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
      document.body.classList.add("dark-theme");
      let link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = "/css/dark_theme.css";
      link.className = "style_dark_theme";
      document.body.appendChild(link);
    }
  }, [authUser, dispatch, themeColor, themeType]);

  useEffect(() => {
    if (authUser !== undefined && authUser !== null && !user.admin) {
      dispatch(getUserRole({ id_user: authUser }));
    }
  }, [authUser, dispatch, user.admin]);

  useEffect(() => {
    if (showMessageApp) {
      MessageAppSwitch({ alertMessageApp, typeMessageApp, timeShowMessageApp });
      dispatch(hideMessageApp());
    }
  }, [alertMessageApp, dispatch, showMessageApp, timeShowMessageApp, typeMessageApp]);

  useEffect(() => {
    if (location.pathname === "/") {
      if (authUser === null) {
        history.push("/signin");
      } else if (
        initURL === "" || initURL === "/" || initURL === "/signin" || (initURL === "/signup" && id_role)
      ) {
        if (user.admin) {
          history.push("/main/dashboard/admin");
        }
        switch (id_role) {
          case PROSPECT_STUDENT_ID:
            history.push("/main/dashboard/geek");
            break;
          case TECHNICAL_MENTOR_ID:
            history.push("/admin/mentor/students");
            break;
          case PSYCHOSOCIAL_ADVISOR_ID:
            history.push("/admin/members/applicants");
            break;
          case COMMUNICATIONS_SUPPORT_ID:
            history.push("/admin/members/registered");
            break;
          case ACADEMIC_COORDINATOR_ID:
            history.push("/admin/members/psychosocialPreApproved");
            break
          default:
            break
        }
      } else {
        history.push(initURL);
      }
    }
  }, [authUser, initURL, location, history, id_role, user.admin]);

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  const setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme')
      document.body.classList.add('dark-theme')
      let link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.href = '/css/dark_theme.css'
      link.className = 'style_dark_theme'
      document.body.appendChild(link)
    }
  }, [themeType])


  const currentAppLocale = AppLocale[locale.locale]
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <Route exact path='/signin' component={SignIn} />
          <Route exact path='/signup' component={SignUp} />
          <Route exact path='/enterprise' component={AlCourses} />
          <Route exact path='/enterprise/cohort_details' component={CorhortDetails} />
          <Route exact path='/enterprise/project_details' component={ProjectDetails} />
          <Route exact path='/enterprise/project_details_V2' component={ProjectDetailsV2} />
          <Route exact path='/forgotpassword' component={ForgotPassword} />
          <Route exact path='/user/validate-cellphone/:token' component={ValidateCellPhone} />
          <Route exact path='/user/validate-email/:token' component={ValidateEmail} />
          <Route exact path='/user/successful-registration/:jwt' component={SuccessfulRegistration} />
          <Route exact path='/user/inactive' component={Inactive} />
          <RestrictedRoute path={`${match.url}`} authUser={authUser} userStage={user_stages_by_id_user[0]} location={location} component={MainApp} />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default memo(App)