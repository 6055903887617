import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Axios from 'axios'
import { getByIdUserCvSuccess, showMessageDialog, hideLoaderPreScholarshipEvaluation } from 'appRedux/actions/PreScholarshipEvaluation'
import { sendEmail } from 'appRedux/actions/Notifications'
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";

import {
    GET_BY_ID_USER_CV,
    EDIT_PRE_SCHOLARSHIP_EVALUATION,
    TYPE_MESSAGE_ERROR,
    TYPE_MESSAGE_SUCCESS,
    EDIT_PRE_SCHOLARSHIP_EVALUATION_INTERN_REVIEW
} from 'constants/ActionTypes';

const serviceGetCvIdUser = async idUser => {
    const { data } = await Axios.get(`${process.env.REACT_APP_API_URL}user_cv/${idUser}`, config())
    return data
}

const serviceEditPreScholarshipEvaluation = async payload => {
    let { id_scholarship_holder, id_scholarship_course, id_scholarship_course_backup } = payload.body

    if (id_scholarship_course !== id_scholarship_course_backup) {
        await Axios.patch(`${process.env.REACT_APP_API_URL}adm_scholarship_holders/${id_scholarship_holder}`, payload.body, config())
    }
    const data = await Axios.post(`${process.env.REACT_APP_API_URL}adm_tracking_log_scholarship`, payload.body, config())
    return data
}

const serviceEditPreScholarshipEvaluationInternReview = async payload => await Axios.patch(`${process.env.REACT_APP_API_URL}adm_scholarship_holders/${payload.body.id_scholarship_holder}`, payload.body, config());

function* getCvByUserIdRequest({ payload }) {
    const { locale, id } = payload;
    try {
        const data = yield call(serviceGetCvIdUser, id);
        return yield put(getByIdUserCvSuccess(data))
    } catch (err) {
        yield put(hideLoaderPreScholarshipEvaluation())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}
function* editPreScholarshipEvaluationRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceEditPreScholarshipEvaluation, payload)
        yield put(sendEmail(payload));
        yield put(hideLoaderPreScholarshipEvaluation())
        yield
        const message = getMessageByString(
            locale,
            'register.scholarships.message.update'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderPreScholarshipEvaluation())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* editPreScholarshipEvaluationInternReviewRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceEditPreScholarshipEvaluationInternReview, payload);
        yield put(hideLoaderPreScholarshipEvaluation())
        const message = getMessageByString(
            locale,
            'register.scholarships.message.update'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))

    } catch (e) {

        yield put(hideLoaderPreScholarshipEvaluation())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;

    }
}

export function* getCvByIdUser() {
    yield takeEvery(GET_BY_ID_USER_CV, getCvByUserIdRequest);
}
export function* editPreScholarshipEvaluation() {
    yield takeEvery(EDIT_PRE_SCHOLARSHIP_EVALUATION, editPreScholarshipEvaluationRequest)
}

export function* editPreScholarshipEvaluationInternReview() {
    yield takeEvery(EDIT_PRE_SCHOLARSHIP_EVALUATION_INTERN_REVIEW, editPreScholarshipEvaluationInternReviewRequest)
}

export default function* rootSaga() {
    yield all([
        fork(getCvByIdUser),
        fork(editPreScholarshipEvaluation),
        fork(editPreScholarshipEvaluationInternReview)
    ]);
}