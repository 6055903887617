import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Card, Col, Row, Tooltip, Modal } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import { fetchInfoTokenEmail, fetchValidateEmail } from 'appRedux/actions/User'
import { showLoaderApp } from 'appRedux/actions/App'
import IntlMessages from 'util/IntlMessages'
import { getMessageByString } from 'util/Messages'
import CircularProgress from 'components/CircularProgress/index'

const ValidateEmail = () => {

  let { token } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const confirm = Modal.confirm

  const { locale } = useSelector(({ settings }) => settings)
  const { authUser } = useSelector(({ auth }) => auth)
  const { showMessageApp, loaderApp } = useSelector(({ app }) => app)
  const { info_token_email } = useSelector(({ user }) => user)

  const [disablebtnValidateEmail, setDisablebtnValidateEmail] = useState(false)

  let validacionEmailMsg = ''

  useEffect(() => {
    if (showMessageApp) {
      setTimeout(() => {
        setDisablebtnValidateEmail(false)
      }, 200)
    }
  }, [showMessageApp])

  useEffect(() => {
    if (info_token_email != null && info_token_email.response_validate_email) {
      history.push('/signin')
    }
  }, [history, info_token_email])

  useEffect(() => {
    if (authUser !== null) {
      history.push('/signin')
    }
  }, [authUser, history])

  useEffect(() => {
    dispatch(showLoaderApp())
    dispatch(fetchInfoTokenEmail(token))
  }, [dispatch, token])

  if (info_token_email) {
    validacionEmailMsg = (
      <ul style={{ textAlign: 'left' }}>
        <li>
          <b>
            <IntlMessages id='cv.basicdata.full_name' />:{' '}
          </b>
          {info_token_email.full_name}
        </li>
        <li>
          <b>
            <IntlMessages id='cv.basicdata.last_name' />:{' '}
          </b>
          {info_token_email.last_name}
        </li>
        <li>
          <b>
            <IntlMessages id='cv.basicdata.cellphone' />:{' '}
          </b>
          {info_token_email.mobile_phone}
        </li>
        <li>
          <b>
            <IntlMessages id='cv.basicdata.email' />:{' '}
          </b>
          {info_token_email.email}
        </li>
      </ul>
    )
  }

  const confirmValidationEmail = () => {
    confirm({
      title: getMessageByString(
        locale,
        'app.user.register.validate.email.confirmation.title'
      ),
      content: getMessageByString(
        locale,
        'app.user.register.validate.email.confirmation.message'
      ),
      okText: getMessageByString(locale, 'button.yes'),
      cancelText: getMessageByString(locale, 'button.no'),
      okType: 'danger',
      onOk() {
        setDisablebtnValidateEmail(true)
        dispatch(showLoaderApp())
        dispatch(
          fetchValidateEmail({
            token,
            mobile_phone: info_token_email.mobile_phone,
            email: info_token_email.email
          })
        )
      },
      onCancel() { }
    })
  }

  return (
    <div className='gx-app-login-wrap'>
      <div className='gx-app-login-container'>
        <Card className={`gx-card-widget gx-card-full gx-text-center`}>
          <div className='gx-pt-4 gx-px-3'>
            <div className='gx-separator gx-bg-success-dark' />
            <h2 className='gx-mb-4 gx-text-success-dark'>
              <IntlMessages id='app.user.register.validate.email' />
            </h2>
            <Card
              className='gx-card-full gx-p-2 gx-text-center gx-text-primary'
              style={{ border: 'none', marginBottom: '2px' }}
            >
              <i className={`icon icon-email gx-fs-iconcard`} />
            </Card>
          </div>
          {info_token_email != null && !info_token_email.response && (
            <Row>
              <Col lg={4} md={3}></Col>
              <Col lg={16} md={18} xs={24}>
                <Alert
                  message='Error'
                  description={
                    <IntlMessages id='app.user.register.validate.email.msgerrorvalidate' />
                  }
                  type='error'
                  showIcon
                />
              </Col>
              <Col lg={4} md={3}></Col>
            </Row>
          )}
          {info_token_email != null && info_token_email.response && (
            <Row>
              <Col lg={4} md={3}></Col>
              <Col lg={16} md={18} xs={18}>
                <Alert
                  message='Información del usuario'
                  description={validacionEmailMsg}
                  type='success'
                  showIcon
                />
              </Col>
              <Col lg={4} md={3}></Col>
            </Row>
          )}
          <ButtonGroup
            size='large'
            className='gx-fs-sm gx-text-uppercase'
            style={{ width: '100%' }}
          >
            {info_token_email != null && info_token_email.response && (
              <Tooltip
                placement='top'
                title={
                  <IntlMessages id='app.user.register.validate.email.msgbtnalertvalidate' />
                }
                arrowPointAtCenter
              >
                <Button
                  type='primary'
                  style={{ marginBottom: '0px' }}
                  className='gx-fs-sm'
                  size='large'
                  htmlType='submit'
                  onClick={confirmValidationEmail}
                  disabled={disablebtnValidateEmail}
                  block
                >
                  <IntlMessages id='app.user.register.validate.email.btn1' />
                </Button>
              </Tooltip>
            )}
            <Button
              type='secundary'
              style={{ marginBottom: '0px' }}
              className='gx-fs-sm'
              size='large'
              htmlType='submit'
              block
            >
              <Link to='/signin'>
                <IntlMessages id='app.userAuth.login' />
              </Link>
            </Button>
          </ButtonGroup>
        </Card>
        {loaderApp && (
          <div className='gx-loader-view'>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  )
}

export default ValidateEmail