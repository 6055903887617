import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { setBaseKeyCurriculumVitae } from "appRedux/actions/CurriculumVitae";
import { pingTokenTest } from "appRedux/actions/Auth";
import asyncComponent from "util/asyncComponent";
import { PSYCHOSOCIAL_ADVISOR_ID } from "../../constants/Roles";

const CurriculumVitae = ({ match }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { user } = useSelector(({ auth }) => auth);
  const urlAdmin = "/admin/members/prospect/curriculum-vitae";
  const { id_role } = useSelector(({ authorization }) => authorization);

  /*
    Cada vez que se cambia la ruta se acciona una saga que hace una peticion al backend para verificar el token, 
    Si este token es válido todo sigue igual, si no se cierra sesion. 
  */
  useEffect(() => {
    dispatch(pingTokenTest());
  }, [dispatch, location]);

  //Establece la ruta base teniendo en cuenta si el usuario en sesión es administrador o no
  useEffect(() => {
    if (user.admin === 1 || id_role === PSYCHOSOCIAL_ADVISOR_ID)
      dispatch(
        setBaseKeyCurriculumVitae(`/admin/members/prospect/curriculum-vitae`)
      );
    else dispatch(setBaseKeyCurriculumVitae(`/curriculum-vitae`));
  }, [dispatch, user, id_role]);

  return (
    <Switch>
      <Redirect
        exact
        from={
          match.url === urlAdmin
            ? `${match.url}/:id_user/:id_cv_user`
            : `${match.url}`
        }
        to={
          match.url === urlAdmin
            ? `${match.url}/:id_user/:id_cv_user/basic-data`
            : `${match.url}/basic-data`
        }
      />
      <Route
        path={
          match.url === urlAdmin
            ? `${match.url}/:id_user/:id_cv_user/basic-data`
            : `${match.url}/basic-data`
        }
        component={asyncComponent(() => import("./BasicData"))}
      />
      <Route
        path={
          match.url === urlAdmin
            ? `${match.url}/:id_user/:id_cv_user/languages-to-develop`
            : `${match.url}/languages-to-develop`
        }
        component={asyncComponent(() => import("./LanguagesToDevelop"))}
      />
      <Route
        path={
          match.url === urlAdmin
            ? `${match.url}/:id_user/:id_cv_user/academic-info/high-school`
            : `${match.url}/academic-info/high-school`
        }
        component={asyncComponent(() => import("./AcademicInfo/HighSchool"))}
      />
      <Route
        path={
          match.url === urlAdmin
            ? `${match.url}/:id_user/:id_cv_user/academic-info/college-education`
            : `${match.url}/academic-info/college-education`
        }
        component={asyncComponent(() =>
          import("./AcademicInfo/CollegeEducation")
        )}
      />
      <Route
        path={
          match.url === urlAdmin
            ? `${match.url}/:id_user/:id_cv_user/academic-info/complementary-courses`
            : `${match.url}/academic-info/complementary-courses`
        }
        component={asyncComponent(() =>
          import("./AcademicInfo/ComplementaryCourses")
        )}
      />
      <Route
        path={
          match.url === urlAdmin
            ? `${match.url}/:id_user/:id_cv_user/languages`
            : `${match.url}/languages`
        }
        component={asyncComponent(() => import("./Languages"))}
      />
      <Route
        path={
          match.url === urlAdmin
            ? `${match.url}/:id_user/:id_cv_user/work-experience`
            : `${match.url}/work-experience`
        }
        component={asyncComponent(() => import("./WorkExperience"))}
      />
      <Route
        path={
          match.url === urlAdmin
            ? `${match.url}/:id_user/:id_cv_user/supplementary-info`
            : `${match.url}/supplementary-info`
        }
        component={asyncComponent(() => import("./SupplementaryInfo"))}
      />
      {
        <Route
          path={
            match.url === urlAdmin
              ? `${match.url}/:id_user/:id_cv_user/printPDF`
              : `${match.url}/printPDF`
          }
          component={asyncComponent(() =>
            import("components/curriculumVitae/PrintCv")
          )}
        />
      }
    </Switch>
  );
};

export default CurriculumVitae;
