import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Card,
  Col,
  Mentions,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { fetchCohort } from "appRedux/actions/RegisterCohorts";
import CohortMocksDM from "./Mocks/MocksADD";
import Basic from "./components/Pagination/Basic";

const AlCourses = () => {
  const dispatch = useDispatch();

  const { locale } = useSelector(({ settings }) => settings);
  const { allCohorts } = useSelector(({ registerCohorts }) => registerCohorts);
  const [selectedYear, setSelectedYear] = useState();
  const [cohort, setCohort] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [cohortActive, setCohortActive] = useState([]);

  const { Option } = Mentions;
  const { Text } = Typography;

  useEffect(() => {
    dispatch(fetchCohort({ locale }));
  }, [dispatch, locale]);

  useEffect(() => {
    setCohortActive(allCohorts);
  }, [allCohorts]);

  const data = (id, projectIDS, repoNames) => {
    sessionStorage.setItem("idCohorte", id);
    sessionStorage.setItem("projectsIDS", JSON.stringify(projectIDS));
    sessionStorage.setItem("repoNames", JSON.stringify(repoNames));
  };

  const searchInput = ({ target }) => {
    setSearch(target.value);
  };

  useEffect(() => {
    const RESULTS = CohortMocksDM.filter((keys) =>
      keys.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
    setCohort(RESULTS);
    setLoading(false);
  }, [search]);

  let colores = [
    "gx-bg-amber-light",
    "gx-bg-primary-light",
    "gx-bg-green-light",
    "gx-bg-cyan",
    "gx-bg-teal",
    "gx-bg-yellow",
    "gx-bg-orange",
    "gx-bg-blue",
    "gx-bg-light-blue",
    "gx-bg-red",
    "gx-bg-info",
    "gx-bg-danger",
    "gx-bg-pink",
    "gx-bg-green",
    "gx-bg-amber",
    "gx-bg-success",
  ];

  if (!cohortActive) {
    return (
      <div style={{ margin: 40 }}>
        <Spin spinning={loading} style={{ textAlign: "center" }}>
          <Alert
            message="Cargando"
            description="Por favor espere mientras se cargan las cohortes"
            type="info"
          />
        </Spin>
      </div>
    );
  }

  const handleFilterYear = (value) => {
    setSelectedYear(value);
    const dataFilter = CohortMocksDM?.filter((fi) => value === fi.year);
    setCohort(dataFilter);
  };

  return (
    <div style={{ padding: 40 }}>
      <Card
        title={
          <h3
            className="gx-text-grey"
            style={{
              marginLeft: 20,
              marginBottom: 0,
              fontSize: 20,
              fontWeight: 400,
            }}
          >
            {" "}
            Cohortes{" "}
          </h3>
        }
      >
        <div
          style={{ width: "100%", marginBottom: "20px", display: "flex" }}
          className="gx-search-bar gx-lt-icon-search-bar-lg gx-module-search-bar gx-d-none gx-d-sm-flex"
        >
          <div className="gx-form-group" style={{ width: "75%" }}>
            <input
              className="ant-input gx-border-0"
              type="search"
              placeholder="Buscar Cohorte"
              onChange={searchInput}
            />
            <span className="gx-search-icon gx-pointer">
              <i className="icon icon-search" />
            </span>
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <Text>Filtrar por año</Text>
            <Select
              defaultValue="2022"
              style={{ width: 120 }}
              onChange={handleFilterYear}
            >
              <Option value="2022">2022</Option>
              <Option value="2023">2023</Option>
              <Option value="2024">2024</Option>
            </Select>
          </div>
        </div>
        <Row className="gx-price-tables gx-pt-circle" style={{ justifyContent: 'center' }}>
          {cohort.map((school, index) => {
            const { id, cohorte, name, dm_teams_project_ids, dm_teams_repo_names } = school;
            const cohortDescription = cohortActive?.find(
              (item) => item.cohort_name === school.cohorte
            );
            return (
              <Col xl={8} lg={12} md={20} xs={24} key={id}>
                <div className="gx-package ">
                  <div
                    className={`gx-package-header ${colores[index]} gx-text-black`}
                    style={{ padding: 20 }}
                  >
                    <div style={{ borderRadius: "50%" }}>
                      <h2 style={{ fontFamily: "NoirPro" }}>
                        {cohorte.slice(0, -5)}
                      </h2>
                    </div>
                  </div>
                  <div className="gx-p-5" style={{ height: "auto" }}>
                    <h4
                      className="gx-p-md-1 gx-m-0"
                      style={{ textAlign: "center" }}
                    >
                      {cohortDescription ? cohortDescription.description : name}
                    </h4>
                    <div className="gx-package-footer">
                      <Button
                        type="primary"
                        className="gx-btn-block"
                        href="/enterprise/cohort_details"
                        onClick={() => data(id, dm_teams_project_ids, dm_teams_repo_names)}
                      >
                        Ver Proyectos
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
        {cohort.length === 0 ? (
          <p>No se encontraron resultados</p>
        ) : (
          <div style={{ margin: "10px auto 0 auto", width: "max-content" }}>
            <Basic />
          </div>
        )}
      </Card>
    </div>
  );
};
export default AlCourses;
