import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { AuditOutlined, CodeOutlined, ScheduleOutlined, SolutionOutlined, TeamOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { userSignOut } from "appRedux/actions/Auth";
import IntlMessages from 'util/IntlMessages'
import { NAV_STYLE_NO_HEADER_MINI_SIDEBAR } from 'constants/ThemeSetting'

const SidebarAdmin = () => {

    const SubMenu = Menu.SubMenu
    const MenuItemGroup = Menu.ItemGroup

    const dispatch = useDispatch();

    let { navStyle } = useSelector(({ settings }) => settings)

    const getNavStyleSubMenuClass = navStyle => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
            return 'gx-no-header-submenu-popup'
        }
        return ''
    }

    return (
      <MenuItemGroup
        key="/admin/main"
        className="gx-menu-group"
        title={<IntlMessages id="sidebar.admin" />}
      >
        <SubMenu
          key="community"
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          title={
            <span>
              <i className="icon icon-user" />
              <span>
                <IntlMessages id="sidebar.admin.community" />
              </span>
            </span>
          }
        >
          <Menu.Item key="/admin/members/registered">
            <Link to="/admin/members/registered">
              <i
                className="icon icon-profile2"
                style={{ fontSize: "18px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.admin.members.registered" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/members/prospects">
            <Link to="/admin/members/prospects">
              <i
                className="icon icon-files"
                style={{ fontSize: "18px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.prospects" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/members/usersStages">
            <Link to="/admin/members/usersStages">
              <span>
                <UserSwitchOutlined
                  style={{ fontSize: "15px", marginRight: "10px" }}
                />
                <IntlMessages id="sidebar.admin.members.users.stages" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/members/reportFilters">
            <Link to="/admin/members/reportFilters">
              <i
                className="icon icon-table"
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.reportFilters" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/members/graduates">
            <Link to="/admin/members/graduates">
              <i
                className="icon icon-graduation"
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.scholarshipHolders" />
              </span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="scholarships"
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          title={
            <span>
              <SolutionOutlined
                style={{
                  fontSize: "20px",
                  marginRight: "5px",
                  marginTop: "10px",
                }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.scholarship" />
              </span>
            </span>
          }
        >
          <Menu.Item key="/admin/members/applicants">
            <Link to="/admin/members/applicants">
              <i className="icon icon-select" />
              <span>
                <IntlMessages id="sidebar.topics.members.applicants" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/members/preScholarshipHolders">
            <Link to="/admin/members/preScholarshipHolders">
              <i
                className="icon icon-feedback"
                style={{ fontSize: "18px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.pre_scholarshipHolders" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/members/psychosocialPreApproved">
            <Link to="/admin/members/psychosocialPreApproved">
              <i
                className="icon icon-tasks"
                style={{ fontSize: "20px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.psychosocial.pre.approved" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/members/scholarshipHolders">
            <Link to="/admin/members/scholarshipHolders">
              <i
                className="icon icon-check"
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.graduates" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/members/student-cohorts">
            <Link to="/admin/members/student-cohorts">
              <i
                className="icon icon-star-o"
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.scholarship" />
              </span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sponsorship"
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          title={
            <span>
              <ScheduleOutlined
                style={{
                  fontSize: "20px",
                  marginRight: "5px",
                  marginTop: "10px",
                }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.sponsorship" />
              </span>
            </span>
          }
        >
          <Menu.Item key="/admin/members/sponsorship/requesters">
            <Link to="/admin/members/sponsorship/requesters">
              <i
                className="icon icon-listing-dbrd"
                style={{ fontSize: "18px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.sponsorship.requesters" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/members/sponsorship/preEndorsed">
            <Link to="/admin/members/sponsorship/preEndorsed">
              <i
                className="icon icon-list-select-o"
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.pre.endorsed" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/members/sponsorship/preSponsorship">
            <Link to="/admin/members/sponsorship/preSponsorship">
              <i
                className="icon icon-ckeditor"
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.pre.sponsorship" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/members/sponsorship/preSponsorshipApproved">
            <Link to="/admin/members/sponsorship/preSponsorshipApproved">
              <i
                className="icon icon-star-half"
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.pre.sponsorship.approved" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/members/sponsorship/sponsorship">
            <Link to="/admin/members/sponsorship/sponsorship">
              <i
                className="icon icon-star"
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
              <span>
                <IntlMessages id="sidebar.topics.members.sponsorship" />
              </span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="custom-views-2"
          title={
            <span>
              <i className="icon icon-diamond" />
              <span>
                <IntlMessages id="sidebar.topics.sponsorships" />
              </span>
            </span>
          }
        >
          <Menu.Item key="/admin/scholarships/register_sponsors">
            <Link to="/admin/scholarships/register_sponsors">
              <span>
                <span>
                  <i
                    className="icon icon-company"
                    style={{ fontSize: "20px", marginRight: "10px" }}
                  />
                </span>
                <IntlMessages id="sidebar.topics.sponsorships.sponsors" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/scholarships/register_scholarships">
            <Link to="/admin/scholarships/register_scholarships">
              <span>
                <AuditOutlined
                  style={{ fontSize: "18px", marginRight: "5px" }}
                />
                <span>
                  <IntlMessages id="sidebar.topics.sponsorships.scholarships" />
                </span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/scholarships/register_sponsorships">
            <Link to="/admin/scholarships/register_sponsorships">
              <span>
                <i
                  className="icon icon-revenue-new"
                  style={{ fontSize: "20px", marginRight: "10px" }}
                />
                <IntlMessages id="sidebar.topics.sponsorships.sponsorships" />
              </span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="git-hub-classroom"
          title={
            <span>
              <i className="icon icon-graduation" />
              <span>
                <IntlMessages id="sidebar.topics.github_classroom" />
              </span>
            </span>
          }
        >
          <Menu.Item key="/admin/gitHubClassroom/participants">
            <Link to="/admin/gitHubClassroom/participants">
              <span>
                <i className="icon icon-family" />
                <IntlMessages id="sidebar.topics.github_classroom.participants" />
              </span>
            </Link>
          </Menu.Item>

          <Menu.Item key="/admin/gitHubClassroom/challenge_deliveries">
            <Link to="/admin/gitHubClassroom/challenge_deliveries">
              <span>
                <i className="icon icon-ckeditor" />
                <IntlMessages id="sidebar.topics.github_classroom.challenge_deliveries" />
              </span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="courses"
          title={
            <span>
              <i className="icon icon-cards-list-view" />
              <span>
                <IntlMessages id="sidebar.topics.courses" />
              </span>
            </span>
          }
        >
          <Menu.Item key="/admin/courses/courses">
            <Link to="/admin/courses/courses">
              <span>
                <i className="icon icon-product-list" />
                <IntlMessages id="sidebar.topics.courses.courses" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/courses/courses/prices">
            <Link to="/admin/courses/courses/prices">
              <span>
                <i className="icon icon-pricing-table" />
                <IntlMessages id="sidebar.topics.courses.prices" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/courses/requirements">
            <Link to="/admin/courses/requirements">
              <span>
                <i className="icon icon-tree" />
                <IntlMessages id="sidebar.topics.courses.requirements" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/courses/cohorts">
            <Link to="/admin/courses/cohorts">
              <span>
                <i className="icon icon-hotel-booking" />
                <IntlMessages id="sidebar.topics.courses.cohorts" />
              </span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="mentor"
          title={
            <span>
              <CodeOutlined
                style={{
                  fontSize: "20px",
                  marginRight: "5px",
                  marginTop: "10px",
                }}
              />
              <span>
                <IntlMessages id="sidebar.topics.mentor" />
              </span>
            </span>
          }
        >
          <Menu.Item key="/admin/mentor/students">
            <Link to="/admin/mentor/students">
              <span>
                <TeamOutlined />
                <IntlMessages id="sidebar.topics.mentor.students" />
              </span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          key="communications"
          title={
            <span>
              <i className="icon icon-alert" />
              <span>
                <IntlMessages id="sidebar.topics.communications" />
              </span>
            </span>
          }
        >
          <Menu.Item key="/admin/communications/dashboard">
            <Link to="/admin/communications/dashboard">
              <span>
                <i className="icon icon-data-display" />
                <IntlMessages id="sidebar.topics.communications.dashboard" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/communications/conversations">
            <Link to="/admin/communications/conversations">
              <span>
                <i className="icon icon-map-event-listener" />
                <IntlMessages id="sidebar.topics.communications.conversations" />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin/communications/templates">
            <Link to="/admin/communications/templates">
              <span>
                <i className="icon icon-feedback" />
                <IntlMessages id="sidebar.topics.communications.templates" />
              </span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/signout/admin" onClick={() => dispatch(userSignOut())}>
          <i className="icon icon-signin" />
          <span>
            <IntlMessages id="appModule.logout" />
          </span>
        </Menu.Item>
      </MenuItemGroup>
    );
};

export default SidebarAdmin;