import { Button, Input } from "antd";
import { Formik } from "formik";
import { Form } from "formik-antd";
import IntlMessages from "util/IntlMessages";
import { getMessageByString } from 'util/Messages'

const FormInactive = ({ validationSchema, initialValues, onSubmit, locale }) => {
    return (
        <div className="gx-app-login-content">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {
                    ({ setFieldValue }) => (
                        <Form name="basic" className="gx-signin-form gx-form-row0">
                            <span ><IntlMessages id='app.userAuth.inactivegeneral.title1' /> </span>
                            <Form.Item name="activation_reason" className="gx-mt-3">
                                <Input.TextArea
                                    suffix={<span />}
                                    title="Petición"
                                    type="request"
                                    name="activation_reason"
                                    placeholder={getMessageByString(locale, "app.userAuth.inactivegeneral.placeholder")}
                                    onChange={(e) => {
                                        setFieldValue('activation_reason', e.target.value)
                                    }}
                                    rows={4}
                                />
                            </Form.Item>
                            <div className="ant-col gx-text-center">
                                <Button
                                    type="primary"
                                    className="gx-mb-0"
                                    htmlType="submit"
                                >
                                    <IntlMessages id='app.userAuth.inactivegeneral.title.button' />
                                </Button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    )
}

export default FormInactive;