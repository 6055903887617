import {
    REGISTER_COURSES_MODAL_DELIVERY_ADD,
    REGISTER_COURSES_MODAL_DELIVERY_EDIT,
    REGISTER_COURSES_CHANGE,
    REGISTER_RESOURCE_MODAL_CLEAN,
    REGISTER_COURSES_MODAL_CLEAN,
    ADD_COURSE,
    ADD_RESOURCE,
    REGISTER_COURSE_ON_SHOW_LOADER,
    REGISTER_COURSE_ON_HIDE_LOADER,
    REGISTER_COURSE_ON_SHOW_MESSAGE,
    REGISTER_COURSE_ON_HIDE_MESSAGE,
    FETCH_COURSES,
    FETCH_COURSES_SUCCESS,
    DELETE_COURSE,
    EDIT_COURSE,
    REGISTER_COURSES_CHANGE_ALL,
    SINGLE_RESOURCE_CHANGE
} from 'constants/ActionTypes'

export const openOrCloseModalAddRegisterCourse = () => {
    return {
        type: REGISTER_COURSES_MODAL_DELIVERY_ADD
    }
}

export const openOrCloseModalEditRegisterCourse = () => {
    return {
        type: REGISTER_COURSES_MODAL_DELIVERY_EDIT
    }
}

export const cleanModal = () => {
    return {
        type: REGISTER_COURSES_MODAL_CLEAN
    }
}

export const cleanModalResources = () => {
    return {
        type: REGISTER_RESOURCE_MODAL_CLEAN
    }
}

export const showLoaderCourse = () => {
    return {
        type: REGISTER_COURSE_ON_SHOW_LOADER
    }
}

export const hideLoaderCourse = () => {
    return {
        type: REGISTER_COURSE_ON_HIDE_LOADER
    }
}

export const showMessageDialog = configMessage => {
    return {
        type: REGISTER_COURSE_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}

export const hideMessageDialog = () => {
    return {
        type: REGISTER_COURSE_ON_HIDE_MESSAGE
    }
}

export const fetchCourse = (payload) => {
    return {
        type: FETCH_COURSES,
        payload: payload
    }
}

export const fetchCourseSuccess = (payload) => {
    return {
        type: FETCH_COURSES_SUCCESS,
        payload: payload
    }
}

export const deleteCourse = (payload) => {
    return {
        type: DELETE_COURSE,
        payload: payload
    }
}

export const editCourse = (payload) => {
    return {
        type: EDIT_COURSE,
        payload: payload
    }
}

export const changeCourseAll = (payload) => {
    return {
        type: REGISTER_COURSES_CHANGE_ALL,
        payload: payload
    }
}

export const changeCourse = payload => {
    return {
        type: REGISTER_COURSES_CHANGE,
        payload: payload
    }
}

export const addCourse = (payload) => {
    return {
        type: ADD_COURSE,
        payload: payload
    }
}

export const addResource = (payload) => {
    return {
        type: ADD_RESOURCE,
        payload: payload
    }
}

export const changeSingleResource = payload => {
    return {
        type: SINGLE_RESOURCE_CHANGE,
        payload: payload
    }
}