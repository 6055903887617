import {
    SET_BASE_KEY_CURRICULUM_VITAE
} from 'constants/ActionTypes'

const INIT_STATE = {
    baseKey: '',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case (SET_BASE_KEY_CURRICULUM_VITAE): {
            return {
                ...state,
                baseKey: action.payload
            }
        }
        default: return state
    }
}