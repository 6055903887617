import {
  CHALLENGE_COURSE_MODAL_DELIVERY_ADD,
  CHALLENGE_COURSE_MODAL_DELIVERY_EDIT,
  CHALLENGE_COURSE_LIST_CHALLENGES,
  CHALLENGE_COURSE_FETCH_LIST_CHALLENGES,
  CHALLENGE_COURSE_CREATE_CHALLENGE,
  CHALLENGE_COURSE_SET_ADM_PROSPECT_PREFERENCE,
  CHALLENGE_COURSE_FETCH_GET_PROSPECT_PREFERENCE,
  CHALLENGE_COURSE_UPDATE_REQUERIMENT_FULLFIMENT,
  CHALLENGE_COURSE_ON_SHOW_LOADER,
  CHALLENGE_COURSE_ON_SHOW_MESSAGE,
  CHALLENGE_COURSE_ON_HIDE_MESSAGE,
  CHALLENGE_COURSE_ON_HIDE_LOADER,
  CHALLENGE_COURSE_LOAD_LIST_FILTER,
  CHALLENGE_COURSE_RELOAD_CHALLENGES_LIST,
  CHALLENGE_COURSE_SHOW_LOAD,
  CHALLENGE_COURSE_HIDE_LOAD,
  CHALLENGE_COURSE_RESET_REQUERIMENT_FULLFIMENT,
  CHALLENGE_COURSE_DELETE,
  CHALLENGE_COURSE_SUCCESSFUL_DELETE,
  CHALLENGE_COURSE_REMOVE_CHALLENGE_FROM_LIST,
  CHALLENGE_COURSE_LOAD_DATA_FROM_REQUERIMENT_FULLFIMENT,
  CHALLENGE_COURSE_UPDATE_CHALLENGE,
  CHALLENGE_COURSE_RESET_FORM,
  CHALLENGE_COURSE_NOT_RESET_FORM
} from 'constants/ActionTypes'

export const notResetFormChallenge = () => {
  return {
    type: CHALLENGE_COURSE_NOT_RESET_FORM
  }
}
export const resetFormChallenge = () => {
  return {
    type: CHALLENGE_COURSE_RESET_FORM,
  }
}

export const successfulDelete = () => {
  return {
    type: CHALLENGE_COURSE_SUCCESSFUL_DELETE
  }
}

export const removeChallengeFromList = challenge => {
  return {
    type: CHALLENGE_COURSE_REMOVE_CHALLENGE_FROM_LIST
  }
}

export const deleteChallenge = challenge => {
  return {
    type: CHALLENGE_COURSE_DELETE,
    payload: challenge
  }
}
export const resetRequirementFullfilment = () => {
  return {
    type: CHALLENGE_COURSE_RESET_REQUERIMENT_FULLFIMENT
  }
}

export const hideLoadChallengeCourse = () => {
  return {
    type: CHALLENGE_COURSE_HIDE_LOAD
  }
}
export const showLoadChallengeCourse = () => {
  return {
    type: CHALLENGE_COURSE_SHOW_LOAD
  }
}
export const fetchLoadListChallenges = (filter) => {
  return {
    type: CHALLENGE_COURSE_LOAD_LIST_FILTER,
    payload: filter
  }
}

export const reloadChallengesList = list => {
  return {
    type: CHALLENGE_COURSE_RELOAD_CHALLENGES_LIST,
    payload: list
  }
}

export const showLoaderChallenge = () => {
  return {
    type: CHALLENGE_COURSE_ON_SHOW_LOADER
  }
}

export const hideLoaderChallenge = () => {
  return {
    type: CHALLENGE_COURSE_ON_HIDE_LOADER
  }
}

export const showMessageDialog = configMessage => {
  return {
    type: CHALLENGE_COURSE_ON_SHOW_MESSAGE,
    payload: configMessage
  }
}

export const hideMessageDialog = () => {
  return {
    type: CHALLENGE_COURSE_ON_HIDE_MESSAGE
  }
}

export const setDataAdmProspectPreferentCourse = data => {
  return {
    type: CHALLENGE_COURSE_SET_ADM_PROSPECT_PREFERENCE,
    payload: data
  }
}

export const getProstpectPreference = data => {
  return {
    type: CHALLENGE_COURSE_FETCH_GET_PROSPECT_PREFERENCE,
    payload: data
  }
}

export const loaDataFromRequestFullfiment = data => {
  return {
    type: CHALLENGE_COURSE_LOAD_DATA_FROM_REQUERIMENT_FULLFIMENT,
    payload: data
  }
}

export const addChallengeCourse = values => {
  return {
    type: CHALLENGE_COURSE_CREATE_CHALLENGE,
    payload: values
  }
}

export const updateChallengeCourse = values => {
  return {
    type: CHALLENGE_COURSE_UPDATE_CHALLENGE,
    payload: values
  }
}

export const openOrCloseModalAddDeliveryChallenge = () => {
  return {
    type: CHALLENGE_COURSE_MODAL_DELIVERY_ADD
  }
}

export const openOrCloseModalEditDeliveryChallenge = () => {
  return {
    type: CHALLENGE_COURSE_MODAL_DELIVERY_EDIT
  }
}

export const fetchListChallenges = data => {
  return {
    type: CHALLENGE_COURSE_FETCH_LIST_CHALLENGES,
    payload: data
  }
}

export const reloadListChallenges = challenges => {
  return {
    type: CHALLENGE_COURSE_LIST_CHALLENGES,
    payload: challenges
  }
}

export const updateRequerimentFullfiment = requerimentFullfiment => {
  return {
    type: CHALLENGE_COURSE_UPDATE_REQUERIMENT_FULLFIMENT,
    payload: requerimentFullfiment
  }
}