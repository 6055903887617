import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  PROSPECT_STUDENT_ID,
  TECHNICAL_MENTOR_ID,
  PSYCHOSOCIAL_ADVISOR_ID,
  COMMUNICATIONS_SUPPORT_ID,
  ACADEMIC_COORDINATOR_ID
} from "constants/Roles";
import Admin from "./admin/index";
import Main from "./main/index";
import CourseInfo from "./courseInfo";
import CurriculumVitae from "./curriculumVitae/index";
import ScholarshipProspect from "./scholarshipProspect/index";
import SponsorshipProspect from "./sponsorshipProspect";
import ErrorPages from "./errorPages";

const App = ({ match }) => {

  const { id_role } = useSelector(({ authorization }) => authorization);
  const { user } = useSelector(({ auth }) => auth);

  //  Editores de markdown que pueden servir 
  // <Route path={`${match.url}editors`} component={Editors} />

  const getRoutes = () => {
    let routes;
    if (id_role === PROSPECT_STUDENT_ID) {
      routes = (
        <Switch>
          <Route path={`${match.url}error-pages`} component={ErrorPages} />
          <Route path={`${match.url}main`} component={Main} />
          <Route path={`${match.url}course-info`} component={CourseInfo} />
          <Route path={`${match.url}curriculum-vitae`} component={CurriculumVitae} />
          <Route path={`${match.url}scholarship-prospect`} component={ScholarshipProspect} />
          <Route path={`${match.url}sponsorship-prospect`} component={SponsorshipProspect} />
          <Redirect to={`${match.url}error-pages/error-404`} />
        </Switch>
      );
    } else if (
      id_role === TECHNICAL_MENTOR_ID ||
      id_role === PSYCHOSOCIAL_ADVISOR_ID ||
      id_role === COMMUNICATIONS_SUPPORT_ID ||
      id_role === ACADEMIC_COORDINATOR_ID
    ) {
      routes = (
        <Switch>
          <Route path={`${match.url}error-pages`} component={ErrorPages} />
          <Route path={`${match.url}admin`} component={Admin} />
          <Redirect to={`${match.url}error-pages/error-404`} />
        </Switch>
      );
    } else if (user.admin) {
      routes = (
        <Switch>
          <Route path={`${match.url}error-pages`} component={ErrorPages} />
          <Route path={`${match.url}admin`} component={Admin} />
          <Route path={`${match.url}main`} component={Main} />
          <Redirect to={`${match.url}error-pages/error-404`} />
        </Switch>
      );
    }
    return routes;
  };

  return (id_role || user.admin) && (
    <div className="gx-main-content-wrapper">
      {getRoutes()}
    </div>
  );
};

export default App;