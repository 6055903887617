import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Contact from "./Contact";
import Common from "./Common";
import Evidences from "./Evidences";
import User from "./User";
import Courses from './Courses';
import SingleCourse from './SingleCourse'
import CvUser from "./CvUser"
import App from './App';
import AppMessage from './AppMessage';
import ProspectPreferences from "./ProspectPreferences";
import ChallengeCourse from "./ChallengeCourse"
import LanguagesToDevelop from "./LanguagesToDevelop"
import Country from "./Country";
import BasicData from "./BasicData";
import Languages from "./Languages";
import WorkExperience from './WorkExperience';
import AcademicInfo from './AcademicInfo';
import RegisterSponsors from './RegisterSponsors'
import RegisterScholarships from './RegisterScholarships'
import Grid from './Grid';
import CurriculumVitae from './CurriculumVitae';
import ScholarshipProspect from './ScholarshipProspect';
import GitHubClassroom from './GitHubClassroom';
import SupplementaryInfo from './SupplementaryInfo'
import PreScholarshipEvaluation from './PreScholarshipEvaluation'
import RegisterCourses from './RegisterCourses'
import RegisterRequirements from './RegisterRequirements'
import RegisterCohorts from './RegisterCohorts'
import Mentors from './Mentors';
import StudentCohort from './StudentCohort';
import Notifications from './Notifications';
import AcademicPerformanceReview from './AcademicPerformanceReview';
import Authorization from './Authorization';
import DemoDayModule from './DemoDayModule';
import RegisterSponsorships from './RegisterSponsorships';
import SponsorshipProspect from './SponsorshipProspect';
import RegisterPrices from './RegisterPrices';
import Langs from './Langs';
import Templates from './Templates'; 
import Messages from './Messages';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  app: App,
  app_message: AppMessage,
  auth: Auth,
  contact: Contact,
  common: Common,
  evidences: Evidences,
  user: User,
  courses: Courses,
  singleCourse: SingleCourse,
  cvUser: CvUser,
  pPreferences: ProspectPreferences,
  languagesToDevelop: LanguagesToDevelop,
  country: Country,
  basicData: BasicData,
  challengecourse: ChallengeCourse,
  languages: Languages,
  workExperience: WorkExperience,
  academicInfo: AcademicInfo,
  registerSponsors: RegisterSponsors,
  registerSponsorships: RegisterSponsorships,
  registerScholarships: RegisterScholarships,
  grid: Grid,
  langs: Langs,
  curriculumVitae: CurriculumVitae,
  scholarshipProspect: ScholarshipProspect,
  gitHubClassroom: GitHubClassroom,
  supplementaryInfo: SupplementaryInfo,
  preScholarshipEvaluation: PreScholarshipEvaluation,
  registerCourses: RegisterCourses,
  registerRequirements: RegisterRequirements,
  registerCohorts: RegisterCohorts,
  registerPrices: RegisterPrices,
  mentors: Mentors,
  studentCohort: StudentCohort,
  notifications: Notifications,
  academicPerformanceReview: AcademicPerformanceReview,
  authorization: Authorization,
  demoDayModule: DemoDayModule,
  sponsorshipProspect: SponsorshipProspect,
  templates: Templates,
  messages: Messages
}
);

export default createRootReducer
