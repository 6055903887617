import {
  GRID_ON_SHOW_LOADER,
  GRID_ON_HIDE_LOADER,
  GRID_GET_DATA,
  GRID_LOAD_DATA,
} from "constants/ActionTypes";

export const showLoaderGrid = () => {
  return {
    type: GRID_ON_SHOW_LOADER,
  };
};

export const hideLoaderGrid = () => {
  return {
    type: GRID_ON_HIDE_LOADER,
  };
};

export const getDataGrid = (params) => {
  return {
    type: GRID_GET_DATA,
    payload: params,
  };
};

export const loadDataGrid = (dataGrid) => {
  return {
    type: GRID_LOAD_DATA,
    payload: dataGrid,
  };
};
