import {
  SHOW_LOADER_ACADEMIC_INFO,
  HIDE_LOADER_ACADEMIC_INFO,
  SHOW_MESSAGE_DIALOG_ACADEMIC_INFO,
  HIDE_MESSAGE_DIALOG_ACADEMIC_INFO,
  ON_CHANGE_HIGH_SCHOOL,
  ACTION_TAKEN_HIGH_SCHOOL,
  GET_HIGH_SCHOOL_SUCCESS,
  ACADEMIC_INFO_MODAL_DELIVERY_ADD,
  ACADEMIC_INFO_MODAL_DELIVERY_EDIT,
  ON_CHANGE_COLLEGE_EDUCATION,
  RESET_COLLEGE_EDUCATION,
  GET_COLLEGE_EDUCATION_SUCCESS,
  SET_COLLEGE_EDUCATION_SINGLE,
  ON_CHANGE_COMPLEMENTARY_COURSES,
  RESET_COMPLEMENTARY_COURSES,
  GET_COMPLEMENTARY_COURSES_SUCCESS,
  SET_COMPLEMENTARY_COURSES_SINGLE,
  GET_EDUCATION_LEVEL_SUCCESS
} from 'constants/ActionTypes'
import { LOCATION_CHANGE } from 'react-router-redux'

const INIT_STATE = {
  show_message_app: false,
  alert_message: '',
  type_message: '',
  loader: false,
  highSchool: {},
  actionTakenHighSchool: '',
  modal_delivery_add: false,
  modal_delivery_edit: false,
  allCollegesEducations: [],
  collegeEducationSingle: {
    id: '',
    inst_name: '',
    inst_url: '',
    id_inst_country: null,
    id_inst_state: '',
    id_inst_city: null,
    program_type: '',
    program_name: '',
    pp_total: '',
    pp_completed: '',
    program_state: '',
    finish_date: null,
    file_certificate: undefined
  },
  allComplementaryCourses: [],
  complementaryCoursesSingle: {
    id: '',
    inst_name: '',
    inst_url: '',
    id_inst_country: null,
    id_inst_state: '',
    id_inst_city: null,
    program_type: '',
    program_name: '',
    program_duration: null,
    program_state: '',
    finish_date: null,
    file_certificate: ''
  },
  lastYearSchooling: {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {

    //Reducers generales para los componentes de AcademicInfo
    case LOCATION_CHANGE:
      return {
        ...INIT_STATE
      }
    case SHOW_LOADER_ACADEMIC_INFO:
      return {
        ...state,
        loader: true
      }
    case HIDE_LOADER_ACADEMIC_INFO:
      return {
        ...state,
        loader: false
      }
    case SHOW_MESSAGE_DIALOG_ACADEMIC_INFO:
      return {
        ...state,
        show_message_app: true,
        alert_message: action.payload.alertMessage,
        type_message: action.payload.typeMessage
      }
    case HIDE_MESSAGE_DIALOG_ACADEMIC_INFO:
      return {
        ...state,
        show_message_app: false,
        type_message: '',
        alert_message: '',
      }

    //Reducers para HighSchool
    case ON_CHANGE_HIGH_SCHOOL:
      state.highSchool[action.payload.field] = action.payload.value
      return {
        ...state
      }
    case ACTION_TAKEN_HIGH_SCHOOL:
      return {
        ...state,
        actionTakenHighSchool: action.payload
      }
    case GET_HIGH_SCHOOL_SUCCESS:
      return {
        ...state,
        highSchool: action.payload
      }

    //Reducers para CollegeEducation y ComplementaryCourses
    case ACADEMIC_INFO_MODAL_DELIVERY_ADD:
      return {
        ...state,
        modal_delivery_add: !state.modal_delivery_add
      }
    case ACADEMIC_INFO_MODAL_DELIVERY_EDIT:
      return {
        ...state,
        modal_delivery_edit: !state.modal_delivery_edit
      }

    //Reducers para CollegeEducation
    case ON_CHANGE_COLLEGE_EDUCATION:
      state.collegeEducationSingle[action.payload.field] = action.payload.value
      return {
        ...state
      }
    case RESET_COLLEGE_EDUCATION:
      return {
        ...state,
        collegeEducationSingle: {}
      }
    case GET_COLLEGE_EDUCATION_SUCCESS:
      return {
        ...state,
        allCollegesEducations: action.payload
      }
    case SET_COLLEGE_EDUCATION_SINGLE:
      return {
        ...state,
        collegeEducationSingle: action.payload
      }

    //Reducers para ComplementaryCourses
    case ON_CHANGE_COMPLEMENTARY_COURSES:
      state.complementaryCoursesSingle[action.payload.field] = action.payload.value
      return {
        ...state
      }
    case RESET_COMPLEMENTARY_COURSES:
      return {
        ...state,
        complementaryCoursesSingle: {}
      }
    case GET_COMPLEMENTARY_COURSES_SUCCESS:
      return {
        ...state,
        allComplementaryCourses: action.payload
      }
    case SET_COMPLEMENTARY_COURSES_SINGLE:
      return {
        ...state,
        complementaryCoursesSingle: action.payload
      }
    case GET_EDUCATION_LEVEL_SUCCESS:
      return {
        ...state,
        lastYearSchooling: action.payload
      }
    default:
      return state
  }
}