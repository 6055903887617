import { GET_DATA_MESSAGES_BY_USER_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
    messages: {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_DATA_MESSAGES_BY_USER_SUCCESS: {
            return {
                ...state,
                messages: {
                    ...state.messages,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }

        default:
            return state;
    }
}