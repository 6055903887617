import {
  WORK_EXPERIENCE_MODAL_DELIVERY_ADD,
  WORK_EXPERIENCE_MODAL_DELIVERY_EDIT,
  WORK_EXPERIENCE_CHANGE,
  ADD_WORK_EXPERIENCE,
  WORK_EXPERIENCE_MODAL_CLEAN,
  WORK_EXPERIENCE_ON_SHOW_LOADER,
  WORK_EXPERIENCE_ON_HIDE_LOADER,
  WORK_EXPERIENCE_ON_SHOW_MESSAGE,
  WORK_EXPERIENCE_ON_HIDE_MESSAGE,
  FETCH_WORK_EXPERIENCES,
  FETCH_WORK_EXPERIENCES_SUCCESS,
  WORK_EXPERIENCE_CHANGE_ALL,
  DELETE_WORK_EXPERIENCE,
  UPDATE_WORK_EXPERIENCE
} from 'constants/ActionTypes'

export const openOrCloseModalAddWorkExperience = () => {
  return {
    type: WORK_EXPERIENCE_MODAL_DELIVERY_ADD
  }
}

export const openOrCloseModalEditWorkExperience = () => {
  return {
    type: WORK_EXPERIENCE_MODAL_DELIVERY_EDIT
  }
}

export const changeWorkExperience = (payload) => {
  return {
    type: WORK_EXPERIENCE_CHANGE,
    payload: payload
  }
}

export const addWorkExperience = (payload) => {
  return {
    type: ADD_WORK_EXPERIENCE,
    payload: payload
  }
}

export const cleanModal = () => {
  return {
    type: WORK_EXPERIENCE_MODAL_CLEAN
  }
}

export const showLoaderWorkExperience = () => {
  return {
    type: WORK_EXPERIENCE_ON_SHOW_LOADER
  }
}

export const hideLoaderWorkExperience = () => {
  return {
    type: WORK_EXPERIENCE_ON_HIDE_LOADER
  }
}

export const showMessageDialog = configMessage => {
  return {
    type: WORK_EXPERIENCE_ON_SHOW_MESSAGE,
    payload: configMessage
  }
}

export const hideMessageDialog = () => {
  return {
    type: WORK_EXPERIENCE_ON_HIDE_MESSAGE
  }
}

export const fetchWorkExperiences = (payload) => {
  return {
    type: FETCH_WORK_EXPERIENCES,
    payload: payload
  }
}

export const fetchWorkExperiencesSuccess = (payload) => {
  return {
    type: FETCH_WORK_EXPERIENCES_SUCCESS,
    payload: payload
  }
}

export const changeAllWorkExperience = (payload) => {
  return {
    type: WORK_EXPERIENCE_CHANGE_ALL,
    payload: payload
  }
}

export const deleteWorkExperience = (payload) => {
  return {
    type: DELETE_WORK_EXPERIENCE,
    payload: payload
  }
}

export const updateWorkExperience = (payload) => {
  return {
    type: UPDATE_WORK_EXPERIENCE,
    payload: payload
  }
}