import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

// thid party
import axios from "axios";

// project imports
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";


// action - state management
import { CREATE_TEMPLATE_FETCH, TYPE_MESSAGE_ERROR, CREATE_TEMPLATE_LANGS_FETCH, DELETE_TEMPLATE_FETCH, DELETE_TEMPLATE_LANGS_FETCH, EDIT_TEMPLATE_FETCH, EDIT_TEMPLATE_LANG_FETCH, GET_ALL_TEMPLATES_FETCH, SEND_MESSAGE_WITHOUT_TEMPLATE_FETCH, SEND_MESSAGE_WITH_TEMPLATE_FETCH, TYPE_MESSAGE_SUCCESS } from "../../constants/ActionTypes";
import { clearDataStateMessage, createTemplateLangsFetch, createTemplateLangsSuccess, getAllTemplatesFetch, getAllTemplatesSuccess, showHideModalCreateNewTemplateLang } from "../actions/Templates";
import { showMessageDialog } from 'appRedux/actions/Templates'

// constant
const getLocale = state => state.settings;

const serviceGetAllTemplates = async () => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}noti/templates/all`, config());
        return data;
    } catch (e) {
        return false;
    }
}

const servicePostTemplates = async payload => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}noti/templates`, payload, config());
        return data;
    } catch (e) {
        return false;
    }
}

const servicePostTemplatesLangs = async payload => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}noti/template_langs`, payload, config());
        return data;
    } catch (e) {
        return false
    }
}

const serviceDeleteTemplates = async id => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}noti/templates/${id}`, config());
        return data;
    } catch (e) {
        return false
    }
}

const servicesDeleteTemplatesLangs = async id => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}noti/template_langs/${id}`, config());
        return data;

    } catch (e) {
        return false
    }
}

const serviceEditTemplate = async payload => {
    try {
        const { id, dataTemplate } = payload;
        await axios.patch(`${process.env.REACT_APP_API_URL}noti/templates/${id}`, dataTemplate, config());
        return true;
    } catch (e) {
        return false
    }
}

const serviceEditTemplateLang = async payload => {
    try {
        const { id, dataTemplate } = payload;
        await axios.patch(`${process.env.REACT_APP_API_URL}noti/template_langs/${id}`, dataTemplate, config());
        return true;
    } catch (e) {
        return false
    }
}

const serviceSendMessageWithTemplate = async payload => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}noti/report_messages_sent/send_message_with_template`, payload, config());
        return data;
    } catch (e) {
        return false
    }
}

const serviceSendMessageWithoutTemplate = async payload => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}noti/report_messages_sent/send_message_without_template`, payload, config());
        return data;
    } catch (e) {
        return false
    }
}


function* createTemplateRequest({ payload }) {
    try {
        const template = yield call(servicePostTemplates, payload.typeTemplate);

        if (template) {
            const newTemplateLang = {
                ...payload.template,
                id_noti_template: template?.id
            }
            yield put(createTemplateLangsFetch(newTemplateLang))
        }
    } catch (error) {

    }
}

function* getAllTemplatesRequest() {
    try {
        const templates = yield call(serviceGetAllTemplates);

        if (templates) {
            yield put(getAllTemplatesSuccess(templates));
        }
    } catch (error) {

    }
}

function* createTemplateLangsRequest({ payload }) {
    try {
        const { locale } = yield select(getLocale);
        const templateLangs = yield call(servicePostTemplatesLangs, payload);

        if (templateLangs) {
            const message = getMessageByString(locale, 'templates.created.message');
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
            yield put(createTemplateLangsSuccess());
            yield put(getAllTemplatesFetch());
            yield put(showHideModalCreateNewTemplateLang(false))
        }
    } catch (error) {

    }
}

function* deleteTemplateRequest({ payload }) {
    const { locale } = yield select(getLocale);
    try {

        const templateDeleted = yield call(serviceDeleteTemplates, payload);

        if (templateDeleted) {
            const message = getMessageByString(locale, 'templates.deleted.main.message');
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
            yield put(getAllTemplatesFetch());
        } else {
            const message = getMessageByString(locale, 'templates.deleted.lang.message.error');
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        }
    } catch (error) {

    }
}

function* deleteTemplateLangsRequest({ payload }) {
    const { locale } = yield select(getLocale);
    try {

        const templateDeleted = yield call(servicesDeleteTemplatesLangs, payload);

        if (templateDeleted) {
            const message = getMessageByString(locale, 'templates.deleted.lang.message');
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
            yield put(getAllTemplatesFetch());
        } else {
            const message = getMessageByString(locale, 'templates.deleted.lang.message.error');
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        }
    } catch (error) {

    }
}

function* editTemplateRequest({ payload }) {
    try {
        const { locale } = yield select(getLocale);
        const data = yield call(serviceEditTemplate, payload)

        if (data) {
            const message = getMessageByString(locale, 'template.update.template.main');
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }));
            yield put(getAllTemplatesFetch());
        }

    } catch (error) {

    }
}

function* editTemplateLangRequest({ payload }) {
    try {
        const { locale } = yield select(getLocale);
        const data = yield call(serviceEditTemplateLang, payload)

        if (data) {
            const message = getMessageByString(locale, 'template.update.template.main');
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }));
            yield put(getAllTemplatesFetch());
        }

    } catch (error) {

    }
}

function* sendMessageWithTemplateRequest({ payload }) {
    try {
        const { locale } = yield select(getLocale);
        const templateLangs = yield call(serviceSendMessageWithTemplate, payload);

        if (templateLangs) {
            const message = getMessageByString(locale, 'template.message.send');
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }));
            yield put(clearDataStateMessage())
        }
    } catch (error) {

    }
}

function* sendMessageWithoutTemplateRequest({ payload }) {
    try {
        const { locale } = yield select(getLocale);
        const templateLangs = yield call(serviceSendMessageWithoutTemplate, payload);

        if (templateLangs) {
            const message = getMessageByString(locale, 'template.message.send');
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }));
            yield put(clearDataStateMessage())
        }
    } catch (error) {

    }
}

export function* getAllTemplates() {
    yield takeEvery(GET_ALL_TEMPLATES_FETCH, getAllTemplatesRequest)
}

export function* createTemplate() {
    yield takeEvery(CREATE_TEMPLATE_FETCH, createTemplateRequest);
}

export function* createTemplateLangs() {
    yield takeEvery(CREATE_TEMPLATE_LANGS_FETCH, createTemplateLangsRequest);
}

export function* deleteTemplate() {
    yield takeEvery(DELETE_TEMPLATE_FETCH, deleteTemplateRequest)
}

export function* deleteTemplateLangs() {
    yield takeEvery(DELETE_TEMPLATE_LANGS_FETCH, deleteTemplateLangsRequest)
}

export function* editTemplate() {
    yield takeEvery(EDIT_TEMPLATE_FETCH, editTemplateRequest)
}

export function* editTemplateLang() {
    yield takeEvery(EDIT_TEMPLATE_LANG_FETCH, editTemplateLangRequest)
}

export function* sendMessageWithTemplate() {
    yield takeEvery(SEND_MESSAGE_WITH_TEMPLATE_FETCH, sendMessageWithTemplateRequest)
}

export function* sendMessageWithoutTemplate() {
    yield takeEvery(SEND_MESSAGE_WITHOUT_TEMPLATE_FETCH, sendMessageWithoutTemplateRequest);
}

export default function* rootSaga() {
    yield all([
        fork(createTemplate),
        fork(createTemplateLangs),
        fork(getAllTemplates),
        fork(deleteTemplate),
        fork(deleteTemplateLangs),
        fork(editTemplate),
        fork(editTemplateLang),
        fork(sendMessageWithTemplate),
        fork(sendMessageWithoutTemplate)
    ])
}