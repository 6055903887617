import {
  GET_USER_ROLE,
  GET_USER_ROLE_SUCCESS
} from 'constants/ActionTypes'

export const getUserRole = idUser => {
  return {
    type: GET_USER_ROLE,
    payload: idUser
  }
}

export const getUserRoleSuccess = userRole => {
  return {
    type: GET_USER_ROLE_SUCCESS,
    payload: userRole
  }
}