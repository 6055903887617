import {
    REGISTER_COHORTS_MODAL_DELIVERY_ADD,
    REGISTER_COHORTS_MODAL_DELIVERY_EDIT,
    REGISTER_COHORTS_CHANGE,
    REGISTER_COHORTS_MODAL_CLEAN,
    ADD_COHORT,
    REGISTER_COHORT_ON_SHOW_LOADER,
    REGISTER_COHORT_ON_HIDE_LOADER,
    REGISTER_COHORT_ON_SHOW_MESSAGE,
    REGISTER_COHORT_ON_HIDE_MESSAGE,
    FETCH_COHORTS,
    FETCH_COHORTS_SUCCESS,
    DELETE_COHORT,
    EDIT_COHORT,
    REGISTER_COHORTS_CHANGE_ALL,
    FILTER_COHORT
} from 'constants/ActionTypes'

export const openOrCloseModalAddRegisterCohort = () => {
    return {
        type: REGISTER_COHORTS_MODAL_DELIVERY_ADD
    }
}

export const openOrCloseModalEditRegisterCohort = () => {
    return {
        type: REGISTER_COHORTS_MODAL_DELIVERY_EDIT
    }
}

export const changeCohort = payload => {
    return {
        type: REGISTER_COHORTS_CHANGE,
        payload: payload
    }
}

export const cleanModal = () => {
    return {
        type: REGISTER_COHORTS_MODAL_CLEAN
    }
}

export const addCohort = (payload) => {
    return {
        type: ADD_COHORT,
        payload: payload
    }
}

export const showLoaderCohort = () => {
    return {
        type: REGISTER_COHORT_ON_SHOW_LOADER
    }
}

export const hideLoaderCohort = () => {
    return {
        type: REGISTER_COHORT_ON_HIDE_LOADER
    }
}

export const showMessageDialog = configMessage => {
    return {
        type: REGISTER_COHORT_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}

export const hideMessageDialog = () => {
    return {
        type: REGISTER_COHORT_ON_HIDE_MESSAGE
    }
}

export const fetchCohort = (payload) => {
    return {
        type: FETCH_COHORTS,
        payload: payload
    }
}

export const fetchCohortSuccess = (payload) => {
    return {
        type: FETCH_COHORTS_SUCCESS,
        payload: payload
    }
}

export const deleteCohort = (payload) => {
    return {
        type: DELETE_COHORT,
        payload: payload
    }
}

export const editCohort = (payload) => {
    return {
        type: EDIT_COHORT,
        payload: payload
    }
}

export const changeCohortAll = (payload) => {
    return {
        type: REGISTER_COHORTS_CHANGE_ALL,
        payload: payload
    }
}
export const filterCohort = (payload) => {
    return {
        type: FILTER_COHORT,
        payload: payload
    }
}