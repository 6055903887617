import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import asyncComponent from 'util/asyncComponent'
import { PROSPECT_STUDENT_ID } from 'constants/Roles'
import ErrorPages from '../../errorPages'

const Dashboard = ({ match }) => {

  const { id_role } = useSelector(({ authorization }) => authorization)
  const { user } = useSelector(({ auth }) => auth)

  const getRoutes = () => {
    let routes
    if (id_role === PROSPECT_STUDENT_ID) {
      routes = (
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/geek`} />
          <Route path={`${match.url}error-pages`} component={ErrorPages} />
          <Route path={`${match.url}/geek`} component={asyncComponent(() => import('./Geek/index'))} />
          <Redirect to='/error-pages/error-404' />
        </Switch>
      )
    } else if (user.admin) {
      routes = (
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/admin`} />
          <Route path={`${match.url}error-pages`} component={ErrorPages} />
          <Route path={`${match.url}/admin`} component={asyncComponent(() => import('./Admin/index'))} />
          <Redirect to='/error-pages/error-404' />
        </Switch>
      )
    }
    return routes
  }

  return (id_role || user.admin) && getRoutes()
}
export default Dashboard