import {
  GET_ALL_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_STATES_BY_COUNTRY_ID,
  GET_STATES_BY_COUNTRY_ID_SUCCESS,
  GET_CITIES_BY_STATE_ID,
  GET_CITIES_BY_STATE_ID_SUCCESS,
  GET_CITY_BY_ID,
  GET_CITY_BY_ID_SUCCESS
}
  from "constants/ActionTypes";


//Traer todos los países
export const getAllCountries = () => {
  return {
    type: GET_ALL_COUNTRIES
  }
};

//Añadir al estado la lista del todos los países
export const getCountriesSuccess = listCoutries => {
  return {
    type: GET_COUNTRIES_SUCCESS,
    payload: listCoutries
  }
};

//Por medio del id del país buscar departamentos 
export const getStatesByCountryId = (idCountry, fieldName) => {
  return {
    type: GET_STATES_BY_COUNTRY_ID,
    idCountry,
    fieldName: fieldName
  }
}

//Añadir al estado la lista de los departamentos filtrado por id del país
export const getStatesByCountryIdSuccess = (listStates, fieldName) => {
  return {
    type: GET_STATES_BY_COUNTRY_ID_SUCCESS,
    payload: listStates,
    fieldName: fieldName
  }
};

//Por medio del id del departamento buscar las ciudades 
export const getCitiesByStateId = (param, fieldName) => {
  return {
    type: GET_CITIES_BY_STATE_ID,
    payload: param,
    fieldName: fieldName
  }
}

//Añadir al estado la lista de las ciudades filtradas por el id del departamento 
export const getCitiesByStateIdSuccess = (listCities, fieldName) => {
  return {
    type: GET_CITIES_BY_STATE_ID_SUCCESS,
    payload: listCities,
    fieldName: fieldName
  }
}

export const getCityById = (payload) => {
  return {
    type: GET_CITY_BY_ID,
    payload: payload
  }
}

export const getCityByIdSuccess = (payload) => {
  return {
    type: GET_CITY_BY_ID_SUCCESS,
    payload: payload
  }
}