import React, { useEffect, useState } from 'react'
import 'styles/my_progress.scss'

const MyProgress = ({ loaderApp }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (loaderApp) {
      setTimeout(() => {
        if (progress > 100) setProgress(0)
        else setProgress(progress + 10)
      }, 1000)
    }
  }, [progress, loaderApp])

  let bar = 'bar'
  let spinner = 'spinner'

  return (
    <div id='nprogressapp' style={{ display: loaderApp ? 'block' : 'none' }}>
      <div className='bar' role={bar} style={{ width: `${progress}%` }}>
        <div className='peg'></div>
      </div>
      <div className='spinner' role={spinner}>
        <div className='spinner-icon'></div>
      </div>
    </div>
  )
}

export default MyProgress