import {
    FETCH_MENTORS_SUCCESS
} from 'constants/ActionTypes'

const INIT_STATE = {
    allMentors: []
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCH_MENTORS_SUCCESS: {
            return {
                ...state,
                allMentors: action.payload
            }
        } default:
            return state
    }
}