import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { pingTokenTest } from 'appRedux/actions/Auth'
import asyncComponent from "util/asyncComponent";

const SponsorshipProspect = ({ match }) => {

  const location = useLocation();
  const dispatch = useDispatch()

  const urlAdmin = '/admin/members/prospect/sponsorship-prospect'

  /*
    Cada vez que se cambia la ruta se acciona una saga que hace una peticion al backend para verificar el token, 
    Si este token es válido todo sigue igual, si no se cierra sesion. 
  */
  useEffect(() => {
    dispatch(pingTokenTest())
  }, [dispatch, location])


  return (
    <Switch>
      {/* Préstamos */}
      <Route path=
        {match.url === urlAdmin ?
          `${match.url}/:id_cv_user/:id_user/all-sponsorships`
          : `${match.url}/all-sponsorships`}
        component={asyncComponent(() => import('./AllSponsorships'))} />
      <Route path=
        {match.url === urlAdmin ?
          `${match.url}/:id_cv_user/:id_user/compare-sponsorships`
          : `${match.url}/compare-sponsorships`}
        component={asyncComponent(() => import('./CompareSponsorshipsRoute'))} />
      <Route path=
        {match.url === urlAdmin ?
          `${match.url}/:id_cv_user/:id_user/applied-sponsorship`
          : `${match.url}/applied-sponsorship`}
        component={asyncComponent(() => import('./AppliedSponsorship'))} />
    </Switch>
  );
};

export default SponsorshipProspect;