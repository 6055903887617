import {
    SHOW_LOADER_BASIC_DATA,
    HIDE_LOADER_BASIC_DATA,
    SHOW_MESSAGE_DIALOG_BASIC_DATA,
    HIDE_MESSAGE_DIALOG_BASIC_DATA,
    GET_BY_ID_USER_CV_BASIC_DATA,
    GET_BY_ID_USER_CV_BASIC_DATA_SUCCESS,
    ON_CHANGE_INPUT_FORM_BASIC_DATA,
    GET_ALL_PHYSICAL_DISABILITY,
    GET_ALL_PHYSICAL_DISABILITY_SUCCESS,
    GET_ALL_SPECIAL_CONDITION,
    GET_ALL_SPECIAL_CONDITION_SUCCESS,
    CHECK_ID_DOCUMENT,
    CHECK_ID_DOCUMENT_SUCCESS,
    UPLOAD_FILES,
    URL_FILE,
    PATCH_BY_ID_USER_CV_BASIC_DATA,
    PATCH_BY_ID_USER_CV_BASIC_DATA_SUCCESS,
} from "constants/ActionTypes";

export const showLoaderBasicData = () => {
    return {
        type: SHOW_LOADER_BASIC_DATA
    }
}

export const hideLoaderBasicData = () => {
    return {
        type: HIDE_LOADER_BASIC_DATA
    }
}

export const showMessageDialogBasicData = configMessage => {
    return {
        type: SHOW_MESSAGE_DIALOG_BASIC_DATA,
        payload: configMessage
    }
}

export const hideMessageDialogBasicData = () => {
    return {
        type: HIDE_MESSAGE_DIALOG_BASIC_DATA
    }
}

export const getByIdUserCvBasicData = idUser => {
    return {
        type: GET_BY_ID_USER_CV_BASIC_DATA,
        idUser
    }
}

export const getByIdUserCvBasicDataSuccess = basicData => {
    return {
        type: GET_BY_ID_USER_CV_BASIC_DATA_SUCCESS,
        payload: basicData
    }
}

//Cuando se realiza un cambio en el formulario se activa esta acción 
export const onChangeInputFormBasicData = data => {
    return {
        type: ON_CHANGE_INPUT_FORM_BASIC_DATA,
        payload: data
    }
}

export const getAllPhysicalDisability = () => {
    return {
        type: GET_ALL_PHYSICAL_DISABILITY
    }
}

export const getAllPhysicalDisabilitySuccess = disabilities => {
    return {
        type: GET_ALL_PHYSICAL_DISABILITY_SUCCESS,
        payload: disabilities
    }
}

export const getAllSpecialCondition = () => {
    return {
        type: GET_ALL_SPECIAL_CONDITION
    }
}

export const gellAllSpecialConditionSuccess = specialConditions => {
    return {
        type: GET_ALL_SPECIAL_CONDITION_SUCCESS,
        payload: specialConditions
    }
}

/*Se ejecuta cada vez que hay un cambio en el número de 
documento, verifica que el número de documento sea único*/
export const checkIdDocument = numberDocument => {
    return {
        type: CHECK_ID_DOCUMENT,
        payload: numberDocument
    }
}

/*Si el documento es valido se establece un estado de 200, si no es
valido se establece el estado en 230 y si el servidor falla es 500*/
export const checkIdDocumentSuccess = status => {
    return {
        type: CHECK_ID_DOCUMENT_SUCCESS,
        payload: status
    }
}

//Se ejecuta inmediatamente se carga un archivo en el frontend
export const uploadFiles = data => {
    return {
        type: UPLOAD_FILES,
        payload: data
    }
}

/*Se ejecuta cuando el archivo ya está subido en Firebase, y trae 
la url pública del archivo para poder acceder a la vista previa*/
export const urlFile = data => {
    return {
        type: URL_FILE,
        payload: data
    }
}

export const patchCvBasicData = (data) => {
    return {
        type: PATCH_BY_ID_USER_CV_BASIC_DATA,
        payload: data,
    }
}

export const pathCvBasicDataSuccess = status => {
    return {
        type: PATCH_BY_ID_USER_CV_BASIC_DATA_SUCCESS,
        payload: status
    }
}