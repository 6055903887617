import {
    REGISTER_SPONSORSHIPS_MODAL_DELIVERY_ADD,
    REGISTER_SPONSORSHIPS_CHANGE,
    REGISTER_SPONSORSHIP_SEARCH_CITY_SUCCESS,
    FETCH_SPONSORS_SUCCESS,
    FETCH_SPONSORSHIP_SUCCESS,
    REGISTER_SPONSORSHIP_ON_SHOW_LOADER,
    REGISTER_SPONSORSHIP_ON_HIDE_LOADER,
    REGISTER_SPONSORSHIP_ON_SHOW_MESSAGE,
    REGISTER_SPONSORSHIP_ON_HIDE_MESSAGE,
    REGISTER_SPONSORSHIPS_MODAL_DELIVERY_EDIT,
    REGISTER_SPONSORSHIPS_MODAL_CLEAN,
    REGISTER_SPONSORSHIPS_CHANGE_ALL,
} from 'constants/ActionTypes'

const INIT_STATE = {
    modal_delivery_add: false,
    modal_delivery_edit: false,
    sponsorship_single: {},
    allSponsorships: [],
    sponsorsList: [],
    all_cities_filter: [],
    loader: false,
    alert_message: '',
    show_message_app: false,
    type_message: '',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case REGISTER_SPONSORSHIPS_MODAL_DELIVERY_ADD: {
            return {
                ...state,
                modal_delivery_add: !state.modal_delivery_add
            }
        }
        case REGISTER_SPONSORSHIPS_MODAL_DELIVERY_EDIT: {
            return {
                ...state,
                modal_delivery_edit: !state.modal_delivery_edit
            }
        }
        case REGISTER_SPONSORSHIPS_CHANGE: {
            return {
                ...state,
                sponsorship_single: {
                    ...state.sponsorship_single,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }
        case REGISTER_SPONSORSHIPS_MODAL_CLEAN: {
            return {
                ...state,
                sponsorship_single: {

                }
            }
        }
        case REGISTER_SPONSORSHIP_ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case REGISTER_SPONSORSHIP_ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case REGISTER_SPONSORSHIP_ON_SHOW_MESSAGE: {
            return {
                ...state,
                show_message_app: true,
                alert_message: action.payload.alertMessage,
                type_message: action.payload.typeMessage
            }
        }
        case REGISTER_SPONSORSHIP_ON_HIDE_MESSAGE: {
            return {
                ...state,
                alert_message: '',
                show_message_app: false,
                loader: false
            }
        }
        case FETCH_SPONSORSHIP_SUCCESS: {
            return {
                ...state,
                allSponsorships: action.payload
            }
        }
        case REGISTER_SPONSORSHIPS_CHANGE_ALL: {
            return {
                ...state,
                sponsorship_single: action.payload
            }
        }
        case FETCH_SPONSORS_SUCCESS:
            return {
                ...state,
                sponsorsList: action.payload
            }

        case REGISTER_SPONSORSHIP_SEARCH_CITY_SUCCESS: {
            return {
                ...state,
                all_cities_filter: action.payload
            }
        }
        default:
            return state
    }
}