import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
// import { storage } from 'firebase/firebase'
import { showMessageDialog, hideLoader, fetchValidUserSponsorshipsSuccess, fetchSponsorshipProspectSuccess, fetchSponsorshipApplicationByUserSuccess, fetchSponsorshipValidationCohortScholarshipSuccess, fetchSponsorshipHistorySuccess, unpostulateSponsorshipSuccess } from 'appRedux/actions/SponsorshipProspect'
import { sendEmail } from 'appRedux/actions/Notifications';
import { config } from "util/Authorization";
import { getMessageByString } from 'util/Messages'
import {
    TYPE_MESSAGE_ERROR, FETCH_VALID_USER_SPONSORSHIPS, TYPE_MESSAGE_WARNING,
    TYPE_MESSAGE_SUCCESS, FETCH_SPONSORSHIP_PROSPECT, FETCH_SPONSORSHIP_APPLICATION_BY_USER, FETCH_SPONSORSHIP_VALIDATION_COHORT_SCHOLARSHIP, CREATE_SPONSORSHIP_HOLDERS, FETCH_SPONSORSHIP_HISTORY, UNPOSTULATE_SPONSORSHIP, REAPPLY_SPONSORSHIP
} from "constants/ActionTypes"
import { TYPE_USER } from 'constants/Scholarship';

//Subir cv a firebase
// const serviceUploadPdfFirestore = async (info) => {
//     const { name, idCv, file, uid } = info
//     try {
//         var storageRef = storage.ref()
//         const nameUser = name.replaceAll(' ', '-');
//         var spaceRef = storageRef.child(`pdf-curriculum-vitae/${idCv}-${nameUser}/${uid}`)
//         await spaceRef.put(file)
//         const publicURL = await spaceRef.getDownloadURL()
//         const metadata = await spaceRef.getMetadata()
//         return { metadata, publicURL, status: true }
//     } catch (error) {
//         return { info: { response: { status: 422 } }, status: false }
//     }
// }

//Prestamos validos para el usuario
const serviceFetchValidUserSponsorships = async (payload) => {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}sponsorship/valid-invalid-by-user/${payload.id_user}`, config());
    return data;
}

//Validación si está en cohorte o beca
const serviceFetchSponsorshipValidationCohortScholarship = async (payload) => {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}sponsorship_application/verify_data_user/${payload.id_cv_user}`, config());
    return data;
}

//Trae todos los prestamos vigentes
const serviceFetchSponsorship = async (payload) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}sponsorship/validate-by-user/${payload.id_user}/${payload.id_sponsorship}`, config());
    return data;
}

//Prestamos aplicados por el usuario
const serviceFetchSponsorshipsApplicationByUser = async (payload) => {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}sponsorship_application/by_user/${payload.id_cv_user}`, config());
    return data;
}

//Aplicar a prestamo
const serviceCreateSponsorshipHolders = async (payload) => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}sponsorship-application/apply`, payload, config())
    return data;
}

//Historial de aplicación
const serviceFetchSponsorshipHistory = async (payload) => {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}sponsorship_application/by_user/${payload.id_cv_user}/${payload.type_user}/history`, config());
    return data
}

//Despostularse
const serviceUnpostulateSponsorship = async (payload) => {
    const data = await axios.delete(`${process.env.REACT_APP_API_URL}sponsorship_application/desist/${payload.id_cv_user}`, payload, config())
    return data
}

//Volver a aplicar al prestamo
const serviceReApplySponsorship = async (payload) => {
    const data = await axios.post(`${process.env.REACT_APP_API_URL}tracking_log_sponsorship`, payload, config())
    return data
}

function* fetchSponsorshipRequest({ payload }) {
    const { locale } = payload;
    try {
        const sponsorship = yield call(serviceFetchSponsorship, payload)
        yield put(fetchSponsorshipProspectSuccess(sponsorship))
        yield put(hideLoader())
    } catch (error) {
        const { response } = error;
        if (response.status === 422) {
            const message = getMessageByString(
                locale,
                'register.sponsorships.message.internalerror.sponsorships.not.found'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_WARNING }));
            return false;
        }
        else {
            const message = getMessageByString(
                locale,
                'register.sponsorships.message.internalerror'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
            return false;
        }
    }
}

function* fetchValidUserSponsorshipsRequest({ payload }) {
    const { locale } = payload;
    try {
        const allValidUserSponsorships = yield call(serviceFetchValidUserSponsorships, payload)
        if (allValidUserSponsorships.status === 204 || allValidUserSponsorships.status === 209) {
            yield put(fetchValidUserSponsorshipsSuccess(allValidUserSponsorships.status))
        } else {
            yield put(fetchValidUserSponsorshipsSuccess(allValidUserSponsorships.data))
            yield put(hideLoader())
        }
    } catch (error) {
        const message = getMessageByString(
            locale,
            'register.sponsorships.message.internalerror'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* fetchSponsorshipValidationCohortScholarshipRequest({ payload }) {
    const { locale } = payload;
    try {
        const allSponsorshipValidationCohortScholarship = yield call(serviceFetchSponsorshipValidationCohortScholarship, payload)
        yield put(fetchSponsorshipValidationCohortScholarshipSuccess(allSponsorshipValidationCohortScholarship))
        yield put(hideLoader())
    } catch (error) {
        const message = getMessageByString(
            locale,
            'register.sponsorships.message.internalerror'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* fetchSponsorshipsApplicationByUserRequest({ payload }) {
    const { locale } = payload;
    try {
        const allSponsorshipApplication = yield call(serviceFetchSponsorshipsApplicationByUser, payload)
        if (allSponsorshipApplication.status === 204 || allSponsorshipApplication.status === 209) {
            yield put(fetchSponsorshipApplicationByUserSuccess(allSponsorshipApplication.status))
        } else {
            yield put(fetchSponsorshipApplicationByUserSuccess(allSponsorshipApplication.data))
            yield put(hideLoader())
        }
    } catch (error) {
        const message = getMessageByString(
            locale,
            'register.sponsorships.message.internalerror'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* createSponsorshipHoldersRequest({ payload }) {
    const { locale, data, dataEmailUser, dataEmailSponsor /*,info*/ } = payload;
    try {
        // const fileStorage = yield call(serviceUploadPdfFirestore, info)
        // dataEmailUser.data.params.cv_url = fileStorage.publicURL
        // dataEmailSponsor.data.params.cv_url = fileStorage.publicURL
        // fileStorage.metadata.publicURL = fileStorage.publicURL
        // data.file = fileStorage.metadata
        yield call(serviceCreateSponsorshipHolders, data)
        yield put(sendEmail(dataEmailUser))
        yield put(sendEmail(dataEmailSponsor))
        data.type_user = TYPE_USER.GEEK
        const response = yield call(serviceFetchSponsorshipHistory, data)
        yield put(fetchSponsorshipHistorySuccess(response.data))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* fetchSponsorshipHistoryRequest({ payload }) {
    const { locale } = payload;
    try {
        const allSponsorshipHistory = yield call(serviceFetchSponsorshipHistory, payload)
        if (allSponsorshipHistory.status === 204) {
            yield put(fetchSponsorshipHistorySuccess(allSponsorshipHistory.status))
        } else {
            yield put(fetchSponsorshipHistorySuccess(allSponsorshipHistory.data))
            yield put(hideLoader())
        }
    } catch (error) {
        const message = getMessageByString(
            locale,
            'register.sponsorships.message.internalerror'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* unpostulateSponsorshipRequest({ payload }) {
    const { locale, dataEmailUser, dataEmailSponsor, data } = payload;
    try {
        const responseUnpostulate = yield call(serviceUnpostulateSponsorship, payload)
        if (responseUnpostulate.status === 200) {
            yield put(sendEmail(dataEmailUser))
            yield put(sendEmail(dataEmailSponsor))
            yield put(hideLoader())
            const allSponsorshipHistory = yield call(serviceFetchSponsorshipHistory, data)
            if (allSponsorshipHistory.status === 204) {
                yield put(fetchSponsorshipHistorySuccess(allSponsorshipHistory.status))
            } else {
                yield put(fetchSponsorshipHistorySuccess(allSponsorshipHistory.data))
            }
        }
        yield put(unpostulateSponsorshipSuccess(responseUnpostulate))
    } catch (error) {
        const message = getMessageByString(
            locale,
            'register.sponsorships.message.internalerror'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* reApplySponsorshipRequest({ payload }) {
    const { locale, body, id_cv_user, type_user } = payload;
    try {
        yield call(serviceReApplySponsorship, body)
        yield put(sendEmail(payload));
        const response = yield call(serviceFetchSponsorshipHistory, { id_cv_user, type_user })
        yield put(fetchSponsorshipHistorySuccess(response.data))
        const message = getMessageByString(
            locale,
            'register.sponsorships.message.updated.successfully'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

export function* fetchValidUserSponsorships() {
    yield takeEvery(FETCH_VALID_USER_SPONSORSHIPS, fetchValidUserSponsorshipsRequest)
}

export function* fetchSponsorshipValidationCohortScholarship() {
    yield takeEvery(FETCH_SPONSORSHIP_VALIDATION_COHORT_SCHOLARSHIP, fetchSponsorshipValidationCohortScholarshipRequest)
}

export function* fetchSponsorshipsApplicationByUser() {
    yield takeEvery(FETCH_SPONSORSHIP_APPLICATION_BY_USER, fetchSponsorshipsApplicationByUserRequest)
}

export function* fetchSponsorship() {
    yield takeEvery(FETCH_SPONSORSHIP_PROSPECT, fetchSponsorshipRequest)
}

export function* createScholarshipHolders() {
    yield takeEvery(CREATE_SPONSORSHIP_HOLDERS, createSponsorshipHoldersRequest);
}

export function* fetchSponsorshipHistory() {
    yield takeEvery(FETCH_SPONSORSHIP_HISTORY, fetchSponsorshipHistoryRequest);
}

export function* unpostulateSponsorship() {
    yield takeEvery(UNPOSTULATE_SPONSORSHIP, unpostulateSponsorshipRequest);
}

export function* reApplySponsorship() {
    yield takeEvery(REAPPLY_SPONSORSHIP, reApplySponsorshipRequest);
}

export default function* rootSaga() {
    yield all([
        fork(fetchValidUserSponsorships),
        fork(fetchSponsorshipsApplicationByUser),
        fork(fetchSponsorship),
        fork(fetchSponsorshipValidationCohortScholarship),
        fork(createScholarshipHolders),
        fork(fetchSponsorshipHistory),
        fork(unpostulateSponsorship),
        fork(reApplySponsorship)
    ])
}