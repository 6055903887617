import { 
    GET_COURSES, 
    FETCH_COURSES_SUCCESS,
    CHANGE_TYPE_OF_LIST_OFFERED_COURSES,
    FETCH_PREREQUISITE_COURSES,
    FETCH_PREREQUISITE_COURSES_SUCCESS
} from "constants/ActionTypes"

export const onGetCourses = () => {
    return {
        type: GET_COURSES
    }
}

export const fetchCoursesSuccess = (coursesList) => {
    return {
        type: FETCH_COURSES_SUCCESS,
        payload: coursesList
    }
};

export const changeTypeOfListOfferedCourses = (payload) => {
    return {
        type: CHANGE_TYPE_OF_LIST_OFFERED_COURSES,
        payload
    }
}

export const fetchPrerequisiteCourses = (payload) => {
    return {
        type: FETCH_PREREQUISITE_COURSES,
        payload
    }
}

export const fetchPrerequisiteCoursesSuccess = (payload) => {
    return {
        type: FETCH_PREREQUISITE_COURSES_SUCCESS,
        payload
    }
}