import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, Col, Row, Spin } from 'antd'
import { getTeamsDemoDay } from 'appRedux/actions/DemodayModule'
import CohortMocksDM from './Mocks/MocksADD'

const CorhortDetails = () => {
  const dispatch = useDispatch()

  const { Meta } = Card

  const { teams, loader } = useSelector(({ demoDayModule }) => demoDayModule)

  const [loading, setLoading] = useState(true)

  const fetchProjectsData = useCallback(async () => {
    const data =
      CohortMocksDM[sessionStorage.getItem('idCohorte')].dm_teams !== undefined
        ? CohortMocksDM[sessionStorage.getItem('idCohorte')].dm_teams
        : ''
    if (teams.length === 0) {
      data.map(async cohort => {
        dispatch(getTeamsDemoDay(cohort))
      })
    }
    setLoading(false)
  }, [dispatch, teams])

  //UseEffect para traer la data;
  useEffect(() => {
    fetchProjectsData()
  }, [fetchProjectsData])

  if (loading) {
    return <div style={{ margin: 40 }}></div>
  }
  return (
    <div style={{ margin: 40 }}>
      {loader ? (
        <Spin spinning={loader} style={{ textAlign: 'center' }}></Spin>
      ) : (
        <Card
          title={
            <>
              <a
                href='/enterprise'
                style={{
                  float: 'left',
                  fontSize: 22,
                  cursor: 'pointer',
                  marginRight: 20
                }}
              >
                <div className='icon icon-arrow-left'></div>
              </a>
              <h3
                className='gx-text-grey'
                style={{
                  marginLeft: 20,
                  marginBottom: 0,
                  fontSize: 20,
                  fontWeight: 400
                }}
              >
                Equipos Demo Day -{' '}
                {CohortMocksDM[sessionStorage.getItem('idCohorte')].name}
              </h3>
            </>
          }
        >
          <div className='gx-main-content'>
            <Row>
              {teams?.map((cohorte, index) => {
                const { name, description, id } = cohorte
                return (
                  <Col xl={8} lg={8} md={8} sm={24} xs={24} key={index}>
                    <Link
                      onClick={() => {
                        sessionStorage.setItem(
                          'projectId',
                          sessionStorage.getItem('idCohorte') < 4
                            ? id
                            : JSON.parse(sessionStorage.getItem('projectsIDS'))[name]
                        )
                        sessionStorage.setItem(
                          'repoName',
                          sessionStorage.getItem('idCohorte') < 4
                            ? id
                            : JSON.parse(sessionStorage.getItem('repoNames'))[name]
                        )
                      }}
                      to={{
                        pathname:
                          sessionStorage.getItem('idCohorte') < 4
                            ? './project_details'
                            : './project_details_V2'
                      }}
                    >
                      <Card
                        className='gx-package '
                        cover={
                          <div style={{ margin: '20px auto' }}>
                            <img
                              alt={`Logo de ${name}`}
                              src={`https://avatars.githubusercontent.com/t/${id}`}
                              style={{
                                width: '200px',
                                height: '200px',
                                display: 'block',
                                margin: 'auto',
                                borderRadius: 0
                              }}
                            />
                          </div>
                        }
                      >
                        <Meta
                          style={{ textAlign: 'justify' }}
                          title={name}
                          description={description}
                        />
                      </Card>
                    </Link>
                  </Col>
                )
              })}
            </Row>
          </div>
        </Card>
      )}
    </div>
  )
}
export default CorhortDetails
