import {
  UPDATE_EVIDENCE_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
  evidenceList: [
    {
      id: '',
      id_user: '',
      evidence_name: '',
      comment_desc: '',
      state: ''
    }
  ]
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_EVIDENCE_SUCCESS: {
      return {
        ...state,
        evidenceList: action.payload,
      }
    }
    default:
      return state;
  }
}