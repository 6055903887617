import {
  CREATE_LANGUAGE,
  CREATE_LANGUAGE_SUCCESS,
  FETCH_LANGUAGES_SUCCESS,
  GET_LANGUAGES,
  LANGUAGES_MODAL_DELIVERY_ADD,
  LANGUAGES_MODAL_DELIVERY_EDIT,
  FETCH_LANGUAGES_BY_ID,
  FETCH_LANGUAGES_BY_ID_SUCCESS,
  DELETE_LANGUAGES,
  DELETE_LANGUAGES_SUCCESS,
  CHANGE_LANGUAGE,
  CHANGE_ALL_LANGUAGE,
  CLEAN_MODAL,
  UPDATE_LANGUAGE,
  UPDATE_LANGUAGE_SUCCESS,
  LANGUAGES_ON_SHOW_LOADER,
  LANGUAGES_ON_HIDE_LOADER,
  LANGUAGES_ON_SHOW_MESSAGE,
  LANGUAGES_ON_HIDE_MESSAGE
} from 'constants/ActionTypes'

export const openOrCloseModalAddLanguage = () => {
  return {
    type: LANGUAGES_MODAL_DELIVERY_ADD
  }
}

export const ModalEditLanguage = () => {
  return {
    type: LANGUAGES_MODAL_DELIVERY_EDIT
  }
}

export const cleanModal = () => {
  return {
    type: CLEAN_MODAL
  }
}

export const onGetLanguages = (language) => {
  return {
    type: GET_LANGUAGES,
    language
  }
}

export const changeLanguage = (payload) => {
  return {
    type: CHANGE_LANGUAGE,
    payload: payload
  }
}
export const changeAllLanguage = (payload) => {
  return {
    type: CHANGE_ALL_LANGUAGE,
    payload: payload
  }
}

export const onGetLanguagesById = (language) => {
  return {
    type: FETCH_LANGUAGES_BY_ID,
    payload: language
  }
}

export const addLanguages = (language) => {
  return {
    type: CREATE_LANGUAGE,
    language
  }
}

export const deleteLanguageById = (language) => {
  return {
    type: DELETE_LANGUAGES,
    language
  }
}

export const addLanguagesSuccess = (language) => {
  return {
    type: CREATE_LANGUAGE_SUCCESS,
    payload: language
  }
}

export const fetchListLanguagesSuccess = (Languages) => {
  return {
    type: FETCH_LANGUAGES_SUCCESS,
    payload: Languages
  }
}

export const fetchListLanguagesById = (language) => {
  return {
    type: FETCH_LANGUAGES_BY_ID_SUCCESS,
    payload: language
  }
}

export const deleteLanguageSuccess = (language) => {
  return {
    type: DELETE_LANGUAGES_SUCCESS,
    payload: language
  }
}

export const updateLanguage = (language) => {
  return {
    type: UPDATE_LANGUAGE,
    language
  }
}

export const updateLanguageSuccess = (language) => {
  return {
    type: UPDATE_LANGUAGE_SUCCESS,
    payload: language
  }
}

export const showLoaderLanguage = () => {
  return {
    type: LANGUAGES_ON_SHOW_LOADER
  }
}

export const hideLoaderLanguage = () => {
  return {
    type: LANGUAGES_ON_HIDE_LOADER
  }
}

export const showMessageDialog = configMessage => {
  return {
    type: LANGUAGES_ON_SHOW_MESSAGE,
    payload: configMessage
  }
}

export const hideMessageDialog = () => {
  return {
    type: LANGUAGES_ON_HIDE_MESSAGE
  }
}