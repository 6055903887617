import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
    addLanguagesSuccess, deleteLanguageSuccess, fetchListLanguagesSuccess, fetchListLanguagesById, updateLanguageSuccess, hideLoaderLanguage, showMessageDialog
} from 'appRedux/actions/Languages'
import { storage } from 'firebase/firebase'
import axios from 'axios'
import { CREATE_LANGUAGE, DELETE_LANGUAGES, FETCH_LANGUAGES_BY_ID, GET_LANGUAGES, UPDATE_LANGUAGE, TYPE_MESSAGE_ERROR, TYPE_MESSAGE_SUCCESS } from "constants/ActionTypes";
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";

const listLanguages = async (language) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}languages`, config());
        return data;
    } catch (err) {
        return false
    }
}

const listFilterLanguages = async (language) => {
    const { id_cv_user } = language;
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}user_cv/${id_cv_user}/languages_info/names`, config());
        return data;
    } catch (err) {
        return false
    }
}

const createLanguageAxios = async (language) => {
    const { id_cv_user, id_language, level } = language
    try {
        const responseCreate = await axios.post(`${process.env.REACT_APP_API_URL}user_cv/${id_cv_user}/languages_info`, { id_language, level }, config())
        const id_language_info = responseCreate.data.id;
        return { id_language_info, status: 200 };
    } catch (error) {
        return false;
    }
}

const getLanguageById = async (language) => {
    const { id_cv_user } = language.payload
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}user_cv/${id_cv_user}/languages_info`, config())
        return data;
    } catch (error) {
        return false
    }
}

const deleteLanguage = async (language) => {
    const { id, id_cv_user } = language
    try {
        await axios.delete(`${process.env.REACT_APP_API_URL}user_cv/${id}/languages_info`, config())
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}user_cv/${id_cv_user}/languages_info`, config());
        return data;
    } catch (error) {
        return false
    }
}

const updateLanguageAxios = async (language) => {
    const { id, id_cv_user, fields } = language;
    try {
        await axios.patch(`${process.env.REACT_APP_API_URL}user_cv/${id}/languages_info`, fields, config())
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}user_cv/${id_cv_user}/languages_info`, config());
        return data;
    } catch (e) {
        return false
    }
}

const uploadLanguageCert = async (language) => {
    const { id_cv_user, user_name, id_language, languageFile } = language;
    try {
        var storageRef = storage.ref()
        var spaceRef = storageRef.child(`curriculum-vitae/${id_cv_user}-${user_name.replaceAll(' ', '-')}/languages/${id_language}/${languageFile.name}`)
        await spaceRef.put(languageFile)
        const publicURL = await spaceRef.getDownloadURL();
        const metada = await spaceRef.getMetadata()
        return { ...metada, publicURL }
    } catch (e) {
        return false
    }
}

const serviceAddFile = async data => {
    try {
        return axios.post(`${process.env.REACT_APP_API_URL}user_cv/${data.id_language_info}/${data.id_user}/languages_info/updateFile`, data.file, config())
    } catch (e) {
        return false;
    }
}

function* createLanguageRequest({ language }) {
    const { locale } = language;
    try {
        //Creamos el cv_language_info
        const created = yield call(createLanguageAxios, language);
        if (!created) created.isError()
        if (language.languageFile !== undefined && language.languageFile !== null) {
            //Subida de archivo
            const file = yield call(uploadLanguageCert, language)
            if (!file) file.isError()

            //Obtenermos el id del recien creado
            const id_language_info = created.id_language_info;
            if (id_language_info === undefined) id_language_info.isError()

            //Guardar archivo en bd
            const responseUpdate = yield call(serviceAddFile, { ...language, file, id_language_info })
            if (!responseUpdate) responseUpdate.isError()
        }
        //Traemos de nuevo la lista actualizada
        const data = yield call(getLanguageById, { payload: { id_cv_user: language.id_cv_user } })
        if (!data) data.isError()
        //Actualizamos
        yield put(addLanguagesSuccess(data))
        //Escondemos el mensaje de cargar
        yield put(hideLoaderLanguage())

        const message = getMessageByString(
            locale,
            'cv.languages.message.create'
        )

        yield put(
            showMessageDialog({
                alertMessage: message,
                typeMessage: TYPE_MESSAGE_SUCCESS
            })
        )
    } catch (error) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )

        yield put(
            showMessageDialog({
                alertMessage: message,
                typeMessage: TYPE_MESSAGE_ERROR
            })
        )
        yield put(hideLoaderLanguage())
    }
}

function* fetchListLanguagesRequest({ language }) {
    try {
        let fetchedLanguages;
        if (language.typeGet === 'Add') {
            fetchedLanguages = yield call(listFilterLanguages, language);
        }
        if (language.typeGet === 'Edit') {
            fetchedLanguages = yield call(listLanguages, language);
        }
        yield put(fetchListLanguagesSuccess(fetchedLanguages))

    } catch (error) {
        yield put(
            showMessageDialog({
                alertMessage: 'Error',
                typeMessage: TYPE_MESSAGE_ERROR
            })
        )
    }
}

function* getlanguageByIdRequest(language) {
    try {
        const getLanguageTD = yield call(getLanguageById, language);
        yield put(fetchListLanguagesById(getLanguageTD))
        yield put(hideLoaderLanguage())
    } catch (error) {
        yield put(
            showMessageDialog({
                alertMessage: 'Error',
                typeMessage: TYPE_MESSAGE_ERROR
            })
        )
    }
}

function* deleteLanguageRequest({ language }) {
    const { locale } = language;
    try {
        const deleteLan = yield call(deleteLanguage, language);
        if (!deleteLan) deleteLan.isError()
        yield put(deleteLanguageSuccess(deleteLan))
        const fetchedLanguages = yield call(listLanguages, language);
        if (!fetchedLanguages) fetchedLanguages.isError()
        yield put(fetchListLanguagesSuccess(fetchedLanguages))

        const message = getMessageByString(
            locale,
            'cv.languages.message.delete'
        )
        yield put(
            showMessageDialog({
                alertMessage: message,
                typeMessage: TYPE_MESSAGE_SUCCESS
            })
        )
    } catch (error) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )

        yield put(
            showMessageDialog({
                alertMessage: message,
                typeMessage: TYPE_MESSAGE_ERROR
            })
        )
    }
}

function* updateLanguageRequest({ language }) {
    const { locale } = language;
    try {

        if (language.languageFile !== null && language.languageFile !== undefined) {
            //Subida de archivo
            const file = yield call(uploadLanguageCert, language)
            if (!file) file.isError()
            //Obtenemos el id a actualizar
            const id_language_info = language.id;
            if (id_language_info === undefined) id_language_info.isError()
            //Guardar archivo en bd
            const responseUpdate = yield call(serviceAddFile, { ...language, file, id_language_info })
            if (!responseUpdate) responseUpdate.isError()
        } else {
            if (language.languageFile !== undefined) language.fields.id_lang_certf = null;
        }
        const updateLan = yield call(updateLanguageAxios, language);
        if (!updateLan) updateLan.isError()

        yield put(updateLanguageSuccess(updateLan))
        yield put(hideLoaderLanguage())
        const message = getMessageByString(
            locale,
            'cv.languages.message.update'
        )
        yield put(
            showMessageDialog({
                alertMessage: message,
                typeMessage: TYPE_MESSAGE_SUCCESS
            })
        )

    } catch (error) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(
            showMessageDialog({
                alertMessage: message,
                typeMessage: TYPE_MESSAGE_ERROR
            })
        )
    }
}

export function* fetchListLanguages() {
    yield takeEvery(
        GET_LANGUAGES,
        fetchListLanguagesRequest
    )
}

export function* createLanguage() {
    yield takeEvery(
        CREATE_LANGUAGE, createLanguageRequest
    )
}

export function* fetchLanguagesById() {
    yield takeEvery(
        FETCH_LANGUAGES_BY_ID,
        getlanguageByIdRequest
    )
}

export function* deleteLanguagesById() {
    yield takeEvery(
        DELETE_LANGUAGES, deleteLanguageRequest
    )
}

export function* updateLanguage() {
    yield takeEvery(
        UPDATE_LANGUAGE, updateLanguageRequest
    )
}

export default function* rootSaga() {
    yield all([
        fork(fetchListLanguages),
        fork(createLanguage),
        fork(fetchLanguagesById),
        fork(deleteLanguagesById),
        fork(updateLanguage)
    ])
}