import { CHANGE_TEMPLATE_TEXT, CLEAR_DATA_STATE_MESSAGE, CREATE_TEMPLATE_LANGS_SUCCESS, DATA_LANG_SELECTED, DATA_LANG_SELECTED_STATIC, DATA_PARAMS_SELECTED_DINAMIC, DATA_TEMPLATE_NEW_LANG, DATA_USERS_TO_SEND_TEMPLATE, GET_ALL_TEMPLATES_SUCCESS, SET_DATA_NEW_TEMPLATE_LANG, SHOW_HIDE_MODAL_CREATE_NEW_TEMPLATE_LANG_TEXT, TEMPLATES_ON_HIDE_MESSAGE, TEMPLATES_ON_SHOW_MESSAGE } from "../../constants/ActionTypes";


const INIT_STATE = {
    all_templates: [],
    textTemplate: '',
    alert_message: '',
    show_message_app: false,
    show_modal_update_template_txt: false,
    show_modal_update_template_lang_txt: true,
    dataNewLanguageTemplateTxt: {},
    dataTemplateLang: {},
    dataLangSelected: {},
    dataParamsSelected: {},
    dataParamsSelectedStatic: {},
    dataUsersToSend: [],
    type_message: '',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_ALL_TEMPLATES_SUCCESS: {
            return {
                ...state,
                all_templates: action.payload
            }
        }


        case CHANGE_TEMPLATE_TEXT: {
            return {
                ...state,
                textTemplate: action.payload
            }
        }

        case TEMPLATES_ON_SHOW_MESSAGE: {
            return {
                ...state,
                show_message_app: true,
                alert_message: action.payload.alertMessage,
                type_message: action.payload.typeMessage
            }
        }

        case TEMPLATES_ON_HIDE_MESSAGE: {
            return {
                ...state,
                show_message_app: false,
                alert_message: '',
                type_message: ''
            }
        }

        case SHOW_HIDE_MODAL_CREATE_NEW_TEMPLATE_LANG_TEXT:{
            return {
                ...state,
                show_modal_update_template_txt: action.payload
            }
        }

        case DATA_TEMPLATE_NEW_LANG: {
            return {
                ...state,
                dataNewLanguageTemplateTxt: action.payload
            }
        }

        case SET_DATA_NEW_TEMPLATE_LANG: {
            return {
                ...state,
                dataTemplateLang: {
                    ...state.dataTemplateLang,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }

        case CREATE_TEMPLATE_LANGS_SUCCESS: {
            return {
                ...state,
                dataTemplateLang: {}
            }
        }

        case DATA_LANG_SELECTED: {
            return {
                ...state,
                dataLangSelected: action.payload
            }
        }

        case DATA_PARAMS_SELECTED_DINAMIC:{
            return {
                ...state,
                dataParamsSelected: {
                    ...state.dataParamsSelected,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }

        case DATA_LANG_SELECTED_STATIC: {
            return{
                ...state,
                dataParamsSelectedStatic: {
                    ...state.dataParamsSelectedStatic,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }

        case DATA_USERS_TO_SEND_TEMPLATE: {
            return {
                ...state,
                dataUsersToSend: action.payload
            }
        }

        case CLEAR_DATA_STATE_MESSAGE: {
            return {
                ...state,
                dataLangSelected: {},
                dataParamsSelected: {},
                dataParamsSelectedStatic: {},
                dataUsersToSend: []
            }
        }

        default:
            return state;
    }
}