import {
    REGISTER_SPONSORSHIPS_MODAL_DELIVERY_ADD,
    REGISTER_SPONSORSHIPS_MODAL_DELIVERY_EDIT,
    REGISTER_SPONSORSHIPS_CHANGE,
    REGISTER_SPONSORSHIPS_MODAL_CLEAN,
    ADD_SPONSORSHIP,
    REGISTER_SPONSORSHIP_ON_SHOW_LOADER,
    REGISTER_SPONSORSHIP_ON_HIDE_LOADER,
    REGISTER_SPONSORSHIP_ON_SHOW_MESSAGE,
    REGISTER_SPONSORSHIP_ON_HIDE_MESSAGE,
    FETCH_SPONSORSHIPS,
    FETCH_SPONSORSHIP_SUCCESS,
    DELETE_SPONSORSHIP,
    EDIT_SPONSORSHIP,
    REGISTER_SPONSORSHIPS_CHANGE_ALL,
    FETCH_SPONSORS_SUCCESS,
    REGISTER_SPONSORSHIP_SEARCH_CITY,
    REGISTER_SPONSORSHIP_SEARCH_CITY_SUCCESS,
    GET_SPONSORS_SPONSORSHIPS
} from 'constants/ActionTypes'

export const openOrCloseModalAddRegisterSponsorships = () => {
    return {
        type: REGISTER_SPONSORSHIPS_MODAL_DELIVERY_ADD
    }
}
export const openOrCloseModalEditRegisterSponsorship = () => {
    return {
        type: REGISTER_SPONSORSHIPS_MODAL_DELIVERY_EDIT
    }
}

export const changeSponsorship = payload => {
    return {
        type: REGISTER_SPONSORSHIPS_CHANGE,
        payload: payload
    }
}

export const cleanModal = () => {
    return {
        type: REGISTER_SPONSORSHIPS_MODAL_CLEAN
    }
}
export const addSponsorship = (payload) => {
    return {
        type: ADD_SPONSORSHIP,
        payload: payload
    }
}
export const showLoaderSponsorship = () => {
    return {
        type: REGISTER_SPONSORSHIP_ON_SHOW_LOADER
    }
}

export const hideLoaderSponsorship = () => {
    return {
        type: REGISTER_SPONSORSHIP_ON_HIDE_LOADER
    }
}

export const showMessageDialog = configMessage => {
    return {
        type: REGISTER_SPONSORSHIP_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}
export const hideMessageDialog = () => {
    return {
        type: REGISTER_SPONSORSHIP_ON_HIDE_MESSAGE
    }
}

export const fetchSponsorship = (payload) => {
    return {
        type: FETCH_SPONSORSHIPS,
        payload: payload
    }
};

export const fetchSponsorshipSuccess = (payload) => {
    return {
        type: FETCH_SPONSORSHIP_SUCCESS,
        payload: payload
    }
}

export const deleteSponsorship = (payload) => {
    return {
        type: DELETE_SPONSORSHIP,
        payload: payload
    }
}

export const editSponsorship = (payload) => {
    return {
        type: EDIT_SPONSORSHIP,
        payload: payload
    }
}
export const changeSponsorshipAll = (payload) => {
    return {
        type: REGISTER_SPONSORSHIPS_CHANGE_ALL,
        payload: payload
    }
}

export const searchCity = (payload) => {
    return {
        type: REGISTER_SPONSORSHIP_SEARCH_CITY,
        payload: payload
    }
}
export const searchCitySuccess = (payload) => {
    return {
        type: REGISTER_SPONSORSHIP_SEARCH_CITY_SUCCESS,
        payload: payload
    }
}

export const onGetSponsors = () => {
    return {
        type: GET_SPONSORS_SPONSORSHIPS
    }
}

export const fetchSponsorsSuccess = (sponsorsList) => {
    return {
        type: FETCH_SPONSORS_SUCCESS,
        payload: sponsorsList
    }
};