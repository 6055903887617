import {
  GET_USER,
  FETCH_USER_SUCCESS,
  FETCH_INFO_TOKEN_CELLPHONE,
  RESPONSE_INFO_TOKEN_CELLPHONE,
  FETCH_VALIDATE_CELLPHONE,
  FETCH_VALIDATE_CELLPHONE_SUCCESS,
  FETCH_INFO_TOKEN_EMAIL,
  FETCH_VALIDATE_EMAIL,
  RESPONSE_INFO_TOKEN_EMAIL,
  FETCH_VALIDATE_EMAIL_SUCCESS,
  FETCH_INFO_USER_JWT,
  RESPONSE_INFO_JWT_TOKEN,
  USER_ON_HIDE_LOADER,
  USER_ON_SHOW_LOADER,
  USER_ON_RESEND_EMAIL,
  USER_ON_RESEND_CELL_PHONE,
  CHANGE_GENERAL_USER_STATUS,
  GET_BY_ID_USER_USER_STAGES,
  GET_BY_ID_USER_USER_STAGES_SUCCESS,
  GET_BY_ID_USER_ALL_STAGES,
  GET_BY_ID_USER_ALL_STAGES_SUCCESS
} from 'constants/ActionTypes'

export const resendCellPhoneUser = infoUser => {
  return {
    type: USER_ON_RESEND_CELL_PHONE,
    payload: infoUser
  }
}

export const resendEmailUser = infoUser => {
  return {
    type: USER_ON_RESEND_EMAIL,
    payload: infoUser
  }
}

export const showLoaderUser = () => {
  return {
    type: USER_ON_SHOW_LOADER
  }
}

export const hideLoaderUser = () => {
  return {
    type: USER_ON_HIDE_LOADER
  }
}
export const reponseInfoJwToken = infoJwt => {
  return {
    type: RESPONSE_INFO_JWT_TOKEN,
    payload: infoJwt
  }
}
export const fetchInfoUseJWT = jwt => {
  return {
    type: FETCH_INFO_USER_JWT,
    payload: jwt
  }
}

export const onGetUser = user => {
  return {
    type: GET_USER,
    user
  }
}

export const fetchUserSuccess = user => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: user
  }
}

export const responseInfoTokenCellPhone = data => {
  return {
    type: RESPONSE_INFO_TOKEN_CELLPHONE,
    payload: data
  }
}

export const fetchValidateCellPhone = data => {
  return {
    type: FETCH_VALIDATE_CELLPHONE,
    payload: data
  }
}

export const fetchValidateCellPhoneSuccess = data => {
  return {
    type: FETCH_VALIDATE_CELLPHONE_SUCCESS,
    payload: data
  }
}

export const fetchValidateEmailSuccess = data => {
  return {
    type: FETCH_VALIDATE_EMAIL_SUCCESS,
    payload: data
  }
}

export const fetchInfoTokenCellPhone = token => {
  return {
    type: FETCH_INFO_TOKEN_CELLPHONE,
    token
  }
}

export const fetchInfoTokenEmail = token => {
  return {
    type: FETCH_INFO_TOKEN_EMAIL,
    token
  }
}

export const fetchValidateEmail = data => {
  return {
    type: FETCH_VALIDATE_EMAIL,
    payload: data
  }
}

export const responseInfoTokenEmail = data => {
  return {
    type: RESPONSE_INFO_TOKEN_EMAIL,
    payload: data
  }
}

export const changeGeneralUserStatus = data => {
  return {
    type: CHANGE_GENERAL_USER_STATUS,
    payload: data
  }
}


export const getByIdUserUserStages = data => {
  return {
    type: GET_BY_ID_USER_USER_STAGES,
    payload: data
  }
}

export const getByIdUserUserStagesSuccess = data => {
  return {
    type: GET_BY_ID_USER_USER_STAGES_SUCCESS,
    payload: data
  }
}

export const getByIdUserAllStages = data => {
  return {
    type: GET_BY_ID_USER_ALL_STAGES,
    payload: data
  }
}

export const getByIdUserAllStagesSuccess = data => {
  return {
    type: GET_BY_ID_USER_ALL_STAGES_SUCCESS,
    payload: data
  }
}