export const SCHOLARSHIP_APPLICATION_ID = 2;
export const WITHDRAWAL_SCHOLARSHIP_ID = 3;
export const SCHOLARSHIP_APPLICATION_APPROVAL_ID = 4;
export const RETURN_SCHOLARSHIP_APPLICATION_ID = 5;
export const DENIAL_SCHOLARSHIP_APPLICATION_ID = 6;
export const NOTIFICATION_CALL_SOCIAL_INTERVIEW_ID = 7;
export const NOTIFICATION_POSTPONEMENT_ACCORDING_TO_PSYCHOSOCIAL_CONCEPT = 8;
export const PSYCHOSOCIAL_INTERVIEW_APPROVAL_NOTICE_ID = 9;
export const NOTIFICATION_DENIAL_APPLICATION_DUE_TO_PSYCHOSOCIAL_CONCEPT_ID = 10;
export const NOTIFICATION_CALL_TECHNICAL_INTERVIEW_ID = 11;
export const DEFERRAL_NOTIFICATION_ACCORDING_TO_TECHNICAL_CONCEPT_ID = 12;
export const NOTICE_APPROVAL_TECHNICAL_INTERVIEW_ID = 13;
export const NOTIFICATION_DENIAL_APPLICATION_DUE_TO_TECHNICAL_CONCEPT_ID = 14;
export const REACTIVATION_REQUEST_NOTIFICATION = 17;
export const REACTIVATION_REQUEST_FAVORABLE_RESPONSE = 18;
export const REACTIVATION_REQUEST_UNFAVORABLE_RESPONSE = 19;
export const NOTIFICATION_TO_FINANCING_ENTITY_APPLICATION = 20;
export const NOTIFICATION_TO_APPLICANT_ABOUT_APPLICATION_WITH_PAGE = 21;
export const NOTIFICATION_TO_APPLICANT_ABOUT_APPLICATION_WITHOUT_PAGE = 22;
export const NOTIFICATION_TO_FINANCING_ENTITY_CANCELLATION = 23;
export const NOTIFICATION_TO_APPLICANT_ABOUT_CANCELLATION_WITH_PAGE = 24;
export const NOTIFICATION_TO_APPLICANT_ABOUT_CANCELLATION_WITHOUT_PAGE = 25;
export const NOTIFICATION_CREDIT_APPLICATION_RETURN = 26
export const NOTIFICATION_CREDIT_APPLICATION_DENIAL = 27
export const NOTIFICATION_CREDIT_APPLICATION_APPROVAL = 28