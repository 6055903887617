import {
  UPDATE_EVIDENCE,
  UPDATE_EVIDENCE_SUCCESS
} from "constants/ActionTypes";

export const onUpdateEvidence = (evidenceList) => {
  return {
    type: UPDATE_EVIDENCE,
    evidenceList
  }
};

export const UpdateEvidenceSuccess = (evidenceList) => {
  return {
    type: UPDATE_EVIDENCE_SUCCESS,
    payload: evidenceList
  };
};