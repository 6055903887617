import {
    CREATE_PROSPECT_PREFERENCES,
    GET_PROSPECT_PREFERENCES,
    CREATE_PROSPECT_PREFERENCES_SUCCESS,
    FETCH_PROSPECT_PREFERENCES_SUCCESS,
    UPDATE_PROSPECT_PREFERENCES,
    UPDATE_PROSPECT_PREFERENCES_SUCCESS,
    GET_PROSPECT_PREFERENCES_BY_COURSE_ID,
    CREATE_PROSPECT_STAGE,
    GET_LAST_PROSPECT_PREFERENCES,
    GET_LAST_PROSPECT_PREFERENCES_SUCCESS,
    GET_APPLICATION_OPPORTUNITIES_BY_USER,
    GET_APPLICATION_OPPORTUNITIES_BY_USER_SUCCESS,
    GET_INFO_ABOUT_COHORT_BY_COURSE_USER,
    GET_INFO_ABOUT_COHORT_BY_COURSE_USER_SUCCESS
} from "constants/ActionTypes";

export const onCreateProspectPreferences = (prospectPreferences) => {
    return {
        type: CREATE_PROSPECT_PREFERENCES,
        prospectPreferences
    };
}

export const onGetProspectPreferences = (prospectPreferences) => {
    return {
        type: GET_PROSPECT_PREFERENCES,
        payload: prospectPreferences
    };
};

export const getProspectPreferences = (prospectPreferences) => {
    return {
        type: GET_PROSPECT_PREFERENCES_BY_COURSE_ID,
        payload: prospectPreferences
    };
};

export const onUpdateProspectPreferences = (prospectPreferences) => {
    return {
        type: UPDATE_PROSPECT_PREFERENCES,
        prospectPreferences
    }
}

export const createProspectPreferencesSuccess = (prospectPreferences) => {
    return {
        type: CREATE_PROSPECT_PREFERENCES_SUCCESS,
        payload: prospectPreferences
    }
}

export const fetchProspectPreferencesSuccess = (prospectPreferences) => {
    return {
        type: FETCH_PROSPECT_PREFERENCES_SUCCESS,
        payload: prospectPreferences
    }
};

export const updateProspectPreferencesSuccess = (prospectPreferences) => {
    return {
        type: UPDATE_PROSPECT_PREFERENCES_SUCCESS,
        payload: prospectPreferences
    }
};

export const onCreateProspectStage = (prospectPreferences) => {
    return {
        type: CREATE_PROSPECT_STAGE,
        prospectPreferences
    }
}

export const getLastProspectPreferences = (prospectPreferences) => {
    return {
        type: GET_LAST_PROSPECT_PREFERENCES,
        payload: prospectPreferences
    }
}

export const getLastProspectPreferencesSuccess = (prospectPreferences) => {
    return {
        type: GET_LAST_PROSPECT_PREFERENCES_SUCCESS,
        payload: prospectPreferences
    }
}

export const getApplicationOpportunitiesByUser = (prospectPreferences) => {
    return {
        type: GET_APPLICATION_OPPORTUNITIES_BY_USER,
        payload: prospectPreferences
    }
}

export const getApplicationOpportunitiesByUserSuccess = (prospectPreferences) => {
    return {
        type: GET_APPLICATION_OPPORTUNITIES_BY_USER_SUCCESS,
        payload: prospectPreferences
    }
}

export const getInfoCohortByCourseAndUser = (prospectPreferences) => {
    return {
        type: GET_INFO_ABOUT_COHORT_BY_COURSE_USER,
        payload: prospectPreferences
    }
}

export const getInfoCohortByCourseAndUserSuccess = (prospectPreferences) => {
    return {
        type: GET_INFO_ABOUT_COHORT_BY_COURSE_USER_SUCCESS,
        payload: prospectPreferences
    }
}