import React from 'react'
import Modal from 'antd/lib/modal/Modal'
import IntlMessages from 'util/IntlMessages'
import pdf from 'assets/resources/POLITICA DE PRIVACIDAD Agile Innova 2022.pdf'

export default function PrivacyPolicies({ open, setCloseModal }) {

  const onPrivacyPolicesClose = () => setCloseModal(false)

  return (
    <>
      <Modal
        title={<IntlMessages id="app.user.register.privacypolicies.title" />}
        visible={open}
        closable={true}
        disabled={true}
        toggle={onPrivacyPolicesClose}
        onCancel={onPrivacyPolicesClose}
        bodyStyle={{ padding: '0' }}
        className='p-0'
        style={{ padding: 0, top: 20 }}
        footer={null}
        width={1000}
        scrollableBody={true}
        maxHeight="75%"
      >
        <iframe src={pdf} title={<IntlMessages id="app.user.register.privacypolicies.title" />} style={{ width: "100%", height: "500px" }}></iframe>
      </Modal>
    </>
  )
}