import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import evidencesSagas from "./Evidences";
import userSagas from "./User"
import coursesSagas from "./Courses"
import singleCourseSagas from "./SingleCourse"
import cvUserSagas from "./CvUser"
import prospectPreferences from "./ProspectPreferences"
import country from "./Country"
import basicData from "./BasicData"
import challengesCourse from "./ChallengeCourse"
import languagesToDevelop from "./LanguagesToDevelop"
import languages from "./Languages"
import workExperience from "./WorkExperience";
import academicInfo from "./AcademicInfo"
import registerSponsors from './RegisterSponsors'
import RegisterScholarships from './RegisterScholarships'
import gridSagas from "./Grid"
import scholarshipProspect from './ScholarshipProspect'
import GitHubClassroom from './GitHubClassroom';
import supplementaryInfo from './SupplementaryInfo'
import preScholarshipEvaluation from './PreScholarshipEvaluation'
import registerCourses from './RegisterCourses'
import registerRequirements from './RegisterRequirements'
import registerCohorts from './RegisterCohorts'
import mentors from './Mentors'
import studentCohort from './StudentCohort'
import notifications from './Notifications'
import AcademicPerformanceReview from "./AcademicPerformanceReview";
import Authorization from "./Authorization"
import DemoDayModule from "./DemoDayModule"
import RegisterSponsorships from "./RegisterSponsorships";
import sponsorshipProspect from "./SponsorshipProspect";
import registerPrices from "./RegisterPrices";
import langs from "./Langs";
import Templates from "./Templates";
import Messages from "./Messages"

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    evidencesSagas(),
    userSagas(),
    coursesSagas(),
    singleCourseSagas(),
    cvUserSagas(),
    prospectPreferences(),
    languagesToDevelop(),
    country(),
    basicData(),
    challengesCourse(),
    languages(),
    workExperience(),
    academicInfo(),
    registerSponsors(),
    RegisterSponsorships(),
    RegisterScholarships(),
    gridSagas(),
    scholarshipProspect(),
    GitHubClassroom(),
    supplementaryInfo(),
    preScholarshipEvaluation(),
    registerCourses(),
    registerRequirements(),
    registerCohorts(),
    registerPrices(),
    mentors(),
    studentCohort(),
    notifications(),
    AcademicPerformanceReview(),
    Authorization(),
    DemoDayModule(),
    sponsorshipProspect(),
    langs(),
    Templates(),
    Messages()
  ]);
}
