import {
    REGISTER_REQUIREMENTS_MODAL_DELIVERY_ADD,
    REGISTER_REQUIREMENTS_MODAL_DELIVERY_EDIT,
    REGISTER_REQUIREMENTS_CHANGE,
    REGISTER_REQUIREMENTS_MODAL_CLEAN,
    REGISTER_DETAILED_REQUIREMENTS_MODAL_CLEAN,
    ADD_REQUIREMENT,
    REGISTER_REQUIREMENT_ON_SHOW_LOADER,
    REGISTER_REQUIREMENT_ON_HIDE_LOADER,
    REGISTER_REQUIREMENT_ON_SHOW_MESSAGE,
    REGISTER_REQUIREMENT_ON_HIDE_MESSAGE,
    FETCH_REQUIREMENTS,
    FETCH_REQUIREMENTS_SUCCESS,
    DELETE_REQUIREMENT,
    EDIT_REQUIREMENT,
    REGISTER_REQUIREMENTS_CHANGE_ALL,
    FILTER_REQUIREMENT,
    ADD_REQUISITE,
    SINGLE_REQUISITE_CHANGE,
} from 'constants/ActionTypes'

export const openOrCloseModalAddRegisterRequirement = () => {
    return {
        type: REGISTER_REQUIREMENTS_MODAL_DELIVERY_ADD
    }
}

export const openOrCloseModalEditRegisterRequirement = () => {
    return {
        type: REGISTER_REQUIREMENTS_MODAL_DELIVERY_EDIT
    }
}

export const changeRequirement = payload => {
    return {
        type: REGISTER_REQUIREMENTS_CHANGE,
        payload: payload
    }
}

export const cleanModal = () => {
    return {
        type: REGISTER_REQUIREMENTS_MODAL_CLEAN
    }
}

export const cleanModalResDetailment = () => {
    return {
        type: REGISTER_DETAILED_REQUIREMENTS_MODAL_CLEAN
    }
}

export const addRequirement = (payload) => {
    return {
        type: ADD_REQUIREMENT,
        payload: payload
    }
}

export const showLoaderRequirement = () => {
    return {
        type: REGISTER_REQUIREMENT_ON_SHOW_LOADER
    }
}

export const hideLoaderRequirement = () => {
    return {
        type: REGISTER_REQUIREMENT_ON_HIDE_LOADER
    }
}

export const showMessageDialog = configMessage => {
    return {
        type: REGISTER_REQUIREMENT_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}

export const hideMessageDialog = () => {
    return {
        type: REGISTER_REQUIREMENT_ON_HIDE_MESSAGE
    }
}

export const fetchRequirement = (payload) => {
    return {
        type: FETCH_REQUIREMENTS,
        payload: payload
    }
}

export const fetchRequirementSuccess = (payload) => {
    return {
        type: FETCH_REQUIREMENTS_SUCCESS,
        payload: payload
    }
}

export const deleteRequirement = (payload) => {
    return {
        type: DELETE_REQUIREMENT,
        payload: payload
    }
}

export const editRequirement = (payload) => {
    return {
        type: EDIT_REQUIREMENT,
        payload: payload
    }
}

export const changeRequirementAll = (payload) => {
    return {
        type: REGISTER_REQUIREMENTS_CHANGE_ALL,
        payload: payload
    }
}

export const filterRequirement = (payload) => {
    return {
        type: FILTER_REQUIREMENT,
        payload: payload
    }
}

export const addRequisite = (payload) => {
    return {
        type: ADD_REQUISITE,
        payload: payload
    }
}

export const changeSingleRequisite = payload => {
    return {
        type: SINGLE_REQUISITE_CHANGE,
        payload: payload
    }
}