import {
    REGISTER_PRICES_MODAL_DELIVERY_ADD,
    REGISTER_PRICES_CHANGE,
    REGISTER_PRICES_MODAL_CLEAN,
    REGISTER_PRICE_ON_SHOW_LOADER,
    REGISTER_PRICE_ON_HIDE_LOADER,
    REGISTER_PRICE_ON_SHOW_MESSAGE,
    REGISTER_PRICE_ON_HIDE_MESSAGE,
    FETCH_PRICES_SUCCESS,
    REGISTER_PRICES_CHANGE_ALL,
    REGISTER_PRICES_MODAL_DELIVERY_EDIT,
    FILTER_PRICE
} from 'constants/ActionTypes'

const INIT_STATE = {
    modal_delivery_add: false,
    modal_delivery_edit: false,
    price_single: {},
    allPrices: [],
    alert_message: '',
    show_message_app: false,
    loader: false,
    type_message: '',
    filterPrice: 'Todos los cursos'
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case REGISTER_PRICES_MODAL_DELIVERY_ADD: {
            return {
                ...state,
                modal_delivery_add: !state.modal_delivery_add
            }
        }
        case REGISTER_PRICES_MODAL_DELIVERY_EDIT: {
            return {
                ...state,
                modal_delivery_edit: !state.modal_delivery_edit
            }
        }
        case REGISTER_PRICES_MODAL_CLEAN: {
            return {
                ...state,
                price_single: {}
            }
        }
        case REGISTER_PRICE_ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case REGISTER_PRICE_ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case REGISTER_PRICE_ON_SHOW_MESSAGE: {
            return {
                ...state,
                show_message_app: true,
                alert_message: action.payload.alertMessage,
                type_message: action.payload.typeMessage
            }
        }
        case REGISTER_PRICE_ON_HIDE_MESSAGE: {
            return {
                ...state,
                alert_message: '',
                show_message_app: false,
                loader: false
            }
        }
        case FETCH_PRICES_SUCCESS: {
            return {
                ...state,
                allPrices: action.payload
            }
        }
        case REGISTER_PRICES_CHANGE_ALL: {
            return {
                ...state,
                price_single: action.payload
            }
        }
        case REGISTER_PRICES_CHANGE: {
            return {
                ...state,
                price_single: {
                    ...state.price_single,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }
        case FILTER_PRICE: {
            return {
                ...state,
                filterPrice: action.payload
            }
        }
        default:
            return state
    }
}