import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";
import { showMessageDialog, fetchCohortSuccess, hideLoaderCohort } from 'appRedux/actions/RegisterCohorts'
import { ADD_COHORT, FETCH_COHORTS, TYPE_MESSAGE_ERROR, TYPE_MESSAGE_SUCCESS, DELETE_COHORT, EDIT_COHORT } from "constants/ActionTypes"

const serviceFetchCohorts = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}course-cohort`, config());
    return data;
}

const serviceAddCohort = async payload => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}course-cohort`, payload.body, config());
    return data;
}

const serviceDeleteCohort = async payload => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}course-cohort/${payload.id}`, config());
}

const serviceEditCohort = async payload => {
    return await axios.patch(`${process.env.REACT_APP_API_URL}course-cohort/${payload.id}`, payload.body, config())
}

function* addCohortRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceAddCohort, payload)
        const allCohorts = yield call(serviceFetchCohorts)
        yield put(fetchCohortSuccess(allCohorts))
        yield put(hideLoaderCohort())
        const message = getMessageByString(
            locale,
            'courses.register.cohort.message.create'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderCohort())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* fetchCohortsRequest({ payload }) {
    const { locale } = payload;
    try {
        const allCohorts = yield call(serviceFetchCohorts)
        yield put(fetchCohortSuccess(allCohorts))
        yield put(hideLoaderCohort())
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* deleteCohortRequest({ payload }) {
    const { locale } = payload;
    try {
        const deleteCohortRes = yield call(serviceDeleteCohort, payload)
        const allCohorts = yield call(serviceFetchCohorts);
        yield put(fetchCohortSuccess(allCohorts))
        yield put(hideLoaderCohort())

        if (deleteCohortRes.status === 270) {
            const message = getMessageByString(
                locale,
                'register.courses.message.internalerror.students'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        } else {
            const message = getMessageByString(
                locale,
                'courses.register.cohort.message.delete'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
        }
    } catch (e) {
        yield put(hideLoaderCohort())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* editCohortRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceEditCohort, payload)
        const allCohorts = yield call(serviceFetchCohorts);
        yield put(fetchCohortSuccess(allCohorts))
        yield put(hideLoaderCohort())

        const message = getMessageByString(
            locale,
            'courses.register.cohort.message.update'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderCohort())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

export function* addCohort() {
    yield takeEvery(ADD_COHORT, addCohortRequest)
}

export function* fetchCohorts() {
    yield takeEvery(FETCH_COHORTS, fetchCohortsRequest)
}

export function* deleteCohort() {
    yield takeEvery(DELETE_COHORT, deleteCohortRequest)
}

export function* editCohort() {
    yield takeEvery(EDIT_COHORT, editCohortRequest)
}

export default function* rootSaga() {
    yield all([
        fork(addCohort),
        fork(fetchCohorts),
        fork(deleteCohort),
        fork(editCohort)
    ])
}