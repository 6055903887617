exports.orderArrayByProperty = (arr,property) => {
    arr.sort(function(a,b){
      if (a[`${property}`]<b[`${property}`]) return 1;
      if (a[`${property}`]>b[`${property}`]) return -1;
      return 0;
    })
    return arr;
}

exports.orderArrayByPropertyAsc = (arr,property) => {
  arr.sort(function(a,b){
    if (a[`${property}`]>b[`${property}`]) return 1;
    if (a[`${property}`]<b[`${property}`]) return -1;
    return 0;
  })
  return arr;
}