import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";
import { showMessageDialog, hideLoaderCourse, fetchCourseSuccess } from 'appRedux/actions/RegisterCourses'
import { ADD_COURSE, FETCH_COURSES, TYPE_MESSAGE_ERROR, TYPE_MESSAGE_SUCCESS, DELETE_COURSE, EDIT_COURSE } from "constants/ActionTypes"

const serviceFetchCourses = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}courses`, config());
    return data;
}

const serviceAddCourse = async payload => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}course`, payload.body, config());
    return data;
}

const serviceAddPrerequisite = async payload => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}course/pre_requisite_couses`, payload, config());
    return data;
}

const serviceDeleteCourse = async payload => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}course/${payload.id}`, config());
}

const serviceDeletePrerequisite = async payload => {
    const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}course/pre_requisite_couses`, payload, config());
    return data;
}

const serviceEditCourse = async payload => {
    const { data } = await axios.patch(`${process.env.REACT_APP_API_URL}course/${payload.id}`, payload.body, config())
    return data
}

function* addCourseRequest({ payload }) {
    const { locale, prerequisite_courses } = payload;
    try {
        const respAddCourse = yield call(serviceAddCourse, payload)
        if (prerequisite_courses.length > 0) {
            const data_prerequisite = {
                array_pre_requisites_course: prerequisite_courses.map((course) => (
                    {
                        id_course_base: respAddCourse.id,
                        id_course_pre_requisite: course
                    }
                ))
            }
            yield call(serviceAddPrerequisite, data_prerequisite)
        }
        const allCourses = yield call(serviceFetchCourses)
        yield put(fetchCourseSuccess(allCourses))
        yield put(hideLoaderCourse())
        const message = getMessageByString(
            locale,
            'courses.register.course.message.create'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderCourse())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* fetchCoursesRequest({ payload }) {
    const { locale } = payload;
    try {
        const allCourses = yield call(serviceFetchCourses)
        yield put(fetchCourseSuccess(allCourses))
        yield put(hideLoaderCourse())
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* deleteCourseRequest({ payload }) {
    const { locale } = payload;
    try {
        const deleteCourseRes = yield call(serviceDeleteCourse, payload)
        const allCourses = yield call(serviceFetchCourses);
        yield put(fetchCourseSuccess(allCourses))
        yield put(hideLoaderCourse())

        if (deleteCourseRes.status === 270) {
            const message = getMessageByString(
                locale,
                'register.courses.message.internalerror.students'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        } else {
            const message = getMessageByString(
                locale,
                'courses.register.course.message.delete'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
        }
    } catch (e) {
        yield put(hideLoaderCourse())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* editCourseRequest({ payload }) {
    const { locale, previous_prerequisite_courses, prerequisite_courses } = payload;
    try {
        let data_prerequisite = {}
        const respEditCourse = yield call(serviceEditCourse, payload)
        if (previous_prerequisite_courses.length === 0 && prerequisite_courses.length > 0) {
            const data_prerequisite_add = {
                array_pre_requisites_course: prerequisite_courses.map((course) => (
                    {
                        id_course_base: respEditCourse.id,
                        id_course_pre_requisite: course
                    }
                ))
            }
            yield call(serviceAddPrerequisite, data_prerequisite_add)
        } else if (previous_prerequisite_courses.length > 0 && prerequisite_courses.length === 0) {
            data_prerequisite = {
                data: {
                    array_pre_requisites_course_id: [previous_prerequisite_courses[0].id]
                }
            }
            yield call(serviceDeletePrerequisite, data_prerequisite)
        } else if (previous_prerequisite_courses[0].id && previous_prerequisite_courses[0].id !== prerequisite_courses[0]) {
            data_prerequisite = {
                data: {
                    array_pre_requisites_course_id: [previous_prerequisite_courses[0].id]
                }
            }
            const data_prerequisite_add = {
                array_pre_requisites_course: prerequisite_courses.map((course) => (
                    {
                        id_course_base: respEditCourse.id,
                        id_course_pre_requisite: course
                    }
                ))
            }
            yield call(serviceDeletePrerequisite, data_prerequisite)
            yield call(serviceAddPrerequisite, data_prerequisite_add)
        }
        const allCourses = yield call(serviceFetchCourses);
        yield put(fetchCourseSuccess(allCourses))
        yield put(hideLoaderCourse())

        const message = getMessageByString(
            locale,
            'courses.register.course.message.update'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderCourse())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

export function* addCourse() {
    yield takeEvery(ADD_COURSE, addCourseRequest)
}

export function* fetchCourses() {
    yield takeEvery(FETCH_COURSES, fetchCoursesRequest)
}

export function* deleteCourse() {
    yield takeEvery(DELETE_COURSE, deleteCourseRequest)
}

export function* editCourse() {
    yield takeEvery(EDIT_COURSE, editCourseRequest)
}

export default function* rootSaga() {
    yield all([
        fork(addCourse),
        fork(fetchCourses),
        fork(deleteCourse),
        fork(editCourse)
    ])
}