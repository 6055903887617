import {
    GET_USER_DATA_GITHUB_CLASSROOM,
    GET_USER_DATA_GITHUB_CLASSROOM_SUCCESS,
    GET_ISSUES_GITHUB_CLASSROOM,
    GET_ISSUES_GITHUB_CLASSROOM_SUCCESS,
    GET_REPOSITORIES_GITHUB_CLASSROOM,
    GET_REPOSITORIES_GITHUB_CLASSROOM_SUCCESS
} from "constants/ActionTypes";

export const getUserDataGithubClassroom = () => {
    return {
        type: GET_USER_DATA_GITHUB_CLASSROOM
    }
}

export const getIssuesEntregasGithubClassroom = (nameIssue) => {
    return {
        type: GET_ISSUES_GITHUB_CLASSROOM,
        payload: nameIssue
    }
}

export const getRepositoriesGithubClassroom = (numPag) => {
    return {
        type: GET_REPOSITORIES_GITHUB_CLASSROOM,
        payload: numPag
    }
}

export const getUserDataGithubClassroomSuccess = (payload) => {
    return {
        type: GET_USER_DATA_GITHUB_CLASSROOM_SUCCESS,
        payload: payload
    }
}

export const getIssuesEntregasGithubClassroomSuccess = (payload) => {
    return {
        type: GET_ISSUES_GITHUB_CLASSROOM_SUCCESS,
        payload: payload
    }
}

export const getRepositoriesGithubClassroomSuccess = (payload) => {
    return {
        type: GET_REPOSITORIES_GITHUB_CLASSROOM_SUCCESS,
        payload: payload
    }
}