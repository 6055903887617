import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { pingTokenTest } from 'appRedux/actions/Auth'
import asyncComponent from "util/asyncComponent";

const ScholarshipProspect = ({ match }) => {

  const location = useLocation();
  const dispatch = useDispatch()

  const urlAdmin = '/admin/members/prospect/scholarship-prospect'

  /*
    Cada vez que se cambia la ruta se acciona una saga que hace una peticion al backend para verificar el token, 
    Si este token es válido todo sigue igual, si no se cierra sesion. 
  */
  useEffect(() => {
    dispatch(pingTokenTest())
  }, [dispatch, location])

  return (
    <Switch>
      <Redirect exact from=
        {match.url === urlAdmin ?
          `${match.url}/:id_cv_user/:id_user`
          : `${match.url}`} to=
        {match.url === urlAdmin ?
          `${match.url}/:id_cv_user/:id_user/applicable-scholarships`
          : `${match.url}/applicable-scholarships`} />
      {/* Becas */}
      <Route path=
        {match.url === urlAdmin ?
          `${match.url}/:id_cv_user/:id_user/applicable-scholarships`
          : `${match.url}/applicable-scholarships`}
        component={asyncComponent(() => import('./ApplicableScholarships'))} />
      <Route path=
        {match.url === urlAdmin ?
          `${match.url}/:id_cv_user/:id_user/all-scholarships`
          : `${match.url}/all-scholarships`}
        component={asyncComponent(() => import('./AllScholarships'))} />
      <Route path=
        {match.url === urlAdmin ?
          `${match.url}/:id_cv_user/:id_user/applied-scholarship`
          : `${match.url}/applied-scholarship`}
        component={asyncComponent(() => import('./AppliedScholarship'))} />
    </Switch>
  )
};

export default ScholarshipProspect;