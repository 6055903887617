import {
  CREATE_LANGUAGE_SUCCESS,
  FETCH_LANGUAGES_BY_ID_SUCCESS,
  FETCH_LANGUAGES_SUCCESS,
  LANGUAGES_MODAL_DELIVERY_ADD,
  LANGUAGES_MODAL_DELIVERY_EDIT,
  DELETE_LANGUAGES_SUCCESS,
  CHANGE_LANGUAGE,
  CHANGE_ALL_LANGUAGE,
  CLEAN_MODAL,
  UPDATE_LANGUAGE_SUCCESS,
  LANGUAGES_ON_SHOW_LOADER,
  LANGUAGES_ON_HIDE_LOADER,
  LANGUAGES_ON_HIDE_MESSAGE,
  LANGUAGES_ON_SHOW_MESSAGE
} from 'constants/ActionTypes'

const INIT_STATE = {
  modal_delivery_add: false,
  modal_delivery_edit: false,
  languages: [],
  language: {
    level: ['', '', '']
  },
  allLanguages: [],
  loader: false,
  show_message_app: false,
  alert_message: '',
  type_message: '',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LANGUAGES_MODAL_DELIVERY_ADD: {
      return {
        ...state,
        modal_delivery_add: !state.modal_delivery_add,
      }
    }
    case LANGUAGES_MODAL_DELIVERY_EDIT: {
      return {
        ...state,
        modal_delivery_edit: !state.modal_delivery_edit,
      }
    }
    case CLEAN_MODAL: {
      return {
        ...state,
        language: {
          level: ['', '', '']
        },
      }
    }
    case FETCH_LANGUAGES_SUCCESS: {
      return {
        ...state,
        languages: action.payload
      }
    }
    case CHANGE_LANGUAGE: {
      return {
        ...state,
        language: {
          ...state.language,
          [action.payload.fielName]: action.payload.value
        }
      }
    }
    case CHANGE_ALL_LANGUAGE: {
      return {
        ...state,
        language: action.payload
      }
    }
    case CREATE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        allLanguages: action.payload,
        language: {
          level: ['', '', '']
        }
      }
    }
    case FETCH_LANGUAGES_BY_ID_SUCCESS: {
      return {
        ...state,
        allLanguages: action.payload
      }
    }
    case DELETE_LANGUAGES_SUCCESS: {
      return {
        ...state,
        allLanguages: action.payload
      }
    }
    case UPDATE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        allLanguages: action.payload
      }
    }
    case LANGUAGES_ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case LANGUAGES_ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case LANGUAGES_ON_SHOW_MESSAGE: {
      return {
        ...state,
        show_message_app: true,
        alert_message: action.payload.alertMessage,
        type_message: action.payload.typeMessage
      }
    }
    case LANGUAGES_ON_HIDE_MESSAGE: {
      return {
        ...state,
        alert_message: '',
        show_message_app: false,
        loader: false
      }
    }
    default:
      return state
  }
}