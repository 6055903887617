import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { config } from "util/Authorization";
import { getMessageByString } from 'util/Messages'
import {
    showMessageDialogSupplementaryInfo,
    showLoaderSupplementaryInfo,
    fetchSupplementaryInfoSuccess
} from 'appRedux/actions/SupplementaryInfo'
import {
    CREATE_SUPPLEMENTARY_INFO,
    TYPE_MESSAGE_ERROR,
    TYPE_MESSAGE_SUCCESS,
    FETCH_SUPPLEMENTARY_INFO,
    UPDATE_SUPPLEMENTARY_INFO
} from 'constants/ActionTypes'

//Peticiones generales para los componentes de AcademicInfo
const axiosCreateSupplementaryInfo = async info => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}user_cv/supplementary_info`, info, config());
    return data;
}

const axiosFetchSupplementaryInfo = async id_cv_user => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}user_cv/${id_cv_user}/supplementary_info`, config())
    return data;
}
const axiosUpdateSupplementaryInfo = async info => {
    const { data } = await axios.patch(`${process.env.REACT_APP_API_URL}user_cv/${info.id}/supplementary_info`, info, config());
    return data;
}

function* createSupplementaryInfoRequest({ payload }) {
    const { locale, info } = payload;
    try {
        yield put(showLoaderSupplementaryInfo());
        yield call(axiosCreateSupplementaryInfo, info);
        const allSupplementaryInfo = yield call(axiosFetchSupplementaryInfo, info.id_cv_user)
        yield put(fetchSupplementaryInfoSuccess(allSupplementaryInfo))
        const messageSuccess = getMessageByString(locale, "supplementary.addsuccess");
        return yield put(showMessageDialogSupplementaryInfo({ alertMessage: messageSuccess, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (err) {
        const messageError = getMessageByString(locale, "app.error.server");
        return yield put(showMessageDialogSupplementaryInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
    }
}

function* fetchSupplementaryInfoRequest({ payload }) {
    const { locale, id_cv_user } = payload;
    try {
        const allSupplementaryInfo = yield call(axiosFetchSupplementaryInfo, id_cv_user)
        yield put(fetchSupplementaryInfoSuccess(allSupplementaryInfo))
    } catch (err) {
        const messageError = getMessageByString(locale, "app.error.server");
        return yield put(showMessageDialogSupplementaryInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
    }
}

function* updateSupplementaryInfoRequest({ payload }) {
    const { locale, info } = payload
    try {
        yield put(showLoaderSupplementaryInfo());
        yield call(axiosUpdateSupplementaryInfo, info);
        const allSupplementaryInfo = yield call(axiosFetchSupplementaryInfo, info.id_cv_user)
        yield put(fetchSupplementaryInfoSuccess(allSupplementaryInfo))
        const messageSuccess = getMessageByString(locale, "supplementary.editsuccess");
        return yield put(showMessageDialogSupplementaryInfo({ alertMessage: messageSuccess, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (err) {
        const messageError = getMessageByString(locale, "app.error.server");
        return yield put(showMessageDialogSupplementaryInfo({ alertMessage: messageError, typeMessage: TYPE_MESSAGE_ERROR }))
    }
}

export function* createSupplementaryInfo() {
    yield takeEvery(CREATE_SUPPLEMENTARY_INFO, createSupplementaryInfoRequest)
}

export function* fetchSupplementaryInfo() {
    yield takeEvery(FETCH_SUPPLEMENTARY_INFO, fetchSupplementaryInfoRequest)
}

export function* updateSupplementaryInfo() {
    yield takeEvery(UPDATE_SUPPLEMENTARY_INFO, updateSupplementaryInfoRequest)
}

export default function* rootSaga() {
    yield all([
        fork(createSupplementaryInfo),
        fork(fetchSupplementaryInfo),
        fork(updateSupplementaryInfo)
    ])
}