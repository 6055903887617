import IntlMessages from "util/IntlMessages";

const Pending = () => {
    return (
        <div className="gx-app-login-content">
            <h1>
                <IntlMessages id='app.userAuth.inactivegeneral.title.pending' />
            </h1>
        </div>)
}

export default Pending;