import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { config } from "util/Authorization";
import {
  CREATE_PROSPECT_PREFERENCES,
  GET_PROSPECT_PREFERENCES,
  UPDATE_PROSPECT_PREFERENCES,
  GET_PROSPECT_PREFERENCES_BY_COURSE_ID,
  CREATE_PROSPECT_STAGE,
  GET_LAST_PROSPECT_PREFERENCES,
  GET_APPLICATION_OPPORTUNITIES_BY_USER,
  GET_INFO_ABOUT_COHORT_BY_COURSE_USER
} from 'constants/ActionTypes';
import {
  // createProspectPreferencesSuccess,
  fetchProspectPreferencesSuccess,
  updateProspectPreferencesSuccess,
  getLastProspectPreferencesSuccess,
  getApplicationOpportunitiesByUserSuccess,
  getInfoCohortByCourseAndUserSuccess,
  onGetProspectPreferences
} from 'appRedux/actions/ProspectPreferences';
import { fetchError } from "appRedux/actions/Common";
import { courseListStages } from "constants/CourseListStages"

const createProspectPreferences = async (prospectPreferences) => {
  const { id_course, id_cv_user, regis_date, state, id_user, id_stage } = prospectPreferences
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}prospect/preferences`, { id_course, id_cv_user, regis_date, state, id_stage }, config())
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}prospect/preferences/${id_user}`, config());
    return data;
  } catch (error) {
    return error;
  }
}

const serviceCreateProspectStage = async (prospectPreferences) => {
  const { id_prospect_preference, id_stage, observation } = prospectPreferences;
  await axios.post(`${process.env.REACT_APP_API_URL}prospect/stages`, { id_prospect_preference, id_stage, observation }, config());
}

// const getProspectPreferences = async (prospectPreferences) => {
//   try {
//     const { data } = await axios.get(`${process.env.REACT_APP_API_URL}prospect/preferences/${prospectPreferences}`, config());
//     return data;
//   } catch (err) {
//     return err;
//   }
// }

const serviceGetProspectPreferenceEnabled = async (prospectPreferences) => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}prospect/preferences/${prospectPreferences.id_cv_user}/bystages`, { stages: prospectPreferences.stages }, config());
  return data;
}

const getProspectPreferencesByCourseId = async (prospectPreferences) => {
  const { id_course, id_cv_user, id_user } = prospectPreferences
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}prospect/preferences/${id_course}/${id_cv_user}/${id_user}`, config());
    return data;
  } catch (err) {
    return err;
  }
}

const getOfficialCourses = async (prospectPreferences) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}course/official/${prospectPreferences.id_cv_user}`, config());
  return data;
}

const updateProspectPreferences = async (prospectPreferences) => {
  const { id_course, id_cv_user, id, state, id_user } = prospectPreferences
  try {
    await axios.put(`${process.env.REACT_APP_API_URL}prospect/preferences/${id}`, { state }, config());
    if (state === "en_revision") {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}prospect/preferences/${id_user}`, config());
      return data;
    } else {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}prospect/preferences/${id_course}/${id_cv_user}/${id_user}`, config());
      return data;
    }
  } catch (error) {
    return error
  }
}

const serviceGetLastProspectPreferences = async (id_cv_user) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}prospect/preferences/${id_cv_user}/last`, config());
  return data;
}

const serviceGetApplicationOpportunitiesByUser = async (id_cv_user) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}prospect/preference/${id_cv_user}/applications_opportunities`, config());
  return data.opportunities;
}

const serviceGetInfoCohortByCourseAndUser = async ({ id_cv_user, id_course }) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}prospect/preference/${id_cv_user}/${id_course}/info_about_cohort`);
  return data;
}

function* createProspectPreferencesRequest({ prospectPreferences }) {
  const { id_cv_user, postulate } = prospectPreferences;
  try {

    // const createProspectP = 
    yield call(createProspectPreferences, prospectPreferences);
    if (postulate) {
      //Cuando se realiza una postulación a un curso
      yield put(onGetProspectPreferences({ ...courseListStages, id_cv_user }))
    }
    //yield put(createProspectPreferencesSuccess(createProspectP))
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchProspectPreferencesRequest(data) {
  let { id_cv_user, stagesCompleted, stagesInProgress } = data.payload;
  try {
    const fetchedProspectPreferences = yield call(serviceGetProspectPreferenceEnabled, { id_cv_user, stages: stagesInProgress });
    const fetchedProspectPreferencesCompleted = yield call(serviceGetProspectPreferenceEnabled, { id_cv_user, stages: stagesCompleted });
    const fetchedOfficialCourses = yield call(getOfficialCourses, { id_cv_user })
    const data = {
      inProgress: fetchedProspectPreferences,
      completed: fetchedProspectPreferencesCompleted,
      offered: fetchedOfficialCourses
    }
    yield put(fetchProspectPreferencesSuccess(data))
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getProspectPreferencesRequest(data) {
  let prospectPreferences = data.payload;
  try {
    const getProspectPreferences = yield call(getProspectPreferencesByCourseId, prospectPreferences);
    yield put(fetchProspectPreferencesSuccess(getProspectPreferences));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* updateProspectPreferencesRequest({ prospectPreferences }) {
  try {
    const updateProspectP = yield call(updateProspectPreferences, prospectPreferences);
    yield put(updateProspectPreferencesSuccess(updateProspectP))
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* createProspectStageRequest({ prospectPreferences }) {
  try {
    yield call(serviceCreateProspectStage, prospectPreferences)
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getLastProspectPreferencesRequest({ payload }) {
  const { id_cv_user } = payload;
  try {
    const prospect = yield call(serviceGetLastProspectPreferences, id_cv_user);
    yield put(getLastProspectPreferencesSuccess(prospect));
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* getApplicationOpportunitiesByUserRequest({ payload }) {
  const { id_cv_user } = payload;
  try {
    const numberOpportunities = yield call(serviceGetApplicationOpportunitiesByUser, id_cv_user);
    yield put(getApplicationOpportunitiesByUserSuccess(numberOpportunities));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getInfoCohortByCourseAndUserRequest({ payload }) {
  try {
    const infoAboutCohort = yield call(serviceGetInfoCohortByCourseAndUser, payload);
    yield put(getInfoCohortByCourseAndUserSuccess(infoAboutCohort));
  } catch (error) {
    yield put(fetchError(error))
  }
}

export function* createPP() {
  yield takeEvery(CREATE_PROSPECT_PREFERENCES, createProspectPreferencesRequest);
}

export function* fetchProspectPreferences() {
  yield takeEvery(GET_PROSPECT_PREFERENCES, fetchProspectPreferencesRequest);
}

export function* getPPByCourseID() {
  yield takeEvery(GET_PROSPECT_PREFERENCES_BY_COURSE_ID, getProspectPreferencesRequest);
}

export function* updatePP() {
  yield takeEvery(UPDATE_PROSPECT_PREFERENCES, updateProspectPreferencesRequest);
}

export function* createProspectStage() {
  yield takeEvery(CREATE_PROSPECT_STAGE, createProspectStageRequest)
}

export function* getLastProspectPreferences() {
  yield takeEvery(GET_LAST_PROSPECT_PREFERENCES, getLastProspectPreferencesRequest)
}

export function* getApplicationOpportunitiesByUser() {
  yield takeEvery(GET_APPLICATION_OPPORTUNITIES_BY_USER, getApplicationOpportunitiesByUserRequest)
}

export function* getInfoCohortByCourseAndUser() {
  yield takeEvery(GET_INFO_ABOUT_COHORT_BY_COURSE_USER, getInfoCohortByCourseAndUserRequest)
}

export default function* rootSaga() {
  yield all([
    fork(fetchProspectPreferences),
    fork(createPP),
    fork(updatePP),
    fork(getPPByCourseID),
    fork(createProspectStage),
    fork(getLastProspectPreferences),
    fork(getApplicationOpportunitiesByUser),
    fork(getInfoCohortByCourseAndUser)
  ]);
}