import {
    GET_PROJECT_DEMODAY,
    GET_PROJECT_DEMODAY_SUCCESS,
    GET_TEAMS_DEMODAY,
    GET_TEAMS_DEMODAY_SUCCESS,
    SHOW_LOADER_MILESTONE,
    HIDE_LOADER_MILESTONE,
    GET_PROJECT_CONTRIBUTORS_DEMODAY,
    GET_PROJECT_CONTRIBUTORS_DEMODAY_SUCCESS,
    GET_PROJECT_MILESTONE_DEMODAY,
    GET_PROJECT_MILESTONE_DEMODAY_SUCCESS
} from "constants/ActionTypes";

// Obtener Teams
export const getTeamsDemoDay = payload => {
    return {
        type: GET_TEAMS_DEMODAY,
        payload: payload
    }
}

export const getTeamsDemoDaySuccess = payload => {
    return {
        type: GET_TEAMS_DEMODAY_SUCCESS,
        payload: payload
    }
}

// Obtener project de cada Team
export const getProjectDemoDay = payload => {
    return {
        type: GET_PROJECT_DEMODAY,
        payload: payload
    }
}

export const getProjectDemoDaySuccess = payload => {
    return {
        type: GET_PROJECT_DEMODAY_SUCCESS,
        payload: payload
    }
}

//Obtener los colaboradores del proyecto
export const getProjectContributors = payload => {
    return {
        type: GET_PROJECT_CONTRIBUTORS_DEMODAY,
        payload: payload
    }
}

export const getProjectContributorsSuccess = payload => {
    return {
        type: GET_PROJECT_CONTRIBUTORS_DEMODAY_SUCCESS,
        payload: payload
    }
}

//Obtener los Milestone de un proyecto
export const getProjectMilestone = payload => {
    return {
        type: GET_PROJECT_MILESTONE_DEMODAY,
        payload: payload
    }
}

export const getProjectMilestoneSuccess = payload => {
    return {
        type: GET_PROJECT_MILESTONE_DEMODAY_SUCCESS,
        payload: payload
    }
}

//Loaders 
export const showLoaderProjectMilestone = () => {
    return {
        type: SHOW_LOADER_MILESTONE
    }
}

export const hideLoaderProjectMilestone = () => {
    return {
        type: HIDE_LOADER_MILESTONE
    }
}