import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout } from 'antd'
import {
  /*switchLanguage,*/ toggleCollapsedSideNav
} from 'appRedux/actions/Setting'
// import CustomScrollbars from "util/CustomScrollbars";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from 'constants/ThemeSetting'
import Sponsors from './Sponsors'
// import languageData from "./languageData";

const { Header } = Layout

const Topbar = () => {
  const { navStyle } = useSelector(({ settings }) => settings)
  const { navCollapsed, width } = useSelector(({ common }) => common)

  // const { searchText, setSearchText } = useState('');

  const dispatch = useDispatch()

  // const updateSearchChatUser = (evt) => {
  //   setSearchText(evt.target.value);
  // };

  // const languageMenu = () => (
  //   <CustomScrollbars className="gx-popover-lang-scroll">
  //     <ul className="gx-sub-popover">
  //       {languageData.map(language =>
  //         <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
  //           dispatch(switchLanguage(language))
  //         }>
  //           <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
  //           <span className="gx-language-text">{language.name}</span>
  //         </li>
  //       )}
  //     </ul>
  //   </CustomScrollbars>);

  return (
    <Header>
      {(navStyle === NAV_STYLE_DRAWER ||
        ((navStyle === NAV_STYLE_FIXED ||
          navStyle === NAV_STYLE_MINI_SIDEBAR) &&
          width < TAB_SIZE)) && (
        //Cuando sea NAV_STYLE_FIXED y la pantalla sea menor a 992
        <div className='gx-linebar gx-mr-3'>
          <i
            className='gx-icon-btn icon icon-menu'
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed))
            }}
          />
        </div>
      )}
      <Sponsors />
      {/* Cuando se le de al logo se dirija al inicio respectivo  */}
      {/* <Link
        to={user.admin ? '/main/dashboard/admin' : '/main/dashboard/geek'}
        className='gx-d-block gx-d-lg-none gx-pointer'
      >
        <img
          alt='Logo de Agile Innova'
          src={require('assets/images/w-logo.png')}
        />
      </Link>
      <ul className='gx-header-notifications gx-ml-auto'>
        <li className='gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none'></li>
        {width >= TAB_SIZE ? null : <Auxiliary></Auxiliary>}
        <li className='gx-language'>
          <Popover
            overlayClassName='gx-popover-horizantal'
            placement='bottomRight'
            content={languageMenu()}
            trigger='click'
          >
            <span className='gx-pointer gx-flex-row gx-align-items-center'>
              <i className={`flag flag-24 flag-${locale.icon}`} />
              <span className='gx-pl-2 gx-language-name'>{locale.name}</span>
              <i className='icon icon-chevron-down gx-pl-2' />
            </span>
          </Popover>
        </li>
        //Se quita, ya que hace que cuando se ajuste a responsive aparezca el
        icono de perfil anterior
        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className='gx-user-nav'>
              <UserInfo />
            </li>
          </Auxiliary>
        )}
      </ul> */}
    </Header>
  )
}

export default Topbar
