import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { showMessageDialogApp, hideLoaderApp } from 'appRedux/actions/App'
import { showMessageDialogAppV1 } from 'appRedux/actions/AppMessage'
import { getMessageByString } from 'util/Messages'
import { PP_EXPELLED } from 'constants/MainStages'
import {
  FETCH_INFO_TOKEN_CELLPHONE,
  GET_USER,
  TYPE_MESSAGE_ERROR,
  TYPE_MESSAGE_SUCCESS,
  FETCH_VALIDATE_CELLPHONE,
  FETCH_INFO_TOKEN_EMAIL,
  FETCH_VALIDATE_EMAIL,
  FETCH_INFO_USER_JWT,
  USER_ON_RESEND_EMAIL,
  USER_ON_RESEND_CELL_PHONE,
  CHANGE_GENERAL_USER_STATUS,
  GET_BY_ID_USER_USER_STAGES,
  GET_BY_ID_USER_ALL_STAGES
} from 'constants/ActionTypes'
import {
  fetchUserSuccess,
  responseInfoTokenCellPhone,
  fetchValidateCellPhoneSuccess,
  responseInfoTokenEmail,
  fetchValidateEmailSuccess,
  reponseInfoJwToken,
  hideLoaderUser,
  getByIdUserUserStagesSuccess,
  getByIdUserAllStagesSuccess
} from 'appRedux/actions/User'
import { getDataGrid } from 'appRedux/actions/Grid'
import { sendEmail } from 'appRedux/actions/Notifications'
import { fetchError } from 'appRedux/actions/Common'
import { config } from 'util/Authorization'

const getUser = async ({ email }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}user/${email}`,
      config()
    )
    return data
  } catch (err) {
    return err
  }
}

const serviceResendEmailUser = async ({ email, id }) =>
  await axios.post(
    `${process.env.REACT_APP_API_URL}user/resend-validate-email`,
    { email, id }
  )

const serviceResendCellPhoneUser = async ({ email, id }) =>
  await axios.post(
    `${process.env.REACT_APP_API_URL}user/resend-validate-cellphone`,
    { email, id }
  )

const getTokenCellPhone = async token =>
  await axios.get(
    `${process.env.REACT_APP_API_URL}user/token-cellphone/${token}`
  )

const getTokenEmail = async token =>
  await axios.get(`${process.env.REACT_APP_API_URL}user/token-email/${token}`)

const serviceValidateCellPhone = async ({ token, email, mobile_phone }) =>
  await axios.post(`${process.env.REACT_APP_API_URL}user/validate-cellphone`, {
    token: token,
    email: email,
    mobile_phone: mobile_phone
  })

const serviceValidateEmail = async ({ token, email, mobile_phone }) =>
  await axios.post(`${process.env.REACT_APP_API_URL}user/validate-email`, {
    token: token,
    email: email,
    mobile_phone: mobile_phone
  })

const serviceInfoUserJwt = async jwt =>
  await axios.post(`${process.env.REACT_APP_API_URL}user/info-user-jwt`, {
    jwt: jwt
  })

const serviceChangeGeneralUserStatus = async payload =>
  await axios.post(`${process.env.REACT_APP_API_URL}user_stages`, payload)

const serviceGetByIdUserUserStages = async id_user =>
  await axios.get(`${process.env.REACT_APP_API_URL}user_stages/${id_user}`)

const serviceGetByIdUserAllStages = async id_user =>
  await axios.get(`${process.env.REACT_APP_API_URL}all_stages/${id_user}`)

//Expulsar a una persona de la cohorte cuando no firme acta
const serviceProspectStages = async (id_prospect) => {
  const preference = {
    id_prospect_preference: id_prospect,
    id_stage: PP_EXPELLED
  }
  await axios.post(
    `${process.env.REACT_APP_API_URL}prospect/stages`,
    preference,
    config()
  )
}

function* fetchUserRequest({ user }) {
  try {
    const fetchedUser = yield call(getUser, user)
    yield put(fetchUserSuccess(fetchedUser))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* fetchInfoTokenEmailRequest({ token }) {
  try {
    const { data } = yield call(getTokenEmail, token)
    yield put(responseInfoTokenEmail(data))
    yield put(hideLoaderApp())
  } catch (error) {
    yield put(
      showMessageDialogApp({
        alertMessageApp: 'Se presento un error',
        typeMessageApp: TYPE_MESSAGE_ERROR,
        timeShowMessageApp: 2
      })
    )
  }
}

function* resendCellPhoneUserRequest({ payload }) {
  const { locale } = payload
  try {
    const { data } = yield call(serviceResendCellPhoneUser, payload)
    if (data.response) {
      yield put(
        showMessageDialogAppV1({
          alertMessageAppV1: getMessageByString(
            locale,
            'app.user.register.resend.cellphone.success'
          ),
          typeMessageAppV1: TYPE_MESSAGE_SUCCESS,
          timeShowMessageAppV1: 5
        })
      )
      yield put(fetchUserSuccess(data.user))
    } else {
      yield put(
        showMessageDialogAppV1({
          alertMessageAppV1: getMessageByString(
            locale,
            data.code === 1
              ? 'app.user.register.resend.cellphone.error.verified.account'
              : data.code === 2
                ? 'app.user.register.resend.error.not.exists.account'
                : 'app.error.server'
          ),
          typeMessageAppV1: TYPE_MESSAGE_ERROR,
          timeShowMessageAppV1: 2
        })
      )
    }
    yield put(hideLoaderUser())
  } catch (e) {
    yield put(
      showMessageDialogAppV1({
        alertMessageAppV1: getMessageByString(locale, 'app.error.server'),
        typeMessageAppV1: TYPE_MESSAGE_ERROR,
        timeShowMessageAppV1: 2
      })
    )
  }
}

function* resendUserEmailRequest({ payload }) {
  const { locale } = payload
  try {
    const { data } = yield call(serviceResendEmailUser, payload)
    if (data.response) {
      yield put(
        showMessageDialogAppV1({
          alertMessageAppV1: getMessageByString(
            locale,
            'app.user.register.resend.email.success'
          ),
          typeMessageAppV1: TYPE_MESSAGE_SUCCESS,
          timeShowMessageAppV1: 5
        })
      )
      yield put(fetchUserSuccess(data.user))
    } else {
      yield put(
        showMessageDialogAppV1({
          alertMessageAppV1: getMessageByString(
            locale,
            data.code === 1
              ? 'app.user.register.resend.email.error.verified.account'
              : data.code === 2
                ? 'app.user.register.resend.error.not.exists.account'
                : 'app.error.server'
          ),
          typeMessageAppV1: TYPE_MESSAGE_ERROR,
          timeShowMessageAppV1: 2
        })
      )
    }
    yield put(hideLoaderUser())
  } catch (e) {
    yield put(
      showMessageDialogAppV1({
        alertMessageAppV1: getMessageByString(locale, 'app.error.server'),
        typeMessageAppV1: TYPE_MESSAGE_ERROR,
        timeShowMessageAppV1: 2
      })
    )
  }
}

function* fetchInfoUserJwtRequest({ payload }) {
  try {
    const { data } = yield call(serviceInfoUserJwt, payload)
    yield put(reponseInfoJwToken(data))
    yield put(fetchUserSuccess(data))
    yield put(hideLoaderApp())
  } catch (e) {
    yield put(
      showMessageDialogApp({
        alertMessageApp: 'Se presento un error',
        typeMessageApp: TYPE_MESSAGE_ERROR
      })
    )
  }
}

function* fetchValidateEmailRequest({ payload }) {
  try {
    const { data } = yield call(serviceValidateEmail, payload)
    yield put(hideLoaderApp())
    if (data.response_validate_email) {
      yield put(fetchValidateEmailSuccess(data))
      yield put(
        showMessageDialogApp({
          alertMessageApp: data.mensaje,
          typeMessageApp: TYPE_MESSAGE_SUCCESS
        })
      )
    } else {
      yield put(
        showMessageDialogApp({
          alertMessageApp: data.mensaje,
          typeMessageApp: TYPE_MESSAGE_ERROR
        })
      )
    }
  } catch (error) {
    yield put(
      showMessageDialogApp({
        alertMessageApp: 'Se presento un error',
        typeMessageApp: TYPE_MESSAGE_ERROR
      })
    )
  }
}

function* fetchInfoTokenCellPhoneRequest({ token }) {
  try {
    const { data } = yield call(getTokenCellPhone, token)
    yield put(responseInfoTokenCellPhone(data))
    yield put(hideLoaderApp())
  } catch (error) {
    yield put(
      showMessageDialogApp({
        alertMessageApp: 'Se presento un error',
        typeMessageApp: TYPE_MESSAGE_ERROR
      })
    )
  }
}

function* fetchValidateCellPhoneRequest({ payload }) {
  try {
    const { data } = yield call(serviceValidateCellPhone, payload)
    yield put(hideLoaderApp())
    if (data.response_validate_cell_phone) {
      yield put(fetchValidateCellPhoneSuccess(data))
      yield put(
        showMessageDialogApp({
          alertMessageApp: data.mensaje,
          typeMessageApp: TYPE_MESSAGE_SUCCESS
        })
      )
    } else {
      yield put(
        showMessageDialogApp({
          alertMessageApp: data.mensaje,
          typeMessageApp: TYPE_MESSAGE_ERROR
        })
      )
    }
  } catch (error) {
    yield put(
      showMessageDialogApp({
        alertMessageApp: 'Se presento un error',
        typeMessageApp: TYPE_MESSAGE_ERROR
      })
    )
  }
}

function* changeGeneralUserStatusRequest({ payload }) {
  const { locale } = payload
  try {
    yield call(serviceChangeGeneralUserStatus, payload)
    //Refrescar los datos de la Grid
    yield put(
      getDataGrid({
        pagination: {
          current: 1,
          pageSize: 10
        },
        sortOrder: 'descend',
        sortField: 'id_user',
        urlGrid: `${process.env.REACT_APP_API_URL}inactive_users_filters`,
      })
    )
    if (payload.data) yield put(sendEmail(payload))
    if (payload.admin) {
      const response = yield call(serviceGetByIdUserUserStages, payload.id_user)
      yield put(getByIdUserUserStagesSuccess(response.data))
    }
    if (payload.id_prospect) {
      yield call(serviceProspectStages, payload.id_prospect)
    }
    yield put(
      showMessageDialogAppV1({
        alertMessageAppV1: getMessageByString(
          locale,
          'users_stages_grid.actions.change_status.change_success'
        ),
        typeMessageAppV1: TYPE_MESSAGE_SUCCESS,
        timeShowMessageAppV1: 2
      })
    )
    yield put(hideLoaderUser())
  } catch (err) {
    yield put(
      showMessageDialogAppV1({
        alertMessageAppV1: getMessageByString(locale, 'app.error.server'),
        typeMessageAppV1: TYPE_MESSAGE_ERROR,
        timeShowMessageAppV1: 4
      })
    )
    yield put(hideLoaderUser())
  }
}

//Voy a dejar de utilizar este request, realizarle debido control de errores
function* getByIdUserUserStagesRequest({ payload }) {
  try {
    const response = yield call(serviceGetByIdUserUserStages, payload.id_user)
    yield put(getByIdUserUserStagesSuccess(response.data))
  } catch (err) {
    return false
  }
}

function* getByIdUserAllStagesRequest({ payload }) {
  try {
    const response = yield call(serviceGetByIdUserAllStages, payload.id_user)
    yield put(getByIdUserAllStagesSuccess(response.data))
  } catch (err) {
    return false
  }
}

export function* fetchUser() {
  yield takeEvery(GET_USER, fetchUserRequest)
}

export function* fetchInfoTokenCellPhone() {
  yield takeEvery(FETCH_INFO_TOKEN_CELLPHONE, fetchInfoTokenCellPhoneRequest)
}

export function* fetchValidateCellPhone() {
  yield takeEvery(FETCH_VALIDATE_CELLPHONE, fetchValidateCellPhoneRequest)
}

export function* fetchInfoTokenEmail() {
  yield takeEvery(FETCH_INFO_TOKEN_EMAIL, fetchInfoTokenEmailRequest)
}

export function* fetchValidateEmail() {
  yield takeEvery(FETCH_VALIDATE_EMAIL, fetchValidateEmailRequest)
}

export function* fetchInfoUserJwt() {
  yield takeEvery(FETCH_INFO_USER_JWT, fetchInfoUserJwtRequest)
}

export function* resendUserEmail() {
  yield takeEvery(USER_ON_RESEND_EMAIL, resendUserEmailRequest)
}

export function* resendCellPhoneUser() {
  yield takeEvery(USER_ON_RESEND_CELL_PHONE, resendCellPhoneUserRequest)
}

export function* changeGeneralUserStatus() {
  yield takeEvery(CHANGE_GENERAL_USER_STATUS, changeGeneralUserStatusRequest)
}

export function* getByIdUserUserStages() {
  yield takeEvery(GET_BY_ID_USER_USER_STAGES, getByIdUserUserStagesRequest)
}

export function* getByIdUserAllStages() {
  yield takeEvery(GET_BY_ID_USER_ALL_STAGES, getByIdUserAllStagesRequest)
}

export default function* rootSaga() {
  yield all([
    fork(fetchUser),
    fork(fetchInfoTokenCellPhone),
    fork(fetchValidateCellPhone),
    fork(fetchInfoTokenEmail),
    fork(fetchValidateEmail),
    fork(fetchInfoUserJwt),
    fork(resendUserEmail),
    fork(resendCellPhoneUser),
    fork(changeGeneralUserStatus),
    fork(getByIdUserUserStages),
    fork(getByIdUserAllStages)
  ])
}