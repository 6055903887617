import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Menu } from 'antd'
import { onGetProspectPreferences } from 'appRedux/actions/ProspectPreferences'
import { onGetCourses } from "appRedux/actions/Courses";
import CustomScrollbars from 'util/CustomScrollbars'
import { NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR, THEME_TYPE_LITE } from 'constants/ThemeSetting'
import { courseListStages } from "constants/CourseListStages"
import SidebarLogo from './SidebarLogo'
import UserProfile from './UserProfile'
import SidebarAdmin from './SidebarAdmin'
import SidebarGeek from './SidebarGeek';

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {

  const dispatch = useDispatch();

  let { navStyle, themeType } = useSelector(({ settings }) => settings)
  let { pathname } = useSelector(({ common }) => common)
  const { user } = useSelector(({ auth }) => auth)

  const id_user = user.id;
  const id_cv_user = user.cv.id
  const selectedKeys = pathname.substr(1)
  const defaultOpenKeys = selectedKeys.split('/')[1]

  useEffect(() => {
    const data = {
      ...courseListStages,
      id_cv_user
    }

    dispatch(onGetProspectPreferences(data));
    dispatch(onGetCourses());
  }, [dispatch, id_cv_user, id_user])

  const getNoHeaderClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return 'gx-no-header-notifications'
    }
    return ''
  }

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className='gx-sidebar-content'>
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          {/* <AppsNavigation /> */}
        </div>
        <CustomScrollbars className='gx-layout-sider-scrollbar'>
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode='inline'
          >
            {user.admin ? <SidebarAdmin /> : <SidebarGeek />}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  )
}

SidebarContent.propTypes = {}
export default SidebarContent