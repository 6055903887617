import { message } from 'antd'
import { TYPE_MESSAGE_SUCCESS, TYPE_MESSAGE_ERROR, TYPE_MESSAGE_INFO, TYPE_MESSAGE_WARNING } from 'constants/ActionTypes'
import AppLocale from './../lngProvider'

if (typeof String.prototype.replaceAll == "undefined") {
    // eslint-disable-next-line no-extend-native
    String.prototype.replaceAll = function (match, replace) {
        return this.replace(new RegExp(match, 'g'), () => replace);
    }
}

export const getMessageByString = (lang = { locale: "en" }, code_message = "", message = "") => {
    let locale
    if ((locale = AppLocale[lang.locale]) !== undefined) return (locale.messages[code_message] ? locale.messages[code_message] : message)
    else return message
}

export const getMessageValidate = (lang = { locale: "en" }, field = "", code_validate = "valid.field.required") => {
    let locale
    if ((locale = AppLocale[lang.locale]) !== undefined) return (locale.messages[code_validate] ? locale.messages[code_validate].replaceAll("%field%", field) : "")
    else return ""
}

export const getMessageStringLengthValidate = (lang = { locale: "en" }, field = "", length, code_validate = "valid.field.required") => {
    let locale
    if ((locale = AppLocale[lang.locale]) !== undefined) return (locale.messages[code_validate] ? locale.messages[code_validate].replaceAll("%field%", field).replaceAll("%number_characters%", length) : "")
    else return ""
}

export const MessageAppSwitch = ({ alertMessageApp, typeMessageApp, timeShowMessageApp = 1 }) => {
    switch (typeMessageApp) {
        case TYPE_MESSAGE_SUCCESS:
            message.success(alertMessageApp.toString(), timeShowMessageApp)
            break;
        case TYPE_MESSAGE_ERROR:
            message.error(alertMessageApp.toString(), timeShowMessageApp)
            break;
        case TYPE_MESSAGE_WARNING:
            message.warning(alertMessageApp.toString(), timeShowMessageApp)
            break;
        case TYPE_MESSAGE_INFO:
            message.info(alertMessageApp.toString(), timeShowMessageApp)
            break;
        default:
            message.info('No se encontró mensaje', timeShowMessageApp)
            break;
    }
}

export const MessageAppSwitchV1 = ({ alertMessageAppV1, typeMessageAppV1, timeShowMessageAppV1 = 1 }) => {
    switch (typeMessageAppV1) {
        case TYPE_MESSAGE_SUCCESS:
            message.success(alertMessageAppV1.toString(), timeShowMessageAppV1)
            break;
        case TYPE_MESSAGE_ERROR:
            message.error(alertMessageAppV1.toString(), timeShowMessageAppV1)
            break;
        default:
            message.info('No se encontró mensaje', timeShowMessageAppV1)
            break;
    }
}