import {
  HIDE_MESSAGE_APP,
  SHOW_MESSAGE_APP,
  TYPE_MESSAGE_SUCCESS,
  ON_SHOW_LOADER_APP,
  ON_HIDE_LOADER_APP
} from 'constants/ActionTypes'

const INIT_STATE = {
  loaderApp: false,
  alertMessageApp: '',
  showMessageApp: false,
  typeMessageApp: TYPE_MESSAGE_SUCCESS,
  timeShowMessageApp: 3
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_MESSAGE_APP: {
      return {
        ...state,
        showMessageApp: true,
        loaderApp: false,
        ...action.payload
      }
    }
    case HIDE_MESSAGE_APP: {
      return {
        ...state,
        alertMessageApp: '',
        showMessageApp: false,
        loaderApp: false
      }
    }
    case ON_SHOW_LOADER_APP: {
      return {
        ...state,
        loaderApp: true
      }
    }
    case ON_HIDE_LOADER_APP: {
      return {
        ...state,
        loaderApp: false
      }
    }
    default:
      return state
  }
}