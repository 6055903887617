import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { showMessageDialog, hideLoaderSponsor, fetchSponsorSuccess } from 'appRedux/actions/RegisterSponsors'
import axios from 'axios'
import { config } from "util/Authorization";
import { ADD_SPONSOR, FETCH_SPONSORS, TYPE_MESSAGE_ERROR, TYPE_MESSAGE_SUCCESS, DELETE_SPONSOR, EDIT_SPONSOR } from "constants/ActionTypes"
import { getMessageByString } from 'util/Messages'

const serviceFetchSponsors = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}adm_partner`, config());
    return data;
}

const serviceAddSponsor = async payload => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}adm_partner`, payload.body, config());
    return data;
}

const serviceDeleteSponsor = async payload => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}adm_partner/${payload.id}`, config());
}

const serviceEditSponsor = async payload => {
    return await axios.patch(`${process.env.REACT_APP_API_URL}adm_partner/${payload.id}`, payload.body, config())
}

function* addSponsorRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceAddSponsor, payload)
        const allSponsors = yield call(serviceFetchSponsors)
        yield put(fetchSponsorSuccess(allSponsors))
        yield put(hideLoaderSponsor())
        const message = getMessageByString(
            locale,
            'register.sponsors.message.create'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderSponsor())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* fetchSponsorsRequest({ payload }) {
    const { locale } = payload;
    try {
        const allSponsors = yield call(serviceFetchSponsors)
        yield put(fetchSponsorSuccess(allSponsors))
        yield put(hideLoaderSponsor())
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* deleteSponsorRequest({ payload }) {
    const { locale } = payload;
    try {
        const deleteSponserRes = yield call(serviceDeleteSponsor, payload)
        const allSponsors = yield call(serviceFetchSponsors);
        yield put(fetchSponsorSuccess(allSponsors))
        yield put(hideLoaderSponsor())
        if (deleteSponserRes.status === 270) {
            const message = getMessageByString(
                locale,
                'register.sponsors.message.internalerror.students'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        } else {
            const message = getMessageByString(
                locale,
                'register.sponsors.message.delete'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
        }
    } catch (e) {
        yield put(hideLoaderSponsor())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* editSponsorRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceEditSponsor, payload)
        const allSponsors = yield call(serviceFetchSponsors);
        yield put(fetchSponsorSuccess(allSponsors))
        yield put(hideLoaderSponsor())

        const message = getMessageByString(
            locale,
            'register.sponsors.message.update'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderSponsor())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

export function* addSponsor() {
    yield takeEvery(ADD_SPONSOR, addSponsorRequest)
}

export function* fetchSponsors() {
    yield takeEvery(FETCH_SPONSORS, fetchSponsorsRequest)
}

export function* deleteSponsor() {
    yield takeEvery(DELETE_SPONSOR, deleteSponsorRequest)
}

export function* editSponsor() {
    yield takeEvery(EDIT_SPONSOR, editSponsorRequest)
}

export default function* rootSaga() {
    yield all([
        fork(addSponsor),
        fork(fetchSponsors),
        fork(deleteSponsor),
        fork(editSponsor)
    ])
}