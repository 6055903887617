import {
    SHOW_LOADER_SUPPLEMENTARY_INFO,
    HIDE_LOADER_SUPPLEMENTARY_INFO,
    SHOW_MESSAGE_DIALOG_SUPPLEMENTARY_INFO,
    HIDE_MESSAGE_DIALOG_SUPPLEMENTARY_INFO,
    CREATE_SUPPLEMENTARY_INFO,
    ON_CHANGE_BACKGROUND_DATA,
    ON_CHANGE_BACKGROUND_DATA_ALL,
    ON_CHANGE_AVAILABILITY_RESOURCES_TIME,
    ON_CHANGE_AVAILABILITY_RESOURCES_TIME_ALL,
    ON_CHANGE_FAMILY_INFORMATION,
    ON_CHANGE_FAMILY_INFORMATION_ALL,
    FETCH_SUPPLEMENTARY_INFO,
    FETCH_SUPPLEMENTARY_INFO_SUCCESS,
    UPDATE_SUPPLEMENTARY_INFO,
    ON_CHANGE_SUPPLEMENTARY_INFO
} from 'constants/ActionTypes'


//Actions generales para los componentes de SupplementaryInfo
export const showLoaderSupplementaryInfo = () => {
    return {
        type: SHOW_LOADER_SUPPLEMENTARY_INFO
    }
}

export const hideLoaderSupplementaryInfo = () => {
    return {
        type: HIDE_LOADER_SUPPLEMENTARY_INFO
    }
}

export const showMessageDialogSupplementaryInfo = configMessage => {
    return {
        type: SHOW_MESSAGE_DIALOG_SUPPLEMENTARY_INFO,
        payload: configMessage
    }
}

export const hideMessageDialogSupplementaryInfo = () => {
    return {
        type: HIDE_MESSAGE_DIALOG_SUPPLEMENTARY_INFO
    }
}

export const createSupplementaryInfo = data => {
    return {
        type: CREATE_SUPPLEMENTARY_INFO,
        payload: data
    }
}

//Actions para BackgroundData
export const onChangeBackgroundData = data => {
    return {
        type: ON_CHANGE_BACKGROUND_DATA,
        payload: data
    }
}

export const onChangeBackgroundDataAll = data => {
    return {
        type: ON_CHANGE_BACKGROUND_DATA_ALL,
        payload: data
    }
}

//Actions para AvailabilityResourcesTime
export const onChangeAvailabilityResourcesTime = data => {
    return {
        type: ON_CHANGE_AVAILABILITY_RESOURCES_TIME,
        payload: data
    }
}

export const onChangeAvailabilityResourcesTimeAll = data => {
    return {
        type: ON_CHANGE_AVAILABILITY_RESOURCES_TIME_ALL,
        payload: data
    }
}

//Actions para FamilyInformation
export const onChangeFamilyInformation = data => {
    return {
        type: ON_CHANGE_FAMILY_INFORMATION,
        payload: data
    }
}

export const onChangeFamilyInformationAll = data => {
    return {
        type: ON_CHANGE_FAMILY_INFORMATION_ALL,
        payload: data
    }
}

export const fetchSupplementaryInfo = (payload) => {
    return {
        type: FETCH_SUPPLEMENTARY_INFO,
        payload: payload
    }
}

export const fetchSupplementaryInfoSuccess = (payload) => {
    return {
        type: FETCH_SUPPLEMENTARY_INFO_SUCCESS,
        payload: payload
    }
}

export const updateSupplementaryInfo = (payload) => {
    return {
        type: UPDATE_SUPPLEMENTARY_INFO,
        payload: payload
    }
}

export const onChangeSupplementaryInfo = (payload) => {
    return {
        type: ON_CHANGE_SUPPLEMENTARY_INFO,
        payload: payload
    }
}