import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getUserDataGithubClassroomSuccess, getIssuesEntregasGithubClassroomSuccess, getRepositoriesGithubClassroomSuccess } from 'appRedux/actions/GitHubClassroom'
import { GET_USER_DATA_GITHUB_CLASSROOM, GET_ISSUES_GITHUB_CLASSROOM, GET_REPOSITORIES_GITHUB_CLASSROOM } from "constants/ActionTypes";
import { config } from "util/Authorization";

const axiosGetUserDataGithubClassroom = async () => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}cv-info-github-accounts`, config())
        return data
    } catch (err) {
        return false
    }
}

const axiosGetRepositoriesGithubClassroom = async (numPag) => {
    try {
        const config = {
            headers: { Authorization: `Token ${process.env.REACT_APP_GITHUB_APIKEY}` }
        };
        const { data } = await axios.get(`https://api.github.com/search/repositories?q=is:private aprendices-geek-entrenamiento- org:academia-geek&per_page=30&page=${numPag}`, config)
        return data
    } catch (err) {
        return false
    }
}

const axiosGetIssuesEntregasGithubClassroom = async (nameIssue) => {
    try {
        const config = {
            headers: {
                Authorization: `Token ${process.env.REACT_APP_GITHUB_APIKEY}`,
                Accept: "application/vnd.github.v3+json"
            }
        };
        const { data } = await axios.get(`https://api.github.com/search/issues?q=org:academia-geek ${nameIssue} in:title type:issue`, config)
        return data
    } catch (err) {
        return false
    }
}

function* getUserDataGithubClassroomRequest({ payload }) {
    try {
        const data = yield call(axiosGetUserDataGithubClassroom, payload)
        if (data !== false) {
            return yield put(getUserDataGithubClassroomSuccess(data))
        }
    } catch (err) {
        return false
    }
}

function* getIssuesEntregasGithubClassroomRequest({ payload }) {
    try {
        const data = yield call(axiosGetIssuesEntregasGithubClassroom, payload)
        if (data !== false) {
            return yield put(getIssuesEntregasGithubClassroomSuccess(data))
        }
    } catch (err) {
        return false
    }
}

function* getRepositoriesGithubClassroomRequest({ payload }) {
    try {
        const data = yield call(axiosGetRepositoriesGithubClassroom, payload)
        if (data !== false) {
            return yield put(getRepositoriesGithubClassroomSuccess(data))
        }
    } catch (err) {
        return false
    }
}


export function* getUserDataGithubClassroom() {
    yield takeEvery(GET_USER_DATA_GITHUB_CLASSROOM, getUserDataGithubClassroomRequest)
}

export function* getIssuesEntregasGithubClassroom() {
    yield takeEvery(GET_ISSUES_GITHUB_CLASSROOM, getIssuesEntregasGithubClassroomRequest)
}

export function* getRepositoriesGithubClassroom() {
    yield takeEvery(GET_REPOSITORIES_GITHUB_CLASSROOM, getRepositoriesGithubClassroomRequest)
}

export default function* rootSaga() {
    yield all([
        fork(getUserDataGithubClassroom),
        fork(getIssuesEntregasGithubClassroom),
        fork(getRepositoriesGithubClassroom),
    ])
}