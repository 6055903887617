import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { HomeOutlined, SolutionOutlined } from '@ant-design/icons'
import { userSignOut } from 'appRedux/actions/Auth'
import IntlMessages from 'util/IntlMessages'
import { NAV_STYLE_NO_HEADER_MINI_SIDEBAR } from 'constants/ThemeSetting'
import { PSYCHOSOCIAL_ADVISOR_ID } from '../../constants/Roles'

const SidebarGeek = () => {
  const SubMenu = Menu.SubMenu
  const MenuItemGroup = Menu.ItemGroup
  const { id_role } = useSelector(({ authorization }) => authorization)

  const dispatch = useDispatch()

  let { navStyle } = useSelector(({ settings }) => settings)

  const getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return 'gx-no-header-submenu-popup'
    }
    return ''
  }

  return (
    <MenuItemGroup
      key='/geek/main'
      className='gx-menu-group'
      title={<IntlMessages id='sidebar.geek' />}
    >
      {id_role !== PSYCHOSOCIAL_ADVISOR_ID ? (
        <>
          <Menu.Item key='/main/dashboard/geek'>
            <Link to='/main/dashboard/geek'>
              <HomeOutlined style={{ fontSize: '20px', marginRight: '10px' }} />
              <span>
                <IntlMessages id='sidebar.geek.home' />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='/curriculum-vitae/basic-data'>
            <Link to='/curriculum-vitae/basic-data'>
              <i className='icon icon-auth-screen' />
              <span>
                <IntlMessages id='sidebar.geek.cv' />
              </span>
            </Link>
          </Menu.Item>
          <SubMenu
            key='scholarships'
            tabIndex='0'
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            title={
              <span>
                <i className='icon icon-diamond' />
                <span>
                  <IntlMessages id='sidebar.geek.scholarships' />
                </span>
              </span>
            }
          >
            <Menu.Item key='available-scholarships'>
              <Link
                to={{
                  pathname: '/scholarship-prospect/all-scholarships'
                }}
              >
                <span>Becas Disponibles</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='applicable-scholarships'>
              <Link
                to={{
                  pathname: '/scholarship-prospect/applicable-scholarships'
                }}
              >
                <span>Aplicar a una</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='applied-scholarships'>
              <Link
                to={{
                  pathname: '/scholarship-prospect/applied-scholarship'
                }}
              >
                <span>Historial de Aplicación a Becas</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key='sponsorships'
            tabIndex='0'
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            title={
              <span>
                <i className='icon icon-revenue-new' />
                <span>
                <IntlMessages id='sidebar.topics.sponsorships.sponsorships' />
                </span>
              </span>
            }
          >
            <Menu.Item key='all-sponsorships'>
              <Link
                to={{
                  pathname: '/sponsorship-prospect/all-sponsorships'
                }}
              >
                <span>Préstamos</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='compare-sponsorships'>
              <Link
                to={{
                  pathname: '/sponsorship-prospect/compare-sponsorships'
                }}
              >
                <span>Comparar opciones de financiación</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='applied-sponsorships'>
              <Link
                to={{
                  pathname: '/sponsorship-prospect/applied-sponsorship'
                }}
              >
                <span>Historial de Aplicación a Préstamos</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key='courses-geek'
            tabIndex='0'
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            title={
              <span>
                <i className='icon icon-tab' />
                <span>
                  <IntlMessages id='sidebar.geek.courses' />
                </span>
              </span>
            }
          >
            <Menu.Item key='/main/dashboard/geek/menu'>
              <Link
                to={{
                  pathname: '/main/dashboard/geek',
                  state: { list_courses: true, activeKey: '1' }
                }}
              >
                <span>
                  Cursos <IntlMessages id='postulation.tab.title.1' />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key='courses-geek-in-progress'>
              <Link
                to={{
                  pathname: '/main/dashboard/geek',
                  state: { list_courses: true, activeKey: '2' }
                }}
              >
                <span>
                  <IntlMessages id='postulation.tab.title.2' />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key='courses-geek-completed'>
              <Link
                to={{
                  pathname: '/main/dashboard/geek',
                  state: { list_courses: true, activeKey: '3' }
                }}
              >
                <span>
                  <IntlMessages id='postulation.tab.title.3' />
                </span>
              </Link>
            </Menu.Item>
            {/* <SubMenu
        key='courses-geek-in-progress'
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        title={
          <span>
            <i className='icon icon-tab' />
            <span>
              <IntlMessages id='postulation.tab.title.2' />
            </span>
          </span>
        }
      >
        {_.map(pPreferences, (item) => (
          item.prospectPrefereces.map(courseList => (
            <Menu.Item key={`/course-info/${courseList.id_course}/course-${courseList.adm_course.course_name.replace(/\s+/g, '-').toLowerCase()}/0`}>
              <Link to={{
                pathname: `/course-info/${courseList.id_course}/course-${courseList.adm_course.course_name.replace(/\s+/g, '-').toLowerCase()}/0`,
                state: { id_pp: courseList.id, adm_course: courseList.adm_course, name: courseList.adm_course.course_name, id: courseList.adm_course.id, id_tab: '2' }
              }}>
                {
                  courseList.id_course === APRENDICES_CURSO_ID ?
                    <RocketOutlined
                      style={{ fontSize: '20px', marginRight: '10px' }}
                    />
                    : courseList.id_course === BACKEND_CURSO_ID ?
                      <BugOutlined
                        style={{ fontSize: '20px', marginRight: '10px' }}
                      />
                      : <CodeOutlined
                        style={{ fontSize: '20px', marginRight: '10px' }}
                      />
                }
                <span>
                  {courseList.adm_course.course_name}
                </span>
              </Link>
            </Menu.Item>
          ))
        ))}
      </SubMenu> */}
            {/* <SubMenu
        key='courses-geek-completed'
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        title={
          <span>
            <i className='icon icon-tab' />
            <span>
              <IntlMessages id='postulation.tab.title.3' />
            </span>
          </span>
        }
      >
        {_.map(pPreferencesCompleted, (item) => (
          item.prospectPrefereces.map(courseList => (
            <Menu.Item key={`/course-info/${courseList.id_course}/course-${courseList.adm_course.course_name.replace(/\s+/g, '-').toLowerCase()}/0/menu`}>
              <Link to={{
                pathname: `/course-info/${courseList.id_course}/course-${courseList.adm_course.course_name.replace(/\s+/g, '-').toLowerCase()}/0`,
                state: { id_pp: courseList.id, adm_course: courseList.adm_course, name: courseList.adm_course.course_name, id: courseList.adm_course.id, id_tab: '2' }
              }}>
                {
                  courseList.id_course === APRENDICES_CURSO_ID ?
                    <RocketOutlined
                      style={{ fontSize: '20px', marginRight: '10px' }}
                    />
                    : courseList.id_course === BACKEND_CURSO_ID ?
                      <BugOutlined
                        style={{ fontSize: '20px', marginRight: '10px' }}
                      />
                      : <CodeOutlined
                        style={{ fontSize: '20px', marginRight: '10px' }}
                      />
                }
                <span>
                  {courseList.adm_course.course_name}
                </span>
              </Link>
            </Menu.Item>
          ))
        ))}
      </SubMenu> */}
          </SubMenu>
        </>
      ) : (
        <SubMenu
          key='scholarships'
          popupClassName={getNavStyleSubMenuClass(navStyle)}
          title={
            <span>
              <SolutionOutlined
                style={{
                  fontSize: '20px',
                  marginRight: '5px',
                  marginTop: '10px'
                }}
              />
              <span>
                <IntlMessages id='sidebar.topics.members.scholarship' />
              </span>
            </span>
          }
        >
          <Menu.Item key='/admin/members/applicants'>
            <Link to='/admin/members/applicants'>
              <i className='icon icon-select' />
              <span>
                <IntlMessages id='sidebar.topics.members.applicants' />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='/admin/members/preScholarshipHolders'>
            <Link to='/admin/members/preScholarshipHolders'>
              <i
                className='icon icon-feedback'
                style={{ fontSize: '18px', marginRight: '10px' }}
              />
              <span>
                <IntlMessages id='sidebar.topics.members.pre_scholarshipHolders' />
              </span>
            </Link>
          </Menu.Item>
        </SubMenu>
      )}

      <Menu.Item key='/signout/geek' onClick={() => dispatch(userSignOut())}>
        <i className='icon icon-signin' />
        <span>
          <IntlMessages id='appModule.logout' />
        </span>
      </Menu.Item>
    </MenuItemGroup>
  )
}

export default SidebarGeek
