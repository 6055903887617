import React from 'react';
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Communications = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('components/admin/communications/Dashboard/index'))} />
        <Route path={`${match.url}/conversations`} component={asyncComponent(() => import('components/admin/communications/Conversations/index'))} />
        <Route path={`${match.url}/channels/:id`} component={asyncComponent(() => import('components/admin/communications/Channels/index'))} />
        <Route path={`${match.url}/templates`} component={asyncComponent(() => import('components/admin/communications/Templates/index'))} />
        <Route path={`${match.url}/create_template_email`} component={asyncComponent(() => import('components/admin/communications/Templates/email/emailCreateTemplate'))} />
        <Route path={`${match.url}/create_template_email_lang`} component={asyncComponent(() => import('components/admin/communications/Templates/email/createTemplateLanguage'))} />
        <Route path={`${match.url}/update_template_email_lang/:id`} component={asyncComponent(() => import('components/admin/communications/Templates/email/updateTemplateLanguage'))} />
        <Route path={`${match.url}/select_params_template/:id`} component={asyncComponent(() => import('components/admin/communications/Templates/sendMessage/selectParams'))} />
    </Switch>
);

export default Communications