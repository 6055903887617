import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";
import { showMessageDialog, hideLoaderPrice, fetchPriceSuccess } from 'appRedux/actions/RegisterPrices'
import {
    ADD_PRICE,
    FETCH_PRICES,
    TYPE_MESSAGE_ERROR,
    TYPE_MESSAGE_SUCCESS,
    //  CHALLENGE_PRICE_LOAD_LIST_FILTER
} from "constants/ActionTypes"

const serviceFetchPrices = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}coursePrice`, config());
    return data;
}

const serviceAddPrice = async payload => {
    const resp = await axios.post(`${process.env.REACT_APP_API_URL}coursePrice`, payload.body, config());
    return resp;
}

// const serviceGetPricesByDate = async ({ filter }) => {
// const { data } = await axios.get(`${process.env.REACT_APP_API_URL}requeriment_fullfiment/prospect/`, { params: { filter: filter } }, config())
// return []
// }

function* fetchPricesRequest({ payload }) {
    const { locale } = payload;
    try {
        const allPrices = yield call(serviceFetchPrices)
        yield put(fetchPriceSuccess(allPrices))
        yield put(hideLoaderPrice())
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* addPriceRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceAddPrice, payload)
        const allPrices = yield call(serviceFetchPrices)
        yield put(fetchPriceSuccess(allPrices))
        yield put(hideLoaderPrice())

        const message = getMessageByString(
            locale,
            'register.prices.message.create'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        const { response } = e;
        if (response.status === 409) {
            yield put(hideLoaderPrice())
            const message = getMessageByString(
                locale,
                'courses.register.price.add.error.date'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
            return false;
        } else {
            yield put(hideLoaderPrice())
            const message = getMessageByString(
                locale,
                'register.courses.message.internalerro'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
            return false;
        }
    }
}

// function* fetchLoadListPricesFilterRequest(payload) {
//     const { locale } = payload;
//     try {
//         // const prices = yield call(serviceGetPricesByDate, payload)
//         // yield put(fetchPriceSuccess(prices))
//         yield put(hideLoaderPrice())
//     } catch (e) {
//         const message = getMessageByString(
//             locale,
//             'app.error.server'
//         )
//         yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
//         return false;
//     }
// }

export function* fetchPrices() {
    yield takeEvery(FETCH_PRICES, fetchPricesRequest)
}

export function* addPrice() {
    yield takeEvery(ADD_PRICE, addPriceRequest)
}

// export function* fetchLoadListPrices() {
// yield takeEvery(CHALLENGE_PRICE_LOAD_LIST_FILTER, fetchLoadListPricesFilterRequest)
// }

export default function* rootSaga() {
    yield all([
        fork(addPrice),
        // fork(fetchLoadListPrices),
        fork(fetchPrices)
    ])
}