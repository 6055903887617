import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  COMPLETE_USER_REGISTRATION,
  RESET_LOGIN_INIT,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  CHANGE_REGISTER_STATE_SUCCESS,
  PING_TOKEN_TEST
} from "constants/ActionTypes";

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};

export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = (dataUserSignIn) => {

  return {
    type: SIGNIN_USER_SUCCESS,
    payload: dataUserSignIn
  }
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};
export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
export const userGoogleSignIn = (user) => {
  return {
    type: SIGNIN_GOOGLE_USER,
    payload: user
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};

export const userFacebookSignIn = (user) => {
  return {
    type: SIGNIN_FACEBOOK_USER,
    payload: user
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userGithubSignIn = (user) => {
  return {
    type: SIGNIN_GITHUB_USER,
    payload: user
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
export const completeUserRegistration = (user) => {
  return {
    type: COMPLETE_USER_REGISTRATION,
    payload: user
  };
};
export const resetLoginInit = () => {
  return {
    type: RESET_LOGIN_INIT,
  };
};
export const createUser = (user) => {
  return {
    type: CREATE_USER,
    user
  }
};
export const createUserSuccess = (user) => {
  return {
    type: CREATE_USER_SUCCESS,
    user
  }
};
export const forgotPassword = (user) => {
  return {
    type: FORGOT_PASSWORD,
    payload: user
  }
};
export const forgotPasswordSuccess = (emailRevocer) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: emailRevocer
  }
};
export const changeRegisterStateSuccess = state => {
  return {
    type: CHANGE_REGISTER_STATE_SUCCESS,
    payload: state
  }
}

export const pingTokenTest = () => {
  return {
    type: PING_TOKEN_TEST
  }
}