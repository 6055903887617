import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import {
  /*TECHNICAL_MENTOR_ID,*/ PSYCHOSOCIAL_ADVISOR_ID,
  COMMUNICATIONS_SUPPORT_ID,
} from "constants/Roles";

const CommunicationsSupportInfo = () => {
  const dispatch = useDispatch();

  const { id_role } = useSelector(({ authorization }) => authorization);
  const { basicData } = useSelector(({ basicData }) => basicData);

  const [visiblePopover, setvisiblePopover] = useState(false);

  const userMenuOptions = () => (
    <ul className="gx-user-popover">
      {id_role === PSYCHOSOCIAL_ADVISOR_ID ? (
        <>
          <li>
            <Link
              to="/admin/members/applicants"
              className="gx-text-dark"
              onClickCapture={() => setvisiblePopover(false)}
            >
              <IntlMessages id="sidebar.topics.members.applicants" />
            </Link>
          </li>
          <li>
            <Link
              to="/admin/members/preScholarshipHolders"
              className="gx-text-dark"
              onClickCapture={() => setvisiblePopover(false)}
            >
              <IntlMessages id="sidebar.topics.members.pre_scholarshipHolders" />
            </Link>
          </li>
        </>
      ) : (
        id_role === COMMUNICATIONS_SUPPORT_ID && (
          <>
            <li>
              <Link
                to="/admin/members/registered"
                className="gx-text-dark"
                onClickCapture={() => setvisiblePopover(false)}
              >
                <IntlMessages id="sidebar.admin.members.registered" />
              </Link>
            </li>
            <li>
              <Link
                to="/admin/members/scholarshipHolders"
                className="gx-text-dark"
                onClickCapture={() => setvisiblePopover(false)}
              >
                <IntlMessages id="sidebar.topics.members.graduates" />
              </Link>
            </li>
            <li>
              <Link
                to="/admin/members/usersStages"
                className="gx-text-dark"
                onClickCapture={() => setvisiblePopover(false)}
              >
                <IntlMessages id="sidebar.admin.members.users.stages" />
              </Link>
            </li>
          </>
        )
      )}
      <li onClick={() => dispatch(userSignOut())}>
        <IntlMessages id="appModule.logout" />
      </li>
    </ul>
  );

  return (
    <Popover
      visible={visiblePopover}
      onVisibleChange={() => setvisiblePopover(!visiblePopover)}
      onClick={() => setvisiblePopover(!visiblePopover)}
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar
        src={
          basicData.url_photo !== undefined &&
          basicData.url_photo !== null &&
          basicData.url_photo !== ""
            ? basicData.url_photo
            : "https://w7.pngwing.com/pngs/592/884/png-transparent-programmer-computer-programming-computer-software-computer-icons-programming-language-avatar.png"
        }
        className="gx-avatar gx-pointer"
        alt=""
      />
    </Popover>
  );
};

export default CommunicationsSupportInfo;
