import {
  GET_USER_ROLE_SUCCESS
} from 'constants/ActionTypes'

const INIT_STATE = {
  id_role: null,
  role_name: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        id_role: action.payload.id_role,
        role_name: action.payload.role_name
      }
    default:
      return state
  }
}