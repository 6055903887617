import {
    GET_BY_ID_USER_CV_SUCCESS,
    PRE_SCHOLARSHIP_EVALUATION_ON_HIDE_LOADER,
    PRE_SCHOLARSHIP_EVALUATION_ON_SHOW_LOADER,
    PRE_SCHOLARSHIP_EVALUATION_ON_SHOW_MESSAGE,
    PRE_SCHOLARSHIP_EVALUATION_ON_HIDE_MESSAGE,
    PATCH_BY_ID_USER_CV_BASIC_DATA_SUCCESS,
    PRE_SCHOLARSHIP_EVALUATION_CHANGE,
} from "constants/ActionTypes";
import { LOCATION_CHANGE } from "react-router-redux";

const INIT_STATE = {
    basicData: {},
    loader: false,
    alert_message: '',
    show_message_app: false,
    type_message: '',
    pre_scholarship_single: {
        coursesScholarship: [],
        scholarship_requirements: [],
        availability_resources: [],
        observation: '',
        observation_confidential: '',
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...INIT_STATE
            }
        case GET_BY_ID_USER_CV_SUCCESS:
            return {
                ...state,
                basicData: action.payload
            }

        case PATCH_BY_ID_USER_CV_BASIC_DATA_SUCCESS:
            return {
                ...state,
                statePatch: action.payload
            }
        case PRE_SCHOLARSHIP_EVALUATION_ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case PRE_SCHOLARSHIP_EVALUATION_ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case PRE_SCHOLARSHIP_EVALUATION_ON_SHOW_MESSAGE: {
            return {
                ...state,
                show_message_app: true,
                alert_message: action.payload.alertMessage,
                type_message: action.payload.typeMessage
            }
        }
        case PRE_SCHOLARSHIP_EVALUATION_ON_HIDE_MESSAGE: {
            return {
                ...state,
                alert_message: '',
                show_message_app: false,
                loader: false
            }
        }
        case PRE_SCHOLARSHIP_EVALUATION_CHANGE: {
            return {
                ...state,
                pre_scholarship_single: {
                    ...state.pre_scholarship_single,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }
        default:
            return state
    }
}