import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { showMessageDialog, hideLoaderProspectEvaluation, fetchRequerimentProspectEvaluationSuccess, fetchFinalProspectEvaluationSuccess } from 'appRedux/actions/AcademicPerformanceReview'
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";
import { ADD_REQUERIMENT_PROSPECT_EVALUATION, FETCH_FINAL_PROSPECT_EVALUATION, FETCH_REQUERIMENT_PROSPECT_EVALUATION, TYPE_MESSAGE_ERROR, TYPE_MESSAGE_SUCCESS, EDIT_REQUERIMENT_PROSPECT_EVALUATION, EDIT_FINAL_PROSPECT_EVALUATION, EDIT_DETAILED_REQUISITE_EVALUATION } from 'constants/ActionTypes'

//Traer la revisión por sprint del prospecto
const serviceFetchRequerimentProspectEvaluation = async (payload) => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}requeriment_prospect_evaluation/by_filters`, { "id_prospect": payload.id_prospect, 'id_course_requeriment': payload.id_course_requeriment }, config());
    return data;
}

//Traer la revisión final del prospecto
const serviceFetchFinalProspectEvaluation = async (id_prospect_preferences) => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}student-cohort/by_filters`, { "id_prospect_student": id_prospect_preferences }, config());
    return data;
}

const serviceAddRequerimentProspectEvaluation = async (payload) => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}requeriment_prospect_evaluation`, payload, config());
    return data;
}

const serviceEditRequerimentProspectEvaluation = async payload => {
    return await axios.patch(`${process.env.REACT_APP_API_URL}requeriment_prospect_evaluation/${payload.id}`, payload.body, config())
}

const serviceEditFinalProspectEvaluation = async payload => {
    const preference = { "id_prospect_preference": payload.id_prospect_preferences, "id_stage": payload.stage, 'observation': '' }

    await axios.post(`${process.env.REACT_APP_API_URL}prospect/stages`, preference, config());
    await axios.patch(`${process.env.REACT_APP_API_URL}student-cohort/${payload.id}`, payload.body, config())
}

const serviceEditDetailedRequisiteEvaluation = async payload => {
    return await axios.patch(`${process.env.REACT_APP_API_URL}requeriment_fullfiment/${payload.id}`, payload.body, config())
}

function* fetchRequerimentProspectEvaluationRequest({ payload }) {
    const { locale } = payload;
    try {
        const requerimentProspectEvaluation = yield call(serviceFetchRequerimentProspectEvaluation, payload)
        yield put(hideLoaderProspectEvaluation())
        yield put(fetchRequerimentProspectEvaluationSuccess(requerimentProspectEvaluation))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* fetchFinalProspectEvaluationRequest({ payload }) {
    const { locale, id_prospect_preferences } = payload;
    try {
        const finalProspectEvaluation = yield call(serviceFetchFinalProspectEvaluation, id_prospect_preferences)
        yield put(hideLoaderProspectEvaluation())
        yield put(fetchFinalProspectEvaluationSuccess(finalProspectEvaluation))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* addRequerimentProspectEvaluationRequest({ payload }) {
    const { locale, body } = payload;
    try {
        yield call(serviceAddRequerimentProspectEvaluation, body)
        yield put(hideLoaderProspectEvaluation())
        const message = getMessageByString(
            locale,
            'requeriment.evaluation.message'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderProspectEvaluation())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* editRequerimentProspectEvaluationRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceEditRequerimentProspectEvaluation, payload)
        yield put(hideLoaderProspectEvaluation())

        const message = getMessageByString(
            locale,
            'requeriment.evaluation.message.update'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderProspectEvaluation())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* editFinalProspectEvaluationRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceEditFinalProspectEvaluation, payload)
        yield put(hideLoaderProspectEvaluation())

        const message = getMessageByString(
            locale,
            'requeriment.evaluation.message.update'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderProspectEvaluation())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* editDetailedRequisiteEvaluationRequest({ payload }) {
    const { locale } = payload;
    try {
        yield call(serviceEditDetailedRequisiteEvaluation, payload)
        yield put(hideLoaderProspectEvaluation())

        const message = getMessageByString(
            locale,
            'requeriment.evaluation.message.update'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        yield put(hideLoaderProspectEvaluation())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

export function* fetchRequerimentProspectEvaluation() {
    yield takeEvery(FETCH_REQUERIMENT_PROSPECT_EVALUATION, fetchRequerimentProspectEvaluationRequest)
}

export function* fetchFinalProspectEvaluation() {
    yield takeEvery(FETCH_FINAL_PROSPECT_EVALUATION, fetchFinalProspectEvaluationRequest)
}

export function* addRequerimentProspectEvaluation() {
    yield takeEvery(ADD_REQUERIMENT_PROSPECT_EVALUATION, addRequerimentProspectEvaluationRequest)
}

export function* editRequerimentProspectEvaluation() {
    yield takeEvery(EDIT_REQUERIMENT_PROSPECT_EVALUATION, editRequerimentProspectEvaluationRequest)
}

export function* editFinalProspectEvaluation() {
    yield takeEvery(EDIT_FINAL_PROSPECT_EVALUATION, editFinalProspectEvaluationRequest)
}

export function* editDetailedRequisiteEvaluation() {
    yield takeEvery(EDIT_DETAILED_REQUISITE_EVALUATION, editDetailedRequisiteEvaluationRequest)
}

export default function* rootSaga() {
    yield all([
        fork(fetchRequerimentProspectEvaluation),
        fork(fetchFinalProspectEvaluation),
        fork(addRequerimentProspectEvaluation),
        fork(editRequerimentProspectEvaluation),
        fork(editFinalProspectEvaluation),
        fork(editDetailedRequisiteEvaluation)
    ])
}