import {
    GET_TEAMS_DEMODAY_SUCCESS,
    GET_PROJECT_DEMODAY_SUCCESS,
    GET_PROJECT_CONTRIBUTORS_DEMODAY_SUCCESS,
    GET_PROJECT_MILESTONE_DEMODAY_SUCCESS,
    HIDE_LOADER_MILESTONE,
    SHOW_LOADER_MILESTONE
} from "constants/ActionTypes";

const INIT_STATE = {
    teams: [],
    project: {},
    contributors: [],
    milestones: [],
    loader: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TEAMS_DEMODAY_SUCCESS:
            return {
                ...state,
                teams: [...state.teams, action.payload],
            }
        case GET_PROJECT_DEMODAY_SUCCESS:
            return {
                ...state,
                project: action.payload
            }
        case GET_PROJECT_CONTRIBUTORS_DEMODAY_SUCCESS:
            return {
                ...state,
                contributors: action.payload
            }

        case GET_PROJECT_MILESTONE_DEMODAY_SUCCESS:
            return {
                ...state,
                milestones: action.payload
            }

        case SHOW_LOADER_MILESTONE:
            return {
                ...state,
                loader: true
            }

        case HIDE_LOADER_MILESTONE:
            return {
                ...state,
                loader: false
            }


        default:
            return state;
    }
};