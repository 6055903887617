import { all, call, fork, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { config } from "util/Authorization";
// import { updateStatus } from 'appRedux/actions/Notifications'

import {
    NOTIFICATIONS_SEND_EMAIL
} from "constants/ActionTypes";

const serviceSendEmail = async (payload) => {
    await axios.post(`${process.env.REACT_APP_API_URL}mail/send-grid`, payload.data, config())
}

function* sendEmailRequest({ payload }) {
    try {
        //yield put(updateStatus('sending'))
        yield call(serviceSendEmail, payload);
        //yield put(updateStatus('sent'))
    } catch (e) {
        //yield put(updateStatus('error'))
    } finally {
        //yield put(updateStatus('no_process'))
    }
}

export function* sendEmail() {
    yield takeEvery(
        NOTIFICATIONS_SEND_EMAIL, sendEmailRequest
    )
}

export default function* rootSaga() {
    yield all([fork(sendEmail)
    ])
}