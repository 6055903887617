import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  firestore,
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
} from "firebase/firebase";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  forgotPasswordSuccess,
  hideAuthLoader,
  userSignOut,
} from "appRedux/actions/Auth";
import { fetchUserSuccess, getByIdUserUserStages } from "appRedux/actions/User";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  completeUserRegistration,
  changeRegisterStateSuccess,
} from "appRedux/actions/Auth";
import { showMessageDialogApp } from "appRedux/actions/App";
import { onNavStyleChange } from "appRedux/actions/Setting";
import { getUserRoleSuccess } from "appRedux/actions/Authorization";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  PING_TOKEN_TEST,
  FORGOT_PASSWORD,
  TYPE_MESSAGE_SUCCESS,
  TYPE_MESSAGE_ERROR,
} from "constants/ActionTypes";
import { USER_ACTIVE_ID } from "constants/MainStages";
import { getMessageByString } from "util/Messages";

const axiosPingTokenTestRequest = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}hello/AcademiaGeek`,
    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
  );
  return data;
};

const axiosCheckMobilePhoneUnique = async (mobile_phone) => {
  const { status } = await axios.get(
    `${process.env.REACT_APP_API_URL}users/check-mobile-phone-unique/${mobile_phone}`
  );
  return status;
};

/*EMAIL AND PASS USER CREATION*/
const createUserWithEmailPasswordRequest = async (
  email,
  password,
  name,
  lastName,
  cellphone
) => {
  let user = await auth
    .createUserWithEmailAndPassword(email, password)
    .then(async (authUser) => {
      await authUser.user.updateProfile({
        displayName: name + " " + lastName,
      });
      return authUser;
    })
    .catch((error) => error);
  return user;
};

// const getUser = async ({ email }) => {
//   try {
//     const { data } = await axios.get(
//       `${process.env.REACT_APP_API_URL}user/${email}` , config()
//     )
//     return data
//   } catch (err) {
//     return err
//   }
// }

const serviceLogin = async (token) => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}login`, {
      token,
    });
    return data;
  } catch (err) {
    return err;
  }
};
/*EMAIL AND PASS USER SIGNIN*/
const signInUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

const signOutRequest = async () =>
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);

/*SIGN IN SOCIAL MEDIA PROVIDERS*/
const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const tokenUserSigninFirebase = async () => await auth.currentUser.getIdToken();

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const forgotPasswordRequest = async (email) =>
  await auth
    .sendPasswordResetEmail(email)
    .then((authUser) => authUser)
    .catch((error) => error);

function* createUserWithEmailPassword({ payload }) {
  //yield put(hideAuthLoader())
  payload.email = payload.email.toLowerCase().split(" ").join("");
  payload.name = payload.name.trim();
  payload.lastName = payload.lastName.trim();
  const {
    email,
    name,
    lastName,
    password,
    cellphone,
    locale,
    currentUser,
    countryCode,
  } = payload;
  try {
    const userExist = yield call(searchUserInFirestoreWithEmail, email);
    const mobilePhoneNoExist = yield call(
      checkMobilePhoneUnique,
      `${countryCode}${cellphone}`
    );

    if (userExist == null && mobilePhoneNoExist) {
      //Poner lo de mobile phone existe
      // Add a new document in collection "users"
      let data = {
        name: name,
        lastName: lastName,
        email: email,
        cellphone: `${countryCode}${cellphone}`,
      };
      let sendEmailConfirmation = false;

      /**
       * Si el usuario se esta regsitrando con un provider (google, github o facebook) la propiedad email de curretUser
       * debe existir, por lo tanto se debe ingresar en la siguiente condición.
       */
      if (currentUser.email) {
        /*ESTE CONDICIONAL SE CUMPLE CON REGISTROS DE GMAIL, FACEBOOK O GITHUB*/
        data.useruid = currentUser.uid;
        data.photoURL = currentUser.photoURL;
        if (password) {
          currentUser.currentUser
            .updatePassword(password)
            .then(() => {})
            .catch((err) => {
              // console.log(err)
            });
        }
      } else {
        /*ESTE CONDICIONAL SE CUMPLE CON REGISTROS DE EMAIL/PASSWORD*/
        const signUpUser = yield call(
          createUserWithEmailPasswordRequest,
          email,
          password,
          name,
          lastName,
          `${countryCode}${cellphone}`
        );
        sendEmailConfirmation = true;

        if (signUpUser.message) {
          yield put(showAuthMessage(signUpUser.message));
        } else {
          data.useruid = signUpUser.user.uid;
        }
      }

      firestore
        .collection("users")
        .add(data)
        .then(() => {
          // console.log('Document successfully written!')
        })
        .catch((error) => {
          // console.error('Error writing document: ', error)
        });

      payload["registerWithProvider"] = !sendEmailConfirmation;
      if (!sendEmailConfirmation && auth.currentUser)
        payload["providerInfo"] = auth.currentUser.providerData;

      /**
       * Se registra usuario en el backend.
       */
      let userRegister = yield call(registerUserAPI, payload);
      let message = "";

      if (sendEmailConfirmation) {
        message = getMessageByString(
          locale,
          "main.signup.successful.registration.mail.cellphone"
        );
      } else {
        message = getMessageByString(
          locale,
          "main.signup.successful.registration.cellphone"
        );
      }

      yield put(fetchUserSuccess(userRegister));
      yield put(userSignUpSuccess({ user: {}, authUser: null }));
      yield put(changeRegisterStateSuccess(true));
      yield put(
        showMessageDialogApp({
          alertMessageApp: message,
          typeMessageApp: TYPE_MESSAGE_SUCCESS,
          timeShowMessageApp: 25,
        })
      );

      let dataCellPhone = yield call(
        sendMessagesValidationCellPhone,
        userRegister
      );
      let dataMail = {};
      if (sendEmailConfirmation) {
        dataMail = yield call(sendMessagesValidationEmail, userRegister);
      }

      if (dataMail.data && dataMail.data.user)
        userRegister = dataMail.data.user;
      else if (dataCellPhone.data && dataCellPhone.data.user)
        userRegister = dataCellPhone.data.user;
      //localStorage.setItem("user_id", userRegister.id);
      //localStorage.setItem("user", JSON.stringify(userRegister));
      yield call(signOutRequest);
    } else {
      let message;
      if (!mobilePhoneNoExist)
        message = getMessageByString(
          locale,
          "main.signup.mobilephoneuserexist"
        );
      else message = getMessageByString(locale, "main.signup.emailuserexist");
      yield put(
        showMessageDialogApp({
          alertMessageApp: message,
          typeMessageApp: TYPE_MESSAGE_ERROR,
        })
      );
    }
  } catch (error) {
    yield put(
      showMessageDialogApp({
        alertMessageApp: error,
        typeMessageApp: TYPE_MESSAGE_ERROR,
      })
    );
  } finally {
    yield put(hideAuthLoader());
  }
}

//¿Por qué recibe el email? :/
const sendMessagesValidationCellPhone = async (userRegister) =>
  axios.post(`${process.env.REACT_APP_API_URL}user/cell-phone-validation`, {
    email: userRegister.email,
  });

const sendMessagesValidationEmail = async (userRegister) =>
  axios.post(`${process.env.REACT_APP_API_URL}user/mail-validation`, {
    email: userRegister.email,
  });

/*REGISTER USER IN DB*/
const registerUserAPI = async (user) => {
  try {
    const info = await axios.post(`${process.env.REACT_APP_API_URL}users`, {
      full_name: user.name,
      last_name: user.lastName,
      email: user.email,
      pass: user.password,
      register_time: null,
      last_entry: null,
      status: USER_ACTIVE_ID,
      parent_id: 0,
      upgrade_time: null,
      mobile_phone: `${user.countryCode}${user.cellphone}`,
      registerWithProvider: user.registerWithProvider,
      providerInfo: user.providerInfo,
      enrollment: true,
      migration_state: user.migration_state,
      birth_date: user.birth_date,
      gender: user.gender,
      physical_disabilities: user.physical_disabilities,
      scolarity: user.scolarity,
      id_address_country: user.id_address_country,
      id_address_city: user.id_address_city,
      availability_activities: user.availability_activities,
    });
    return info.data;
  } catch (err) {
    return err;
  }
};

/*SEARCH USER IN FIREBASE*/
const searchUserInFireBase = async (user) => {
  //aDNc7BATOKf7Go5elHAymyqzLSF3
  const querySnapshot = await firestore
    .collection("users")
    .where("useruid", "==", user.useruid)
    .get();
  return querySnapshot;
};
const searchUserInFirestoreWithEmail2 = async (email) => {
  //aDNc7BATOKf7Go5elHAymyqzLSF3
  // eslint-disable-next-line no-undef

  let searchUser;
  await auth
    .sendPasswordResetEmail(email)
    .then(() => {
      searchUser = null;
    })
    .catch((error) => {
      searchUser = false;
    });
  return searchUser;
  // try {
  //   const querySnapshot = await firestore
  //     .collection("users")
  //     .where("email", "==", email)
  //     .get();

  //   let user = null;
  //   querySnapshot.forEach((doc) => {
  //     user = doc.data();
  //   });
  //   return user;
  // } catch (e) {
  //   return false;
  // }
};

const searchUserInFirestoreWithEmail = async (email) => {
  //aDNc7BATOKf7Go5elHAymyqzLSF3
  // eslint-disable-next-line no-undef

  // let searchUser;
  // await auth
  //   .sendPasswordResetEmail(email)
  //   .then(() => {
  //     searchUser = null;
  //   })
  //   .catch((error) => {
  //     searchUser = false;
  //   });
  // return searchUser;
  try {
    const querySnapshot = await firestore
      .collection("users")
      .where("email", "==", email)
      .get();

    let user = null;
    querySnapshot.forEach((doc) => {
      user = doc.data();
    });
    return user;
  } catch (e) {
    return false;
  }
};

const checkMobilePhoneUnique = async (mobile_phone) => {
  const status = await axiosCheckMobilePhoneUnique(mobile_phone);
  if (status === 200) return true;
  else if (status === 230) return false;
};

const getCurrentUserAuth = async () => await auth.currentUser;

function* signInUserWithGoogle({ payload }) {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);

    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      let existUser = false;
      const r = yield call(searchUserInFireBase, {
        useruid: signUpUser.user.uid,
        email: signUpUser.user.email,
      });

      r.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        existUser = true;
        // user = doc.data()
      });

      if (existUser) {
        const tokenFirebase = yield call(tokenUserSigninFirebase);
        const fetchedUser = yield call(serviceLogin, tokenFirebase);
        yield put(getByIdUserUserStages({ id_user: fetchedUser.id }));
        if (
          fetchedUser.email_verification_info &&
          fetchedUser.email_verification_info.mail_verified === false
        ) {
          let message = getMessageByString(
            payload.locale,
            "app.userAuth.signIn.error.mail_verified"
          );
          yield put(
            showMessageDialogApp({
              alertMessageApp: message,
              typeMessageApp: TYPE_MESSAGE_ERROR,
            })
          );
          yield put(hideAuthLoader());
          yield call(signOutRequest);
        } else if (
          fetchedUser.phone_verification_info &&
          fetchedUser.phone_verification_info.cell_phone_verified === false
        ) {
          let message = getMessageByString(
            payload.locale,
            "app.userAuth.signIn.error.cellphone_verified"
          );
          yield put(
            showMessageDialogApp({
              alertMessageApp: message,
              typeMessageApp: TYPE_MESSAGE_ERROR,
            })
          );
          yield put(hideAuthLoader());
          yield call(signOutRequest);
        } else {
          localStorage.setItem("user_id", fetchedUser.id);
          localStorage.setItem("user", JSON.stringify(fetchedUser));
          localStorage.setItem("token", fetchedUser.accessToken);

          yield put(onNavStyleChange(fetchedUser.nav_style));
          yield put(
            userGoogleSignInSuccess({
              user_id: fetchedUser.id,
              user: fetchedUser,
            })
          );
        }
        //    {authUser: signInUser.user.uid, user: fetchedUser }
        //yield put(userSignInSuccess({authUser: signInUser.user.uid, user: fetchedUser }));
      } else {
        /**
         * Si el usuario no esta registrado en firestore se carga en el estado de REDUX, de manera que una vez se retorne
         * al componente de SIGNIN.js, se redirirecciona al componente de registro SIGNUP.js.
         */
        const currentUser = yield call(getCurrentUserAuth);
        yield put(
          completeUserRegistration({
            ...splitFullName(signUpUser.user.displayName),
            phoneNumber: signUpUser.user.phoneNumber,
            email: signUpUser.user.email,
            photoURL: signUpUser.user.photoURL,
            uid: signUpUser.user.uid,
            currentUser: currentUser,
          })
        );
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

const splitFullName = (name) => {
  if (name !== null && name !== undefined) {
    const splitedName = name.split(" ");

    switch (splitedName.length) {
      case 1:
        return { name: splitedName[0], lastname: "" };
      case 2:
        return { name: splitedName[0], lastname: splitedName[1] };
      case 3:
        return {
          name: splitedName[0],
          lastname: splitedName[1] + " " + splitedName[2],
        };
      case 4:
        return {
          name: splitedName[0] + " " + splitedName[1],
          lastname: splitedName[2] + " " + splitedName[3],
        };
      default:
        return { name: name, lastname: "" };
    }
  } else {
    return { name: "", lastname: "" };
  }
};

function* signInUserWithFacebook({ payload }) {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      let existUser = false;
      const r = yield call(searchUserInFireBase, {
        useruid: signUpUser.user.uid,
        email: signUpUser.user.email,
      });

      r.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        existUser = true;
        // user = doc.data()
      });

      if (existUser) {
        const tokenFirebase = yield call(tokenUserSigninFirebase);
        const fetchedUser = yield call(serviceLogin, tokenFirebase);
        if (
          fetchedUser.email_verification_info &&
          fetchedUser.email_verification_info.mail_verified === false
        ) {
          let message = getMessageByString(
            payload.locale,
            "app.userAuth.signIn.error.mail_verified"
          );
          yield put(
            showMessageDialogApp({
              alertMessageApp: message,
              typeMessageApp: TYPE_MESSAGE_ERROR,
            })
          );
          yield call(signOutRequest);
        } else if (
          fetchedUser.phone_verification_info &&
          fetchedUser.phone_verification_info.cell_phone_verified === false
        ) {
          let message = getMessageByString(
            payload.locale,
            "app.userAuth.signIn.error.cellphone_verified"
          );
          yield put(
            showMessageDialogApp({
              alertMessageApp: message,
              typeMessageApp: TYPE_MESSAGE_ERROR,
            })
          );
          yield call(signOutRequest);
        } else {
          localStorage.setItem("user_id", fetchedUser.id);
          localStorage.setItem("user", JSON.stringify(fetchedUser));
          localStorage.setItem("token", fetchedUser.accessToken);

          yield put(onNavStyleChange(fetchedUser.nav_style));
          yield put(
            userFacebookSignInSuccess({
              user_id: signUpUser.user.uid,
              user: fetchedUser,
            })
          );
        }
      } else {
        const currentUser = yield call(getCurrentUserAuth);
        yield put(
          completeUserRegistration({
            ...splitFullName(signUpUser.user.displayName),
            phoneNumber: signUpUser.user.phoneNumber,
            email: signUpUser.user.email,
            photoURL: signUpUser.user.photoURL,
            uid: signUpUser.user.uid,
            currentUser: currentUser,
          })
        );
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub({ payload }) {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);

    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      let existUser = false;
      const r = yield call(searchUserInFireBase, {
        useruid: signUpUser.user.uid,
        email: signUpUser.user.email,
      });

      r.forEach((doc) => {
        existUser = true;
      });

      if (existUser) {
        const tokenFirebase = yield call(tokenUserSigninFirebase);
        const fetchedUser = yield call(serviceLogin, tokenFirebase);
        if (
          fetchedUser.email_verification_info &&
          fetchedUser.email_verification_info.mail_verified === false
        ) {
          let message = getMessageByString(
            payload.locale,
            "app.userAuth.signIn.error.mail_verified"
          );
          yield put(
            showMessageDialogApp({
              alertMessageApp: message,
              typeMessageApp: TYPE_MESSAGE_ERROR,
            })
          );
          yield put(hideAuthLoader());
          yield call(signOutRequest);
        } else if (
          fetchedUser.phone_verification_info &&
          fetchedUser.phone_verification_info.cell_phone_verified === false
        ) {
          let message = getMessageByString(
            payload.locale,
            "app.userAuth.signIn.error.cellphone_verified"
          );
          yield put(
            showMessageDialogApp({
              alertMessageApp: message,
              typeMessageApp: TYPE_MESSAGE_ERROR,
            })
          );
          yield put(hideAuthLoader());
          yield call(signOutRequest);
        } else {
          localStorage.setItem("user_id", fetchedUser.id);
          localStorage.setItem("user", JSON.stringify(fetchedUser));
          localStorage.setItem("token", fetchedUser.accessToken);
          yield put(onNavStyleChange(fetchedUser.nav_style));
          yield put(
            userGithubSignInSuccess({
              user_id: signUpUser.user.uid,
              user: fetchedUser,
            })
          );
        }
      } else {
        const currentUser = yield call(getCurrentUserAuth);

        yield put(
          completeUserRegistration({
            ...splitFullName(signUpUser.user.displayName),
            phoneNumber: signUpUser.user.phoneNumber,
            email: signUpUser.user.email,
            photoURL: signUpUser.user.photoURL,
            uid: signUpUser.user.uid,
            currentUser: currentUser,
          })
        );
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  payload.email = payload.email.toLowerCase().split(" ").join("");
  const { email, password } = payload;
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );

    if (signInUser.message) {
      yield put(
        showAuthMessage(
          getMessageByString(
            payload.locale,
            signInUser.code,
            signInUser.message
          )
        )
      );
    } else {
      const tokenFirebase = yield call(tokenUserSigninFirebase);
      const fetchedUser = yield call(serviceLogin, tokenFirebase);
      yield put(getByIdUserUserStages({ id_user: fetchedUser.id }));
      if (
        fetchedUser.email_verification_info &&
        fetchedUser.email_verification_info.mail_verified === false
      ) {
        let message = getMessageByString(
          payload.locale,
          "app.userAuth.signIn.error.mail_verified"
        );
        yield put(
          showMessageDialogApp({
            alertMessageApp: message,
            typeMessageApp: TYPE_MESSAGE_ERROR,
          })
        );
        yield put(hideAuthLoader());
        yield call(signOutRequest);
      } else if (
        fetchedUser.phone_verification_info &&
        fetchedUser.phone_verification_info.cell_phone_verified === false
      ) {
        let message = getMessageByString(
          payload.locale,
          "app.userAuth.signIn.error.cellphone_verified"
        );
        yield put(
          showMessageDialogApp({
            alertMessageApp: message,
            typeMessageApp: TYPE_MESSAGE_ERROR,
          })
        );
        yield put(hideAuthLoader());
        yield call(signOutRequest);
      } else {
        localStorage.setItem("user", JSON.stringify(fetchedUser));
        yield put(onNavStyleChange(fetchedUser.nav_style));
        localStorage.setItem("user_id", fetchedUser.id);
        localStorage.setItem("token", fetchedUser.accessToken);

        yield put(
          userSignInSuccess({
            authUser: fetchedUser.id,
            user: fetchedUser,
          })
        );
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      yield put(getUserRoleSuccess({ id_role: null, role_name: null }));
      localStorage.removeItem("user_id");
      localStorage.removeItem("user");
      localStorage.removeItem("nombre");
      localStorage.removeItem("apellido");
      localStorage.removeItem("id_course");
      localStorage.removeItem("token");
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* forgot_Password({ payload }) {
  const email = payload.email.toLowerCase().split(" ").join("");
  try {
    const searchUser = yield call(searchUserInFirestoreWithEmail2, email);
    const userForgotPassword = yield call(forgotPasswordRequest, email);
    if (searchUser === false) {
      if (userForgotPassword.message) {
        yield put(
          showAuthMessage(
            getMessageByString(
              payload.locale,
              userForgotPassword.code,
              userForgotPassword.message
            )
          )
        );
      }
    } else {
      // localStorage.setItem("user", JSON.stringify(searchUser));
      yield put(forgotPasswordSuccess(email));
      yield put(
        showMessageDialogApp({
          alertMessageApp: `Se envió un correo electrónico a ${email} para reestablecer la contraseña`,
          typeMessageApp: TYPE_MESSAGE_SUCCESS,
        })
      );
    }
  } catch (error) {
    yield put(
      showMessageDialogApp({
        alertMessageApp: error,
        typeMessageApp: TYPE_MESSAGE_ERROR,
      })
    );
  }
}

function* pingTokenTestRequest() {
  try {
    yield call(axiosPingTokenTestRequest);
  } catch (e) {
    if (e.request.status === 401 || e.request.status === 400)
      yield put(userSignOut());
    return false;
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* forgotPasswordUser() {
  yield takeEvery(FORGOT_PASSWORD, forgot_Password);
}

export function* pingTokenTest() {
  yield takeEvery(PING_TOKEN_TEST, pingTokenTestRequest);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithGithub),
    fork(signOutUser),
    fork(forgotPasswordUser),
    fork(pingTokenTest),
  ]);
}
