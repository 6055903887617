import React from 'react';
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Mentor = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/students/:cohort_code/:id_user/:id_prospect_student/review`} component={asyncComponent(() => import('components/mentor/AcademicPerformanceReview/index.js'))} />
        <Route path={`${match.url}/students`} component={asyncComponent(() => import('components/mentor/Students/index'))} />
    </Switch>
);
export default Mentor