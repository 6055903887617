import {
  SHOW_LOADER_ACADEMIC_INFO,
  HIDE_LOADER_ACADEMIC_INFO,
  SHOW_MESSAGE_DIALOG_ACADEMIC_INFO,
  HIDE_MESSAGE_DIALOG_ACADEMIC_INFO,
  CREATE_ACADEMIC_INFO,
  EDIT_ACADEMIC_INFO,
  DELETE_ACADEMIC_INFO,
  ON_CHANGE_HIGH_SCHOOL,
  ACTION_TAKEN_HIGH_SCHOOL,
  GET_HIGH_SCHOOL,
  GET_HIGH_SCHOOL_SUCCESS,
  ACADEMIC_INFO_MODAL_DELIVERY_ADD,
  ACADEMIC_INFO_MODAL_DELIVERY_EDIT,
  ON_CHANGE_COLLEGE_EDUCATION,
  RESET_COLLEGE_EDUCATION, 
  GET_COLLEGE_EDUCATION,
  GET_COLLEGE_EDUCATION_SUCCESS,
  SET_COLLEGE_EDUCATION_SINGLE,
  ON_CHANGE_COMPLEMENTARY_COURSES,
  RESET_COMPLEMENTARY_COURSES,
  GET_COMPLEMENTARY_COURSES,
  GET_COMPLEMENTARY_COURSES_SUCCESS,
  SET_COMPLEMENTARY_COURSES_SINGLE,
  GET_EDUCATION_LEVEL,
  GET_EDUCATION_LEVEL_SUCCESS
} from 'constants/ActionTypes'

//Actions generales para los componentes de AcademicInfo
export const showLoaderAcademicInfo = () => {
  return {
    type: SHOW_LOADER_ACADEMIC_INFO
  }
}

export const hideLoaderAcademicInfo = () => {
  return {
    type: HIDE_LOADER_ACADEMIC_INFO
  }
}

export const showMessageDialogAcademicInfo = configMessage => {
  return {
    type: SHOW_MESSAGE_DIALOG_ACADEMIC_INFO,
    payload: configMessage
  }
}

export const hideMessageDialogAcademicInfo = () => {
  return {
    type: HIDE_MESSAGE_DIALOG_ACADEMIC_INFO
  }
}

export const createAcademicInfo = payload => {
  return {
    type: CREATE_ACADEMIC_INFO,
    payload: payload
  }
}

export const editAcademicInfo = payload => {
  return {
    type: EDIT_ACADEMIC_INFO, 
    payload: payload
  }
}

export const deleteAcademicInfo = payload => {
  return {
    type: DELETE_ACADEMIC_INFO, 
    payload: payload
  }
}

export const getEducationLevel = payload => {
  return {
    type: GET_EDUCATION_LEVEL, 
    payload: payload
  }
}

export const getEducationLevelSuccess = payload => {
  return {
    type: GET_EDUCATION_LEVEL_SUCCESS, 
    payload: payload
  }
}
//Actions para HighSchool
export const onChangeHighSchool = data => {
  return {
    type: ON_CHANGE_HIGH_SCHOOL,
    payload: data
  }
}

export const actionTakenHighSchool  = payload => {
  //El payload puede tomar el valor de 'edit' o 'create'
  return {
    type: ACTION_TAKEN_HIGH_SCHOOL, 
    payload: payload
  }
}

export const getHighSchool = payload => {
  return {
    type: GET_HIGH_SCHOOL,
    payload: payload
  }
}

export const getHighSchoolSuccess = payload => {
  return {
    type: GET_HIGH_SCHOOL_SUCCESS, 
    payload: payload
  }
}

//Actions para CollegeEducation y ComplementaryCourses
export const openOrCloseModalAddAcademicInfo = () => {
  return {
    type: ACADEMIC_INFO_MODAL_DELIVERY_ADD
  }
}

export const openOrCloseModalEditAcademicInfo = () => {
  return {
    type: ACADEMIC_INFO_MODAL_DELIVERY_EDIT
  }
}

//Actions para CollegeEducation
export const onChangeCollegeEducation = data => {
  return {
    type: ON_CHANGE_COLLEGE_EDUCATION,
    payload: data
  }
}

export const resetCollegeEducation = () => {
  return {
    type: RESET_COLLEGE_EDUCATION
  }
}

export const getCollegeEducation = payload => {
  return {
    type: GET_COLLEGE_EDUCATION, 
    payload: payload
  }
}

export const getCollegeEducationSuccess = payload => {
  return {
    type: GET_COLLEGE_EDUCATION_SUCCESS, 
    payload: payload
  }
}

export const setCollegeEducatioSingle = payload => {
  return {
    type: SET_COLLEGE_EDUCATION_SINGLE, 
    payload: payload
  }
}

//Actions para ComplementaryCourses
export const onChangeComplemetaryCourses = data => {
  return {
    type: ON_CHANGE_COMPLEMENTARY_COURSES,
    payload: data
  }
}

export const resetComplementaryCourses = () => {
  return {
    type: RESET_COMPLEMENTARY_COURSES
  }
}

export const getComplementaryCourses = payload => {
  return {
    type: GET_COMPLEMENTARY_COURSES, 
    payload: payload
  }
}

export const getComplementaryCoursesSuccess = payload => {
  return {
    type: GET_COMPLEMENTARY_COURSES_SUCCESS, 
    payload: payload
  }
}

export const setComplementaryCoursesSingle = payload => {
  return {
    type: SET_COMPLEMENTARY_COURSES_SINGLE, 
    payload: payload
  }
}

