import { all, call, put, takeEvery, fork } from 'redux-saga/effects'
import axios from 'axios'
import {
    REAPPLY_SCHOLARSHIP,
    FETCH_SCHOLARSHIP_PROSPECT,
    TYPE_MESSAGE_ERROR,
    SCHOLARSHIP_PROSPECT_VINCULATION_STATUS,
    FETCH_SCHOLARSHIP_HOLDERS,
    CREATE_SCHOLARSHIP_HOLDERS,
    UNPOSTULATE_SCHOLARSHIP,
    VALIDATE_SCHOLARSHIP_STAGE
} from "constants/ActionTypes"
import {
    hideLoader,
    showMessageDialog,
    fetchScholarshipProspectSuccess,
    checkVinculationUserWorksSuccess,
    fetchScholarshipHoldersSuccess,
    validateScholarshipStagSuccess
} from 'appRedux/actions/ScholarshipProspect'
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";
import { orderArrayByProperty } from 'util/Ordering'
import { sendEmail } from 'appRedux/actions/Notifications';

const orderTrackinglog = (sh) => {
    for (let i = 0; i < sh.length; i++) {
        let arr = [];
        const tracking = sh[i].adm_tracking_log_scholarship;
        for (let j = 0; j < tracking.length; j++) {
            arr.push({
                id: tracking[j].id,
                id_scholarship_holder: tracking[j].id_scholarship_holder,
                id_stage: tracking[j].id_stage,
                registration_date: tracking[j].registration_date,
                observation: tracking[j].observation,
                main_stage: tracking[j].main_stage,
                observation_confidential: tracking[j].observation_confidential
            })
        }
        sh[i].adm_tracking_log_scholarship = orderArrayByProperty(arr, 'id');
    }
}

const serviceFetchScholarshipProspect = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}scholarships`, config())
    return data;
}

const serviceCheckVinculationUserWorks = async (payload) => {
    const { id_cv_user } = payload;
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}user_cv/${id_cv_user}/work_info/validate_user_works`, config())
    return data;
}

const serviceFetchScholarshipHolders = async (payload) => {
    const { id_cv_user, type_user } = payload;
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}adm_scholarship_holders/${id_cv_user}/${type_user}/all`, config())
    return data;
}

const serviceCreateScholarshipHolders = async (payload) => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}adm_scholarship_holders`, payload, config())
    return data;
}
const serviceCreateTrackingLog = async (payload) => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}adm_tracking_log_scholarship`, payload, config())
    return data;
}

const serviceDeleteScholarshipHolders = async (payload) => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}adm_scholarship_holders/${payload.id_scholarship_holder}`)
}

const serviceValidateScholarshipStage = async (payload) => {
    const { id_cv_user, array_stages } = payload
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}scholarship/validate_status_scholarship/${id_cv_user}`, { array_stages }, config())
    return data;
}

function* fetchScholarshipProspectRequest({ payload }) {
    const { locale } = payload;
    try {
        const scholarshipsProspect = yield call(serviceFetchScholarshipProspect)
        yield put(fetchScholarshipProspectSuccess(scholarshipsProspect))
    } catch (e) {
        yield put(hideLoader())
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* checkVinculationUserWorksRequest({ payload }) {
    const { locale } = payload;
    try {
        const responseCheck = yield call(serviceCheckVinculationUserWorks, payload)
        yield put(checkVinculationUserWorksSuccess(responseCheck))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* fetchScholarshipHoldersRequest({ payload }) {
    const { locale } = payload;
    try {
        const scholarshipsHolders = yield call(serviceFetchScholarshipHolders, payload)
        orderTrackinglog(scholarshipsHolders)
        yield put(fetchScholarshipHoldersSuccess(scholarshipsHolders))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* createScholarshipHoldersRequest({ payload }) {
    const { locale } = payload;
    try {
        const scholarshipHolser = yield call(serviceCreateScholarshipHolders, payload)
        yield call(serviceCreateTrackingLog, { id_scholarship_holder: scholarshipHolser.id_sh, id_stage: 19 })
        yield put(sendEmail(payload))
        const response = yield call(serviceFetchScholarshipHolders, payload)
        yield put(fetchScholarshipHoldersSuccess(response))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* unpostulateScholarshipRequest({ payload }) {
    const { locale, id_main_stage } = payload;
    try {
        if (id_main_stage === 19) {
            yield call(serviceDeleteScholarshipHolders, payload);
        } else {
            yield call(serviceCreateTrackingLog, payload);
        }
        yield put(sendEmail(payload))
        const scholarshipsHolders = yield call(serviceFetchScholarshipHolders, payload)
        orderTrackinglog(scholarshipsHolders)
        yield put(fetchScholarshipHoldersSuccess(scholarshipsHolders))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* reapplyScholarshipRequest({ payload }) {
    const { locale, id_scholarship_holder } = payload;
    try {
        yield call(serviceCreateTrackingLog, { id_scholarship_holder: id_scholarship_holder, id_stage: 19 });
        const scholarshipsHolders = yield call(serviceFetchScholarshipHolders, payload)
        orderTrackinglog(scholarshipsHolders)
        yield put(fetchScholarshipHoldersSuccess(scholarshipsHolders))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* validateScholarshipStageRequest({ payload }) {
    try {
        const data = yield call(serviceValidateScholarshipStage, payload);
        yield put(validateScholarshipStagSuccess(data));
    } catch (err) {
        return false;
    }
}

export function* checkVinculationUserWorks() {
    yield takeEvery(SCHOLARSHIP_PROSPECT_VINCULATION_STATUS, checkVinculationUserWorksRequest)
}

export function* fetchScholarshipProspect() {
    yield takeEvery(FETCH_SCHOLARSHIP_PROSPECT, fetchScholarshipProspectRequest)
}

export function* fetchScholarshipHolders() {
    yield takeEvery(FETCH_SCHOLARSHIP_HOLDERS, fetchScholarshipHoldersRequest)
}

export function* createScholarshipHolders() {
    yield takeEvery(CREATE_SCHOLARSHIP_HOLDERS, createScholarshipHoldersRequest);
}

export function* unpostulateScholarship() {
    yield takeEvery(UNPOSTULATE_SCHOLARSHIP, unpostulateScholarshipRequest)
}

export function* reapplyScholarship() {
    yield takeEvery(REAPPLY_SCHOLARSHIP, reapplyScholarshipRequest);
}

export function* validateScholarshipStage() {
    yield takeEvery(VALIDATE_SCHOLARSHIP_STAGE, validateScholarshipStageRequest);
}

export default function* rootSaga() {
    yield all([
        fork(fetchScholarshipProspect),
        fork(checkVinculationUserWorks),
        fork(fetchScholarshipHolders),
        fork(createScholarshipHolders),
        fork(unpostulateScholarship),
        fork(reapplyScholarship),
        fork(validateScholarshipStage)
    ])
}