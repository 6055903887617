import {
  LANGUAGES_TO_DEVELOP_ON_SHOW_LOADER,
  LANGUAGES_TO_DEVELOP_ON_HIDE_LOADER,
  LANGUAGES_TO_DEVELOP_ON_SHOW_MESSAGE,
  LANGUAGES_TO_DEVELOP_ON_HIDE_MESSAGE,
  LANGUAGES_TO_DEVELOP_MODAL_DELIVERY_ADD,
  LANGUAGES_TO_DEVELOP_MODAL_DELIVERY_EDIT,
  GET_LANGUAGES_TO_DEVELOP_AVAILABLE_SUCCESS,
  CREATE_LANGUAGE_TO_DEVELOP_SUCCESS,
  FETCH_LANGUAGES_TO_DEVELOP_SUCCESS,
  FETCH_LANGUAGES_TO_DEVELOP_BY_ID_SUCCESS,
  EDIT_LANGUAGE_TO_DEVELOP,
  DELETE_LANGUAGES_TO_DEVELOP_SUCCESS
} from 'constants/ActionTypes'

const INIT_STATE = {
  loader: false,
  show_message_app: false,
  alert_message: '',
  type_message: '',
  modal_delivery_add: false,
  modal_delivery_edit: false,
  languagesToDevelop: [],
  language: [],
  languageToEdit: {},
  languangesAvailabe: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LANGUAGES_TO_DEVELOP_ON_SHOW_LOADER:
      return {
        ...state,
        loader: true
      }
    case LANGUAGES_TO_DEVELOP_ON_HIDE_LOADER:
      return {
        ...state,
        loader: false
      }
    case LANGUAGES_TO_DEVELOP_ON_SHOW_MESSAGE:
      return {
        ...state,
        show_message_app: true,
        alert_message: action.payload.alertMessage,
        type_message: action.payload.typeMessage
      }
    case LANGUAGES_TO_DEVELOP_ON_HIDE_MESSAGE:
      return {
        ...state,
        show_message_app: false,
        type_message: '',
        alert_message: '',
      }
    case LANGUAGES_TO_DEVELOP_MODAL_DELIVERY_ADD:
      return {
        ...state,
        modal_delivery_add: !state.modal_delivery_add
      }
    case LANGUAGES_TO_DEVELOP_MODAL_DELIVERY_EDIT:
      return {
        ...state,
        modal_delivery_edit: !state.modal_delivery_edit
      }
    case GET_LANGUAGES_TO_DEVELOP_AVAILABLE_SUCCESS:
      return {
        ...state,
        languangesAvailabe: action.payload
      }
    case CREATE_LANGUAGE_TO_DEVELOP_SUCCESS:
      return {
        ...state,
        language: action.payload
      }
    case FETCH_LANGUAGES_TO_DEVELOP_SUCCESS:
      return {
        ...state,
        languagesToDevelop: action.payload
      }
    case FETCH_LANGUAGES_TO_DEVELOP_BY_ID_SUCCESS:
      return {
        ...state,
        language: action.payload
      }
    case EDIT_LANGUAGE_TO_DEVELOP:
      return {
        ...state,
        languageToEdit: action.payload
      }
    case DELETE_LANGUAGES_TO_DEVELOP_SUCCESS:
      return {
        ...state,
        language: action.payload
      }
    default:
      return state
  }
}