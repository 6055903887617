import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GRID_GET_DATA } from "constants/ActionTypes";
import { loadDataGrid } from "appRedux/actions/Grid";
import axios from "axios";
import { config } from "util/Authorization";
import { pingTokenTest } from "appRedux/actions/Auth";
const getRandomuserParams = (params) => ({
  limit: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const serviceGetDataGrid = async (params) =>
  await axios.get(params.urlGrid, {
    method: "get",
    type: "json",
    params: getRandomuserParams(params),
    headers: config().headers,
  });

function* getDataGridRequest({ payload }) {
  try {
    yield put(pingTokenTest());
    const { pagination } = payload;
    const { data } = yield call(serviceGetDataGrid, payload);
    yield put(
      loadDataGrid({
        loading: false,
        data: data.results,
        pagination: {
          current: data.page,
          ...pagination,
          total: data.records,
        },
        filter: payload.filter,
      })
    );
  } catch (e) {
    return false;
  }
}

export function* getDataGrid() {
  yield takeEvery(GRID_GET_DATA, getDataGridRequest);
}

export default function* rootSaga() {
  yield all([fork(getDataGrid)]);
}
