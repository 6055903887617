import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Layout } from 'antd'
import HorizontalNav from "../HorizontalNav";

const { Header } = Layout

const InsideHeader = () => {

  const { user } = useSelector(({ auth }) => auth);

  return (
    <div
      className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal"
      style={{ backgroundColor: "#d4dce3" }}
    >
      <Header className="gx-header-horizontal-main">
        <div className="gx-container" style={{ padding: "10px 0 10px 0" }}>
          <div className="gx-header-horizontal-main-flex">
            <Link
              to={user.admin ? "/main/dashboard/admin" : "/main/dashboard/geek"}
              className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
            >
              <img
                alt="Logo de Agile Innova"
                src={require("assets/images/w-logo.png").default}
              />
            </Link>
            <Link
              to={user.admin ? "/main/dashboard/admin" : "/main/dashboard/geek"}
              className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
            >
              <img
                alt="Logo de Agile Innova"
                src={require("assets/images/logo.png").default}
              />
            </Link>
            <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
              <HorizontalNav />
            </div>
            <ul className="gx-header-notifications gx-ml-auto">
              <li className="gx-notify gx-notify-search"></li>
              <li className="gx-user-nav">{/* <UserInfo /> */}</li>
            </ul>
          </div>
        </div>
      </Header>
    </div>
  );
}

export default InsideHeader