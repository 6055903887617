import React from 'react';
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const GitHubClassroom = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/challenge_deliveries`} component={asyncComponent(() => import('components/admin/gitHubClassroom/ChallengeDeliveries/index'))} />
        <Route path={`${match.url}/participants`} component={asyncComponent(() => import('components/admin/gitHubClassroom/Participants/index'))} />
    </Switch>
);

export default GitHubClassroom