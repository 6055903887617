import {
  CREATE_PROSPECT_PREFERENCES_SUCCESS,
  FETCH_PROSPECT_PREFERENCES_SUCCESS,
  UPDATE_PROSPECT_PREFERENCES_SUCCESS,
  GET_LAST_PROSPECT_PREFERENCES_SUCCESS,
  GET_APPLICATION_OPPORTUNITIES_BY_USER_SUCCESS,
  GET_INFO_ABOUT_COHORT_BY_COURSE_USER_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
  prospectPreferences: [],
  prospectPreferecesCompleted: [],
  prospectPreferecesOffered: [],
  postulacion_exitosa: false,
  pPreferences: [
    {
      prospectPrefereces: []
    }
  ],
  lastProspectPreferences: {
    adm_prospect_stages: []
  },
  numberOpportunities: 0,
  infoCohortPrerequisiteCourse: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_PROSPECT_PREFERENCES_SUCCESS:
      return {
        ...state,
        prospectPreferences: action.payload,
        postulacion_exitosa: true
      }
    case FETCH_PROSPECT_PREFERENCES_SUCCESS:
      return {
        ...state,
        prospectPreferences: action.payload.inProgress,
        prospectPreferecesCompleted: action.payload.completed,
        prospectPreferecesOffered: action.payload.offered
      }
    case UPDATE_PROSPECT_PREFERENCES_SUCCESS:
      return {
        ...state,
        prospectPreferences: action.payload,
      }
    case GET_LAST_PROSPECT_PREFERENCES_SUCCESS:
      return {
        ...state,
        lastProspectPreferences: action.payload
      }
    case GET_APPLICATION_OPPORTUNITIES_BY_USER_SUCCESS:
      return {
        ...state,
        numberOpportunities: action.payload + 1
      }
    case GET_INFO_ABOUT_COHORT_BY_COURSE_USER_SUCCESS:
      return {
        ...state,
        infoCohortPrerequisiteCourse: action.payload
      }
    default:
      return state;
  }
}