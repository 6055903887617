import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { config } from "util/Authorization";
import { UPDATE_EVIDENCE } from 'constants/ActionTypes';
import { UpdateEvidenceSuccess } from 'appRedux/actions/Evidences';
import { fetchError } from "appRedux/actions/Common";

const updateEvidence = async (evidence) => {
  const info = { ...evidence }; delete info.id;
  try {
    const { data } = await axios.put(`${process.env.REACT_APP_API_URL}comment/${evidence.id}`, info, config());
    return data;
  } catch (err) {
    return err;
  }
};

function* updateEvidenceRequest({ evidenceList }) {
  try {
    const updatedEvidence = yield call(updateEvidence, evidenceList);
    yield put(UpdateEvidenceSuccess(updatedEvidence));
  } catch (error) {
    yield put(fetchError(error));
  }
};

export function* fetchUpdateEvidence() {
  yield takeEvery(UPDATE_EVIDENCE, updateEvidenceRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchUpdateEvidence)
  ]);
}