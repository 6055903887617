import {
  HIDE_MESSAGE_APP_v1,
  SHOW_MESSAGE_APP_V1,
  TYPE_MESSAGE_SUCCESS
} from 'constants/ActionTypes'

const INIT_STATE = {
  alertMessageAppV1: '',
  showMessageAppV1: false,
  loaderAppV1: false,
  timeShowMessageAppV1: 3,
  typeMessageAppV1: TYPE_MESSAGE_SUCCESS
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case HIDE_MESSAGE_APP_v1: {
      return {
        ...state,
        alertMessageAppV1: '',
        showMessageAppV1: false,
        loaderAppV1: false
      }
    }
    case SHOW_MESSAGE_APP_V1: {
      return {
        ...state,
        showMessageAppV1: true,
        loaderAppV1: false,
        ...action.payload
      }
    }
    default:
      return state
  }
}