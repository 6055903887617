import {
    NOTIFICATIONS_SEND_EMAIL_UPDATE_STATUS
} from 'constants/ActionTypes'

const INIT_STATE = {
    // sending, sent, error, no_process
    statusSendEmail: 'no_process'
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case NOTIFICATIONS_SEND_EMAIL_UPDATE_STATUS:
            return {
                ...state,
                statusSendEmail: action.payload
            }
        default:
            return state
    }
}