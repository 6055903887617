import {
  GRID_ON_SHOW_LOADER,
  GRID_ON_HIDE_LOADER,
  GRID_LOAD_DATA,
  GRID_GET_DATA
} from 'constants/ActionTypes';
import { LOCATION_CHANGE } from 'react-router-redux'
const INIT_STATE = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 10
  },
  sortOrder: 'descend',
  sortField: '',
  loading: false,
  urlGrid: '',
  filter: {},
  columns: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        data: [],
        pagination: {
          current: 1,
          pageSize: 10
        },
        sortOrder: 'descend',
        sortField: '',
        loading: false,
        urlGrid: '',
        filter: {},
        columns: []
      }

    case GRID_ON_HIDE_LOADER: {
      return {
        ...state,
        loading: false
      }
    }
    case GRID_ON_SHOW_LOADER: {
      return {
        ...state,
        loading: true
      }
    }
    case GRID_GET_DATA: {
      return {
        ...state,
        loading: true
      }
    }
    case GRID_LOAD_DATA: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}