import {
    FETCH_SPONSORSHIP_PROSPECT_SUCCESS,
    SPONSORSHIP_PROSPECT_MODAL,
    SPONSORSHIP_PROSPECT_MODAL_DATA,
    FETCH_VALID_USER_SPONSORSHIPS_SUCCESS,
    FETCH_SPONSORSHIP_APPLICATION_BY_USER_SUCCESS,
    FETCH_SPONSORSHIP_VALIDATION_COHORT_SCHOLARSHIP_SUCCESS,
    FETCH_SPONSORSHIP_HISTORY_SUCCESS,
    UNPOSTULATE_SPONSORSHIP_SUCCESS,
    SPONSORSHIP_EVALUATION_CHANGE,
    SPONSORSHIP_PROSPECT_ON_SHOW_LOADER,
    SPONSORSHIP_PROSPECT_ON_HIDE_LOADER,
    SPONSORSHIP_PROSPECT_ON_HIDE_MESSAGE,
    SPONSORSHIP_PROSPECT_ON_SHOW_MESSAGE
} from 'constants/ActionTypes'

const INIT_STATE = {
    loader: false,
    alert_message: '',
    show_message_app: false,
    type_message: '',
    sponsorship: {},
    modal_open_close: false,
    modal_data: {},
    allValidUserSponsorships: [],
    allSponsorshipApplication: [],
    allValidCohortScholarship: [],
    sponsorshipHistory: [],
    statusUnpostulate: null,
    sponsorship_single: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_SPONSORSHIP_PROSPECT_SUCCESS: {
            return {
                ...state,
                sponsorship: action.payload
            }
        }
        case FETCH_VALID_USER_SPONSORSHIPS_SUCCESS: {
            return {
                ...state,
                allValidUserSponsorships: action.payload
            }
        }
        case FETCH_SPONSORSHIP_VALIDATION_COHORT_SCHOLARSHIP_SUCCESS: {
            return {
                ...state,
                allValidCohortScholarship: action.payload
            }
        }
        case FETCH_SPONSORSHIP_APPLICATION_BY_USER_SUCCESS: {
            return {
                ...state,
                allSponsorshipApplication: action.payload
            }
        }
        case FETCH_SPONSORSHIP_HISTORY_SUCCESS: {
            return {
                ...state,
                sponsorshipHistory: action.payload
            }
        }
        case SPONSORSHIP_PROSPECT_MODAL: {
            return {
                ...state,
                modal_open_close: !state.modal_open_close
            }
        }
        case SPONSORSHIP_PROSPECT_MODAL_DATA: {
            return {
                ...state,
                modal_data: action.payload
            }
        }
        case UNPOSTULATE_SPONSORSHIP_SUCCESS: {
            return {
                ...state,
                statusUnpostulate: action.payload
            }
        }
        case SPONSORSHIP_PROSPECT_ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case SPONSORSHIP_PROSPECT_ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case SPONSORSHIP_PROSPECT_ON_SHOW_MESSAGE: {
            return {
                ...state,
                show_message_app: true,
                alert_message: action.payload.alertMessage,
                type_message: action.payload.typeMessage
            }
        }
        case SPONSORSHIP_PROSPECT_ON_HIDE_MESSAGE: {
            return {
                ...state,
                alert_message: '',
                show_message_app: false,
                loader: false
            }
        }
        case SPONSORSHIP_EVALUATION_CHANGE: {
            return {
                ...state,
                sponsorship_single: {
                    ...state.sponsorship_single,
                    [action.payload.fieldName]: action.payload.value
                }
            }
        }
        default:
            return state
    }
}