import React from 'react'
import { useSelector } from 'react-redux'
import { Menu } from 'antd'
// import { NAV_STYLE_ABOVE_HEADER, NAV_STYLE_BELOW_HEADER, NAV_STYLE_DEFAULT_HORIZONTAL, NAV_STYLE_INSIDE_HEADER_HORIZONTAL } from 'constants/ThemeSetting'
import { PROSPECT_STUDENT_ID } from 'constants/Roles'

const HorizontalNav = () => {
  const { pathname } = useSelector(({ common }) => common)
  const { id_role } = useSelector(({ authorization }) => authorization);


  // const getNavStyleSubMenuClass = navStyle => {
  //   switch (navStyle) {
  //     case NAV_STYLE_DEFAULT_HORIZONTAL:
  //       return 'gx-menu-horizontal gx-submenu-popup-curve '
  //     case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
  //       return 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve'
  //     case NAV_STYLE_BELOW_HEADER:
  //       return 'gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve'
  //     case NAV_STYLE_ABOVE_HEADER:
  //       return 'gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve'
  //     default:
  //       return 'gx-menu-horizontal'
  //   }
  // }

  const selectedKeys = pathname.substr(1)
  const defaultOpenKeys = selectedKeys.split('/')[1]
  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode='horizontal'
    >
      {id_role !== PROSPECT_STUDENT_ID && (
        <>
          {/* <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key='custom-views'
            title={
              <span>
                <i className='icon icon-user' />
                <IntlMessages id='sidebar.topics.members' />
              </span>
            }
          >
            <Menu.Item key='main/dashboard/crypto'>
              <Link to='/admin/members/prospects'>
                <i className='icon icon-files' />
                <span>
                  <IntlMessages id='sidebar.topics.members.prospects' />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key='/admin/members/registered'>
              <Link to='/admin/members/registered'>
                <i className='icon icon-profile2' />
                <span>
                  <IntlMessages id='sidebar.admin.members.registered' />
                </span>
              </Link>
            </Menu.Item>
          </SubMenu> */}
          {/* <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key='custom-views-2'
            title={
              <span>
                <i className='icon icon-user' />
                <IntlMessages id='sidebar.topics.sponsorships' />
              </span>
            }
          >
            <Menu.Item key='/admin/scholarships/register_sponsors'>
              <Link to='/admin/scholarships/register_sponsors'>
                <span>
                 <IntlMessages id="sidebar.topics.sponsorships.sponsors"/>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key='/admin/scholarships/register_scholarships'>
              <Link to='/admin/scholarships/register_scholarships'>
                <span>
                 <IntlMessages id="sidebar.topics.sponsorships.scholarships"/>
                </span>
              </Link>
            </Menu.Item>
          </SubMenu> */}
          {/* <SubMenu
            popupClassName={getNavStyleSubMenuClass(navStyle)}
            key='custom-views'
            title={
              <span>
              <i className='icon icon-signin gx-mr-1' />
                <IntlMessages id='appModule.logout' />
              </span>
            }
          >
          </SubMenu> */}
        </>
      )}
    </Menu>
  )
}

HorizontalNav.propTypes = {}

export default HorizontalNav