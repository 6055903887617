import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Divider, Progress, Row, Spin, Avatar, Typography } from 'antd';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { getProjectContributors, getProjectDemoDay, getProjectMilestone } from 'appRedux/actions/DemodayModule';

const CorhortDetails = () => {

    const dispatch = useDispatch();

    const { Meta } = Card;
    const { Text, Link } = Typography;

    const { project, contributors, milestones, loader } = useSelector(({ demoDayModule }) => demoDayModule);

    const [result, setResult] = useState();

    const dataMilestone = useCallback(() => {
        const cuadrants = milestones?.map(ppi => {
            return (ppi.open_issues + ppi.closed_issues) === 0
                ? 0
                : parseInt((ppi.closed_issues * 100) / (ppi.open_issues + ppi.closed_issues))
        });
        const sum = cuadrants?.reduce(function (a, b) {
            return a + b
        }, 0);
        setResult(((sum * 100) / 200).toFixed(1))
    }, [milestones])

    useEffect(() => {
        let id = sessionStorage.getItem('projectId');
        dispatch(getProjectDemoDay(id));
    }, [dispatch])

    useEffect(() => {
        if (project.owner_url) {
            dispatch(getProjectContributors(project?.owner_url));
            dispatch(getProjectMilestone(project?.owner_url));
        }
    }, [dispatch, project])

    useEffect(() => {
        dataMilestone();
    }, [dataMilestone, milestones])

    return (
        <div style={{ margin: 40 }}>
            {
                loader
                    ?
                    <Spin spinning={loader} style={{ textAlign: "center", }}></Spin>
                    :
                    <Card
                        title={
                            <>
                                <a
                                    href='/enterprise/cohort_details'
                                    style={{ float: 'left', fontSize: 22, cursor: 'pointer' }}
                                >
                                    <div className="icon icon-arrow-left"></div>
                                </a>
                                <h3
                                    className="gx-text-grey"
                                    style={{
                                        marginLeft: 20,
                                        marginBottom: 0,
                                        fontSize: 20,
                                        fontWeight: 400,
                                        display: 'inline-block'
                                    }}
                                >
                                    {project?.name}
                                </h3>
                            </>
                        }
                    >
                        <ReactMarkdown remarkPlugins={[gfm]}>{project?.body}</ReactMarkdown>
                        <div
                            style={{ textAlign: "center", display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            className="site-card-wrapper">
                            <br />
                            <div>
                                <Button type='primary' href={project?.owner_url?.replace("https://api.github.com/repos/", "https://github.com/")} target='_blanck'>VER REPOSITORIO</Button>
                                <Button type='primary' href={project?.owner_url?.replace("https://api.github.com/repos/", "https://github.com/") + "/graphs/contributors"} target='_blanck'>VER ESTADISTICAS</Button>
                            </div>
                            <Divider />
                            <h2 className="ant-typography">Colaboradores</h2>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        {
                                            contributors?.map((item, index) => (
                                                index % 2 === 0 && item.name != null && <Link key={item.id} href={`https://github.com/${item.login}`} target='_blank'>
                                                    <Card style={{ width: 300, marginTop: 16 }}>
                                                        <Meta
                                                            style={{ textAlign: 'start', display: 'flex', alignItems: 'center' }}
                                                            avatar={<Avatar src={item.avatar_url} />}
                                                            title={item.name || item.login}
                                                            description={item.bio}
                                                        />
                                                    </Card>
                                                </Link>
                                            ))
                                        }
                                    </Col>
                                    <Col span={12}>
                                        {
                                            contributors?.map((item, index) => (
                                                index % 2 !== 0 && item.name != null && <Link key={item.id} href={`https://github.com/${item.login}`} target='_blank'>
                                                    <Card style={{ width: 300, marginTop: 16 }}>
                                                        <Meta
                                                            style={{ textAlign: 'start', display: 'flex', alignItems: 'center' }}
                                                            avatar={<Avatar src={item.avatar_url} />}
                                                            title={item.name}
                                                            description={item.bio}
                                                        />
                                                    </Card>
                                                </Link>
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <Divider />
                            <h2 className="ant-typography">Porcentaje de Cumplimiento del Proyecto</h2>
                            <Progress strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                            }}
                                type="circle" percent={result} />
                            <Divider />
                            <h2 className="ant-typography">Cuadrantes</h2>
                            <div style={{ width: 'fit-contend', display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                                {
                                    milestones?.map((ppi, index) => (
                                        <Link key={index} href={ppi.html_url} target='_blank'>
                                            <Card
                                                title={ppi.title}
                                                style={{ width: 300, marginTop: 16 }}
                                            >
                                                <Text strong>Actividades</Text><br />
                                                <Text>{`Pendientes: ${ppi.open_issues}`}</Text><br />
                                                <Text> {`Completas: ${ppi.closed_issues}`}</Text><br />
                                                <Divider />
                                                <Text strong>Porcentaje de Avance</Text><br /><br />
                                                <Progress
                                                    type="circle"
                                                    strokeColor={{
                                                        '0%': '#108ee9',
                                                        '100%': '#87d068',
                                                    }}
                                                    percent={(ppi.open_issues + ppi.closed_issues) === 0 ? 0 : parseInt((ppi.closed_issues * 100) / (ppi.open_issues + ppi.closed_issues))}
                                                    width={80}
                                                />
                                            </Card>
                                        </Link>
                                    ))
                                }
                            </div>
                        </div>
                    </Card>
            }
        </div>
    )
}
export default CorhortDetails