import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { config } from "util/Authorization";
import { GET_SINGLE_COURSE, FETCH_CHALLENGES_AND_DELIVERIES } from 'constants/ActionTypes';
import { fetchSingleCourseSuccess, fetchChallengesAndDeliveriesSuccess } from 'appRedux/actions/SingleCourse';
import { fetchError } from "appRedux/actions/Common";

const serviceGetCourse = async (courseList) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}course/${courseList}`, config());
    return data;
  } catch (err) {
    return err;
  }
}

const serviceFetchChallengesAndDeliveries = async (info) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}course/${info.id_course}/challenges/deliveries/${info.id_user}`, config());
  return data;
}

function* fetchSingleCourseRequest({ courseList }) {
  try {
    const fetchedSingleCourse = yield call(serviceGetCourse, courseList);
    yield put(fetchSingleCourseSuccess(fetchedSingleCourse));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchChallengesAndDeliveriesRequest({ payload }) {
  const { id_course, id_user } = payload;
  try {
    const challengesDeliveries = yield call(serviceFetchChallengesAndDeliveries, { id_course, id_user });
    yield put(fetchChallengesAndDeliveriesSuccess(challengesDeliveries))
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* fetchCourses() {
  yield takeEvery(GET_SINGLE_COURSE, fetchSingleCourseRequest);
}

export function* fetchChallengesAndDeliveries() {
  yield takeEvery(FETCH_CHALLENGES_AND_DELIVERIES, fetchChallengesAndDeliveriesRequest)
}

export default function* rootSaga() {
  yield all([fork(fetchCourses),
  fork(fetchChallengesAndDeliveries)]);
}