import {
  HIDE_MESSAGE_APP_v1,
  SHOW_MESSAGE_APP_V1
} from 'constants/ActionTypes'

export const showMessageDialogAppV1 = configMessage => {
  return {
    type: SHOW_MESSAGE_APP_V1,
    payload: configMessage
  }
}

export const hideMessageAppV1 = () => {
  return {
    type: HIDE_MESSAGE_APP_v1
  }
}