import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import moment from 'moment';
import { showMessageDialog, fetchStudentCandidateCohortSuccess, fetchStudentCohortSuccess, hideLoaderStudentCohort } from '../actions/StudentCohort'
import { FETCH_STUDENT_CANDIDATE_COHORT, SAVE_STUDENT_COHORT, DELETE_STUDENT_COHORT_AFTER_DEADLINE, FETCH_STUDENT_COHORT, TYPE_MESSAGE_ERROR, TYPE_MESSAGE_SUCCESS } from "constants/ActionTypes"
import { PP_SCHOLARSHIP_HOLDER_ID, PP_INSCRIBED, PP_MENTORED_APPRENTICE } from 'constants/MainStages'
import { getMessageByString } from 'util/Messages'
import { config } from "util/Authorization";

//Traer candidatos
const serviceFetchStudentCandidateCohorts = async (filtros_save) => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}student-cohort/candidates`, { filters: filtros_save }, config());
    return data;
}

//Traer miembros de una cohorte especifica
const serviceFetchStudentCohorts = async (id_cohort) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}student-cohort/${id_cohort}`, config());
    return data;
}

//Validar si se puede agregar o desasignar un participante a una cohorte según el tiempo
const serviceCheckPosibilityAddOrDeleteStudents = async (id_course_cohort) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}course-cohort/check_posibility_add_or_delete_students/${id_course_cohort}`, config());
    return data;
}

// Cambiar estado a Aprendiz Mentoreado - Agregar prospect stages
const serviceProspectStages = async ({ array_student }) => {
    array_student.map(async student => {
        const preference = { "id_prospect_preference": student.id_prospect, "id_stage": PP_MENTORED_APPRENTICE, 'observation': '' }
        await axios.post(`${process.env.REACT_APP_API_URL}prospect/stages`, preference, config());
    })
}

// Agregar prospect preference - Agregar Prospect Stage (becario, inscrito, Aprendiz Mentoreado)
const serviceProspectPreference = async ({ array_student, id_course, id_stage }) => {
    const array_student_new = array_student.map(async student => {
        const preference = { "id_course": id_course, "id_cv_user": student.id_cv_user, "state": "en_revision", "id_stage": id_stage === false ? (student.id_sponsorship_status !== null ? PP_INSCRIBED : PP_SCHOLARSHIP_HOLDER_ID) : id_stage }
        const data = await axios.post(`${process.env.REACT_APP_API_URL}prospect/preferences`, preference, config());
        return (data)
    })

    const promesas = Promise.all(array_student_new).then((data) => data).catch(error => error)

    return promesas
}

// Eliminar el ultimo prospect stages o el ultimo prospect preference, eliminar el estudiante de la cohorte
const serviceDeleteProspectStagesProspectPreference = async ({ array_student_borrar }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}student-cohort/unassign_prospect`, array_student_borrar, config());
}

// Eliminar ultimo prospect stages
// const serviceDeleteProspectStages = async ({ array_student_borrar }) => {
//     array_student_borrar.map(async student => {
//         await axios.delete(`${process.env.REACT_APP_API_URL}prospect/stages/${student.id_last_prospect_stage}`, config());
//     })
// }

// Eliminar ultimo prospect preference
// const serviceDeleteProspectPreference = async ({ array_student_borrar }) => {
//     array_student_borrar.map(async student => {
//         await axios.delete(`${process.env.REACT_APP_API_URL}prospect/preferences/${student.id_prospect}`, config());
//     })
// }

//Eliminar participantes de una cohorte
// const serviceDeleteStudentCohort = async ({ array_student_borrar }) => {
//     const array_student_cohort_id = {
//         array_student_cohort_id: array_student_borrar.map(student => student.id)
//     }
//     await axios.delete(`${process.env.REACT_APP_API_URL}student-cohort`, { data: array_student_cohort_id }, config());
// }

//Agregar participantes a una cohorte
const serviceAddStudentCohort = async ({ array_student, id_cohort }) => {
    const array_student_cohort = {
        array_student_cohort: array_student.map(student => {
            return {
                "id_prospect_student": student.id_prospect,
                "id_cohort": Number(id_cohort)
            }
        })
    }
    await axios.post(`${process.env.REACT_APP_API_URL}student-cohort`, array_student_cohort, config());
}

//Eliminar participantes de una cohorte despues de la feche de limite
const serviceDeleteStudentCohortAfterDeadline = async payload => {
    const { array_student_borrar, state, observation } = payload;
    array_student_borrar.map(async student => {
        const preference = { "id_prospect_preference": student.id_prospect, "id_stage": state, 'observation': observation }
        await axios.post(`${process.env.REACT_APP_API_URL}prospect/stages`, preference, config());
    })
}

//Asignar o desasignar participantes a una cohorte
const serviceSaveStudentCohort = async payload => {
    const { array_student, id_course, array_student_borrar, id_cohort, courseBase } = payload;
    // Agregar
    if (array_student.length > 0) {
        //Si es de un curso de aprendices
        if (courseBase === false) {
            //participantes nuevos, les agrega un nuevo prospect stage y nueva cohorte
            let students_add_prospect_stage = array_student.filter(student => student.state_prospect === 'Aprendiz Autónomo')
            if (students_add_prospect_stage.length > 0) {
                serviceProspectStages({ array_student: students_add_prospect_stage })
                serviceAddStudentCohort({ array_student: students_add_prospect_stage, id_cohort })
            }

            //participantes que se postergaron, aplazaron o aprobaron
            let students_add_prospect = array_student.filter(student => student.state_prospect !== 'Aprendiz Autónomo')
            if (students_add_prospect.length > 0) {
                //Stage Aprendiz Mentoreado
                const arrayProspectPreference = await serviceProspectPreference({ array_student: students_add_prospect, id_course, id_stage: PP_MENTORED_APPRENTICE })
                students_add_prospect.forEach((student, index) => {
                    student.id_prospect = arrayProspectPreference[index].data.id
                })
                serviceAddStudentCohort({ array_student: students_add_prospect, id_cohort })
            }
        } else {
            //Si es becario o inscrito
            const arrayProspectPreference = await serviceProspectPreference({ array_student, id_course, id_stage: false })
            array_student.forEach((student, index) => {
                student.id_prospect = arrayProspectPreference[index].data.id
            })
            serviceAddStudentCohort({ array_student, id_cohort })
        }
    }

    // Borrar
    if (array_student_borrar.array_students.length > 0) {
        serviceDeleteProspectStagesProspectPreference({ array_student_borrar })
    }
}

function* deleteStudentCohortAfterDeadlineRequest({ payload }) {
    const { locale, id_cohort } = payload;;
    try {
        yield call(serviceDeleteStudentCohortAfterDeadline, payload)

        const studentCohort = yield call(serviceFetchStudentCohorts, id_cohort)

        //Se agrega la propiedad de key, ya que en los componentes tipo transfer table se necesita una key, esta key será la misma del id_prospect pero convertida a string ya que asi lo pide
        const students = studentCohort.map(student => {
            let students = {
                ...student,
                full_name: `${student.full_name} ${student.last_name}`,
                age: parseInt(moment().diff(student.birth_date, 'years', true)),
                key: student.id_prospect.toString(),
                departament_city: `${student.state_name}/${student.city_name}`
            }
            return students
        })
        students.forEach((studentc) => {
            if (studentc.sponsorship_status === null) {
                studentc.sponsorship_status = 'Sin préstamo'
            }
            if (studentc.scholarship_status === null) {
                studentc.scholarship_status = 'Sin beca'
            }
            if (studentc.state_prospect === null) {
                studentc.state_prospect = 'Sin estado'
            }
            if (studentc.gender === null) {
                studentc.gender = 'Sin genero'
            }
            if (studentc.physical_disabilities === null) {
                studentc.physical_disabilities = 'Sin discapacidad'
            }
        })

        yield put(fetchStudentCohortSuccess(students))
        const message = getMessageByString(
            locale,
            'courses.delete.student.cohort'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* saveStudentCohortRequest({ payload }) {
    const { localeApp, id_cohort, filtros_save } = payload;
    try {
        const { validationResult } = yield call(serviceCheckPosibilityAddOrDeleteStudents, id_cohort);

        if (validationResult) {
            yield call(serviceSaveStudentCohort, payload)

            const studentCohort = yield call(serviceFetchStudentCohorts, id_cohort)
            const studentCandidateCohort = yield call(serviceFetchStudentCandidateCohorts, filtros_save)

            //Se agrega la propiedad de key, ya que en los componentes tipo transfer table se necesita una key, esta key será la misma del id_prospect pero convertida a string ya que asi lo pide
            const students = studentCohort.map(student => {
                let students = {
                    ...student,
                    full_name: `${student.full_name} ${student.last_name}`,
                    age: parseInt(moment().diff(student.birth_date, 'years', true)),
                    key: student.id_prospect.toString(),
                    departament_city: `${student.state_name}/${student.city_name}`
                }
                return students
            })

            students.forEach((studentc) => {
                if (studentc.sponsorship_status === null) {
                    studentc.sponsorship_status = 'Sin préstamo'
                }
                if (studentc.scholarship_status === null) {
                    studentc.scholarship_status = 'Sin beca'
                }
                if (studentc.state_prospect === null) {
                    studentc.state_prospect = 'Sin estado'
                }
                if (studentc.gender === null) {
                    studentc.gender = 'Sin genero'
                }
                if (studentc.physical_disabilities === null) {
                    studentc.physical_disabilities = 'Sin discapacidad'
                }
            })

            studentCandidateCohort.forEach((studentc) => {
                studentc.key = studentc.id_prospect.toString()
                if (studentc.sponsorship_status === null) {
                    studentc.sponsorship_status = 'Sin préstamo'
                }
                if (studentc.scholarship_status === null) {
                    studentc.scholarship_status = 'Sin beca'
                }
                if (studentc.state_prospect === null) {
                    studentc.state_prospect = 'Sin estado'
                }
                if (studentc.gender === null) {
                    studentc.gender = 'Sin genero'
                }
                if (studentc.physical_disabilities === null) {
                    studentc.physical_disabilities = 'Sin discapacidad'
                }
            })

            yield put(fetchStudentCohortSuccess(students))
            yield put(fetchStudentCandidateCohortSuccess(studentCandidateCohort))
            const message = getMessageByString(
                localeApp,
                'courses.register.student.cohort'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_SUCCESS }))
        }
        else {
            const message = getMessageByString(
                localeApp,
                'courses.cohorts.message.save.error'
            )
            yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        }
        yield put(hideLoaderStudentCohort())
    } catch (e) {
        const message = getMessageByString(
            localeApp,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        yield put(hideLoaderStudentCohort())
        return false;
    }
}

function* fetchStudentCandidateCohortsRequest({ payload }) {
    const { locale, filtros_save } = payload;
    try {
        const studentCandidateCohort = yield call(serviceFetchStudentCandidateCohorts, filtros_save)
        //Se agrega la propiedad de key, ya que en los componentes tipo tranfer table se necesita una key, esta key será la misma del id_prospect pero convertida a string ya que asi lo pide
        studentCandidateCohort.forEach((studentc) => {
            studentc.key = studentc.id_prospect.toString()
            if (studentc.sponsorship_status === null) {
                studentc.sponsorship_status = 'Sin préstamo'
            }
            if (studentc.scholarship_status === null) {
                studentc.scholarship_status = 'Sin beca'
            }
            if (studentc.state_prospect === null) {
                studentc.state_prospect = 'Sin estado'
            }
            if (studentc.gender === null) {
                studentc.gender = 'Sin genero'
            }
            if (studentc.physical_disabilities === null) {
                studentc.physical_disabilities = 'Sin discapacidad'
            }
        })
        yield put(hideLoaderStudentCohort())
        yield put(fetchStudentCandidateCohortSuccess(studentCandidateCohort))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

function* fetchStudentCohortsRequest({ payload }) {
    const { locale, id_cohort } = payload;
    try {
        const studentCohort = yield call(serviceFetchStudentCohorts, id_cohort)

        //Se agrega la propiedad de key, ya que en los componentes tipo transfer table se necesita una key, esta key será la misma del id_prospect pero convertida a string ya que asi lo pide

        const students = studentCohort.map(student => {
            let students = {
                ...student,
                full_name: `${student.full_name} ${student.last_name}`,
                age: parseInt(moment().diff(student.birth_date, 'years', true)),
                key: student.id_prospect.toString(),
                departament_city: `${student.state_name}/${student.city_name}`
            }
            return students
        })
        students.forEach((studentc) => {
            if (studentc.sponsorship_status === null) {
                studentc.sponsorship_status = 'Sin préstamo'
            }
            if (studentc.scholarship_status === null) {
                studentc.scholarship_status = 'Sin beca'
            }
            if (studentc.state_prospect === null) {
                studentc.state_prospect = 'Sin estado'
            }
            if (studentc.gender === null) {
                studentc.gender = 'Sin genero'
            }
            if (studentc.physical_disabilities === null) {
                studentc.physical_disabilities = 'Sin discapacidad'
            }
        })
        yield put(hideLoaderStudentCohort())
        yield put(fetchStudentCohortSuccess(students))
    } catch (e) {
        const message = getMessageByString(
            locale,
            'app.error.server'
        )
        yield put(showMessageDialog({ alertMessage: message, typeMessage: TYPE_MESSAGE_ERROR }))
        return false;
    }
}

export function* deleteStudentCohortAfterDeadline() {
    yield takeEvery(DELETE_STUDENT_COHORT_AFTER_DEADLINE, deleteStudentCohortAfterDeadlineRequest)
}

export function* saveStudentCohort() {
    yield takeEvery(SAVE_STUDENT_COHORT, saveStudentCohortRequest)
}

export function* fetchStudentCandidateCohorts() {
    yield takeEvery(FETCH_STUDENT_CANDIDATE_COHORT, fetchStudentCandidateCohortsRequest)
}

export function* fetchStudentCohorts() {
    yield takeEvery(FETCH_STUDENT_COHORT, fetchStudentCohortsRequest)
}

export default function* rootSaga() {
    yield all([
        fork(deleteStudentCohortAfterDeadline),
        fork(saveStudentCohort),
        fork(fetchStudentCandidateCohorts),
        fork(fetchStudentCohorts),
    ])
}