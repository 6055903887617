import { all, call, fork, put, takeEvery } from "redux-saga/effects";

// thid party
import axios from "axios";

// project imports
import { config } from "util/Authorization";
import { GET_LANGS_FETCH } from "../../constants/ActionTypes";
import { getLangsSuccess } from "../actions/Langs";



const serviceGetLanguages = async () => {
    try {
        const {data }= await axios.get(`${process.env.REACT_APP_API_URL}languages`, config());
        
        return data;
    } catch (e) {
        return false;
    }
}

function* getDataLangsRequest() {
    try {
        const languages = yield call(serviceGetLanguages);
        
        if(languages){
            yield put(getLangsSuccess(languages));
        }
    } catch (error) {

    }
}



export function* getDataLangs() {
    yield takeEvery(GET_LANGS_FETCH, getDataLangsRequest)
}

export default function* rootSaga() {
    yield all([
        fork(getDataLangs)
    ])
}