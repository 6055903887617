import {
  LANGUAGES_TO_DEVELOP_ON_SHOW_LOADER,
  LANGUAGES_TO_DEVELOP_ON_HIDE_LOADER,
  LANGUAGES_TO_DEVELOP_ON_SHOW_MESSAGE,
  LANGUAGES_TO_DEVELOP_ON_HIDE_MESSAGE,
  LANGUAGES_TO_DEVELOP_MODAL_DELIVERY_ADD,
  LANGUAGES_TO_DEVELOP_MODAL_DELIVERY_EDIT,
  GET_LANGUAGES_TO_DEVELOP_AVAILABLE,
  GET_LANGUAGES_TO_DEVELOP_AVAILABLE_SUCCESS,
  CREATE_LANGUAGE_TO_DEVELOP,
  CREATE_LANGUAGE_TO_DEVELOP_SUCCESS,
  FETCH_LANGUAGES_TO_DEVELOP_SUCCESS,
  GET_LANGUAGES_TO_DEVELOP,
  FETCH_LANGUAGES_TO_DEVELOP_BY_ID,
  FETCH_LANGUAGES_TO_DEVELOP_BY_ID_SUCCESS,
  EDIT_LANGUAGE_TO_DEVELOP,
  PATCH_LANGUAGE_TO_DEVELOP,
  DELETE_LANGUAGES_TO_DEVELOP,
  DELETE_LANGUAGES_TO_DEVELOP_SUCCESS,
} from 'constants/ActionTypes'

//Muestra el Spinner que indica que algo se está cargando 
export const showLoaderLanguageToDevelop = () => {
  return {
    type: LANGUAGES_TO_DEVELOP_ON_SHOW_LOADER
  }
}

//Oculta el Spinner que indica que algo se está cargando 
export const hideLoaderLanguageToDevelop = () => {
  return {
    type: LANGUAGES_TO_DEVELOP_ON_HIDE_LOADER
  }
}

//Muestra el mensaje de dialogo para notificar al usuario 
export const showMessageDialog = configMessage => {
  return {
    type: LANGUAGES_TO_DEVELOP_ON_SHOW_MESSAGE,
    payload: configMessage
  }
}

//Oculta el mensaje de dialogo para notificar al usuario 
export const hideMessageDialog = () => {
  return {
    type: LANGUAGES_TO_DEVELOP_ON_HIDE_MESSAGE
  }
}

//Activa la acción para cerrar o abrir el modal de crear lenguaje de programación 
export const openOrCloseModalAddLanguageToDevelop = () => {
  return {
    type: LANGUAGES_TO_DEVELOP_MODAL_DELIVERY_ADD
  }
}

//Activa la acción para cerrar o abrir el modal de editar lenguaje de programación
export const ModalEditLanguageToDevelop = () => {
  return {
    type: LANGUAGES_TO_DEVELOP_MODAL_DELIVERY_EDIT
  }
}

//Activa la acción para traer los lenguajes de programación que estan disponibles para ser creados 
export const onGetLanguagesToDevelopAvailable = payload => {
  return {
    type: GET_LANGUAGES_TO_DEVELOP_AVAILABLE,
    payload: payload
  }
}

//Establece la lista de los lenguajes de programación que están disponible para ser creados 
export const onGetLanguagesToDevelopAvailableSuccess = payload => {
  return {
    type: GET_LANGUAGES_TO_DEVELOP_AVAILABLE_SUCCESS,
    payload: payload
  }
}

export const addLanguagesToDevelop = language => {
  return {
    type: CREATE_LANGUAGE_TO_DEVELOP,
    language
  }
}

export const addLanguagesToDevelopSuccess = language => {
  return {
    type: CREATE_LANGUAGE_TO_DEVELOP_SUCCESS,
    payload: language
  }
}

export const fetchListLanguagesToDevelop = languagesToDevelop => {
  return {
    type: FETCH_LANGUAGES_TO_DEVELOP_SUCCESS,
    payload: languagesToDevelop
  }
}

export const onGetLanguagesToDevelop = () => {
  return {
    type: GET_LANGUAGES_TO_DEVELOP
  }
}

export const onGetLanguagesToDevelopById = language => {
  return {
    type: FETCH_LANGUAGES_TO_DEVELOP_BY_ID,
    payload: language
  }
}

export const fetchListLanguagesToDevelopById = language => {
  return {
    type: FETCH_LANGUAGES_TO_DEVELOP_BY_ID_SUCCESS,
    payload: language
  }
}

//Establece los datos del lenguaje de programación seleccionado para editar 
export const editLanguageToDevelop = language => {
  return {
    type: EDIT_LANGUAGE_TO_DEVELOP,
    payload: language
  }
}

//Activa la acción para realizar el patch de los lenguajes de programación en el servidor 
export const patchLanguageToDevelop = language => {
  return {
    type: PATCH_LANGUAGE_TO_DEVELOP,
    payload: language
  }
}

export const deleteLanguageById = language => {
  return {
    type: DELETE_LANGUAGES_TO_DEVELOP,
    language
  }
}

export const deleteLanguageSuccess = language => {
  return {
    type: DELETE_LANGUAGES_TO_DEVELOP_SUCCESS,
    payload: language
  }
}