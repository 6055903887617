import { PSYCHOSOCIAL_ADVISOR_ID } from "../constants/Roles";

/*Función que retorna true si el usuario en sesion es administrador,
si no es administrador retorna false*/
export const checkAdmin = ({ admin }, id_role) => {
  if (admin === 1 || id_role === PSYCHOSOCIAL_ADVISOR_ID) return true;
  else if (admin === 0) return false;
};

/*Retorna la clave para las tabs en curriculum vitae, con base en
si el usuario en sesión es administrador o no */
export const setKeyCurriculumVitae = (
  { admin },
  { params },
  baseKey,
  id_role
) => {
  if (admin === 1 || id_role === PSYCHOSOCIAL_ADVISOR_ID)
    return `${baseKey}/${params.id_user}/${params.id_cv_user}`;
  else if (admin === 0) return `${baseKey}`;
};
