import {
    FETCH_REQUERIMENT_PROSPECT_EVALUATION,
    FETCH_REQUERIMENT_PROSPECT_EVALUATION_SUCCESS,
    FETCH_FINAL_PROSPECT_EVALUATION,
    FETCH_FINAL_PROSPECT_EVALUATION_SUCCESS,
    PROSPECT_EVALUATION_ON_SHOW_MESSAGE,
    PROSPECT_EVALUATION_ON_HIDE_MESSAGE,
    PROSPECT_EVALUATION_ON_SHOW_LOADER,
    PROSPECT_EVALUATION_ON_HIDE_LOADER,
    PROSPECT_REQUERIMENT_EVALUATION_CHANGE,
    PROSPECT_REQUERIMENT_EVALUATION_SINGLE,
    DETAILED_REQUISITE_EVALUATION_CHANGE,
    DETAILED_REQUISITE_EVALUATION_SINGLE,
    EDIT_FINAL_PROSPECT_EVALUATION,
    FINAL_PROSPECT_EVALUATION_CHANGE,
    FINAL_PROSPECT_EVALUATION_SINGLE,
    ADD_REQUERIMENT_PROSPECT_EVALUATION,
    EDIT_REQUERIMENT_PROSPECT_EVALUATION,
    EDIT_DETAILED_REQUISITE_EVALUATION
} from 'constants/ActionTypes'

export const showMessageDialog = configMessage => {
    return {
        type: PROSPECT_EVALUATION_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}

export const hideMessageDialog = () => {
    return {
        type: PROSPECT_EVALUATION_ON_HIDE_MESSAGE
    }
}

export const showLoaderProspectEvaluation = () => {
    return {
        type: PROSPECT_EVALUATION_ON_SHOW_LOADER
    }
}

export const hideLoaderProspectEvaluation = () => {
    return {
        type: PROSPECT_EVALUATION_ON_HIDE_LOADER
    }
}

export const fetchRequerimentProspectEvaluation = (payload) => {
    return {
        type: FETCH_REQUERIMENT_PROSPECT_EVALUATION,
        payload: payload
    }
}

export const fetchRequerimentProspectEvaluationSuccess = (payload) => {
    return {
        type: FETCH_REQUERIMENT_PROSPECT_EVALUATION_SUCCESS,
        payload: payload
    }
}

export const fetchFinalProspectEvaluation = (payload) => {
    return {
        type: FETCH_FINAL_PROSPECT_EVALUATION,
        payload: payload
    }
}

export const fetchFinalProspectEvaluationSuccess = (payload) => {
    return {
        type: FETCH_FINAL_PROSPECT_EVALUATION_SUCCESS,
        payload: payload
    }
}

export const changeRequerimentProspectEvaluation = payload => {
    return {
        type: PROSPECT_REQUERIMENT_EVALUATION_CHANGE,
        payload: payload
    }
}

export const changeFinalProspectEvaluation = payload => {
    return {
        type: FINAL_PROSPECT_EVALUATION_CHANGE,
        payload: payload
    }
}

export const changeDetailedRequisiteEvaluation = payload => {
    return {
        type: DETAILED_REQUISITE_EVALUATION_CHANGE,
        payload: payload
    }
}

export const addRequerimentProspectEvaluation = (payload) => {
    return {
        type: ADD_REQUERIMENT_PROSPECT_EVALUATION,
        payload: payload
    }
}

export const editRequerimentProspectEvaluation = (payload) => {
    return {
        type: EDIT_REQUERIMENT_PROSPECT_EVALUATION,
        payload: payload
    }
}

export const editFinalProspectEvaluation = (payload) => {
    return {
        type: EDIT_FINAL_PROSPECT_EVALUATION,
        payload: payload
    }
}

export const editDetailedRequisiteEvaluation = (payload) => {
    return {
        type: EDIT_DETAILED_REQUISITE_EVALUATION,
        payload: payload
    }
}

export const RequerimentProspectEvaluationSingle = (payload) => {
    return {
        type: PROSPECT_REQUERIMENT_EVALUATION_SINGLE,
        payload: payload
    }
}

export const FinalProspectEvaluationSingle = (payload) => {
    return {
        type: FINAL_PROSPECT_EVALUATION_SINGLE,
        payload: payload
    }
}

export const DetailedRequisiteEvaluationSingle = (payload) => {
    return {
        type: DETAILED_REQUISITE_EVALUATION_SINGLE,
        payload: payload
    }
}