import { CHANGE_TEMPLATE_TEXT, CLEAR_DATA_STATE_MESSAGE, CREATE_TEMPLATE_FETCH, CREATE_TEMPLATE_LANGS_FETCH, CREATE_TEMPLATE_LANGS_SUCCESS, CREATE_TEMPLATE_SUCCESS, DATA_LANG_SELECTED, DATA_LANG_SELECTED_STATIC, DATA_PARAMS_SELECTED_DINAMIC, DATA_TEMPLATE_NEW_LANG, DATA_USERS_TO_SEND_TEMPLATE, DELETE_TEMPLATE_FETCH, DELETE_TEMPLATE_LANGS_FETCH, EDIT_TEMPLATE_FETCH, EDIT_TEMPLATE_LANG_FETCH, GET_ALL_TEMPLATES_FETCH, GET_ALL_TEMPLATES_SUCCESS, SEND_MESSAGE_WITHOUT_TEMPLATE_FETCH, SEND_MESSAGE_WITH_TEMPLATE_FETCH, SET_DATA_NEW_TEMPLATE_LANG, SHOW_HIDE_MODAL_CREATE_NEW_TEMPLATE_LANG_TEXT, TEMPLATES_ON_HIDE_MESSAGE, TEMPLATES_ON_SHOW_MESSAGE } from "../../constants/ActionTypes"

export const createTemplateFetch = payload => {
    return {
        type: CREATE_TEMPLATE_FETCH,
        payload: payload
    }
}

export const createTemplateSuccess = payload => {
    return {
        type: CREATE_TEMPLATE_SUCCESS,
        payload: payload
    }
}

export const createTemplateLangsFetch = payload => {
    return {
        type: CREATE_TEMPLATE_LANGS_FETCH,
        payload: payload
    }
}

export const createTemplateLangsSuccess = payload => {
    return {
        type: CREATE_TEMPLATE_LANGS_SUCCESS,
    }
}

export const getAllTemplatesFetch = () => {
    return {
        type: GET_ALL_TEMPLATES_FETCH
    }
}

export const getAllTemplatesSuccess = payload => {
    return {
        type: GET_ALL_TEMPLATES_SUCCESS,
        payload: payload
    }
}

export const changeTemplate = payload => {
    return {
        type: CHANGE_TEMPLATE_TEXT,
        payload: payload
    }
}

export const deleteTemplateFetch = payload => {
    return {
        type: DELETE_TEMPLATE_FETCH,
        payload: payload
    }
}

export const deleteTemplatesLangsFetch = payload => {
    return {
        type: DELETE_TEMPLATE_LANGS_FETCH,
        payload: payload
    }
}

export const showMessageDialog = configMessage => {
    return {
        type: TEMPLATES_ON_SHOW_MESSAGE,
        payload: configMessage
    }
}

export const onHideMessage = () => {
    return {
        type: TEMPLATES_ON_HIDE_MESSAGE
    }
}

export const showHideModalCreateNewTemplateLang = payload => {
    return {
        type: SHOW_HIDE_MODAL_CREATE_NEW_TEMPLATE_LANG_TEXT,
        payload: payload
    }
}

export const dataTemplateNewLang = payload => {
    return {
        type: DATA_TEMPLATE_NEW_LANG,
        payload: payload
    }
}

export const changeDataTemplateLang = payload => {
    return {
        type: SET_DATA_NEW_TEMPLATE_LANG,
        payload: payload
    }
}

export const editTemplateFetch = payload => {
    return {
        type: EDIT_TEMPLATE_FETCH,
        payload: payload
    }
}

export const editTemplateLangFetch = payload => {
    return {
        type: EDIT_TEMPLATE_LANG_FETCH,
        payload: payload
    }
}

export const dataLangSelected = payload => {
    return {
        type: DATA_LANG_SELECTED,
        payload: payload
    }
}

export const dataParamsSelectedDinamic = payload => {
    return {
        type: DATA_PARAMS_SELECTED_DINAMIC,
        payload: payload
    }
}

export const dataParamsSelectedStaticAction = payload => {
    return {
        type: DATA_LANG_SELECTED_STATIC,
        payload: payload
    }
}

export const dataUsersToSendTemplate = payload => {
    return {
        type: DATA_USERS_TO_SEND_TEMPLATE,
        payload: payload
    }
}

export const sendMessageWithTemplate = payload => {
    return {
        type: SEND_MESSAGE_WITH_TEMPLATE_FETCH,
        payload: payload
    }
}

export const sendMessageWithoutTemplate = payload => {
    return {
        type: SEND_MESSAGE_WITHOUT_TEMPLATE_FETCH,
        payload: payload
    }
}

export const clearDataStateMessage = () => {
    return {
        type: CLEAR_DATA_STATE_MESSAGE
    }
}

